<div class="buttons-toggle-group {{ style }}" [class.disabled]="c.disabled">
  <div class="label">
    <span *ngIf="required === true" class="required-star">*</span>
    <strong>{{ label | transloco }}</strong>
  </div>

  <div class="message" *ngIf="!!message">{{ message | transloco }}</div>

  <span *ngFor="let option of options">
    <button
      class="button mat-button {{ option.style }}"
      [class.selected]="innerValue === option.value"
      mat-button
      [disabled]="(option.disabled !== undefined && option.disabled === true) || c.disabled"
      (click)="onChange($event, option.value)"
    >
      {{ option.label | transloco }}<ng-container *ngIf="option.info"
        ><span style="font-size: 12px">&nbsp;({{ option.info | transloco }})</span></ng-container
      >
    </button>
  </span>
</div>
