import { IAdminSettings } from '@betrail-libs/shared/interfaces/interfaces';
import { EPopUpStatus, IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';

const apiTag = '[Admin API] ';

// => POP-UPS

export class CreateNewPopUp {
  public static readonly type = apiTag + 'Create popup';
  constructor(public newPopup: Partial<IPopUpData>) {}
}

export class LoadAllPopUps {
  public static readonly type = apiTag + 'Load all popups';
}

export class EditPopupData {
  public static readonly type = apiTag + 'Edit popup data';
  constructor(public editedPopup: IPopUpData) {}
}

export class AddViewToPopup {
  public static readonly type = apiTag + 'Add view to popup';
  constructor(public popupId: number) {}
}

export class CreatePopupLog {
  public static readonly type = apiTag + 'Update popup stats';
  constructor(public userId: number, public popupId: number, public linkClicked: boolean, public timeOpen: number) {}
}

export class ChangePopupStatus {
  public static readonly type = apiTag + 'Change popup status';
  constructor(public popupId: number, public newStatus: EPopUpStatus, public oldStatus: EPopUpStatus) {}
}

export class CheckForActivePopUp {
  public static readonly type = apiTag + 'Check for active popup';
}

// => SETTINGS

export class LoadAllSettings {
  public static readonly type = apiTag + 'Load all settings';
}

export class SaveAdminSetting {
  public static readonly type = apiTag + 'Save admin setting';
  constructor(public setting: IAdminSettings) {}
}
