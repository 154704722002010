<event-header [event]="data?.result?.race?.event"> </event-header>
<div class="result_infos" fxLayout="row">
  <div fxFlex="33" class="result_distance" fxLayout="column">
    <span><mat-icon>keyboard_tab</mat-icon></span
    ><span>{{ data?.result?.race?.distance }}km</span><span class="label">distance</span>
  </div>
  <div fxFlex="33" class="result_time" fxLayout="column">
    <span><mat-icon>access_time</mat-icon></span
    ><span>{{ data?.result?.result_seconds | toHms }}</span><span class="label">time</span>
  </div>
  <div fxFlex="33" class="result_position" fxLayout="column">
    <span><mat-icon>person</mat-icon></span
    ><span>{{ data?.result?.position }}</span><span class="label">position</span>
  </div>
</div>
