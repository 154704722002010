import { Component, OnInit, Inject } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { EventService } from '@betrail-libs/trail-data-state';
import { LoadSignupRegistrationWithRunner } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'app-edit-registration-dialog',
  templateUrl: './edit-registration-dialog.component.html',
  styleUrls: ['./edit-registration-dialog.component.scss', '../../common-orga.scss'],
})
export class EditRegistrationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    private store: Store,
  ) {}

  save(runnerForm: UntypedFormGroup) {
    //TODO: formValue to RegistrationValue
    this.eventService
      .updateRegistration(this.data.registration._id, { ...this.data.registration, formData: runnerForm.value })
      .subscribe(() => {
        this.store.dispatch(new LoadSignupRegistrationWithRunner(this.data.registration._id));
        this.dialogRef.close();
      });
  }

  close() {
    this.dialogRef.close();
  }
}
