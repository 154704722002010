<result-form-header [result]="data.result"></result-form-header>
<div class="container">
  <div class="trail">
    <div class="text">
      <p class="question">{{ 'WE_PUBLISH_ONLY_OFFICIAL_RANKINGS' | transloco }}</p>
      <div innerHTML="{{'EDIT_RESULT_EXPLANATION' | transloco}}"></div>

      <button class="button" mat-button (click)="close()">{{ 'UNDERSTOOD' | transloco }}</button>
    </div>
  </div>
</div>
