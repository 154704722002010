import { IClaimedElement } from '@betrail-libs/shared/interfaces/interfaces';
import { RaceData } from './encodage.model';

export class StartRaceProcessing {
  static readonly type = '[Encodage] StartRaceProcessing';
  constructor(public payload: { id: any; data: { action: string; totalSteps: number; currentStep: number } }) {}
}

export class EndRaceProcessing {
  static readonly type = '[Encodage] EndRaceProcessing';
  constructor(public payload: { id: any }) {}
}
export class AllRacesProcessingSync {
  static readonly type = '[Encodage] AllRacesProcessingSync';
  constructor(public payload: { states: any }) {}
}

export class UpdateRaceProcessing {
  static readonly type = '[Encodage] update progress processing';
  constructor(public payload: { raceProcessing: RaceData }) {}
}

// /**
//  * @Deprecated use SubscribeToRaceIds
//  */
// export class SubscribeTo {
//   static readonly type = '[Encodage] subscribeTo';
//   constructor(public payload: number[]) {}
// }

export class SubscribeToRaceIds {
  static readonly type = '[Encodage] subscribeToRaces';
  constructor(public payload: number[]) {}
}

export class LoadRaceProcessings {
  static readonly type = '[Encodage] Load RaceProcessings';

  constructor(public payload: RaceData[]) {}
}

export class LoadEncodingDashboard {
  static readonly type = '[Encodage] Load Encoding Dashboard';
  constructor(public params: { encoded: boolean; range: string; country?: string }) {}
}

export class ClaimOrUnclaimEventEncoding {
  static readonly type = '[Encodage] Claim or Unclaim Event Encoding';
  constructor(public claim: IClaimedElement) {}
}

export class RemoveEventEncoding {
  static readonly type = '[Encodage] Remove Event Encoding';
  constructor(public eventId: number) {}
}
