export const environment = {
  production: true,
  envName: 'Beta',
  betrailUri: 'https://www.betrail.run',
  api: 'https://beta.betrail.run/api_beta/encodage/',
  hmr: false,
  betrail: 'https://www.betrail.run',
  wsPath: '/api_beta/websocket',
  wsServer: 'https://beta.betrail.run',
  nodejsApi: 'https://beta.betrail.run/api_beta',
  signupApi: 'https://beta.betrail.run/api_beta/signupapi',
  mangoURL: 'https://api.mangopay.com',
  mangoClientId: 'betrail',
  mock: false,
  websocket: true,
};
