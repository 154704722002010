import { Component, Input } from '@angular/core';

@Component({
  selector: 'bux-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() userLang: string;

  date = new Date();
}
