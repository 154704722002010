import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'app-show-message-to-organizer-dialog',
  templateUrl: './show-message-to-organizer-dialog.component.html',
  styleUrls: ['./show-message-to-organizer-dialog.component.scss', '../../common-orga.scss'],
})
export class ShowMessageToOrganizerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ShowMessageToOrganizerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
