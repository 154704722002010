import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersService } from './users';
import { IsAdminDirective } from './is-admin.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthStateService } from './auth-state.service';
import { TokenService } from './token.service';
import { IsEncoderDirective } from './is-encoder.directive';
import { HasRunnerDirective } from './has-runner.directive';
import { IsPremiumDirective } from './is-premium.directive';
import { NgxsAuthState } from './ngxs/auth.state';
import { NgxsModule } from '@ngxs/store';
import { IsOrgaDirective } from './is-orga.directive';
import { IsNotPremiumDirective } from './is-not-premium.directive';
import { JwtInterceptor } from './jwt-interceptor';
export { AuthStateService } from './auth-state.service';

export const AUTH_KEY_STORE = 'auth';

// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//   return localStorageSync({ keys: ['user'], rehydrate: true })(reducer);
// }

// const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([NgxsAuthState])],
  exports: [
    IsAdminDirective,
    IsOrgaDirective,
    IsEncoderDirective,
    HasRunnerDirective,
    IsPremiumDirective,
    IsNotPremiumDirective,
  ],
  declarations: [
    IsAdminDirective,
    IsOrgaDirective,
    IsEncoderDirective,
    HasRunnerDirective,
    IsPremiumDirective,
    IsNotPremiumDirective,
  ],
})
export class AuthStateModule {
  static forRoot(): ModuleWithProviders<AuthStateModule> {
    return {
      ngModule: AuthStateModule,
      providers: [
        UsersService,
        TokenService,
        AuthStateService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true,
        },
      ],
    };
  }
}
