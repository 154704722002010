import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { asRoleId } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';
import { TimerFormComponent } from '@betrail/ux/components/forms/timer-form/timer-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { DialogResult } from '../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../common/dialog/dialogResultStatus';
import { LoadTimerById } from '../../../timers-state/timers.action';
import { EditTimerDialogData } from './editTimerDialogData';

@UntilDestroy()
@Component({
  selector: 'app-edit-timer-dialog',
  templateUrl: './edit-timer-dialog.component.html',
  styleUrls: ['./edit-timer-dialog.component.scss'],
})
export class EditTimerDialogComponent implements OnInit {
  timer: ITimer;
  event: IEvent;
  mainForm: UntypedFormGroup;
  user: any;
  loading$ = new BehaviorSubject<boolean>(false);
  formActivated = false;
  datesWarning = false;
  timerFormComponent: TimerFormComponent;

  /* View Childs */
  @ViewChild('timerForm') set contentTimer(content: TimerFormComponent) {
    if (content) {
      this.timerFormComponent = content;
    }
  }

  constructor(
    public eventService: EventService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditTimerDialogComponent>,
    private store: Store,
    private authState: AuthStateService,
    @Inject(MAT_DIALOG_DATA) public data: EditTimerDialogData,
  ) {
    this.timer = data.timer;
    //this.mainForm.addControl('message', new FormControl('', Validators.required));
  }

  ngOnInit() {
    this.authState
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(u => {
        this.user = u;
        if (asRoleId(this.user, 3) || asRoleId(this.user, 8)) {
          this.formActivated = true;
          this.patchValues();
        }
      });
    this.mainForm = this.formBuilder.group({});
  }

  patchValues() {
    setTimeout(() => {
      if (this.timerFormComponent) {
        this.timerFormComponent.patchFormValues(this.timer);
        this.mainForm.addControl('timer', this.timerFormComponent.formGroup);
      }
    });
  }

  goToForm() {
    this.formActivated = true;
    this.patchValues();
  }

  onOkClick() {
    const dialogResult = new DialogResult();
    this.loading$.next(true);

    const data = { ...this.mainForm.value.timer, dont_exist: this.mainForm.value.timer.dont_exist === true ? 1 : 0 };

    this.eventService.updateTimer(this.timer.id, data).subscribe({
      next: r => {
        dialogResult.status = DialogResultStatus.Ok;
        let id;
        if (r) {
          id = (r as any).id;
        } else if (this.timer && this.timer.id !== 'new') {
          id = this.timer.id;
        }
        if (id) {
          this.store.dispatch(new LoadTimerById(id));
        }
        this.loading$.next(false);
        this.dialogRef.close(dialogResult);
      },
      error: err => {
        alert('error : ' + JSON.stringify(err));
        this.loading$.next(false);
      },
    });
  }

  onCancelClick() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  close() {
    this.dialogRef.close();
  }
}
