import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthStateService, UsersService } from '@betrail-libs/auth-state';
import { Runner } from '@betrail-libs/shared/interfaces/runner.model';
import { ALL_COUNTRIES_CODES, DEFAULT_WELCOME_STEPS, REGEX_EMAIL_VALIDATION } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {
  newPasswordButtonDisabled: boolean;
  newPasswordEmail: any;
  passwordToggleText = 'SHOW_PASSWORD';
  passwordToggleIcon = 'visibility_off';
  runnersToCheck: any[];
  newAccountValues: any = { place: '', birthdate: '', gender: '', firstname: '', lastname: '' };
  fullscreen = false;
  displayPassword = false;
  error: string;
  isCreateAccount = false;
  isWelcome = false;
  formType: string = 'waiting';
  countries = ALL_COUNTRIES_CODES;
  welcomeSteps = DEFAULT_WELCOME_STEPS;
  doneSteps: number = 0;
  registrationForm: UntypedFormGroup;
  welcomeForm: UntypedFormGroup;
  welcomeStep: string = 'welcome';
  loginButtonDisabled: boolean = false;
  registerButtonDisabled: boolean;
  saveButtonDisabled: boolean;
  registerText: string = 'CREATE_AN_ACCOUNT';
  loginText: string = 'LOG_IN';
  saveText: string = 'SAVE';
  createdUser: any;
  _runner: any;

  user$ = this.auth.getUser();
  isLoadingUser$ = this.auth.isLoading();

  @ViewChild('pass') passwordInput: ElementRef;
  @Output() action = new EventEmitter();
  @Input()
  set runner(runner: Runner) {
    this.registrationForm = this.fb.group({
      firstname: [runner ? runner.firstname : '', Validators.required],
      lastname: [runner ? runner.lastname : '', Validators.required],
      mail: [undefined, Validators.pattern(REGEX_EMAIL_VALIDATION)],
      pass: [undefined, Validators.required],
      birthdate: ['', Validators.required],
      place: ['', Validators.required],
      gender: [runner ? (runner.gender === 1 ? 'FEMALE' : 'MALE') : '', Validators.required],
      cgu: [false, Validators.required],
      charte: [false, Validators.required],
      newsletter: [true],
    });
    this._runner = runner;
  }

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private auth: AuthStateService,
    private usersService: UsersService,
  ) {}

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe({
      next: u => {
        if (u && u.uid && +u.uid > 0) {
          this.formType = 'loggedIn';
          this.close();
        } else {
          this.error = '';
        }
      },
      error: () => {
        this.error = 'ERROR_PLEASE_TRY_AGAIN';
      },
    });
  }

  getRunner() {
    return this._runner;
  }

  displayPasswordToggle() {
    if (this.displayPassword === false) {
      this.passwordInput.nativeElement.type = 'text';
      this.passwordToggleText = 'HIDE_PASSWORD';
      this.passwordToggleIcon = 'visibility';
      this.displayPassword = true;
    } else {
      this.passwordInput.nativeElement.type = 'password';
      this.passwordToggleText = 'SHOW_PASSWORD';
      this.passwordToggleIcon = 'visibility_off';
      this.displayPassword = false;
    }
  }

  enableButton(type: string) {
    if (type == 'login') {
      this.loginButtonDisabled = false;
      this.loginText = 'LOG_IN';
    } else if (type == 'register') {
      this.registerButtonDisabled = false;
      this.formType = 'register';
      this.registerText = 'CREATE_AN_ACCOUNT';
    } else if (type == 'save') {
      this.saveButtonDisabled = false;
      this.saveText = 'SAVE';
    }
  }

  disableButton(type: string) {
    if (type == 'login') {
      this.loginButtonDisabled = true;
      this.loginText = 'LOGGING_IN';
    } else if (type == 'register') {
      this.registerButtonDisabled = true;
      this.registerText = 'CREATING_YOUR_ACCOUNT';
    } else if (type == 'save') {
      this.saveButtonDisabled = true;
      this.saveText = 'SAVING';
    }
  }

  loginWithUsername(user: string, password: string) {
    this.disableButton('login');
    this.error = undefined;
    this.action.emit({ loginType: 'email', loginData: { user, password } });
  }

  retrievePassword(user: string) {
    this.action.emit({ loginType: 'retrievePassword', loginData: { user } });
  }

  forgottenPassword() {
    this.formType = 'forgottenPassword';
  }

  loginForm() {
    this.formType = 'login';
  }

  registerForm() {
    this.formType = 'register';
  }

  sendNewPassword(email) {
    this.newPasswordButtonDisabled = true;
    this.usersService.sendNewPassword(email).subscribe({
      next: user => {
        if (user['uid'] && user['uid'] > 0) {
          this.newPasswordButtonDisabled = false;
          this.newPasswordEmail = email;
          this.formType = 'newPasswordSent';
        } else {
          this.newPasswordButtonDisabled = false;
          this.error = 'ERROR_PLEASE_TRY_AGAIN';
        }
      },
      error: error => {
        if (error?.error?.text == "TypeError: Cannot read property 'uid' of null") {
          error = 'ERROR_NO_ACCOUNT_FOUND';
        }
        this.newPasswordButtonDisabled = false;
        this.error = error;
      },
    });
  }

  submitRegistration() {
    this.disableButton('register');
    if (this.registrationForm.valid && this._runner && this._runner.id) {
      this.error = undefined;
      this.createNewAccount(this._runner);
    } else if (this.registrationForm.valid) {
      let params: any = {
        title: this.registrationForm.value.lastname + ' ' + this.registrationForm.value.firstname,
      };
      if (this.registrationForm.value.birthdate) {
        params.birthdate = this.registrationForm.value.birthdate ? this.registrationForm.value.birthdate.getTime() : '';
      }
      if (this.registrationForm.value.gender) {
        params.gender = this.registrationForm.value.gender == 'FEMALE' ? 1 : 0;
      }
      this.newAccountValues = this.registrationForm.value;

      this.eventService.searchRunner(params).subscribe(e => {
        if (e.body.match === true) {
          this.runnersToCheck = [e.body.match_runner];
          this.createNewAccount(e.body.match_runner);
        } else {
          this.createNewAccount({ id: 9876543 });
        }
      });
    }
  }

  createNewAccount(runner) {
    this.action.emit({
      loginType: 'register',
      loginData: {
        ...this.registrationForm.value,
        birthdate: this.registrationForm.value.birthdate ? this.registrationForm.value.birthdate.getTime() : undefined,
        runner,
        ruid: runner.id || runner.ruid,
      },
    });
  }

  close() {
    this.dialogRef.close();
  }
}
