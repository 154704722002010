<div class="flex-container" fxLayout="row" fxLayoutAlign="space-between stretch">
  <!-- {{ _defaultDay }} {{ _defaultMonth }} {{ _defaultYear }} -->
  <mat-form-field appearance="fill" fxFlex="25.5" class="example-full-width">
    <mat-label>{{ 'DAY' | transloco }}</mat-label>
    <mat-select
      required
      [placeholder]="'DAY' | transloco"
      [(ngModel)]="day"
      [disabled]="isDisable"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let val of days" [value]="val"> {{ val }} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" fxFlex="35.5" class="example-full-width">
    <mat-label>{{ 'MONTH' | transloco }}</mat-label>
    <mat-select
      required
      [placeholder]="'MONTH' | transloco"
      [(ngModel)]="month"
      [disabled]="isDisable"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let val of months" [value]="val"> {{ getMonthName(val) | date: 'LLLL' }} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" fxFlex="30.5" class="example-full-width">
    <mat-label>{{ 'YEAR' | transloco }}</mat-label>
    <mat-select
      required
      [placeholder]="'YEAR' | transloco"
      [disabled]="isDisable"
      [(ngModel)]="year"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let val of years" [value]="val"> {{ val }} </mat-option>
    </mat-select>
  </mat-form-field>
</div>
