<footer class="footer">
  <div>
    <div class="flex justify-center mb-[5px]">
      <a href="https://www.facebook.com/betrail.run" target="_blank" class="mr-[15px]">
        <mat-icon svgIcon="facebookLight" matTooltipPosition="above" matTooltip="Facebook"></mat-icon>
      </a>
      <a href="https://www.instagram.com/betrail_officiel" target="_blank">
        <mat-icon svgIcon="instagramLight" matTooltipPosition="above" matTooltip="Instagram"></mat-icon>
      </a>
    </div>
    <span class="strong">© Betrail 2015-{{ date | date: 'yyyy' }}</span>
    <div innerHTML="{{ 'BETRAIL_FOOTER' | transloco }}"></div>
  </div>
  <div class="legal">
    <a href="../assets/docs/Terms of Service.pdf" target="_blank">{{ 'TERMS_OF_USE' | transloco }}</a> -
    <a href="../assets/docs/Politique vie privée et traitements des données v1.01.pdf" target="_blank"
      >{{ 'PRIVACY_POLICY' | transloco }}</a
    >
  </div>
  <div class="partners">
    <a
      class="footer-link"
      [href]="userLang === 'nl' ? 'https://bit.ly/Visit-Wallonia-footer-NL' : 'https://bit.ly/Visit-Wallonia-footer-FR'"
      target="_blank"
      ><img
        class="sm:hidden"
        src="../assets/img/VISITWallonia_logo.png"
        alt="{{ 'VISIT_WALLONIA_LOGO' | transloco }}" />
      <img
        class="hidden sm:block sm:!visible"
        src="../assets/img/VISITWallonia_big.png"
        alt="{{ 'VISIT_WALLONIA_LOGO' | transloco }}"
    /></a>
  </div>
</footer>
