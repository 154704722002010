import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ALL_COUNTRIES_CODES } from '@betrail-libs/shared/utils';
import { Router } from '@angular/router';
import { EventService } from '@betrail-libs/trail-data-state';
import { Store, Select } from '@ngxs/store';
import {
  TrailDataState,
  LoadAllSponsors,
  LoadRunnersOfSponsor,
  DeleteSponsor,
  UpdateSponsorData,
} from '@betrail-libs/trail-data-state';
import { ImageUploadService } from '../../../../../common/images/image-upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-edit-sponsor-dialog',
  templateUrl: './edit-sponsor-dialog.component.html',
  styleUrls: ['./edit-sponsor-dialog.component.scss'],
})
export class EditSponsorDialogComponent implements OnInit {
  @Select(TrailDataState.sponsoredRunners) sponsoredRunners$: Observable<any[]>;

  editSponsorForm: UntypedFormGroup = this.setForm();
  formValid = false;
  countries = ALL_COUNTRIES_CODES;
  newImageData = '';
  selectedSponsor: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<EditSponsorDialogComponent>,
    private store: Store,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private router: Router,
    private imgService: ImageUploadService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadRunnersOfSponsor(this.data.sponsor.data.id));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  setForm() {
    return this.fb.group(
      {
        name: [this.data.sponsor.data.name],
        country: [this.data.sponsor.data.country],
        link: [this.data.sponsor.data.link],
        linkEnabled: [this.data.sponsor.data.linkEnabled === 1 ? true : false],
        picture: [this.data.sponsor.image],
      },
      {
        validators: this.validateSponsor(),
      },
    );
  }

  addImage(file: Event | File): void {
    if ('size' in file) {
      if (file.size < 2000000) {
        const imgObservable = this.imgService.getImageObservableFromFile(file);
        imgObservable.subscribe(d => {
          this.newImageData = d;
          this.editSponsorForm.patchValue({
            picture: d,
          });
        });
      } else {
        this.snackbar.open(this.transloco.translate('ERROR_FILE_TOO_LARGE'), 'OK');
      }
    }
  }

  navigateToRunner(runner) {
    this.router.navigate(['/', 'runner', runner.alias]);
    this.closeDialog();
  }

  selectSponsor(sponsor) {
    this.selectedSponsor = sponsor;
  }

  migrateSponsorData() {
    if (
      confirm(`Êtes-vous sûr de vouloir supprimer le sponsor ${this.data.sponsor.data.name} (ID ${this.data.sponsor.data.id})
      et d'envoyer les coureurs associés vers le sponsor ${this.selectedSponsor.data.name} (ID ${this.selectedSponsor.data.id}) ?`)
    ) {
      this.eventService.replaceSponsorUsersLinks(this.data.sponsor.data.id, this.selectedSponsor.data.id).subscribe({
        error: err => console.error(err),
        complete: () => {
          this.store.dispatch(new DeleteSponsor(this.data.sponsor.data.id));
          this.closeDialog();
        },
      });
    }
  }

  deleteSponsor(spid) {
    if (
      confirm(
        `Voulez-vous supprimer définitivement le sponsor ${this.data.sponsor.data.name} (ID ${this.data.sponsor.data.id}) ?`,
      )
    ) {
      this.store.dispatch(new DeleteSponsor(spid));
      this.closeDialog();
    }
  }

  updateSponsor() {
    const form = { ...this.editSponsorForm.value };
    const sponsor = {
      id: this.data.sponsor.data.id,
      name: form.name,
      country: form.country,
      link: form.link,
      linkEnabled: form.linkEnabled === true ? 1 : 0,
      picture: this.newImageData ? this.newImageData : this.data.sponsor.image,
    };
    this.store.dispatch(new UpdateSponsorData(sponsor));
    this.store.dispatch(new LoadAllSponsors());
    this.closeDialog();
  }

  validateSponsor(this: EditSponsorDialogComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = [];
      const name = control.get('name').value;
      const picture = control.get('picture').value;

      if (name == '') error.push('nameError');
      if (picture == '') error.push('imageError');

      const errors = {};
      for (let i = 0; i < error.length; i++) {
        errors[error[i]] = true;
      }
      error.length === 0 ? (this.formValid = true) : (this.formValid = false);
      return error.length == 0 ? null : errors;
    };
  }
}
