import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from '../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../common/dialog/dialogResultStatus';

@Component({
  selector: 'app-confirm-cancel-form-item-dialog',
  templateUrl: './confirm-cancel-form-item-dialog.component.html',
  styleUrls: ['./confirm-cancel-form-item-dialog.component.scss'],
})
export class ConfirmCancelFormItemDialogComponent {
  constructor(private dialogRef: MatDialogRef<ConfirmCancelFormItemDialogComponent>) {}

  cancel() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  confirm() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Ok;

    this.dialogRef.close(dialogResult);
  }
}
