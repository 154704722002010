import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'race-search',
  templateUrl: './race-search.component.html',
  styleUrls: ['./race-search.component.scss'],
})
export class RaceSearchComponent {
  constructor(private eventService: EventService) {}

  @ViewChild('searchElem', { static: true }) searchNativeElem;
  @ViewChild('searchElem', { read: MatAutocompleteTrigger, static: true }) searchElem: MatAutocompleteTrigger;

  @Output() raceSelected = new EventEmitter();
  @Input() placeholder: string;
  @Input() otherOption: boolean;

  currentSearchValue: any;
  isOpen = false;
  justSelected = false;
  searchStatus: boolean = false;
  searchValue = new Subject<any>();

  searchAutocomplete = this.searchValue.pipe(
    debounceTime(200),
    //distinct(),
    switchMap(value => (value ? this.eventService.searchRunners(value) : of(undefined))),
  );

  onSearchChange(value) {
    if (value != this.currentSearchValue) {
      this.searchValue.next(value);
      this.currentSearchValue = value;
    }
    if (value != '' && this.justSelected == false) {
      this.searchElem.openPanel();
      this.isOpen = true;
    } else {
      this.searchElem.closePanel();
      this.isOpen = false;
      if (this.justSelected == true) {
        this.justSelected = false;
      }
    }
  }

  onSearchSelect(value) {
    if (value.add_course) {
      value.value = this.currentSearchValue;
      value.trail_title = this.currentSearchValue;
    }
    this.raceSelected.emit(value);
    this.searchStatus = false;
    this.justSelected = true;
    this.searchNativeElem.nativeElement.value = value.trail_title;
    this.searchClose();
  }

  searchClose() {
    this.searchElem.closePanel();
    this.searchStatus = false;
  }

  setIsOpenParam(val) {
    this.isOpen = val;
  }
}
