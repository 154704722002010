import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRunnerVisualizerData } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-search-runner-dialog',
  templateUrl: './search-runner-dialog.component.html',
})
export class SearchRunnerDialogComponent {
  selectedRunner?: IRunnerVisualizerData;
  toExclude = false;
  trackingNotAllowed = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; runnersToExclude?: number[]; excludeMessage?: string; forTracking?: boolean },
    private dialogRef: MatDialogRef<SearchRunnerDialogComponent>,
  ) {}

  selectRunner(data: {
    _source: {
      alias: string;
      allow_tracking: 0 | 1;
      avatar: string;
      display_title: string;
      gender: 0 | 1;
      is_premium: 0 | 1;
      nationality: string;
      ruid: number | string;
      title: string;
    };
  }) {
    const r = data._source;
    this.selectedRunner = {
      ruid: +r.ruid,
      name: r.display_title || r.title,
      alias: r.alias,
      nationality: r.nationality,
      gender: r.gender === 1 ? 'F' : 'M',
      premium: r.is_premium === 1,
      photo: r.avatar,
      disabled: r.allow_tracking === 0,
    };
    if (this.data.runnersToExclude) {
      this.toExclude = this.data.runnersToExclude.includes(this.selectedRunner.ruid);
    }
    if (this.data.forTracking) {
      this.trackingNotAllowed = r.allow_tracking === 0;
    }
  }

  closeAndReturnRunner() {
    this.dialogRef.close(this.selectedRunner.ruid);
  }
}
