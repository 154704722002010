import { Component } from '@angular/core';
import { SetDefaultCountry } from '@betrail-libs/app-params-state';

import { Store } from '@ngxs/store';

@Component({
  selector: 'choose-default-country',
  templateUrl: './choose-default-country.component.html',
  styleUrls: ['./choose-default-country.component.scss'],
})
export class ChooseDefaultCountryComponent {
  constructor(private store: Store) {}

  selectDefaultCountry(country: string) {
    this.store.dispatch(new SetDefaultCountry(country));
  }
}
