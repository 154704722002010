import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Runner } from '@betrail-libs/shared/interfaces/runner.model';
import { TrailDataService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'app-select-organizer',
  templateUrl: './select-organizer.component.html',
  styleUrls: ['./select-organizer.component.scss'],
})
export class SelectOrganizerComponent {
  selectedRunner: Runner | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { organization: any },
    public dialogRef: MatDialogRef<SelectOrganizerComponent>,
    private trailStateService: TrailDataService,
  ) {}

  selectRunner(data: any) {
    this.selectedRunner = data._source;
  }

  linkRunnerToOrga() {
    if (this.selectedRunner) {
      this.trailStateService.linkUserWithOrga(this.data.organization.id, this.selectedRunner.uid);
      this.dialogRef.close();
    }
  }
}
