<form [formGroup]="loginForm" class="form login-form" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
  <h2 class="centeredAligned">{{ 'CONNEXION' | transloco }}</h2>
  <div class="message">
    <p><strong>Connectez-vous à un compte Betrail.</strong></p>
    <p>Vous pourrez alors lier à ce compte la page <br />"{{ trail?.title }}".</p>
  </div>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
    <mat-icon class="suffixIcon" matSuffix>account_circle</mat-icon>
    <input trimInput matInput #email type="text" placeholder="Votre adresse email" formControlName="user" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="full-width">
    <mat-icon class="suffixIcon" matSuffix>lock</mat-icon>
    <mat-label tabindex="1">Votre mot de passe</mat-label>
    <input matInput #pass type="password" placeholder="Mot de passe" formControlName="password" />
  </mat-form-field>

  <span class="passwordToggle" (click)="displayPasswordToggle()"
    ><mat-icon class="eye">{{ passwordToggleIcon }}</mat-icon>{{ passwordToggleText | transloco }}</span
  >

  <div class="center-align action-line">
    <button class="login" [disabled]="!loginForm.valid" mat-raised-button (click)="login()">
      {{ 'LOG_IN' | transloco }}
    </button>
  </div>

  <div class="bottom">
    <div class="message">
      <p>
        <a (click)="sendNewPassword()">Mot de passe oublié ? Cliquez ici</a>
      </p>
      <p>
        Pas encore de compte Betrail ? <br />
        <a (click)="noAccount()">Créez un nouveau compte Organisateur</a>.
      </p>
    </div>
  </div>
  <!--
    <button mat-raised-button disabled (click)="loginGoogle()" class="google">Google login</button>
    <button mat-raised-button disabled (click)="loginFacebook()" class="facebook">Facebook login</button>
  -->
  <!--
    <p class="message centeredAligned">
      Vous n'avez pas encore de compte Betrail ?
      <a (click)="formType = 'register'">{{ 'CREATE_AN_ACCOUNT' | transloco }}</a>
    </p>
  -->

  <!-- <p class="message">Lost password <a (click)="retrievePassword(user.value)">Send password by email</a></p> -->
</form>
