<ng-container [formGroup]="eventFormGroup">
  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="event-form">
    <!--<div class="subtitle" fxFlex="100"><strong>{{ "" | transloco}} Généralités</strong></div>-->
    <!--<div fxFlex="100">
          La prochaine édition dont nous n'avons pas encore la date est : {{ minDate.getFullYear() }}
        </div>-->
    <div>
      <p>{{ 'BASIC_INFORMATIONS_TEXT' | transloco }}</p>
    </div>

    <input style="display: none" type="text" name="fakeusernamerememberedev" />
    <input style="display: none" type="text" name="fakeemailrememberedev" />
    <input style="display: none" type="password" name="fakepasswordrememberedev" />

    <div class="subtitle" fxFlex="100">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'EVENT_NAME' | transloco }}</mat-label>
        <input
          matInput
          placeholder="Name of the event"
          formControlName="event_name"
          autocomplete="abdk8655s2z4cc5s4e8541"
        />
      </mat-form-field>
    </div>

    <div class="subtitle" fxFlex="100">
      <strong>{{ 'EVENT_DATES' | transloco }}</strong>
    </div>

    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between">
      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48%">
        <mat-label>
          <ng-container *ngIf="severalDays.checked; else defaultDate">{{ 'START_DATE' | transloco }}</ng-container>
          <ng-template #defaultDate>{{ 'DATE' | transloco }}</ng-template>
        </mat-label>
        <input
          ng-model-options="{timezone: 'utc'}"
          matInput
          #date
          [min]="minDate"
          [matDatepicker]="startPicker"
          placeholder="jj/mm/aaaa (ou cliquez sur le calendrier)"
          formControlName="date"
          [disabled]="!acceptDateChange && (isAdmin$ | async) === false && (isEncoder$ | async) === false"
        />
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48%" *ngIf="severalDays.checked">
        <mat-label>{{ 'END_DATE' | transloco }}</mat-label>
        <input
          ng-model-options="{timezone: 'utc'}"
          [disabled]="!date.value || !severalDays.checked"
          matInput
          [min]="minDate"
          [matDatepicker]="endPicker"
          placeholder="jj/mm/aaaa (ou cliquez sur le calendrier)"
          formControlName="end_date"
          [disabled]="!acceptDateChange && (isAdmin$ | async) === false && (isEncoder$ | async) === false"
        />
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [startAt]="eventFormGroup.get('date').value"></mat-datepicker>
      </mat-form-field>
    </div>
    <p fxFlex="100">
      <mat-checkbox
        color="primary"
        fxFlex="100"
        #severalDays
        formControlName="several_days"
        [disabled]="!acceptDateChange && (isAdmin$ | async) === false && (isEncoder$ | async) === false"
        >{{ 'EVENT_ON_SEVERAL_DAYS' | transloco }}
      </mat-checkbox>
    </p>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>{{ 'EDITION_NUMBER' | transloco }}</mat-label>
      <mat-select formControlName="edition_number">
        <mat-option value="1">{{ 'EVENT_EDITION_1' | transloco }}</mat-option>
        <mat-option value="2">{{ 'EVENT_EDITION_2' | transloco }}</mat-option>
        <mat-option value="3">{{ 'EVENT_EDITION_3' | transloco }}</mat-option>
        <mat-option value="4">4{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="5">5{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="6">6{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="7">7{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="8">8{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="9">9{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="10">10{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="11">11{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="12">12{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="13">13{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="14">14{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="15">15{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="16">16{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="17">17{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="18">18{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="19">19{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="20">20{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="21">21{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="22">22{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="23">23{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="24">24{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="25">25{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="26">26{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="27">27{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="28">28{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
        <mat-option value="29">29{{ 'EVENT_EDITION_X' | transloco }}</mat-option>
      </mat-select>
    </mat-form-field>
    <p *ngIf="(isAdmin$ | async) || (isEncoder$ | async)" fxFlex="100">
      <mat-checkbox color="primary" fxFlex="100" formControlName="out_of_criteria"
        >{{ 'EVENT_OUT_OF_CRITERIA' | transloco }}
      </mat-checkbox>
    </p>
    <p *ngIf="(isAdmin$ | async) || (isEncoder$ | async) || (showOrgaOptions$ | async)" fxFlex="100">
      <mat-checkbox color="primary" fxFlex="100" formControlName="canceled"
        >{{ 'EVENT_CANCELED' | transloco }}
      </mat-checkbox>
    </p>
    <p *ngIf="(isAdmin$ | async) || (isEncoder$ | async) || (showOrgaOptions$ | async)" fxFlex="100">
      <mat-checkbox color="primary" fxFlex="100" formControlName="no_next_edition"
        >{{ 'EVENT_NO_NEXT_EDITION' | transloco }}
      </mat-checkbox>
    </p>
  </div>
  <mat-divider></mat-divider>

  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="event-form">
    <div class="subtitle" fxFlex="100">
      <strong>{{ 'TIMER' | transloco }}</strong>
    </div>
    <timer-selector fxFlex="100" formControlName="timer" [c]="$any(eventFormGroup.controls.timer)"></timer-selector>
  </div>
  <mat-divider></mat-divider>

  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="event-form">
    <div class="subtitle" fxFlex="100">
      <strong>{{ 'REGISTRATIONS' | transloco }}</strong>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="48%" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-slide-toggle #prereg formControlName="preregistration" fxFlex="100" color="primary">
        {{ 'EVENT_ONLINE_REGISTRATION' | transloco }}</mat-slide-toggle
      >

      <mat-form-field appearance="fill" fxFlex="100%" *ngIf="prereg.checked">
        <mat-icon class="suffixIcon" matSuffix>web</mat-icon>
        <mat-label>{{ 'EVENT_ONLINE_REGISTRATION_URL' | transloco }}</mat-label>
        <input matInput placeholder="http://www..." formControlName="registration_url" />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100%" *ngIf="prereg.checked">
        <mat-label>{{ 'EVENT_ONLINE_REGISTRATION_START_DATE' | transloco }}</mat-label>
        <input
          matInput
          [matDatepicker]="pickerStart"
          placeholder="jj/mm/aaaa"
          formControlName="registration_start_date"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100%" *ngIf="prereg.checked">
        <mat-label>{{ 'EVENT_ONLINE_REGISTRATION_END_DATE' | transloco }}</mat-label>
        <input matInput [matDatepicker]="pickerEnd" placeholder="jj/mm/aaaa" formControlName="registration_end_date" />
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
    </div>

    <div fxFlex="100%" fxFlex.gt-sm="48%" fxLayout="column" fxLayoutAlign="start stretch" dir="ltr">
      <mat-slide-toggle #onsitereg formControlName="onsite_registration" fxFlex.lt-md="100%" color="primary"
        >{{ 'EVENT_ONSITE_REGISTRATION' | transloco }}</mat-slide-toggle
      >

      <mat-form-field appearance="fill" fxFlex="100%" fxFlex.gt-sm="48.5%" *ngIf="onsitereg.checked">
        <mat-icon class="suffixIcon" matSuffix>access_time</mat-icon>
        <mat-label>{{ 'EVENT_ONSITE_REGISTRATION_START_TIME' | transloco }} ({{ 'HH_MM' | transloco }})</mat-label>
        <input
          matInput
          [ngxTimepicker]="picker"
          placeholder="Ex.: 8:30"
          [format]="24"
          enableKeyboardInput="true"
          minutesGap="5"
          formControlName="onsite_registration_start_time"
        />
        <ngx-material-timepicker #picker [theme]="darkTheme"></ngx-material-timepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100%" fxFlex.gt-sm="48.5%" *ngIf="onsitereg.checked">
        <mat-icon class="suffixIcon" matSuffix>access_time</mat-icon>
        <mat-label>{{ 'EVENT_ONSITE_REGISTRATION_END_TIME' | transloco }} (hh:mm)</mat-label>
        <input
          matInput
          [ngxTimepicker]="picker2"
          placeholder="Ex.: 11:30"
          [format]="24"
          enableKeyboardInput="true"
          minutesGap="5"
          formControlName="onsite_registration_end_time"
        />
        <ngx-material-timepicker #picker2 [theme]="darkTheme"></ngx-material-timepicker>
      </mat-form-field>

      <div fxFlex="100%">&nbsp;</div>
    </div>
    <div *ngIf="(isAdmin$ | async)" fxFlex="100">
      <mat-checkbox color="primary" fxFlex="100" formControlName="external_registration_allowed"
        >{{ 'EVENT_ALLOW_EXTERNAL_REGISTRATION' | transloco }} (Admin)
      </mat-checkbox>
    </div>
  </div>

  <ng-container *ngIf="showLaunchOnlineRegistration">
    <mat-divider></mat-divider>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <button mat-raised-button class="rounded-button">{{ 'LAUNCH_ONLINE_REGISTRATION' | transloco }}</button>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <p>{{ 'LAUNCH_ONLINE_REGISTRATION_TEXT' | transloco }}</p>
    </div>
  </ng-container>

  <mat-divider></mat-divider>

  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="event-form">
    <div class="subtitle" fxFlex="100">
      <strong>{{ 'RACE_RULES' | transloco }}</strong>
    </div>
    <div fxFlex="100">
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>{{ 'RACE_RULES_URL_FILE' | transloco }}*</mat-label>
        <input matInput placeholder="" formControlName="rules_url" />
      </mat-form-field>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="event-form">
    <div class="title" fxFlex="100">
      <strong>{{ 'EVENT_DEPARTURE_PLACE' | transloco }}</strong>
    </div>
    <div>
      <p>{{ 'EVENT_DEPARTURE_PLACE_DESCRIPTION' | transloco }}</p>
    </div>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between">
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>{{ 'ADDRESS' | transloco }} *</mat-label>
        <textarea rows="4" placeholder="adresse" matInput formControlName="departure_address"> </textarea>
      </mat-form-field>
    </div>

    <!--<div class="subtitle" fxFlex="100">
      <strong>{{ 'GPS_COORDINATES' | transloco }}</strong>
    </div>

    <div fxFlex="100%" fxFlex.gt-sm="48%" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field appearance="fill" fxFlex="100%">
        <mat-label>{{ 'LATITUDE' | transloco }}</mat-label>
        <input matInput placeholder="" formControlName="geo_lat" />
      </mat-form-field>

    </div>

    <div fxFlex="100%" fxFlex.gt-sm="48%" fxLayout="column" fxLayoutAlign="start stretch" dir="ltr">
      <mat-form-field appearance="fill" fxFlex="100%">
        <mat-label>{{ 'LONGITUDE' | transloco }}</mat-label>
        <input matInput placeholder="" formControlName="geo_lon" />
      </mat-form-field>
    </div>-->
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="event-form">
    <div (click)="toggle()" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
      <strong>{{ 'EVENT_AMENITIES_DETAILS' | transloco }}</strong>
      <button mat-mini-fab class="more-toggle" color="primary">
        <mat-icon *ngIf="toggleMore === false">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="toggleMore === true">keyboard_arrow_up</mat-icon>
      </button>
    </div>
    <div fxLayout="row wrap" *ngIf="toggleMore === true">
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="douches">
        {{ 'SHOWERS_AND_CLOAKROOMS' | transloco }}
      </mat-slide-toggle>
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="parking"
        >{{ 'PARKING' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="bar"
        >{{ 'BAR' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="restauration"
        >{{ 'SNACKS' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="kine"
        >{{ 'PHYSIOTHERAPIST' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="consigne"
        >{{ 'BAGGAGE_ROOM' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="garderie"
        >{{ 'NURSERY' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="jeux_enfants"
        >{{ 'CHILDRENS_GAMES' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="animations"
        >{{ 'ANIMATIONS' | transloco }}</mat-slide-toggle
      >
      <mat-slide-toggle color="primary" fxFlex="100" fxFlex.gt-sm="50" formControlName="soiree"
        >{{ 'POST_RACE_EVENING' | transloco }}</mat-slide-toggle
      >
    </div>
  </div>
</ng-container>
