import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  exceptions = [
    'l',
    'd',
    'à',
    'le',
    'la',
    'les',
    'au',
    'aux',
    'en',
    'de',
    'du',
    'des',
    'sur',
    'sous',
    'par',
    'près',
    'vers',
    'chez',
    'devant',
    'derrière',
    'delà',
    'van',
    'den',
    'op',
    'in',
    'boven',
    'uit',
    'aan',
  ];

  wordsMap = (word: string, index: number) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else if (this.exceptions.includes(word)) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  };

  transform(value?: string): string | undefined {
    if (value) {
      const text = value.toLowerCase();
      const spaceHandled = text.split(' ').map(this.wordsMap).join(' ');
      const dashHandled = spaceHandled.split('-').map(this.wordsMap).join('-');
      const apostropheHandled = dashHandled.split("'").map(this.wordsMap).join("'");
      const parenthesisHandled = apostropheHandled.split('(').map(this.wordsMap).join('(');

      return parenthesisHandled;
    }
    return value;
  }
}
