import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import '@angular/compiler';
import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule, Optional } from '@angular/core';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationStart, Params, Router, RouterEvent, RouterStateSnapshot } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AdminState } from '@betrail-libs/admin-state';
import { AppParamsState } from '@betrail-libs/app-params-state';
import { AuthStateModule, AuthStateService, IUserState } from '@betrail-libs/auth-state';
import { IoServiceModule } from '@betrail-libs/io-service';
import { TrackingState } from '@betrail-libs/tracking-state';
import { TrailDataStateModule } from '@betrail-libs/trail-data-state';
import { PwaService } from '@betrail/ux';
import { PlatformServiceService } from '@betrail/ux/platform-service.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer as NgxsRouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { GtagModule } from 'angular-gtag';
import { Request } from 'express';
import { lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppRoutingModule, LangAliasResolver } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogState } from './blog-state/blog.state';
import { CoreModule } from './core/core.module';
import { I18nState } from './i18nState/i18n.state';
import { MockHttpClient } from './mock-http-client';
import { RankingsState } from './rankings-page/services/rankings.state';
import { SettingsService } from './settings.service';
import { TimersState } from './timers-state/timers.state';
import { TranslocoRootModule } from './transloco-root.module';

export function preloadUser(userService: AuthStateService, transloco: TranslocoService, request?: Request) {
  return function () {
    //SSR MODE => set language from url or request header
    if (request) {
      transloco.setActiveLang('fr');
      return transloco.load('fr').toPromise();
    } else {
      //load cached language then if user take from user if none take from browser
      return lastValueFrom(userService.geUserAfterLoading().pipe(take(1))).then((user: IUserState) => {
        let language = 'fr';
        if (user?.language) {
          language = user.language;
        }
        transloco.setActiveLang(language);
        return transloco.load(language).toPromise();
      });
    }
  };
}

export const preLoad = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: preloadUser,
  deps: [AuthStateService, TranslocoService, [new Optional(), REQUEST]],
};

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

export interface State {
  router: {};
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  data: Params;
}

// Map the router snapshot to { url, params, queryParams }
@Injectable()
export class CustomRouterStateSerializer implements NgxsRouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    let params = route.params || {};
    let data = route.data || {};
    while (route.firstChild) {
      route = route.firstChild;
      params = { ...params, ...route.params };
      data = { ...data, ...route.data };
    }

    return { url, params, data, queryParams };
  }
}

const importsX = [];

if (typeof window !== 'undefined') {
  importsX.push(GtagModule.forRoot({ trackingId: 'UA-63871879-2', trackPageviews: true }));
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    GtagModule.forRoot({ trackingId: 'UA-63871879-2', trackPageviews: true }),
    BrowserModule.withServerTransition({ appId: 'betrail2-app' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    AppRoutingModule,
    IoServiceModule,
    /* !environment.production ? StoreDevtoolsModule.instrument() : [],*/
    NgxsModule.forRoot([I18nState, AppParamsState, BlogState, TimersState, AdminState, TrackingState, RankingsState], {
      developmentMode: !environment.production,
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: ['app_params.defaultCountry'] }),
    !environment.production ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    BrowserAnimationsModule,

    AuthStateModule.forRoot(),
    TrailDataStateModule,
    TranslocoModule,
    NgOptimizedImage,
    CoreModule,
    // CookieModule.forRoot(),

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslocoRootModule,
  ],
  providers: [
    { provide: NgxsRouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: 'appsConfig', useValue: environment },
    { provide: HttpClient, useClass: environment.mock ? MockHttpClient : HttpClient },
    { provide: 'AHttpClient', useClass: HttpClient },
    { provide: 'BETRAIL2_NODE_API', useValue: environment.nodejsApi },
    { provide: 'DATE_PIPE_DEFAULT_TIMEZONE', useValue: 'Europe/Brussels' },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { firstDayOfWeek: 1 } },
    preLoad,
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    SettingsService,
    LangAliasResolver,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(pwaService: PwaService, auth: AuthStateService, router: Router, platformService: PlatformServiceService) {
    if (platformService.isBrowserRendering()) {
      pwaService.listenToUpdate();
      router.events
        .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (pwaService.asToUpdate && window && event.url) {
            //test chnage
            window.location.href = 'https://' + window.location.host + event.url;
          }
        });
    }
  }
}
