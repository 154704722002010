import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetrailUxModule } from '@betrail/ux/betrail-ux.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, BetrailUxModule],
  exports: [BetrailUxModule, FormsModule, CommonModule],
  declarations: [],
})
export class CoreModule {}
