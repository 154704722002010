<form [formGroup]="editSponsorForm" class="sponsor-form">
  <div class="form-title">
    <h3>Modifier sponsor</h3>
  </div>

  <div class="sponsor-inputs">
    <div class="sponsor-data">
      <mat-form-field appearance="fill" color="accent">
        <mat-icon class="suffixIcon" matSuffix>description</mat-icon>
        <mat-label>{{ 'NAME_GENERIC' | transloco }}</mat-label>
        <input matInput placeholder="{{ 'NAME_GENERIC' | transloco }}" formControlName="name" />
        <p *ngIf="editSponsorForm.hasError('nameError')" class="formError">{{ 'ERROR_MISSING_FIELD' | transloco }}</p>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-icon class="suffixIcon" matSuffix>flag</mat-icon>
        <mat-label>{{ 'COUNTRY' | transloco }}</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
            >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-icon class="suffixIcon" matSuffix>language</mat-icon>
        <mat-label>{{ 'RACE_WEBSITE' | transloco }}</mat-label>
        <input matInput type="url" placeholder="{{ 'RACE_WEBSITE' | transloco }}" formControlName="link" />
      </mat-form-field>

      <mat-checkbox class="checkbox" color="accent" formControlName="linkEnabled" [labelPosition]="'before'">
        Autoriser lien clickable (Admin)
      </mat-checkbox>
    </div>

    <div class="sponsor-image">
      <div>
        <input
          #fileInput
          hidden
          type="file"
          accept=".jpeg,.jpg,.png"
          (click)="$event.stopPropagation()"
          (change)="addImage(fileInput.files[0])"
        />
      </div>
      <div>
        <button
          class="addImageButton"
          tdFileDrop
          #addImageButton
          mat-raised-button
          (click)="fileInput.click(); $event.stopPropagation()"
          (fileDrop)="addImage($event)"
        >
          <mat-icon>image</mat-icon>&nbsp; Upload image
        </button>
      </div>

      <p class="advisedSize">{{ 'SPONSOR_ADVISED_IMG_SIZE' | transloco }}</p>
      <img *ngIf="!newImageData" [src]="data.sponsor.image" class="uploaded-img" />
      <img *ngIf="newImageData" [src]="newImageData" class="uploaded-img" />
      <p *ngIf="editSponsorForm.hasError('imageError')" class="formError imgErr">{{ 'IMAGE_REQUIRED' | transloco }}</p>
    </div>
  </div>

  <div *ngIf="sponsoredRunners$ | async" class="runner-list">
    <div>
      <h4>Coureurs associés à ce sponsor:</h4>
    </div>
    <div>
      <mat-list role="list">
        <ng-container *ngIf="(sponsoredRunners$ | async).length > 0; else noRunnerLinked">
          <mat-list-item *ngFor="let runner of (sponsoredRunners$ | async); let index = index" role="listitem">
            <a class="runner-link" (click)="navigateToRunner(runner)">
              {{ index + 1 }}. {{ runner.firstname }} {{ runner.lastname }}&nbsp;
              <br class="smallScreenOnly" />
              (UID: {{ runner.uid }} / RUID: {{ runner.id }})
            </a>
          </mat-list-item>
        </ng-container>
        <ng-template #noRunnerLinked>
          <mat-list-item>
            <i>Aucun coureur lié à ce sponsor</i>
          </mat-list-item>
        </ng-template>
      </mat-list>
    </div>
  </div>

  <div class="btnContainer">
    <button color="primary" mat-raised-button (click)="closeDialog()">{{ 'CANCEL' | transloco }}</button>
    <button type="submit" color="accent" mat-raised-button (click)="updateSponsor()" [disabled]="!formValid">
      {{ 'SAVE' | transloco }}
    </button>
    <button mat-raised-button color="warn" (click)="deleteSponsor(data.sponsor.data.id)">
      {{ 'DELETE' | transloco }}
    </button>
  </div>

  <div class="migrateContainer">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> TRANSFERT </mat-panel-title>
          <mat-panel-description>
            <span class="bigScreenOnly">Fusionner ce sponsor avec un autre</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inside-panel">
          <em
            >Attention ! Cette action supprimera le sponsor actuel
            <br />
            et enverra les coureurs liés chez le sponsor selectionné
          </em>
          <div class="action-container">
            <bux-search-sponsor
              [spid]="data.sponsor.data.id"
              (selectedSponsor)="selectSponsor($event)"
            ></bux-search-sponsor>
            <button mat-raised-button color="primary" [disabled]="!selectedSponsor" (click)="migrateSponsorData()">
              Envoyer
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</form>
