<header class="form-title">
  {{ 'OTHER_RESULTS_ADD_TITLE' | transloco }}
  <button class="btnClose" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</header>

<ng-container>
  <ng-container>
    <form [formGroup]="otherResultForm" class="register-form">
      <div class="form-container">
        <!-- CHOOSE A RACE -->
        <ng-container>
          <div class="subtitle"><span>1</span> {{ 'RACE_TYPE' | transloco }}</div>
          <div class="card">
            <h3>{{ 'RACE_TRAIL_TITLE' | transloco }}</h3>
            <race-search
              placeholder="RACE_NAME"
              (raceSelected)="onTrailChoice($event)"
              [otherOption]="true"
            ></race-search>
            <br />

            <div *ngIf="isToCreate" style="width: 100%" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
              <div fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                  <mat-icon class="suffixIcon" matSuffix>flag</mat-icon>
                  <mat-label>{{ 'COUNTRY' | transloco }}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
                      >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                  <mat-icon class="suffixIcon" matSuffix>place</mat-icon>
                  <mat-label>{{ 'POSTAL_CODE' | transloco }}</mat-label>
                  <input matInput placeholder="Postal Code" formControlName="zipcode" />
                  <p
                    *ngIf="otherResultForm.hasError('zipcodeError')"
                    style="color: red; padding: 0; margin: 0; font-size: 12px"
                  >
                    {{ 'ERROR_MISSING_FIELD' | transloco}}
                  </p>
                </mat-form-field>
              </div>

              <mat-form-field appearance="fill" fxFlex="100">
                <mat-icon class="suffixIcon" matSuffix>place</mat-icon>
                <mat-label>{{ 'LOCATION' | transloco }}</mat-label>
                <input matInput type="text" placeholder="Location" formControlName="location" />
                <p
                  *ngIf="otherResultForm.hasError('locationError')"
                  style="color: red; padding: 0; margin: 0; font-size: 12px"
                >
                  {{ 'ERROR_MISSING_FIELD' | transloco}}
                </p>
              </mat-form-field>
            </div>
          </div>
        </ng-container>

        <!-- CHOOSE AN EVENT -->
        <ng-container *ngIf="isFind">
          <div class="subtitle"><span>2</span> {{ 'CHOOSE_EVENT' | transloco }}</div>
          <div class="card">
            <h3>{{ 'EVENTS' | transloco }}</h3>
            <div *ngIf="!isToCreate">
              <buttons-toggle
                fxFlex="100"
                [options]="sortEventsOptions(eventsOptions)"
                (btnSelected)="onEventChoice($event)"
              ></buttons-toggle>
            </div>
            <div *ngIf="isToCreate || isOtherEventSelected">
              <!--<birthdate-select class="mat-form-field" formControlName="date"></birthdate-select>-->

              <mat-form-field appearance="fill" class="example-full-width" fxFlex="33">
                <mat-label>{{ 'DAY' | transloco }}</mat-label>
                <mat-select formControlName="day">
                  <mat-option *ngFor="let val of days" [value]="val"> {{val}} </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" class="example-full-width" fxFlex="33">
                <mat-label>{{ 'MONTH' | transloco }}</mat-label>
                <mat-select formControlName="month">
                  <mat-option *ngFor="let val of months" [value]="val"> {{ 'MONTH_' + val | transloco }} </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" class="example-full-width" fxFlex="33">
                <mat-label>{{ 'YEAR' | transloco }}</mat-label>
                <mat-select formControlName="year">
                  <mat-option *ngFor="let val of years" [value]="val"> {{val}} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <p *ngIf="otherResultForm.hasError('dateError')" style="color: red; padding: 0; margin: 0; font-size: 12px">
              {{ 'ERROR_MISSING_FIELD' | transloco}}
            </p>
            <p
              *ngIf="otherResultForm.hasError('futurDateError')"
              style="color: red; padding: 0; margin: 0; font-size: 12px"
            >
              {{ 'FUTUR_DATE_ERROR' | transloco}}
            </p>
          </div>
        </ng-container>

        <!-- CHOOSE A RACE -->
        <ng-container *ngIf="isEventSelected">
          <div class="subtitle"><span>3</span> {{ 'DISTANCE_CHOICE' | transloco }}</div>
          <div class="card">
            <h3>{{ 'RACE_DISTANCES' | transloco }}</h3>
            <div *ngIf="!isToCreate && !isOtherEventSelected">
              <buttons-toggle
                fxFlex="100"
                [options]="racesOptions"
                (btnSelected)="onRaceChoice($event)"
              ></buttons-toggle>
            </div>
            <div
              *ngIf="isToCreate || isOtherEventSelected || isOtherRaceSelected"
              style="width: 100%"
              fxLayout="column"
              fxLayoutAlign="centeredAligned stretch"
            >
              <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48" *ngIf="!isOtherRunType">
                <mat-icon class="suffixIcon" matSuffix>directions_run</mat-icon>
                <mat-label>{{ 'TYPE' | transloco }}</mat-label>
                <mat-select formControlName="run_type">
                  <mat-option *ngFor="let runType of runTypes" [value]="runType"
                    >{{ 'RUN_TYPE_' + runType | transloco }}</mat-option
                  >
                  <!-- <mat-option (click)="onOtherRunTypeChoice()">{{ 'OTHER' | transloco }}</mat-option> -->
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48" *ngIf="isOtherRunType">
                  <mat-icon class="suffixIcon" matSuffix>directions_run</mat-icon>
                  <mat-label>{{ 'TYPE' | transloco }}</mat-label>
                  <input matInput type="text" placeholder="Run type" formControlName="run_type" #runtypeinput/>
                  <p *ngIf="otherResultForm.hasError('runTypeError')" style="color: red; padding:0;margin:0;font-size:12px;">{{ 'ERROR_MISSING_FIELD' | transloco}}</p>
                </mat-form-field> -->

              <mat-form-field appearance="fill" fxFlex="100">
                <mat-icon class="suffixIcon" matSuffix>straighten</mat-icon>
                <mat-label>{{ 'DISTANCE' | transloco }} ({{ 'RACE_DISTANCE_UNIT' | transloco }})</mat-label>
                <input matInput type="number" placeholder="Distance" formControlName="distance" />
                <p
                  *ngIf="otherResultForm.hasError('distanceError')"
                  style="color: red; padding: 0; margin: 0; font-size: 12px"
                >
                  {{ 'ERROR_MISSING_FIELD' | transloco}}
                </p>
                <p
                  *ngIf="otherResultForm.hasError('distanceZeroError')"
                  style="color: red; padding: 0; margin: 0; font-size: 12px"
                >
                  {{ 'DISTANCE_NULL_ERROR' | transloco}}
                </p>
              </mat-form-field>

              <div fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                  <mat-icon class="suffixIcon" matSuffix>360</mat-icon>
                  <mat-label>{{ 'RACE_RACE_TYPE' | transloco }}</mat-label>
                  <mat-select formControlName="race_type">
                    <mat-option *ngFor="let resultType of raceTypeValues" [value]="resultType"
                      >{{ 'RACE_TYPE_' + resultType | transloco }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                  <mat-icon class="suffixIcon" matSuffix>panorama</mat-icon>
                  <mat-label>{{ 'RACE_HEIGHT_DIFFERENCE' | transloco }} (m)</mat-label>
                  <input matInput type="number" placeholder="Height Difference" formControlName="height_difference" />
                </mat-form-field>
              </div>

              <mat-slide-toggle
                color="primary"
                fxLayout="row"
                fxLayoutAlign="start center"
                formControlName="night_race"
                fxFlex.lt-md="100"
                color="primary"
              >
                {{ 'RACE_NIGHT' | transloco }}
              </mat-slide-toggle>
              <br />
            </div>
          </div>
        </ng-container>

        <!-- ENTER RESULT -->
        <ng-container *ngIf="isRaceSelected">
          <div class="subtitle"><span>4</span> {{ 'OTHER_RESULT_ADD' | transloco }}</div>
          <div class="card">
            <h3>{{ 'RESULT' | transloco }}</h3>
            <div style="width: 100%" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
              <mat-form-field appearance="fill">
                <mat-icon class="suffixIcon" matSuffix>emoji_events</mat-icon>
                <mat-label>{{ 'RESULT_TYPE' | transloco }}</mat-label>
                <mat-select formControlName="result_type" (selectionChange)="onResultTypeChoice($event)">
                  <mat-option *ngFor="let resultTypeCode of resultTypes" [value]="resultTypeCode">
                    {{ 'RESULT_TYPE_' + resultTypeCode | transloco }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div *ngIf="!isDnf" style="width: 98%" fxFlex="100" fxFlex.gt-sm="48">
                <div class="result-time-input">
                  <mat-label class="mat-label">{{ 'RUNNER_TIME' | transloco }}</mat-label>
                  <mat-form-field appearance="fill" style="width: 15%">
                    <input
                      matInput
                      type="number"
                      step="1"
                      formControlName="hours"
                      placeholder="hh"
                      min="0"
                      max="999"
                    /> </mat-form-field
                  >:
                  <mat-form-field appearance="fill" style="width: 15%">
                    <input
                      matInput
                      type="number"
                      step="1"
                      formControlName="minutes"
                      placeholder="mm"
                      min="0"
                      max="59"
                    /> </mat-form-field
                  >:
                  <mat-form-field appearance="fill" style="width: 15%">
                    <input
                      matInput
                      type="number"
                      step="1"
                      formControlName="seconds"
                      placeholder="ss"
                      min="0"
                      max="59"
                    />
                  </mat-form-field>
                  <mat-icon class="suffixIcon time-icon" matSuffix>av_timer</mat-icon>
                </div>
                <p
                  *ngIf="otherResultForm.hasError('timeError')"
                  style="color: red; padding: 0; margin: 0; font-size: 12px"
                >
                  {{ 'ERROR_MISSING_FIELD' | transloco}}
                </p>
              </div>

              <div *ngIf="!isSpec && !isDnf" fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                  <mat-icon class="suffixIcon" matSuffix>emoji_events</mat-icon>
                  <mat-label>{{ 'FILTER_MY_POSITION' | transloco }}</mat-label>
                  <input matInput type="number" placeholder="Position" formControlName="position" />
                  <p
                    *ngIf="otherResultForm.hasError('positionError')"
                    style="color: red; padding: 0; margin: 0; font-size: 12px"
                  >
                    {{ 'POSITION_BIGGER_ERROR' | transloco}}
                  </p>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                  <mat-icon class="suffixIcon" matSuffix>groups</mat-icon>
                  <mat-label>{{ 'RACE_FINISHERS' | transloco }}</mat-label>
                  <input matInput type="number" placeholder="Position" formControlName="total_finishers" />
                </mat-form-field>
              </div>

              <mat-form-field *ngIf="isDnf" appearance="fill" fxFlex="100" fxFlex.gt-sm="48">
                <mat-icon class="suffixIcon" matSuffix>straighten</mat-icon>
                <mat-label>{{ 'DISTANCE_DNF' | transloco }} (km)</mat-label>
                <input matInput type="number" placeholder="distance dnf" formControlName="distance_dnf" />
                <p
                  *ngIf="otherResultForm.hasError('scoreError')"
                  style="color: red; padding: 0; margin: 0; font-size: 12px"
                >
                  {{ 'DISTANCE_DNF_BIGGER_ERROR' | transloco}}
                </p>
              </mat-form-field>
            </div>
          </div>
          <div class="buttonContainer" fxLayout="row wrap">
            <button
              type="submit"
              color="accent"
              mat-raised-button
              (click)="saveOtherResult()"
              [class.spinner]="loading"
              [disabled]="loading || otherResultForm.invalid"
            >
              {{ 'SAVE' | transloco }}
            </button>
            <p *ngIf="isSaved" class="success">{{ 'SAVED' | transloco }}</p>
          </div>
        </ng-container>
      </div>
    </form>
  </ng-container>
</ng-container>
