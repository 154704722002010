<ng-container *ngIf="_notifs && _notifs.length > 0; else noNotif">
  <user-notification
    [open]="open"
    [notif]="notif"
    [status]="_notifsstatus[notif.id] || 'new'"
    *ngFor="let notif of _notifs"
  ></user-notification>
</ng-container>
<ng-template #noNotif>
  <div class="noNotif" fxLayout="row" fxLayoutAlign="center center">
    <span>{{ 'NO_NOTIF' | transloco }}</span>
  </div>
</ng-template>
