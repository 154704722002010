import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-alert-dialog',
  templateUrl: './basic-alert-dialog.component.html',
  styleUrls: ['./basic-alert-dialog.component.scss'],
})
export class BasicAlertDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<BasicAlertDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
