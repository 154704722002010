<div class="notification-container notification-{{ status }}">
  <button
    *ngIf="notif?.type == 'premium_payment_waiting'"
    class="notification"
    mat-menu-item
    [routerLink]="['/','premium', 'subscribe',  notif.target_id]"
  >
    <div class="notification-content" fxFlex="90" fxLayout="row" (click)="changeNotificationStatus(notif?.id, 'read')">
      <div class="image-container">
        <mat-icon class="report-icon">report</mat-icon>
      </div>
      <div class="text-container">
        <div
          class="text"
          [innerHTML]="
          notif?.model | transloco: { notif: notif, date: notif?.date | date: 'dd/MM/yyyy' }
        "
        ></div>
        <div class="date">{{ notif?.createdDate | toRelativeDate }}</div>
      </div>
    </div>
  </button>

  <button
    *ngIf="notif?.type == 'result_available'"
    class="notification"
    mat-menu-item
    [routerLink]="['/', 'runner', notif?.alias, 'results', notif?.reid]"
  >
    <div class="notification-content" fxFlex="90" fxLayout="row" (click)="changeNotificationStatus(notif?.id, 'read')">
      <div class="image-container">
        <img class="image" [src]="notif?.image | sanitizeUrl" />
      </div>
      <div class="text-container">
        <div
          class="text"
          [innerHTML]="
            notif?.model | transloco: { event_name: notif?.event_name, date: (notif?.date | date: 'dd/MM/yyyy') }
          "
        ></div>
        <div class="date">{{ notif?.createdDate | toRelativeDate }}</div>
      </div>
    </div>
  </button>

  <div class="notification-menu" fxFlex="30px" fxLayout="column" fxLayoutAlign="center center">
    <button mat-icon-button *ngIf="status !== 'unread'" (click)="changeStatus($event, 'unread')">
      <mat-icon class="small-button" matTooltipPosition="above" matTooltip="{{ 'MARK_AS_UNREAD' | transloco }}">
        trip_origin
      </mat-icon>
    </button>
    <button mat-icon-button *ngIf="status === 'unread'" (click)="changeStatus($event, 'read')">
      <mat-icon class="small-button" matTooltipPosition="above" matTooltip="{{ 'MARK_AS_READ' | transloco }}">
        lens
      </mat-icon>
    </button>
    <a mat-icon-button target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{ notif?.url }}">
      <mat-icon matTooltipPosition="above" matTooltip="{{ 'SHARE_ON_FACEBOOK' | transloco }}">share</mat-icon>
    </a>
  </div>
</div>
