import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  FRANCE_DEPARTMENTS,
  BELGIUM_PROVINCES,
  NETHERLANDS_PROVINCES,
  COVERED_COUNTRIES,
  getDepartmentFromPostalCode,
} from '@betrail-libs/shared/utils';
import { Runner } from '@betrail-libs/shared/interfaces/runner.model';
import { UsersService, UpdateUserAction } from '@betrail-libs/auth-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'welcome-step-region',
  templateUrl: './welcome-step-region.component.html',
  styleUrls: ['./welcome-step-region.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepRegionComponent implements OnInit, OnDestroy {
  departments = FRANCE_DEPARTMENTS.sort((a, b) => (a.code > b.code ? 1 : -1));
  beProvinces = BELGIUM_PROVINCES.sort((a, b) => (a.title > b.title ? 1 : -1));
  nlProvinces = NETHERLANDS_PROVINCES.sort((a, b) => (a.title > b.title ? 1 : -1));
  countries = COVERED_COUNTRIES;

  @Input() runner: Runner;
  @Input() user: any;
  @Input() userParams: any;

  @Output() next = new EventEmitter();

  saveButtonDisabled: boolean = false;
  saveText: string = 'SAVE';

  constructor(private usersService: UsersService, private store: Store) {}

  ngOnInit() {
    this.fillUserParams(this.runner);
  }

  // default regions selector

  nextStep() {
    let u = {
      field_user_params: { und: [{ value: JSON.stringify(this.userParams) }] },
    };
    this.saveButtonDisabled = true;
    this.saveText = 'SAVING';
    this.usersService
      .updateUser(this.user.uid, u)
      .pipe(untilDestroyed(this))
      .subscribe(updatedUser => {
        this.store.dispatch(new UpdateUserAction({ user: updatedUser }));
        this.next.emit();
        this.saveText = 'SAVE';
        this.saveButtonDisabled = false;
      });
  }

  fillUserParams(runner) {
    if (
      this.userParams &&
      (!this.userParams.defaultCountry || this.countries.indexOf(this.userParams.defaultCountry) < 0) &&
      runner.country
    ) {
      if (this.countries.indexOf(runner.country) > 0) {
        this.userParams.defaultCountry = runner.country;
      }
    }
    if (
      this.userParams &&
      !this.userParams.defaultDepartement &&
      runner.country &&
      runner.postal_code &&
      runner.country != 'LU'
    ) {
      let department = getDepartmentFromPostalCode(runner.postal_code, runner.country);
      if (department && !!this.departments) {
        this.userParams.defaultDepartment = department.code;
      }
    }
  }

  disableButton(type: String) {
    if (type == 'save') {
      this.saveButtonDisabled = true;
      this.saveText = 'SAVING';
    }
  }

  enableButton() {
    this.saveText = 'SAVE';
    this.saveButtonDisabled = false;
  }

  ngOnDestroy() {}
}
