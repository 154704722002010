<div class="container max-w-[416px]" ngClass.gt-sm="large">
  <div class="time" *ngIf="detailsType === 'performance'">
    <h2>{{ 'SIMULATOR_DETAIL_COMPUTE_LEVEL' | transloco }}</h2>
    <div>
      <p>
        {{ 'SIMULATOR_DETAIL_ESTIMATE_EXPLANATION' | transloco }} {{ simulation.targetedRace?.event?.event_name }} {{
        simulation.targetedRace?.date * 1000 | date: 'yyyy' }} · {{ simulation.targetedRace?.distance }}km
      </p>
    </div>
    <table width="100%">
      <tr *ngFor="let result of simulation.finalResults">
        <td class="first">{{ result.race.event.event_name }}</td>
        <td>{{ result.race.event.date * 1000 | date: 'yyyy' }}</td>
        <td>{{ result.race.distance }}km</td>
        <td class="last">{{ result.performance | number:'0.2-2' }}</td>
      </tr>
      <tr class="last">
        <td class="first" colspan="3"><strong>{{ 'SIMULATOR_DETAIL_PERF' | transloco }}</strong></td>
        <td class="last">
          <strong>{{ simulation.estimatedByBetrail | number:'0.2-2' }}</strong>
        </td>
      </tr>
    </table>
    <div>
      <p>{{ 'SIMULATOR_DETAIL_COMPUTE_BASED_PERF' | transloco }}</p>
    </div>
    <ul>
      <li>{{ 'SIMULATOR_DETAIL_REGULARITY' | transloco }} {{ simulation.regularity }}%</li>
      <li>{{ 'SIMULATOR_DETAIL_PROXIMITY' | transloco }} {{ simulation.trackProximity }}%</li>
      <li>{{ 'SIMULATOR_DETAIL_FIABILITY' | transloco }} {{ simulation.fiability }}%</li>
    </ul>
  </div>
  <div *ngIf="detailsType === 'time' && estimationType === 'simulation'">
    <h2>{{ 'SIMULATOR_DETAIL_COMPUTE_TIME' | transloco }}</h2>
    <div>
      <p>
        {{ 'SIMULATOR_DETAIL_TIME' | transloco :
        {event_name:simulation.targetedRace?.event?.event_name,distance:simulation.targetedRace?.distance,year:simulation.targetedRace?.date
        * 1000 | date: 'yyyy'} }}
      </p>
      <p>{{ 'SIMULATOR_DETAIL_TIME_END' | transloco }} {{ simulation.estimatedPerformance }}.</p>
    </div>
  </div>
  <div *ngIf="detailsType === 'time' && estimationType === 'prediction'">
    <h2>{{ 'SIMULATOR_DETAIL_COMPUTE_TIME' | transloco }}</h2>
    <div>
      <p>
        {{ 'SIMULATOR_DETAIL_TIME_EXPLANATION' | transloco :
        {distance:simulation.targetedRace?.distance,elevation:simulation.targetedRace?.elevation} }}
      </p>
      <p>
        {{ 'SIMULATOR_DETAIL_TIME_EXPLANATION_NEXT' | transloco : {performance : simulation.estimatedPerformance |
        number:'0.2-2'} }}
      </p>
    </div>
    <table width="100%">
      <tr *ngFor="let race of simulation.usedRaces">
        <td class="first">{{ race?.event?.event_name }}</td>
        <td>{{ race?.date * 1000 | date: 'yyyy' }}</td>
        <td>{{ race?.distance }}km</td>
        <td class="last">
          <span class="estimated-time-details">({{ simulation.targetedRace.distance }}km)&nbsp;</span>{{
          race?.estimatedTime | toHms }}
        </td>
      </tr>
      <tr class="last">
        <td class="first" colspan="3"><strong>{{ 'SIMULATOR_DETAIL_ESTIMATE' | transloco }}</strong></td>
        <td class="last">
          <strong>{{ simulation.estimatedTime | toHms }}</strong>
        </td>
      </tr>
    </table>
    <div>
      <p>{{ 'SIMULATOR_DETAIL_ACCURACY' | transloco }} {{ simulation.estimationDelta / 60 | number: '0.0-0' }}min.</p>
    </div>
  </div>
  <div class="actions" fxLayout="row" fxLayoutAlign="center">
    <button mat-button class="bt-button red" (click)="close()">{{ 'CLOSE' | transloco }}</button>
  </div>
</div>
