import { Inject, Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';

import { RaceData } from '@betrail-libs/encodage-state';

import { AuthStateService } from '@betrail-libs/auth-state';
import {
  ClaimOrUnclaimEventEncoding,
  EndRaceProcessing,
  LoadRaceProcessings,
  RemoveEventEncoding,
  StartRaceProcessing,
  UpdateRaceProcessing,
} from '@betrail-libs/encodage-state/model';
import { IClaimedElement } from '@betrail-libs/shared/interfaces/interfaces';
import { ClaimEvent, MarkClaimedEvents } from '@betrail-libs/trail-data-state';
import { PlatformServiceService } from '@betrail/ux/platform-service.service';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IoService {
  public isConnected = new BehaviorSubject(false);
  private server;
  private path;
  socket: Socket;
  lastData = {};

  constructor(
    @Inject('appsConfig') config,
    private store: Store,
    //private notificationService: NotificationService,
    private authState: AuthStateService,
    platform: PlatformServiceService,
  ) {
    this.server = config.wsServer;
    this.path = config.wsPath;

    if (platform.isBrowserRendering() && config.websocket) {
      this.authState.isLogged().subscribe(logged => {
        if (logged) {
          this.connect();
        } else {
          this.stopConnection();
        }
      });
    }
  }

  send(command, data) {
    this.socket.emit(command, data);
  }

  connect() {
    this.socket = io(this.server, {
      path: this.path,
      transports: ['websocket', 'polling'], // use WebSocket first, if available
    });
    this.isConnected.next(true);

    this.configureHandler();
  }

  resetConnection() {
    this.socket.disconnect();
    this.connect();
  }

  stopConnection() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.isConnected.next(false);
  }

  configureHandler() {
    this.socket.on('connect', () => {
      //get store race subscription and resubscribe if needed
      let races = this.store.selectSnapshot(state => state?.encodage?.currentRaceInterest);
      if (races?.length > 0) this.send('SUBSCRIBE_TO_RACES', races);
    });

    this.socket.on('RACE_PROCESS_END', (data: { id: string }) => {
      this.store.dispatch(new EndRaceProcessing(data));
    });

    this.socket.on('RACE_PROCESS_ERROR', (data: { id: string }) => {
      this.store.dispatch(new EndRaceProcessing(data));
    });

    this.socket.on('RACE_PROCESS_SYNC', (data: { states: any }) => {
      // this.raceProcessingService.allRaceProcessingSync(data);
    });

    this.socket.on(
      'RACE_PROCESS_START',
      (data: { id: string; data: { action: string; totalSteps: number; currentStep: number } }) => {
        this.store.dispatch(new StartRaceProcessing(data));
      },
    );

    this.socket.on(
      'RACE_PROCESS_UPDATE',
      (data: { id: string; data: { action: string; totalSteps: number; currentStep: number } }) => {
        this.store.dispatch(new StartRaceProcessing(data));
      },
    );

    this.socket.on('DATA_RESPONSE', data => {
      switch (data.path) {
        case 'race_processings':
          this.store.dispatch(new LoadRaceProcessings(data.body));
          break;
        case 'race_processing':
          if (data.body) this.store.dispatch(new UpdateRaceProcessing({ raceProcessing: data.body as RaceData }));

          break;
        case 'race_new_index':
          //this.store.dispatch(new UpsertRecomputedIndex({ newIndex: data.body }));
          // this.notificationService.updateNotification({
          //   notification: { ...data.body.error },
          // });

          break;
        case 'errors':
          // this.notificationService.updateNotification({
          //   notification: { ...data.body.error },
          // });

          break;
        case 'error':
          // this.notificationService.updateNotification({
          //   notification: { ...data.body.error },
          // });
          break;
        default:
          break;
      }
    });

    this.socket.on(
      'CALENDAR_ADDITION_RESPONSE',
      (data: { path: string; body: IClaimedElement | IClaimedElement[] }) => {
        switch (data.path) {
          case 'mark-claimed-events':
            this.store.dispatch(new MarkClaimedEvents(data.body as IClaimedElement[]));
            break;
          case 'claim-event':
            this.store.dispatch(new ClaimEvent(data.body as IClaimedElement));
          default:
            console.error(`IoService : Unknown path (${data.path}) for CALENDAR_ADDITION_RESPONSE`);
            break;
        }
      },
    );

    this.socket.on('EVENT_ENCODING_RESPONSE', (data: { body: IClaimedElement | number }) => {
      if (typeof data.body === 'number') {
        this.store.dispatch(new RemoveEventEncoding(data.body));
      } else {
        this.store.dispatch(new ClaimOrUnclaimEventEncoding(data.body));
      }
    });
  }
}
