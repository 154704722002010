export class SetCalendarDistanceMin {
  static readonly type = '[AppParams] Set calendar minimal distance';
  constructor(public calendarDistanceMin: number) {}
}

export class SetCalendarDistanceMax {
  static readonly type = '[AppParams] Set calendar maximal distance';
  constructor(public calendarDistanceMax: number) {}
}

export class SetCalendarDistance {
  static readonly type = '[AppParams] Set calendar distance';
  constructor(public calendarDistance: boolean) {}
}

export class SetCalendarElevationMin {
  static readonly type = '[AppParams] Set calendar minimal elevation';
  constructor(public calendarElevationMin: number) {}
}

export class SetCalendarElevationMax {
  static readonly type = '[AppParams] Set calendar maximal elevation';
  constructor(public calendarElevationMax: number) {}
}

export class SetCalendarElevation {
  static readonly type = '[AppParams] Set calendar elevation';
  constructor(public calendarElevation: boolean) {}
}

export class SetCalendarDropMin {
  static readonly type = '[AppParams] Set calendar minimal drop';
  constructor(public calendarDropMin: number) {}
}

export class SetCalendarDropMax {
  static readonly type = '[AppParams] Set calendar maximal drop';
  constructor(public calendarDropMax: number) {}
}

export class SetCalendarDrop {
  static readonly type = '[AppParams] Set calendar drop';
  constructor(public calendarDrop: boolean) {}
}

export class SetCalendarTitleFilter {
  static readonly type = '[AppParams] Set calendar title filter';
  constructor(public title: string) {}
}

export class SetCalendarTitle {
  static readonly type = '[AppParams] Set calendar title';
  constructor(public calendarTitle: boolean) {}
}

export class SetCalendarCountry {
  static readonly type = '[AppParams] Set calendar country';
  constructor(public calendarCountry: boolean) {}
}

export class SetCalendarSelectedCountry {
  static readonly type = '[AppParams] Set calendar seleted country';
  constructor(public calendarSelectedCountry: string) {}
}

export class SetCalendarMonth {
  static readonly type = '[AppParams] Set calendar month';
  constructor(public dates: any) {}
}

export class SetCalendarSelectedCountries {
  static readonly type = '[AppParams] Set calendar selectes countries';
  constructor(public selectedCountries: string[]) {}
}

export class SetCalendarDateStart {
  static readonly type = '[AppParams] Set calendar start date';
  constructor(public calendarDateStart: number) {}
}

export class SetCalendarDateEnd {
  static readonly type = '[AppParams] Set calendar end date';
  constructor(public calendarDateEnd: number) {}
}

export class SetCalendarDate {
  static readonly type = '[AppParams] Set calendar date';
  constructor(public calendarDate: boolean) {}
}

export class SetCalendarCategory {
  static readonly type = '[AppParams] Set calendar category';
  constructor(public calendarCategory: boolean) {}
}

export class SetCalendarCanceled {
  static readonly type = '[AppParams] Set calendar canceled';
  constructor(public calendarCanceled: boolean) {}
}

export class SetCalendarRegion {
  static readonly type = '[AppParams] Set calendar region';
  constructor(public calendarRegion: boolean) {}
}

export class SetCalendarSelectedRegion {
  static readonly type = '[AppParams] Set calendar selected region';
  constructor(public region: string) {}
}

export class SetCalendarDepartment {
  static readonly type = '[AppParams] Set calendar department';
  constructor(public calendarDepartment: boolean) {}
}

export class SetCalendarSelectedDepartment {
  static readonly type = '[AppParams] Set calendar selected department';
  constructor(public department: string) {}
}

export class SetCalendarGeolocation {
  static readonly type = '[AppParams] Set calendar geolocation';
  constructor(public calendarGeolocation: boolean) {}
}

export class SetCalendarGeolocationCoordinates {
  static readonly type = '[AppParams] Set calendar geolocation coordinates';
  constructor(public calendarGeolocationCoordinates: [number | string[], number, string?]) {}
}
export class SetCalendarSelectedCategories {
  static readonly type = '[AppParams] Set calendar selected catagories';
  constructor(public selectedCategories: string[]) {}
}
export class SetCalendarCanceledValue {
  static readonly type = '[AppParams] Set calendar canceled value';
  constructor(public canceled: string[]) {}
}
export class SetCalendarFilterSummary {
  static readonly type = '[AppParams] Set calendar filter summary';
  constructor(public key: string, public summary: string) {}
}
export class SetUserParams {
  static readonly type = '[AppParams] Set user params';
  constructor(public userParams: any) {}
}
export class SetDefaultCountry {
  static readonly type = '[AppParams] Set default country';
  constructor(public defaultCountry: string) {}
}
export class SetAvatarLater {
  static readonly type = '[AppParams] Set avatar later';
  constructor(public avatarLater: boolean) {}
}
export class SetConfirmationLater {
  static readonly type = '[AppParams] Set confirmation later';
  constructor(public confirmationLater: boolean) {}
}
export class SetResultSortDate {
  static readonly type = '[AppParams] Set result sort date';
}
export class SetResultSortDistance {
  static readonly type = '[AppParams] Set result sort distance';
}
export class SetResultSortElevation {
  static readonly type = '[AppParams] Set result sort elevation';
}
export class SetResultSortTime {
  static readonly type = '[AppParams] Set result sort time';
}
export class SetResultSortPoints {
  static readonly type = '[AppParams] Set result sort points';
}
export class SetResultSortPerformance {
  static readonly type = '[AppParams] Set result sort performance';
}
export class SetResultSortTrailName {
  static readonly type = '[AppParams] Set result sort trail name';
}
export class SetResultSortPercentage {
  static readonly type = '[AppParams] Set result sort ranking percentage';
}
export class SetResultSortPosition {
  static readonly type = '[AppParams] Set result sort positions';
}
export class SetResultSortRankingCourse {
  static readonly type = '[AppParams] Set result sort ranking course';
}
export class SetResultSortDesc {
  static readonly type = '[AppParams] Set result sort Desc';
  constructor(public isDesc: boolean) {}
}
export class SetResultFilterCountry {
  static readonly type = '[AppParams] Set result filter country';
  constructor(public resultFilterCountry: boolean) {}
}
export class SetResultFilterSelectedCountry {
  static readonly type = '[AppParams] Set result filter selected country';
  constructor(public resultFilterSelectedCountry: string[]) {}
}
export class SetResultFilterDistance {
  static readonly type = '[AppParams] Set result filter distance';
  constructor(public resultFilterDistance: boolean) {}
}
export class SetResultFilterSelectedDistance {
  static readonly type = '[AppParams] Set result filter selected distance';
  constructor(public resultFilterSelectedDistance: number[]) {}
}
export class SetResultFilterElevation {
  static readonly type = '[AppParams] Set result filter elevation';
  constructor(public resultFilterElevation: boolean) {}
}
export class SetResultFilterSelectedElevation {
  static readonly type = '[AppParams] Set result filter selected elevation';
  constructor(public resultFilterSelectedElevation: number[]) {}
}
export class SetResultFilterDrop {
  static readonly type = '[AppParams] Set result filter drop';
  constructor(public resultFilterDrop: boolean) {}
}
export class SetResultFilterSelectedDrop {
  static readonly type = '[AppParams] Set result filter selected drop';
  constructor(public resultFilterSelectedDrop: number[]) {}
}
export class SetResultFilterCategory {
  static readonly type = '[AppParams] Set result filter category';
  constructor(public resultFilterCategory: boolean) {}
}
export class SetResultFilterSelectedCategory {
  static readonly type = '[AppParams] Set result filter selected category';
  constructor(public resultFilterSelectedCategory: string[]) {}
}
export class SetResultFilterDate {
  static readonly type = '[AppParams] Set result filter date';
  constructor(public resultFilterDate: boolean) {}
}
export class SetResultFilterDateStart {
  static readonly type = '[AppParams] Set result filter date start';
  constructor(public resultFilterDateStart: number | Date) {}
}
export class SetResultFilterDateEnd {
  static readonly type = '[AppParams] Set result filter date end';
  constructor(public resultFilterDateEnd: number | Date) {}
}
export class SetResultFilterRunType {
  static readonly type = '[AppParams] Set result filter run type';
  constructor(public resultFilterRunType: boolean) {}
}
export class SetResultFilterSelectedRunType {
  static readonly type = '[AppParams] Set result filter selected run type';
  constructor(public resultFilterSelectedRunType: string[]) {}
}
export class SetCalendarFilterRunType {
  static readonly type = '[AppParams] Set calendar filter run type';
  constructor(public calendarFilterRunType: boolean) {}
}
export class SetCalendarFilterSelectedRunType {
  static readonly type = '[AppParams] Set calendar filter selected run type';
  constructor(public calendarFilterSelectedRunType: string[]) {}
}

export class SetResultFilterRaceType {
  static readonly type = '[AppParams] Set result filter race type';
  constructor(public resultFilterRaceType: boolean) {}
}
export class SetResultFilterSelectedRaceType {
  static readonly type = '[AppParams] Set result filter selected race type';
  constructor(public resultFilterSelectedRaceType: string[]) {}
}

export class SetDisplayFixedRaceDistanceSubPageMenu {
  static readonly type = '[AppParams] Set display fixed distance subpage menu';
  constructor(public displayFixedRaceDistanceSubPageMenu: boolean) {}
}
