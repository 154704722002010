import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IUserState } from '@betrail-libs/auth-state';
import { EventService, LoadRunnerForAlias } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
var clusterMaker = require('clusters');

@UntilDestroy()
@Component({
  selector: 'welcome-step-results-check',
  templateUrl: './welcome-step-results-check.component.html',
  styleUrls: ['./welcome-step-results-check.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepResultsCheckComponent {
  _runner: any;
  closeRunners: any;
  results$ = new BehaviorSubject<any>([]);
  results = [];
  loading = true;
  searching = true;
  buttonText: string = 'SAVE';
  geoclusters: any;
  totalResults: number;
  selectedResults: any;
  predictedLevel: any;
  probableResults: any[];
  potentialResults: any;

  resultsFormGroup: UntypedFormGroup = this.fb.group({});

  options = [
    {
      label: 'YES',
      value: 'yes',
      style: 'green',
      disabled: false,
    },
    {
      label: 'NO',
      value: 'no',
      style: 'red',
      disabled: false,
    },
  ];
  private _user: IUserState;
  userParams: any;
  maxToShow: number = 10;

  stats$ = this.eventService.getStats();
  newSelectedResults: any;
  currentResultsIdentifiedAsOnlyProbable: any;
  otherProbableResultsNotLinkedYet: any;
  createdFor: number;

  constructor(private eventService: EventService, private fb: UntypedFormBuilder, private store: Store) {}

  @Output() next = new EventEmitter();

  @Input('user') set user(u: IUserState) {
    this._user = u;
    this.createdFor = new Date().getTime() / 1000 - u.created;
    this.userParams = {};
    if (this._user?.userDrupal?.userParams) {
      this.userParams = JSON.parse(this._user.userDrupal.userParams);
    }
  }
  @Input() isLightUser = false;
  @Input('runner') set runner(runner) {
    this._runner = runner;
    if (!this.potentialResults) {
      let i = this.eventService
        .getRunnerPotentialResults(runner.id)
        .pipe(untilDestroyed(this))
        .subscribe(r => {
          this.potentialResults = r;
          this.newSelectedResults = r.selectedResults.filter(a => a.isNew == true);
          this.currentResultsIdentifiedAsOnlyProbable = r.probableResults
            .filter(a => a.isNew == false)
            .sort((a, b) => b.date - a.date);
          this.otherProbableResultsNotLinkedYet = r.probableResults.filter(a => a.isNew == true);
          if (
            this.potentialResults &&
            this.potentialResults.selectedResults &&
            this.potentialResults.selectedResults.length > 0
          ) {
            this.potentialResults.selectedResults.forEach(r => {
              let fg = this.fb.group({
                selected: ['yes', Validators.required],
                verified: ['yes'],
              });
              this.resultsFormGroup.addControl(r.reid, fg);
            });
          }
          if (
            this.potentialResults &&
            this.potentialResults.probableResults &&
            this.potentialResults.probableResults.length > 0
          ) {
            this.potentialResults.probableResults.forEach(r => {
              let fg = this.fb.group({
                selected: ['no', Validators.required],
                verified: ['yes'],
              });
              this.resultsFormGroup.addControl(r.reid, fg);
            });
          }

          // if we display only new selected results, all other selected results will not be verified
          if (this.potentialResults.runnerHasResults == true && this.createdFor >= 3600) {
            this.potentialResults.selectedResults.forEach(r => {
              if (r.isNew == false) {
                this.resultsFormGroup.controls[r.reid].patchValue({ verified: 'no' });
              }
            });
          }

          if (
            this.currentResultsIdentifiedAsOnlyProbable &&
            this.currentResultsIdentifiedAsOnlyProbable.length > 0 &&
            this.createdFor >= 3600
          ) {
            this.currentResultsIdentifiedAsOnlyProbable.forEach(r => {
              let values = { selected: '', verified: 'yes' };
              this.resultsFormGroup.controls[r.reid].patchValue(values);
            });
          }
          this.loading = false;
          this.searching = false;
          if (this.potentialResults.selectedResults.length == 0 && this.potentialResults.probableResults.length == 0) {
            this.buttonText = 'CONTINUE';
          }
        });
    }
  }

  setOptionsDisabled(disabled) {
    this.options = this.options.map(o => {
      return { ...o, disabled };
    });
  }

  showAll() {
    this.maxToShow = 9999999;
  }

  nextStep() {
    if (this.resultsFormGroup.value) {
      this.loading = true;
      this.buttonText = 'SAVING';
      this.setOptionsDisabled(true);
      this.eventService
        .setCheckedResults(this._runner.id, this.resultsFormGroup.value, this._user)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.store.dispatch(new LoadRunnerForAlias(this._runner.alias));
          this.loading = false;
          this.setOptionsDisabled(false);
          this.buttonText = 'SAVE';
          this.next.emit();
        });
    } else {
      this.next.emit();
    }
  }
}
