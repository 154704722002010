<event-header [trail]="trail"></event-header>
<div class="default-form">
  <div class="center-align">
    <h2>{{ 'CONFIRMATION' | transloco }}</h2>
    <mat-icon class="green big">check</mat-icon>
    <p class="message title">
      {{ 'TRAIL_ORGA_LINKED_OK' | transloco: { trail: trail.title, organization: organization.name } }}
    </p>
    <div class="actions flex flex-col">
      <button
        mat-button
        class="bt-button orange"
        [routerLink]="['/', 'organizer', 'my-organization']"
        (click)="close()"
      >
        {{ 'DISCOVER_ORGA_SPACE' | transloco }}
      </button>
      <button mat-button class="bt-button !mt-4" (click)="close()">{{ 'CLOSE' | transloco }}</button>
    </div>
  </div>
</div>
