import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpBackend,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  refreshTokenRequest = new HttpRequest(
    'GET',
    this.config.nodejsApi + '/user/token',
    {},
    {
      withCredentials: true,
      responseType: 'json',
    },
  );

  constructor(@Inject('appsConfig') private config: any, private client: HttpBackend) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            return this.client.handle(this.refreshTokenRequest).pipe(
              filter(event => {
                return event instanceof HttpResponse || event instanceof HttpErrorResponse;
              }),
              switchMap(() => {
                request = request.clone();
                // retry with refresh Token
                return next.handle(request);
              }),
            );
          }
        }

        return throwError(err);
      }),
    );
  }
}
