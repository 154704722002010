<header class="form-title">{{ trail.title }}</header>
<ng-container *ngIf="user && user.uid && (+user.uid !== 0 || user.uid !== '0'); else pleaseLogin">
  <ng-container *ngIf="formActivated === true; else warning">
    <ng-container *ngIf="datesWarning === false; else datesChangeWarning">
      <form [formGroup]="mainForm">
        <div class="form-container" [class.loading]="loading$ | async">
          <!-- <bt-card>
          <ng-container card-title> {{ "GENERAL_INFORMATION" | transloco }} </ng-container>
          <div class="card-body" card-body> -->

          <header>
            <h3 class="first">{{ 'GENERAL_INFORMATIONS' | transloco }}</h3>
          </header>
          <bux-trail-form #trailForm></bux-trail-form>
          <!-- </div>
        </bt-card> -->

          <header>
            <h3>{{ 'EDITION' | transloco }} {{ event.date * 1000 | date: 'yyyy' }}</h3>
          </header>
          <bux-event-form #eventForm></bux-event-form>
          <!-- <bt-card>
            <ng-container card-title> {{ "GENERAL_INFORMATION" | transloco }} </ng-container>
            <div class="card-body" card-body>

            </div>
        </bt-card> -->

          <div class="spinner-container" *ngIf="loading$ | async">
            <mat-spinner strokeWidth="3" class="spinner-indicator"></mat-spinner>
          </div>
        </div>
        <div class="actions-container">
          <div class="textarea-label" [class.red]="!mainForm.get('message').valid">Message aux modérateurs</div>
          <textarea
            class="moderator-message"
            rows="2"
            matInput
            appearance="outline"
            formControlName="message"
            placeholder="Expliquez pourquoi vous avez modifié ces informations."
          ></textarea>
          <button mat-button (click)="onCancelClick()">{{ 'CANCEL' | transloco }}</button>
          <button
            class="confirm-button"
            mat-raised-button
            [disabled]="(loading$ | async) || !mainForm.valid || !mainForm.dirty || watchEmail(mainForm.value?.trail?.organizer_email)"
            (click)="onOkClick()"
          >
            {{ ((loading$ | async) ? 'SAVING' : 'SAVE') | transloco }}
          </button>
        </div>
      </form>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #pleaseLogin>
  <div class="message">Please log in to use this feature.</div>
  <button mat-button (click)="close()">{{ 'CLOSE' | transloco }}</button>
</ng-template>

<ng-template #warning>
  <div class="message">
    <div innerHTML="{{ 'FORMS_WARNING' | transloco }}"></div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>
        <button mat-raised-button (click)="goToForm()">{{ 'UNDERSTOOD' | transloco }}</button>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #datesChangeWarning>
  <div class="message">
    <div innerHTML="{{ 'MULTIPLE_DATES_EVENT_CHANGE_WARNING' | transloco }}"></div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>
        <button mat-raised-button (click)="close()">{{ 'UNDERSTOOD' | transloco }}</button>
      </p>
    </div>
  </div>
</ng-template>
