import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Race } from '@betrail-libs/shared/interfaces/race.model';

@Component({
  selector: 'app-edit-race-index-dialog',
  template: `
    <section>
      <h2 class="text-center text-base">{{ 'EDIT_RACE_INDEX' | transloco }}</h2>
      <hr class="mt-4" />

      <div class="p-4">
        <mat-form-field appearance="outline" class="my-4 w-full">
          <mat-label>Index</mat-label>
          <input matInput type="number" [formControl]="indexForm" />
        </mat-form-field>

        <div class="flex justify-between">
          <button mat-raised-button mat-dialog-close color="warn" class="w-28 !mr-4">{{ 'CANCEL' | transloco }}</button>
          <button mat-raised-button color="accent" class="w-28" [disabled]="indexForm.invalid" (click)="saveIndex()">
            {{ 'SAVE' | transloco }}
          </button>
        </div>
      </div>
    </section>
  `,
})
export class EditRaceIndexDialogComponent {
  indexForm: FormControl = new FormControl('', Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { race: Race },
    private dialogRef: MatDialogRef<EditRaceIndexDialogComponent>,
  ) {
    this.indexForm.patchValue(data.race.betrail_index);
  }

  saveIndex() {
    this.dialogRef.close(this.indexForm.value);
  }
}
