import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISimulation } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-race-simulator-details',
  templateUrl: './race-simulator-details.component.html',
  styleUrls: ['./race-simulator-details.component.scss'],
})
export class RaceSimulatorDetailsComponent {
  @Input() simulation: ISimulation;
  @Input() detailsType: string;
  @Input() estimationType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      simulation: ISimulation;
      type: string;
      estimationType: string;
    },
    public dialogRef: MatDialogRef<RaceSimulatorDetailsComponent>,
  ) {
    this.simulation = data.simulation;
    this.detailsType = data.type;
    this.estimationType = data.estimationType;
  }

  close() {
    this.dialogRef.close();
  }
}
