export const DEFAULT_WELCOME_STEPS = {
  welcome: { next: 'identity', display: true },
  identity: { next: 'results', display: true },
  results: { next: 'resultsClaimingTuto', display: true },
  resultsClaimingTuto: { next: 'emailconfirmation', display: true },
  emailconfirmation: { next: 'avatar', display: true },
  avatar: { next: 'done', display: true },
  done: { next: false, display: true },
};

export const ENCODING_TEXTS = {
  CREATE_RUNNER_NODE: 'Publication des résultats...',
  SAVE_DATA: 'Action en cours ...',
  COMPUTE_RACE: "Calcul de l'indice...",
  RUNNER_IDENTIFICATION: 'Identification des coureurs...',
  RE_COMPUTE_RACE: "Recalcul de l'indice en cours...",
  file: 'Choisissez un fichier Excel ou PDF.',
  columnId: 'Identifiez les colonnes. Requis : NOM et PRÉNOM, GENRE, TEMPS',
  checkRunner: 'Cliquez sur "Lancer l\'identification".',
  //'checkRunner' : 'Vérifiez l\'identification des coureurs en cliquant sur chaque case orange.',
  savingResult: 'Vérifiez les performances. Sont-elles cohérentes ?',
  savingRunner: 'Cliquez sur "Publier sur Betrail" pour enregistrer.',
  sendingNotifs: "Vérifiez bien les perfs avant d'envoyer les notifs !",
  processDone: 'Félicitations ! Les résultats sont maintenant sur Betrail =).',
};

export const LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const LEVEL_RANKING_DISTANCES = [10, 21, 42, 80, 160, 320];

export const LEVEL_RANKING_ELEVATIONS = [7, 20, 50, 120];

export const LIST_AMENITIES = [
  'douches',
  'parking',
  'bar',
  'restauration',
  'kine',
  'consigne',
  'garderie',
  'jeux_enfants',
  'animations',
  'soiree',
];

export const LIST_AMENITIES_LABELS = {
  douches: 'AM_DOUCHES',
  parking: 'AM_PARKING',
  bar: 'AM_BAR',
  restauration: 'AM_RESTAURATION',
  kine: 'AM_KINE',
  consigne: 'AM_CONSIGNE',
  garderie: 'AM_GARDERIE',
  jeux_enfants: 'AM_JEUX_ENFANTS',
  animations: 'AM_ANIMATIONS',
  soiree: 'AM_SOIREE',
};

export const LIST_CATEGORIES =
  'j|c|s|v|e|ve|se|veteran|veterans|master|masters|sen|senior|seniors|es|esp|espoir|espoirs|ju|jun|junior|juniors|ca|cad|cadet|cadets|mi|min|minime|minimes|be|po|ea|bb|e';

export const LIST_GENDER_NAMES = 'homme|femme|hommes|femmes|dames|heren|dame|heer';

export const MONTH_STRINGS = {
  janvier: 1,
  fevrier: 2,
  mars: 3,
  avril: 4,
  mai: 5,
  juin: 6,
  juillet: 7,
  aout: 8,
  septembre: 9,
  octobre: 10,
  novembre: 11,
  decembre: 12,
  januari: 1,
  februari: 2,
  maart: 3,
  april: 4,
  mei: 5,
  juni: 6,
  juli: 7,
  augustus: 8,
  september: 9,
  oktober: 10,
  november: 11,
  december: 12,
  january: 1,
  february: 2,
  march: 3,
  //april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  //september: 9,
  october: 10,
  //november: 11,
  //december: 12
};

export const MONTHS_FR = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juilllet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const ORDER_STATUS = {
  Completed: {
    icon: 'check_circle',
    status: 'Completed',
    class: 'success',
  },
  failed: {
    icon: 'warning',
    status: 'failed',
    class: 'failed',
  },
  Canceled: {
    icon: 'warning',
    status: 'Canceled',
    class: 'failed',
  },
  new: {
    icon: 'hourglass_empty',
    status: 'new',
    class: 'pending',
  },
};

export const RANKINGS_DISTANCES = ['ALL', 'KV', '10', '21', '42', '80', '160', '320'];

export const REG_STATUS = {
  Completed: {
    icon: 'check_circle',
    status: 'Completed',
    class: 'success',
  },
  failed: {
    icon: 'warning',
    status: 'failed',
    class: 'failed',
  },
  Canceled: {
    icon: 'warning',
    status: 'Canceled',
    class: 'failed',
  },
  Revoked: {
    icon: 'warning',
    status: 'Canceled',
    class: 'failed',
  },
  Created: {
    icon: 'hourglass_empty',
    status: 'new',
    class: 'pending',
  },
};

export const TRACK_LIMIT_NOT_PREMIUM = 2;
// ! if this value is changed, it must be changed in the database trigger as well
// => betrail > users_roles > triggers > usersroles_afterdelete > update >  limit 2 as older_two

export const VALUES_FEMALE = new Set([
  'f',
  'F',
  'v',
  'V',
  'd',
  'D',
  'da',
  'DA',
  'w',
  'W',
  'Femme',
  'femme',
  'FEMME',
  'Femmes',
  'femmes',
  'FEMMES',
  'Dames',
  'dames',
  'DAMES',
  'dame',
  'Dame',
  'DAME',
  'a1',
  'a2',
  'a3',
  'a4',
  'a5',
  'a6',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  '1',
]);

export const VALUES_MALE = new Set([
  'h',
  'g',
  'G',
  's',
  'S',
  'se',
  'SE',
  'H',
  'm',
  'M',
  'Homme',
  'homme',
  'HOMME',
  'Hommes',
  'hommes',
  'HOMMES',
  'heren',
  'Heren',
  'HEREN',
  'v1',
  'v2',
  'v3',
  'v4',
  'v5',
  'v6',
  'V1',
  'V2',
  'V3',
  'V4',
  'V5',
  'V6',
  '0',
]);

export const VALUES_RACE_TYPE = [
  'solo',
  'team_2',
  'team_3',
  'team_4',
  'team_5',
  'relay',
  'multistage',
  'multistage_duo',
  'multistage_team',
  'clock',
  'stop',
  'walk',
  'nordic_walking',
  'canicross',
];

export const VALUES_RESULT_SORT_PARAMS = [
  'DATE',
  'DISTANCE',
  'PERFORMANCE',
  'POINTS',
  //'RANK',
  'TIME',
  'TRAIL_NAME',
  'PERCENTAGE',
  'POSITION',
];

export const VALUES_RUN_TYPE = ['trail', 'nature_race', 'road_race', 'other'];
