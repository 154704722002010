<form [formGroup]="editRankingForm">
  <div class="form-title">
    <h3>{{ 'RANKING' | transloco }} {{ options.rankingName }} {{ options.country }}</h3>
    <hr />
  </div>

  <mat-form-field appearance="outline" class="input">
    <mat-label>{{ 'RANKING_DISPLAY_NAME' | transloco }}</mat-label>
    <input matInput formControlName="rankingName" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="input">
    <mat-label>{{ 'SPONSOR_DISPLAY_NAME' | transloco }}</mat-label>
    <input matInput formControlName="sponsorName" />
  </mat-form-field>

  <div class="sponsor-selector">
    <h4>{{ 'SPONSOR_LOGO' | transloco }}</h4>
    <bux-search-sponsor
      [existingSponsor]="options.sponsor || null"
      (selectedSponsor)="selectSponsor($event)"
    ></bux-search-sponsor>
  </div>

  <div class="btn-container">
    <button
      type="submit"
      color="accent"
      mat-raised-button
      (click)="updateRanking()"
      [disabled]="!editRankingForm.valid"
    >
      {{ 'SAVE' | transloco }}
    </button>
    <button color="warn" mat-raised-button (click)="closeDialog()">{{ 'CANCEL' | transloco }}</button>
  </div>
</form>
