import { Component, Input } from '@angular/core';
import { getDepartmentFromPostalCode, FRANCE_DEPARTMENTS } from '@betrail-libs/shared/utils';

@Component({
  selector: 'place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css'],
})
export class PlaceComponent {
  pc: any;
  _place: any;
  _uriPlace: string;
  _department: { code: string; title: string; region: string; url_alias: string };

  @Input('place') set place(place) {
    this._place = place;
    this._uriPlace = encodeURI(place);
  }
  @Input() country;
  @Input('postal') set setPostal(postal) {
    this.pc = postal;
    if (this.pc && this.country) {
      this._department = getDepartmentFromPostalCode(this.pc, this.country);
    }
  }
  @Input() section;
  @Input() longitude;
  @Input() latitude;
}
