<header class="form-title">{{ timer.title }}</header>
<ng-container *ngIf="user && user.uid && +user.uid !== 0; else pleaseLogin">
  <form [formGroup]="mainForm">
    <div class="form-container" [class.loading]="loading$ | async">
      <bux-timer-form #timerForm></bux-timer-form>

      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner strokeWidth="3" class="spinner-indicator"></mat-spinner>
      </div>
    </div>
    <div class="actions-container">
      <button mat-button (click)="onCancelClick()">{{ 'CANCEL' | transloco }}</button>
      <button
        class="confirm-button"
        mat-raised-button
        [disabled]="(loading$ | async) || !mainForm.valid"
        (click)="onOkClick()"
      >
        {{ ((loading$ | async) ? 'SAVING' : 'SAVE') | transloco }}
      </button>
    </div>
  </form>
</ng-container>

<ng-template #pleaseLogin>
  <div class="message">Please log in to use this feature.</div>
  <button mat-button (click)="close()">{{ 'CLOSE' | transloco }}</button>
</ng-template>

<ng-template #warning>
  <div class="message">
    <div innerHTML="{{ 'FORMS_WARNING' | transloco }}"></div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>
        <button mat-raised-button (click)="goToForm()">{{ 'UNDERSTOOD' | transloco }}</button>
      </p>
    </div>
  </div>
</ng-template>
