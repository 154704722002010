import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'bux-active-orders-message',
  templateUrl: './active-orders-message.component.html',
  styleUrls: ['./active-orders-message.component.scss'],
})
export class ActiveOrdersMessageComponent {
  constructor(private router: Router, public snackBarRef: MatSnackBarRef<ActiveOrdersMessageComponent>) {}

  goToOrders() {
    this.router.navigate(['/', 'cart', 'history']);
    this.close();
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
