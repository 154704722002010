<div class="content">
  <header class="form-title">{{ 'DELETE_REGISTRATION' | transloco }}</header>

  <div class="form-content">
    <ng-container *ngIf="data?.isFree; else paidRegistration">
      <p class="warning">
        {{ 'DELETE_FREE_REGISTRATION_WARNING' | transloco:{email: data?.registration?.formData?.email} }}
      </p>
    </ng-container>
    <ng-template #paidRegistration>
      <p *ngIf="data?.registration?.status === 'Completed'" class="warning">
        {{ 'DELETE_COMPLETED_REGISTRATION_WARNING' | transloco:{email: data?.registration?.formData?.email} }}
      </p>
    </ng-template>
    <p><strong>{{ 'CONFIRM_DELETE_REGISTRATION_TEXT' | transloco }}</strong></p>
    <div class="actions-container" fxLayout="row" fxLayoutAlign="space-between">
      <button class="button" mat-button (click)="close()">{{ 'CANCEL' | transloco }}</button>
      <button class="button color" mat-raised-button (click)="save()">{{ 'CONFIRM' | transloco }}</button>
    </div>
  </div>
</div>
