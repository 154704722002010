import { Component, Input } from '@angular/core';
import { IRunnerVisualizerData } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-runner-table-visualizer',
  templateUrl: './runner-table-visualizer.component.html',
})
export class RunnerTableVisualizerComponent {
  @Input() runner: IRunnerVisualizerData;
  @Input() allowLink = true;
}
