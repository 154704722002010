<div class="dialog-box">
  <h3>{{ data.title | transloco }}</h3>
  <hr />
  <p>{{ data.message | transloco: data.replacements }}</p>
  <ng-container *ngIf="data.messageSuppl !== ''">
    <p>{{ data.messageSuppl | transloco }}</p>
  </ng-container>
  <div class="btn-container">
    <button mat-raised-button color="accent" (click)="closeDialog()">OK</button>
  </div>
</div>
