<section class="max-w-[650px]">
  <h2 class="text-center text-2xl">{{'DETECTED_PENDING_SUB' | transloco}}</h2>
  <premium-subscription-preview
    [subscription]="data"
    [options]="{showActions : true, isFromDialog: true}"
    (subscriptionDeleted)="close()"
    (goToPayment)="close()"
    (remindLater)="postponeNextReminder()"
  >
  </premium-subscription-preview>
</section>
