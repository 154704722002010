<result-form-header [result]="data.result"></result-form-header>
<div class="container">
  <div class="trail">
    <ng-container *ngIf="user$ | async ; else login; let user">
      <ng-container *ngIf="user?.roles; else login">
        <ng-container *ngIf="isRunner$ | async; else validateEmail">
          <ng-container *ngIf="user?.runner?.id > 0; else linkToRunner">
            <ng-container *ngIf="step === 1">
              <div class="question">{{ 'ARE_YOU_SHURE_TO_CLAIM_THIS_RESULT' | transloco }} ?</div>
              <div>
                {{ 'RESULT_CLAIM_ASKINGJ_CONFIRMATION' | transloco : { distance: data.result.race.distance, trail:
                data.result.race.event.event_name, time: data.result.result_seconds | toHms, date: data.result.date *
                1000 | date } }}
              </div>
              <div class="actions" fxLayout="row" fxLayoutGap="0.7em" fxLayoutAlign="center">
                <button class="button light-button" mat-button (click)="cancel()">{{ 'CANCEL' | transloco }}</button>
                <button class="button" (click)="confirm(data.result)" mat-raised-button>{{ 'YES' | transloco }}</button>
              </div>
            </ng-container>
            <ng-container *ngIf="step === 2">
              <div class="know" *ngIf="response === 'DONE'; else working">
                <mat-icon class="green">check</mat-icon> {{ 'DONE' | transloco }}
              </div>
            </ng-container>
            <ng-template #working> {{ 'WORKING' | transloco }} </ng-template>
          </ng-container>
          <ng-template #linkToRunner>
            <div class="question">{{ 'ACCOUNT_NOT_LINKED_TO_RUNNER' | transloco }}</div>
            <div>
              {{ 'CONTACT_US_TO_FIX_THIS_PROBLEM' | transloco }} :
              <a href="mailto:help@betrail.run">help&#64;betrail.run</a>.
            </div>
          </ng-template>
        </ng-container>
        <ng-template #validateEmail>
          <div class="question">{{ 'ACCOUNT_NOT_VALID' | transloco }}</div>
          <div>{{ 'CONFIRM_YOUR_EMAIL_TO_USE_THIS_FEATURE' | transloco }}</div>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #login>
      <div class="question">{{ 'IS_THIS_RESULT_YOURS' | transloco }}</div>
      <p>{{'LOG_IN_TO_USE_THIS_FEATURE' | transloco }}</p>
      <p>{{ 'ACCOUNT_CREATION_EXPLANATION' | transloco }}</p>
    </ng-template>
  </div>
</div>
