<article [style.max-width.px]="false || 500" class="max-h-[90vh] overflow-y-auto">
  <header class="mt-[-14px] text-center">
    <h2 class="text-4xl font-medium" [innerHtml]="data.popup['title' + data.lang] | safe: 'html'"></h2>
  </header>
  <section>
    <div *ngIf="data.popup.hasImage" class="px-[24px] text-center relative">
      <img [src]="apiUri + '/image/pop-up/' + data.popup.id" class="w-full h-auto" />
    </div>

    <p class="p-[24px] text-center text-sm" [innerHtml]="data.popup['text' + data.lang] | safe: 'html' "></p>
  </section>
  <footer class="flex" [style.justify-content]="data?.popup?.link ? 'space-between' : 'center'">
    <button
      mat-raised-button
      mat-dialog-close
      (click)="createPopupLog()"
      class="!m-[24px] !mt-0 w-[125px] !bg-gray-400 !text-white"
    >
      {{ 'HAVE_READ' | transloco }}
    </button>
    <a
      *ngIf="data?.popup?.link"
      mat-raised-button
      cdkFocusInitial
      color="accent"
      class="!no-underline !m-[24px] !mt-0 w-[125px]"
      [href]="data.popup.link"
      target="_blank"
      (click)="createPopupLog(true)"
      mat-dialog-close
    >
      {{ 'LEARN_MORE' | transloco }}
    </a>
  </footer>
</article>
