export interface IPopUp {
  id: number;
  status: EPopUpStatus;
  country: string;
  dateStart: Date;
  dateStop: Date;
  titleFr: string;
  titleNl: string;
  titleEn: string;
  textFr: string;
  textNl: string;
  textEn: string;
  link?: string;
  imgSmall?: Buffer;
  imgLarge?: Buffer;
  hasSmallImg?: boolean;
  hasLargeImg?: boolean;
  views: number;
  clickOk: number;
  clickLink: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPopUpData {
  id: number;
  type: EPopUpType;
  status: EPopUpStatus;
  target: EPopUpTarget;
  country: string;
  dateStart: Date;
  dateStop: Date;
  titleFr: string;
  titleNl: string;
  titleEn: string;
  textFr: string;
  textNl: string;
  textEn: string;
  link?: string;
  image?: Buffer;
  hasImage?: boolean;
  views: number;
  clickOk?: number;
  clickLink?: number;
  logs?: IPopUpLog[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IPopUpLog {
  id: number;
  uid: number;
  pdid: number;
  click: EPopUpClickType;
  timeOpen: number;
  device: string;
  createdAt: Date;
}

export enum EPopUpTarget {
  all = 'all',
  account = 'account',
  premium = 'premium',
}

export enum EPopUpType {
  article = 'article',
  tips = 'tips',
  promoBetrail = 'promoBetrail',
  promoOrga = 'promoOrga',
  promoOther = 'promoOther',
}

export enum EPopUpClickType {
  ok = 'ok',
  link = 'link',
}

export enum EPopUpStatus {
  created = 'created',
  active = 'active',
  paused = 'paused',
  stopped = 'stopped',
}
