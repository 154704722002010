import { Component, Inject, ViewChild, inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService, IUserState } from '@betrail-libs/auth-state';
import { DrupalRole, Trail } from '@betrail-libs/shared/interfaces/interfaces';
import { asRoleId, toHMS } from '@betrail-libs/shared/utils';
import { LoadTrailForAlias, TrailDataService } from '@betrail-libs/trail-data-state';
import { EventService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { DialogResult } from '../../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../../common/dialog/dialogResultStatus';
import { RaceFormComponent } from '../../../../shared/race-form/race-form.component';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { Race } from '@betrail-libs/shared/interfaces/race.model';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { EditRaceDialogData } from './editRaceDialogData';
import { DialogService } from '../../../../common/dialog/dialogService';
import { TranslocoService } from '@ngneat/transloco';
import { BetrailUxFormsService } from '@betrail/ux';

@UntilDestroy()
@Component({
  selector: 'app-edit-race-dialog',
  templateUrl: './edit-race-dialog.component.html',
  styleUrls: ['./edit-race-dialog.component.scss'],
})
export class EditRaceDialogComponent {
  dialogService = inject(DialogService);
  transloco = inject(TranslocoService);

  /* Members  */
  toggleMore = false;
  raceForm: UntypedFormGroup;
  several_days;
  datesInfos;
  //availableDates : any[] = [];
  race: Race;
  event: IEvent;
  trail: Trail;
  user: IUserState;
  isOrga: boolean;
  isAdmin$ = this.authState.hasRole(DrupalRole.Administrator);
  isEncoder$ = this.authState.hasRole(DrupalRole.Encoder);
  isOrganizer$ = this.authState.hasOrgaRight();
  loading$ = new BehaviorSubject<boolean>(false);

  /* ViewChilds */
  @ViewChild('raceFormComponent') raceFormComponent: RaceFormComponent;
  formActivated = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private trailDataService: TrailDataService,
    private store: Store,
    private authState: AuthStateService,
    private formService: BetrailUxFormsService,
    private dialogRef: MatDialogRef<EditRaceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditRaceDialogData,
  ) {
    this.race = this.data.race;
    this.event = this.data.event;
    this.trail = this.data.trail;
    combineLatest([this.authState.getUser(), this.authState.hasOrgaRight()])
      .pipe(untilDestroyed(this))
      .subscribe(([u, isOrga]) => {
        this.user = u;
        this.isOrga = isOrga;
        if (
          asRoleId(u, DrupalRole.Administrator) ||
          asRoleId(u, DrupalRole.Encoder) ||
          (asRoleId(u, DrupalRole.VerifiedOrganizer) && isOrga)
        ) {
          this.formActivated = true;
          this.patchValues();
        }
      });

    const messageNotRequired =
      this.isOrga || this.user?.roles.map(r => r.rid).includes(DrupalRole.Encoder, DrupalRole.Administrator);
    this.raceForm = this.formBuilder.group({});
    this.raceForm.addControl(
      'message',
      new UntypedFormControl(
        this.isOrga ? (this.race.id === 'NEW' ? "Ajout par l'organisateur" : "Modification par l'organisateur") : '',
        messageNotRequired ? undefined : Validators.required,
      ),
    );

    this.buildDateInfos(this.data.event);
  }

  patchValues() {
    setTimeout(() => {
      this.raceFormComponent.patchFormValues(this.race, this.data.multiple);
      this.raceForm.addControl('race', this.raceFormComponent.formGroup);
    });
  }

  buildDateInfos(event: IEvent) {
    this.datesInfos = {
      several_days: false,
      dates: [],
    };

    if (event) {
      if (event.date /* && event.end_date*/) {
        const startDate = moment(event.date * 1000);
        const endDate = event.end_date ? moment(event.end_date * 1000) : moment(event.date * 1000);

        const date = startDate,
          dates = [];

        while (date.isBefore(endDate) || date.isSame(endDate)) {
          dates.push(date.valueOf());
          date.add(1, 'days');
        }

        if (!endDate.isSame(startDate, 'day')) {
          this.datesInfos.several_days = true;
        }
        this.datesInfos.dates = dates;
      }
    }
  }

  toggle() {
    this.toggleMore = !this.toggleMore;
  }

  goToForm() {
    this.formActivated = true;
    this.patchValues();
  }

  onOkClick() {
    const dialogResult = new DialogResult();
    this.loading$.next(true);

    if (!this.data.multiple) {
      const data = this.formatDataForDrupalApi(this.raceForm.value.race, this.raceForm.value.message);

      this.eventService
        .updateTrailData(data)
        .pipe(untilDestroyed(this))
        .subscribe({
          complete: () => {
            dialogResult.status = DialogResultStatus.Ok;
            this.loading$.next(false);
            this.dialogRef.close(dialogResult);
            this.store.dispatch(new LoadTrailForAlias(this.trail.alias));
          },
          error: err => {
            alert('ERROR : ' + JSON.stringify(err));
            this.loading$.next(false);
            this.dialogRef.close(dialogResult);
            this.store.dispatch(new LoadTrailForAlias(this.trail.alias));
          },
        });
    } else {
      const distances = [];
      const laps = this.raceForm.value.race.laps;
      const nbOfLoops = laps.split(',');
      const form = this.raceForm.value;
      delete form.race.laps;
      const loop = {
        distance: form.race.distance,
        elevation: form.race.elevation,
      };

      for (const n of nbOfLoops) {
        const form = this.cloneRaceForm(this.raceForm);
        const values = form.value;

        values.route_type = +n;
        values.distance = Math.round(values.distance * +n);
        values.elevation = Math.round((values.elevation * +n) / 10) * 10;
        values.refreshments = (values.refreshments + 1) * +n - 1;
        values.race_name = `${n} ${
          values.race_name && values.race_name !== '' ? values.race_name : +n === 1 ? 'boucle' : 'boucles'
        }`;

        distances.push(this.formatDataForDrupalApi(values, this.raceForm.value.message));
      }

      const data = { distances, loop };

      this.eventService
        .generateLastmanRaces(data)
        .pipe(untilDestroyed(this))
        .subscribe({
          complete: () => {
            dialogResult.status = DialogResultStatus.Ok;
            this.loading$.next(false);
            this.dialogRef.close(dialogResult);
            this.store.dispatch(new LoadTrailForAlias(this.trail.alias));
          },
          error: err => {
            alert('ERROR : ' + JSON.stringify(err));
            this.loading$.next(false);
            this.dialogRef.close(dialogResult);
            this.store.dispatch(new LoadTrailForAlias(this.trail.alias));
          },
        });
    }
  }

  cloneRaceForm(form: FormGroup) {
    const newForm = this.formService.buildRaceForm();
    newForm.patchValue(form.value.race);
    return newForm;
  }

  formatDataForDrupalApi(raceForm: any, message: string) {
    let oldData = {};
    if (this.race) {
      oldData = {
        race: {
          ...this.race,
          date: new Date(this.race.date * 1000 + 12 * 60 * 60 * 1000),
          departure_time: toHMS(this.race.departure_time, 'hm'),
        },
      };
    }

    if (raceForm.official_ranking_url && raceForm.official_ranking_url !== '') {
      let rankingUrl = raceForm.official_ranking_url.trim();
      if (rankingUrl.includes('fbclid')) {
        rankingUrl = rankingUrl.split('fbclid')[0].slice(0, -1);
        raceForm.official_ranking_url = rankingUrl;
      }
    }

    const numberOne = 1;
    const numberZero = 0;
    const data = {
      message: message,
      newData: {
        race: {
          ...raceForm,
          trailcup_only: raceForm.trailcup_only === true ? numberOne : numberZero,
          canceled: raceForm.canceled === true ? numberOne : numberZero,
        },
      },
      oldData,
      eventName: this.event.title,
    };

    if (this.race && this.race.id === 'NEW') {
      data.newData.race['title'] = this.race.title;
      data.newData.race['evid'] = this.race.evid;
    }

    return data;
  }

  onCancelClick() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  removeDistance(hasResults?: boolean) {
    const title = this.transloco.translate('CONFIRM_DELETE_RACE_TITLE', { distance: this.race.distance });
    if (hasResults) {
      const message = this.transloco.translate('DELETE_RACE_HAS_RESULTS_WARNING');
      this.dialogService.showAlertDialog(title, message);
    } else {
      const message = this.transloco.translate('CONFIRM_DELETE_RACE_MESSAGE', {
        distance: this.race.distance,
        year: new Date(this.race.date * 1000).getFullYear(),
        race: this.race.race_name || this.race.title || 'NO NAME',
        trail: this.trail.title,
      });
      this.dialogService
        .showConfirmDialog(title, message)
        .pipe(untilDestroyed(this))
        .subscribe((validated: boolean) => {
          if (validated) {
            this.trailDataService.deleteRaceData(this.race.id);
            this.close();
          }
        });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
