import { Injectable, Inject } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MockHttpClient {
  constructor(@Inject('appsConfig') private config: any, @Inject('AHttpClient') private realHttp: HttpClient) {}

  get(url, opt) {
    // this.config.nodejsApi + '/user/uid', { withCredentials: true });
    if (url.startsWith('../assets') || url.startsWith('./assets')) {
      return this.realHttp.get(url);
    } else {
      switch (url) {
        case this.config.nodejsApi + '/user/uid':
          return of({ body: { uid: 6567 } });
        case this.config.nodejsApi + '/user':
          return this.realHttp.get('./mock/userMica.json');
        //return of({});
        case this.config.nodejsApi + '/config/i18n/en':
          return this.realHttp.get('./mock/configEn.json');
        case this.config.nodejsApi + '/config/i18n/fr':
          return this.realHttp.get('./mock/configFr.json');
        case this.config.nodejsApi + '/config/i18n/nl':
          return this.realHttp.get('./mock/configNl.json');
        case this.config.nodejsApi + '/config':
          return this.realHttp.get('./mock/config.json');

        default:
          return throwError('no mock');
      }
    }
  }
}
