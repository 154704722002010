<div class="welcomeSteps" *ngIf="_runner && _runner.id && _user">
  <ng-container *ngIf="welcomeSteps[welcomeStep] && welcomeSteps[welcomeStep]?.display">
    <ng-container *ngIf="welcomeStep === 'welcome'">
      <div class="stepContainer">
        <h2 class="centeredAligned">
          <ng-container *ngIf="_runner?.firstname; else defaultWelcomeText"
            >{{ 'WS_WELCOME' | transloco }}, <span class="nowrap">{{ _runner?.firstname }} !</span></ng-container
          >
          <ng-template #defaultWelcomeText>{{ 'WS_WELCOME_ON_BT' | transloco }} !</ng-template>
        </h2>
        <div class="message centeredAligned">
          <ng-container *ngIf="isLightUser; else fullAccountText">
            <p [innerHtml]="'LIGHT_USER_WELCOME_MSG' | transloco"></p>
          </ng-container>
          <ng-template #fullAccountText>
            {{ 'WS_YOU_ARE_LOGGED_IN' | transloco }}<br /><br />
            <ng-container *ngIf="+numberOfWelcomeSteps() === 1; else multipleSteps"
              >{{ 'WS_ONE_STEP_LEFT' | transloco }}</ng-container
            >
            <ng-template #multipleSteps
              >{{ 'WS_MULTIPLE_STEPS_LEFT' | transloco: { steps: numberOfWelcomeSteps() } }}</ng-template
            >
          </ng-template>
        </div>
        <div class="buttonContainer">
          <button class="bt-button blue" mat-raised-button (click)="nextStep()">
            {{ 'CONTINUE_TO_CONFIRM_ACCOUNT' | transloco }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'identity'">
      <welcome-step-runner-data
        [runner]="_runner"
        [formGroup]="runnerMissingDataFormGroup"
        [isLightUser]="isLightUser"
        (submitted)="saveRunnerMissingData($event)"
      ></welcome-step-runner-data>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'results'">
      <welcome-step-results-check
        [runner]="_runner"
        [isLightUser]="isLightUser"
        [user]="_user"
        (next)="nextStep()"
      ></welcome-step-results-check>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'resultsClaimingTuto'">
      <welcome-step-results-claiming-tuto (next)="nextStep()" [user]="_user"></welcome-step-results-claiming-tuto>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'emailconfirmation'">
      <welcome-step-confirm-email [user]="_user" (next)="nextStep()"></welcome-step-confirm-email>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'avatar'">
      <welcome-step-avatar [runner]="_runner" [user]="_user" (next)="nextStep()"></welcome-step-avatar>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'defaultCountry'">
      <welcome-step-region
        [runner]="_runner"
        [user]="_user"
        [userParams]="userParams"
        (next)="nextStep()"
      ></welcome-step-region>
    </ng-container>

    <ng-container *ngIf="welcomeStep === 'done'">
      <div class="stepContainer">
        <h2 class="centeredAligned">{{ 'WS_PERFECT' | transloco }}</h2>
        <div class="message centeredAligned">
          {{ 'WS_SURF_NOW' | transloco }}<br />
          <br />
          {{ 'WS_GOOD_TIME_ON_BETRAIL' | transloco }}, {{ _runner.firstname }}.
        </div>
        <div class="buttonContainer">
          <button (click)="close()" class="bt-button blue" mat-raised-button>
            {{ 'CONTINUE_ON_BETRAIL' | transloco }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
