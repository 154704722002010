import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthStateService } from '@betrail-libs/auth-state';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';
import { Race } from '@betrail-libs/shared/interfaces/race.model';
import { asRoleId, toHMS } from '@betrail-libs/shared/utils';
import { BetrailUxFormsService } from '@betrail/ux/betrail-ux-forms.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'bux-race-form',
  templateUrl: './race-form.component.html',
  styleUrls: ['./race-form.component.scss'],
})
export class RaceFormComponent implements OnInit, OnChanges {
  /* Members  */
  toggleMore = false;
  raceFormGroup: UntypedFormGroup;
  several_days = false;
  selectedLoopType = 1;
  showNumberOfLoop = false;
  availableDates: any[] = [];
  commentMaxLength = 250;

  isAdmin$ = this.authState.hasRole(DrupalRole.Administrator);
  isEncoder$ = this.authState.hasRole(DrupalRole.Encoder);
  showOrgaOptions$ = this.authState.hasOrgaRight();
  user$ = this.authState.getUser();

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff',
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#3ca896',
      clockFaceTimeInactiveColor: '#fff',
    },
  };

  @Select(RouterState.url) url$: Observable<string>;

  @Input() formGroup: UntypedFormGroup;
  @Input() datesInfos: any;
  @Input() verified = false;
  @Input() multiple = false;

  @Output() distanceChanged = new EventEmitter<number>();

  constructor(
    private betrailUxFormsService: BetrailUxFormsService,
    private translate: TranslocoService,
    private authState: AuthStateService,
  ) {}

  disableVerifiedFields() {
    if (this.verified === true) {
      this.formGroup.get('distance').disable();
      this.formGroup.get('refreshments').disable();
      this.formGroup.get('elevation').disable();
      this.formGroup.get('offroad').disable();
      this.formGroup.get('trail').disable();
    }
  }

  ngOnInit() {
    /*

    This form ca be used in 2 cases :

    - encapsulted in form array (ex: used in add to calendar feature)
    - stand alone formGroup in a popup for example

    if it's in stand alone mode, the formGroup has to be built.
    Otherwise, it's given by the form Array via @Input

    */

    if (!this.formGroup) {
      this.formGroup = this.betrailUxFormsService.buildRaceForm();
      /* Disable these fields if the race is verified by the Betrail team : this info can no longer be modified */
    } else if (this.formGroup.value?.route_type === '0') {
      this.selectedLoopType = 0;
    }

    if (this.multiple) {
      this.formGroup.addControl('laps', new FormControl('', Validators.required));
    }

    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      if (asRoleId(user, 3)) {
        this.disableVerifiedFields();
      }
    });

    /* Build available dates in the race's event is on more than one day */
    if (this.datesInfos) {
      this.buildAvailableDays();
    }

    /* If only one date is available, setting this date as default date value */
    if (this.availableDates && this.availableDates.length > 0) {
      this.setDefaultDate();
    }

    /*
    listen to distance changes.
    if there is a modification, notify it to the parent form to be able to update title information
    */
    this.formGroup
      .get('distance')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((distanceChanges: number) => {
        this.distanceChanged.emit(distanceChanges);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datesInfos && !changes.datesInfos.firstChange) {
      this.buildAvailableDays();
      this.setDefaultDate();
    }
  }

  setDefaultDate() {
    let currentDateIsInTheList = false;

    if (this.availableDates && this.availableDates.length > 0) {
      for (const d of this.availableDates) {
        // checking if current selected date is still in the list
        if (this.formGroup.get('date').value === d.value) {
          currentDateIsInTheList = true;
        }
      }

      // setting the default date
      if (!currentDateIsInTheList) {
        this.formGroup.patchValue({
          date: this.availableDates?.[0]?.value,
        });
      }
    }
  }

  toggle() {
    this.toggleMore = !this.toggleMore;
  }

  handleNbOfLoops(type: 0 | 1 | 2) {
    if (type === 2) {
      this.formGroup.patchValue({ route_type: [type, Validators.required] });
      this.showNumberOfLoop = true;
    } else {
      this.formGroup.patchValue({ route_type: '' + type });
      this.formGroup.markAsDirty();
    }
  }

  patchFormValues(race: Race, multiple = false) {
    this.buildAvailableDays();
    let raceDate;
    if (race.date > 0) {
      raceDate = new Date(race.date * 1000);
      raceDate = moment(raceDate).format('YYYY-MM-DD');
    } else if (this.availableDates && this.availableDates.length > 0) {
      raceDate = this.availableDates[0].value;
    }
    if (+race.route_type > 1 || multiple) {
      this.selectedLoopType = 2;
    } else {
      this.selectedLoopType = race?.route_type ? +race.route_type : 1;
    }

    this.formGroup.patchValue({
      id: race.id,
      date: raceDate,
      distance: race.distance,
      race_name: race.race_name,
      elevation: race.elevation,
      departure_time: race.departure_time ? toHMS(race.departure_time, 'hm') : undefined,
      price: race.price,
      offroad: race.offroad,
      trail: race.trail,
      onsite_price: race.onsite_price,
      refreshments: race.refreshments,
      utmb_points: race.utmb_points,
      max_runners: race.max_runners,
      night: race.night,
      trailcup_only: race.trailcup_only === 1 ? true : false,
      offroad_certified: race.offroad_certified,
      altitude_max: race.max_altitude,
      altitude_min: race.min_altitude,
      route_type: race.route_type || (multiple ? 2 : 1),
      race_type: race.race_type || (multiple ? 'clock' : 'solo'),
      timing_type: race.timing_type || 'puces',
      evid: race.evid || 0,
      title: race.title,
      country: race.country,
      official_ranking_url: race.official_ranking_url,
      paid: '',
      canceled: race.canceled === 1 ? true : false,
      comment: race.comment,
    });

    const isAdmin = this.authState.hasRoleSnapshot(DrupalRole.Administrator);
    const isEncoder = this.authState.hasRoleSnapshot(DrupalRole.Encoder);

    if (!isAdmin && !isEncoder && race.id !== 'NEW') {
      this.formGroup?.get('offroad')?.disable();
      this.formGroup?.get('trail')?.disable();
    }

    const nowInSec = Math.floor(Date.now() / 1000);
    if (!isAdmin && !isEncoder && nowInSec > race.date) {
      this.formGroup?.get('comment')?.disable();
    }

    if (multiple && race.id === 'NEW') {
      this.formGroup.removeControl('route_type');
    } else if (+race.route_type > 1 || multiple) {
      this.showNumberOfLoop = true;
    }
  }

  addPathId(paid) {
    this.formGroup.patchValue({ paid: paid });
  }

  private buildAvailableDays() {
    this.several_days = this.datesInfos.several_days;

    this.availableDates.length = 0;

    for (let i = 0, len = this.datesInfos.dates.length; i < len; i++) {
      const date = this.datesInfos.dates[i];

      moment.locale(this.translate.getActiveLang());

      this.availableDates.push({
        displayValue: moment(date).format('dddd DD/MM/YYYY'),
        value: moment(date).format('YYYY-MM-DD'),
      });
    }
    this.setDefaultDate();
  }
}
