<div class="search-container">
  <input
    #searchElem
    [matAutocomplete]="auto"
    (click)="$any($event).target.setSelectionRange(0, $any($event).target?.value?.length)"
    (keyup)="onSearchChange($any($event).target.value)"
    class="search bt-rounded bt-shadowed"
    placeholder="{{ placeholder | transloco }}"
    autofocus
    [class.open]="isOpen"
  />
</div>
<div id="searchPanel">
  <mat-autocomplete
    (optionSelected)="onSearchSelect($event.option.value)"
    #auto="matAutocomplete"
    connectedTo="#searchElem"
    autoActiveFirstOption="true"
    (opened)="setIsOpenParam(true)"
    (closed)="setIsOpenParam(false)"
  >
    <div id="close">
      <button mat-icon-button (click)="searchClose()"><mat-icon>close</mat-icon></button>
    </div>
    <ng-container *ngIf="(searchAutocomplete | async); let auto; else: noResult">
      <ng-container *ngIf="auto && auto?.events && auto.events?.length > 0; else noResult">
        <mat-option *ngIf="otherOption"> {{ 'SELECT_TRAIL_MESSAGE' | transloco }}</mat-option>
        <mat-option *ngFor="let event of auto.events" [value]="event">
          <div class="image" [style.background]="event.image_small_url | sanitizeStyleUrl"></div>
          <div class="trailContent" fxLayout="column" fxLayoutAlign="center">
            <span class="notranslate">{{ event?.trail_title }}</span>
            <span class="place">
              <place
                class="eventPlace"
                [place]="event?.place"
                [country]="event?.country"
                [postal]="event?.postal_code"
              ></place>
            </span>
          </div>
        </mat-option>
        <mat-option *ngIf="otherOption" [value]="{trail_title:'Creation de trail',add_course:true}">
          {{ 'SELECT_ADD_TRAIL_MESSAGE' | transloco }}</mat-option
        >
        <mat-option *ngIf="otherOption" [value]="{trail_title:'Creation de trail',add_course:true}">
          <div class="image" [style.background]="'#e2e2e2'"></div>
          <div class="trailContent" fxLayout="column" fxLayoutAlign="center">
            <span>{{ currentSearchValue }}</span>
            <span class="place">
              <place class="eventPlace" [place]="" [country]="" [postal]=""></place>
            </span>
          </div>
        </mat-option>
      </ng-container>
    </ng-container>
    <ng-template #noResult>
      <ng-container *ngIf="isOpen == true">
        <mat-optgroup label="{{ 'HOME_NORESULT' | transloco }} {{currentSearchValue}} {{ searchValue | async }}">
          <mat-option *ngIf="!otherOption">{{ 'SEARCH_RETRY' | transloco }} </mat-option>
          <mat-option *ngIf="otherOption" [value]="{trail_title:'Creation de trail',add_course:true}">
            {{ 'SELECT_ADD_TRAIL_MESSAGE' | transloco }}</mat-option
          >
          <mat-option *ngIf="otherOption" [value]="{trail_title:'Creation de trail',add_course:true}">
            <div class="image" [style.background]="'#e2e2e2'"></div>
            <div class="trailContent" fxLayout="column" fxLayoutAlign="center">
              <span>{{ currentSearchValue }}</span>
              <span class="place">
                <place class="eventPlace" [place]="" [country]="" [postal]=""></place>
              </span>
            </div>
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </ng-template>
  </mat-autocomplete>
</div>
