<div>
  <header class="form-title">{{ 'ADD_NEW_FORM_ITEM' | transloco }}</header>
  <form class="form-content" [formGroup]="newItemForm">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'INPUT_FIELD_TYPE' | transloco }}</mat-label>
        <mat-select formControlName="inputFieldType">
          <mat-option *ngFor="let inputFieldType of inputFieldTypes" [value]="inputFieldType">
            {{ inputFieldType.key | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'INPUT_FIELD_NAME' | transloco }}</mat-label>
        <input matInput [placeholder]="'INPUT_FIELD_NAME' | transloco" type="text" formControlName="name" />
      </mat-form-field>
    </div>
  </form>

  <div class="actions-container">
    <button mat-button class="button" (click)="cancel()">{{ 'CANCEL' | transloco }}</button>

    <button
      [disabled]="!newItemForm.valid"
      class="button color"
      [class.disabled]="!newItemForm.valid"
      mat-raised-button
      (click)="confirm()"
    >
      {{ 'ADD' | transloco }}
    </button>
  </div>
</div>
