import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BELGIUM_PROVINCES, BELGIUM_REGIONS, FRANCE_DEPARTMENTS, FRANCE_REGIONS } from '@betrail-libs/shared/utils';
import { FilterElement } from '../filterElement.model';

@Component({
  selector: 'calendar-filter-region-department',
  templateUrl: './calendar-filter-region-department.component.html',
  styleUrls: ['./calendar-filter-region-department.component.scss'],
})
export class CalendarFilterRegionDepartmentComponent implements OnInit {
  region_departments: { code: string; title: string; region: string; url_alias: string; iso2?: string }[];

  @Input() filteredElements: FilterElement[];
  @Input() value: { country: string; region: string; department: string } = {
    country: 'FR',
    region: 'ALL',
    department: 'ALL',
  };
  @Input() country: 'FR' | 'BE' = 'FR';
  @Input() region = 'ALL';
  @Input() department = 'ALL';
  @Input() description: string;
  @Output() regionFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();
  @Output() apply = new EventEmitter();

  regions = FRANCE_REGIONS;
  departments = FRANCE_DEPARTMENTS;
  overseas = FRANCE_DEPARTMENTS.filter(d => d.region === 'DROM-COM');

  ngOnInit(): void {
    this.value = { country: this.country, region: this.region, department: this.department };
    if (this.country === 'FR') {
      this.region_departments = FRANCE_DEPARTMENTS.filter(d => d.region === this.value.region);
    } else {
      this.regions = BELGIUM_REGIONS;
      this.departments = BELGIUM_PROVINCES;
      this.region_departments = BELGIUM_PROVINCES.filter(p => p.region === this.value.region);
    }
    this.regionFilter.emit(this.value);
  }

  changeValue(value, type = 'region') {
    if (type === 'region') {
      this.value.department = 'ALL';
    }
    this.value[type] = value;
    this.region_departments =
      this.country === 'FR'
        ? FRANCE_DEPARTMENTS.filter(d => d.region === this.value.region)
        : BELGIUM_PROVINCES.filter(p => p.region === this.value.region);
    this.regionFilter.emit(this.value);
    let summary = '';
    if (this.value.department != 'ALL') {
      const dep =
        this.country === 'FR'
          ? FRANCE_DEPARTMENTS.find(d => d.code == this.value.department)
          : BELGIUM_PROVINCES.find(p => p.code === this.value.department);
      summary = dep.title + ' (' + dep.code + ')';
    } else {
      if (value !== 'ALL') {
        summary = this.regions.find(r => r.code === this.value.region).title;
      } else {
        summary = this.country === 'FR' ? 'ALL' : 'ALL_FEM';
      }
    }
    this.updateSummary.emit(summary);
  }

  selectRegion(value) {
    this.changeValue(value, 'region');
  }

  selectOverseaDep(code: string) {
    this.value.region = 'DROM-COM';
    this.changeValue(code, 'department');
  }
}
