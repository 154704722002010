import { Injectable } from '@angular/core';
import { Select, Selector, State } from '@ngxs/store';

export interface AppStateModel {
  loading: string[];
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: [],
  },
})
@Injectable({ providedIn: 'root' })
export class AppState {
  @Selector()
  public static getLoading(state: AppStateModel) {
    return state.loading;
  }

  @Selector()
  public static isLoading(state: AppStateModel) {
    return state.loading.length > 0;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  @Select(AppState.isLoading)
  public isLoading$;

  isLoading() {
    return this.isLoading$;
  }
}
