import { Injectable } from '@angular/core';
import { EAdminSettingType, IAdminSettings } from '@betrail-libs/shared/interfaces/interfaces';
import { EPopUpStatus, IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import {
  AddViewToPopup,
  ChangePopupStatus,
  CheckForActivePopUp,
  CreateNewPopUp,
  CreatePopupLog,
  EditPopupData,
  LoadAllPopUps,
  SaveAdminSetting,
} from './admin.action';
import { AdminState } from './admin.state';

@Injectable({
  providedIn: 'root',
})
export class AdminStateService {
  @Select(AdminState.allPopUps) allPopUps$: Observable<IPopUpData[]>;
  @Select(AdminState.activePopupForAll) activeForAllPopup$: Observable<IPopUpData | undefined>;
  @Select(AdminState.settings) settings$: Observable<{ [type: string]: IAdminSettings }>;

  constructor(private store: Store) {}

  createNewPopup = (popup: Partial<IPopUpData>) => this.store.dispatch(new CreateNewPopUp(popup));

  editPopupData = (popupData: IPopUpData) => this.store.dispatch(new EditPopupData(popupData));

  loadAllPopups = () => this.store.dispatch(new LoadAllPopUps());

  changePopupStatus = (popup: IPopUpData, newStatus: EPopUpStatus) =>
    this.store.dispatch(new ChangePopupStatus(popup.id, newStatus, popup.status));

  checkForActivePopUp = () => this.store.dispatch(new CheckForActivePopUp());

  getActivePopupsSnapShot = () => this.store.selectSnapshot(AdminState.activePopUps);

  addViewToPopup = (popupId: number) => this.store.dispatch(new AddViewToPopup(popupId));

  createPopupLog = (userId: number, popupId: number, linkClicked: boolean, timeOpen: number) =>
    this.store.dispatch(new CreatePopupLog(userId, popupId, linkClicked, timeOpen));

  getAdminSettings = () => this.store.selectSnapshot(AdminState.settings);

  saveSetting = (setting: IAdminSettings) => this.store.dispatch(new SaveAdminSetting(setting));

  getIfAdBannerIsHidden = () =>
    this.settings$.pipe(map(settings => settings[EAdminSettingType.AD_MY_SPORTS_BANNER].data === 'hide'));
}
