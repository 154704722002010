<div class="stepContainer">
  <h2 class="centeredAligned">{{ 'WS_RESULTS_CLAIMING_TUTO' | transloco }}</h2>
  <div class="message centeredAligned">
    <p>{{ 'WS_RESULTS_CLAIMING_EXPLANATION' | transloco }}</p>
    <img class="gif" src="./assets/img/tuto_manage_results.gif" />
  </div>
  <div class="buttonContainer">
    <button [disabled]="buttonDisabled" class="bt-button blue" mat-raised-button (click)="nextStep()">
      {{ buttonText | transloco }}
    </button>
  </div>
</div>
