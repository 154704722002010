<div class="stepContainer">
  <h2 class="centeredAligned">{{ 'WS_CONFIRM_REGION' | transloco }}</h2>
  <div class="message centeredAligned">{{ 'WS_REGION_EXPLANATION' | transloco }}.</div>
  <div fxLayout="column">
    <mat-form-field appearance="fill" color="secondary" fxFlex="100">
      <mat-label>{{ 'USER_DEFAULT_COUNTRY' | transloco }}</mat-label>
      <mat-select [(value)]="userParams.defaultCountry">
        <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
          >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="userParams?.defaultCountry == 'BE'">
      <mat-form-field class="region-select" appearance="fill" color="secondary" fxFlex="100">
        <mat-label>{{ 'USER_DEFAULT_PROVINCE' | transloco }}</mat-label>
        <mat-select [(value)]="userParams.defaultDepartment">
          <mat-option *ngFor="let province of beProvinces" [value]="province.code">{{ province.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="userParams?.defaultCountry == 'NL'">
      <mat-form-field class="region-select" appearance="fill" color="secondary" fxFlex="100">
        <mat-label>{{ 'USER_DEFAULT_PROVINCE' | transloco }}</mat-label>
        <mat-select [(value)]="userParams.defaultDepartment">
          <mat-option *ngFor="let province of nlProvinces" [value]="province.code">{{ province.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="userParams?.defaultCountry == 'FR'">
      <mat-form-field class="region-select" appearance="fill" color="secondary" fxFlex="100">
        <mat-label>{{ 'USER_DEFAULT_DEPARTMENT' | transloco }}</mat-label>
        <mat-select [(value)]="userParams.defaultDepartment">
          <mat-option value="all">{{ 'DEPARTMENTS_ALL' | transloco }}</mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.code"
            >{{ department.code }} {{ department.title }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="buttonContainer">
    <button [disabled]="saveButtonDisabled" class="bt-button blue" mat-raised-button (click)="nextStep()">
      {{ saveText | transloco }}
    </button>
  </div>
</div>
