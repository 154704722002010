import { Directive, HostListener } from '@angular/core';
import { SlidingPanelService } from './sliding-panel.service';

@Directive({
  selector: '[slidingPanelNext]',
})
export class SlidingPanelNextDirective {
  constructor(private slidingPanelService: SlidingPanelService) {}

  @HostListener('click') onclick() {
    this.slidingPanelService.next();
  }
}
