<div class="stepContainer">
  <h2 class="centeredAligned">{{ 'WS_CHECK_YOUR_RESULTS' | transloco }}</h2>
  <div *ngIf="searching" class="searching">
    {{ 'WS_SEARCHING_RESULTS' | transloco: { nb: (stats$ | async)?.results || 3078074 | number } }}
  </div>

  <div *ngIf="potentialResults">
    <div *ngIf="potentialResults.runnerHasResults == false || createdFor < 3600">
      <ng-container *ngIf="potentialResults.selectedResults.length > 0 || potentialResults.probableResults.length > 0">
        <div class="message centeredAligned" innerHTML="{{ 'WS_CHECK_RESULTS_INTRO_NEW_RUNNER' | transloco }}"></div>

        <form [formGroup]="resultsFormGroup">
          <div *ngIf="potentialResults?.selectedResults?.length > 0">
            <strong class="intertitle"
              >{{ 'WS_DID_YOU_RUN' | transloco }} ? ({{ potentialResults.selectedResults.length }})</strong
            >
            <div
              *ngFor="let result of potentialResults.selectedResults; let i = index"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="result"
              [formGroupName]="result.reid"
            >
              <div fxFlex="70">
                <div>
                  <strong>
                    <span *ngIf="result.proximity < 100">"{{ result?.original_name }}" ٠ </span>
                    <span class="eventname" ngClass.gt-xs="large"> {{ result?.event_name }}</span> ٠ {{ result?.distance
                    }}km
                  </strong>
                </div>
                <div>
                  {{ result?.date * 1000 | date }} | {{ result?.result_seconds | toHms }} | {{ result?.performance }}
                </div>
              </div>
              <div fxFlex="30" fxLayout="row" class="buttons-container">
                <buttons-toggle
                  [style]="'small'"
                  formControlName="selected"
                  [c]="$any(resultsFormGroup.controls[result.reid]).controls.selected"
                  [options]="options"
                  [disabled]="loading === true"
                ></buttons-toggle>
              </div>
            </div>
          </div>
          <div *ngIf="potentialResults?.probableResults && potentialResults?.probableResults.length > 0">
            <strong class="intertitle">{{ 'WS_OTHER_POSSIBLE_RESULTS' | transloco }}</strong>
            <ng-container
              *ngFor="let result of potentialResults.probableResults; let i = index"
              [formGroupName]="result.reid"
            >
              <div *ngIf="i < maxToShow" fxLayout="row" fxLayoutAlign="start center" class="result">
                <div fxFlex="70">
                  <div>
                    <strong>
                      <span *ngIf="result.proximity < 100">"{{ result?.original_name }}" ٠ </span>
                      <span class="eventname" ngClass.gt-xs="large"> {{ result?.event_name }}</span> ٠ {{
                      result?.distance }}km
                    </strong>
                  </div>
                  <div>
                    {{ result?.date * 1000 | date }} | {{ result?.result_seconds | toHms }} | {{ result?.performance }}
                  </div>
                </div>
                <div fxFlex="30" fxLayout="row" class="buttons-container">
                  <buttons-toggle
                    [style]="'small'"
                    formControlName="selected"
                    [c]="$any(resultsFormGroup.controls[result.reid]).controls.selected"
                    [options]="options"
                  ></buttons-toggle>
                </div>
              </div>
            </ng-container>

            <button *ngIf="potentialResults?.probableResults?.length > maxToShow" mat-button (click)="showAll()">
              {{ 'WS_SHOW_MORE_RESULTS' | transloco: { nb: potentialResults.probableResults.length - maxToShow } }}
            </button>
          </div>
        </form>
      </ng-container>
      <ng-container
        *ngIf="potentialResults.probableResults?.length == 0 && potentialResults.selectedResults?.length == 0"
      >
        <div
          class="message centeredAligned"
          innerHTML="{{
            'WS_CHECK_RESULTS_INTRO_NO_RESULT_FOUND' + (isLightUser ? '_LIGHT' : '') | transloco: { nb: (stats$ | async)?.results || 3078074 | number }
          }}"
        ></div>
      </ng-container>
    </div>
    <div *ngIf="potentialResults.runnerHasResults === true && createdFor >= 3600">
      <div
        class="message centeredAligned"
        innerHTML="{{ 'WS_CHECK_RESULTS_INTRO_HAS_RESULTS' | transloco }}"
        *ngIf="currentResultsIdentifiedAsOnlyProbable.length > 0 || otherProbableResultsNotLinkedYet.length > 0"
      ></div>
      <div
        class="message centeredAligned"
        innerHTML="{{ 'WS_CHECK_RESULTS_INTRO_HAS_RESULTS_NONE_TO_CHECK' | transloco }}"
        *ngIf="currentResultsIdentifiedAsOnlyProbable.length == 0 && otherProbableResultsNotLinkedYet.length == 0"
      ></div>
      <form [formGroup]="resultsFormGroup">
        <div *ngIf="currentResultsIdentifiedAsOnlyProbable.length > 0">
          <strong class="intertitle">{{ 'WS_DID_YOU_RUN_ALREADY_LINKED' | transloco }} ?</strong>
          <ng-container
            *ngFor="let result of currentResultsIdentifiedAsOnlyProbable; let i = index"
            [formGroupName]="result.reid"
          >
            <div fxLayout="row" fxLayoutAlign="start center" class="result">
              <div fxFlex="70">
                <div>
                  <strong>
                    <span *ngIf="result.proximity < 100">"{{ result?.original_name }}" ٠ </span>
                    <span class="eventname" ngClass.gt-xs="large"> {{ result?.event_name }}</span> ٠ {{ result?.distance
                    }}km
                  </strong>
                </div>
                <div>
                  {{ result?.date * 1000 | date }} | {{ result?.result_seconds | toHms }} | {{ result?.performance }}
                </div>
              </div>
              <div fxFlex="30" fxLayout="row" class="buttons-container">
                <buttons-toggle
                  [style]="'small'"
                  formControlName="selected"
                  [c]="$any(resultsFormGroup.controls[result.reid]).controls.selected"
                  [options]="options"
                ></buttons-toggle>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="newSelectedResults.length > 0 || otherProbableResultsNotLinkedYet.length > 0">
          <strong class="intertitle">{{ 'WS_DID_YOU_RUN_NOT_LINKED_YET' | transloco }} ?</strong>
        </ng-container>

        <div *ngIf="newSelectedResults.length > 0">
          <div
            *ngFor="let result of newSelectedResults; let i = index"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="result"
            [formGroupName]="result.reid"
          >
            <div fxFlex="70">
              <div>
                <strong>
                  <span *ngIf="result.proximity < 100">"{{ result?.original_name }}" ٠ </span>
                  <span class="eventname" ngClass.gt-xs="large"> {{ result?.event_name }}</span> ٠ {{ result?.distance
                  }}km
                </strong>
              </div>
              <div>
                {{ result?.date * 1000 | date }} | {{ result?.result_seconds | toHms }} | {{ result?.performance }}
              </div>
            </div>
            <div fxFlex="30" fxLayout="row" class="buttons-container">
              <buttons-toggle
                [style]="'small'"
                formControlName="selected"
                [c]="$any(resultsFormGroup.controls[result.reid]).controls.selected"
                [options]="options"
                [disabled]="loading === true"
              ></buttons-toggle>
            </div>
          </div>
        </div>

        <div *ngIf="otherProbableResultsNotLinkedYet.length > 0">
          <ng-container
            *ngFor="let result of otherProbableResultsNotLinkedYet; let i = index"
            [formGroupName]="result.reid"
          >
            <div *ngIf="i < maxToShow" fxLayout="row" fxLayoutAlign="start center" class="result">
              <div fxFlex="70">
                <div>
                  <strong>
                    <span *ngIf="result.proximity < 100">"{{ result?.original_name }}" ٠ </span>
                    <span class="eventname" ngClass.gt-xs="large"> {{ result?.event_name }}</span> ٠ {{ result?.distance
                    }}km
                  </strong>
                </div>
                <div>
                  {{ result?.date * 1000 | date }} | {{ result?.result_seconds | toHms }} | {{ result?.performance }}
                </div>
              </div>
              <div fxFlex="30" fxLayout="row" class="buttons-container">
                <buttons-toggle
                  [style]="'small'"
                  formControlName="selected"
                  [c]="$any(resultsFormGroup.controls[result.reid]).controls.selected"
                  [options]="options"
                ></buttons-toggle>
              </div>
            </div>
          </ng-container>
        </div>

        <button *ngIf="otherProbableResultsNotLinkedYet.length > maxToShow" mat-button (click)="showAll()">
          {{ 'WS_SHOW_MORE_RESULTS' | transloco: { nb: otherProbableResultsNotLinkedYet.length - maxToShow } }}
        </button>
      </form>
    </div>
  </div>
  <div class="buttonContainer">
    <button [disabled]="loading || !resultsFormGroup.valid" mat-button class="bt-button blue" (click)="nextStep()">
      {{ buttonText | transloco }}
    </button>
  </div>
</div>
