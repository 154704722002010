import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';
import { Runner } from '@betrail-libs/shared/interfaces/runner.model';
import { DEFAULT_WELCOME_STEPS } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
})
export class WelcomeDialogComponent implements OnInit {
  runnersToCheck: any[];
  newAccountValues: any = { place: '', birthdate: '', gender: '', firstname: '', lastname: '' };
  fullscreen = false;
  saveButtonDisabled: boolean;
  saveText: string = 'SAVE';
  welcomeSteps = DEFAULT_WELCOME_STEPS;
  welcomeForm: UntypedFormGroup;
  welcomeStep: string = 'welcome';
  doneSteps: number = 0;
  error: string;
  isCreateAccount = false;
  isWelcome = false;
  formType: string = 'waiting';
  _runner: any;

  user$ = this.auth.getUser();
  isLoadingUser$ = this.auth.isLoading();
  isLightUser = false;

  @Output() action = new EventEmitter();
  @Input()
  set runner(runner: Runner) {
    this.fillWelcomeForm(runner);
    this._runner = runner;
  }

  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private auth: AuthStateService,
  ) {}

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe({
      next: u => {
        this.isLightUser = u?.roles.filter(role => role.rid === DrupalRole.LightUser).length > 0;
        if (this.isLightUser) {
          delete this.welcomeSteps['resultsClaimingTuto'];
        }
        if (u && u.uid && +u.uid > 0) {
          if (this.formType != 'welcome') {
            this.formType = 'loggedIn';
            this.close();
          }
        } else {
          this.error = '';
        }
      },
      error: () => {
        this.error = 'ERROR_PLEASE_TRY_AGAIN';
      },
    });
  }

  getRunner() {
    return this._runner;
  }

  setWelcomeStatus(status) {
    if (status == 'done') {
      this.close();
    }
  }

  fillWelcomeForm(runner) {
    this.welcomeForm = this.fb.group({
      place: [runner && runner.place ? runner.place : this.newAccountValues.place || '', Validators.required],
      postal: [runner && runner.postal_code ? runner.postal_code : '', Validators.required],
      country: [runner && runner.country ? runner.country : '', Validators.required],
      nationality: [runner && runner.nationality ? runner.nationality : '', Validators.required],
      gender: [
        runner && runner.gender
          ? runner.gender == 1 || runner.gender == '1'
            ? '1'
            : '0'
          : this.newAccountValues.gender && this.newAccountValues.gender == 'FEMALE'
          ? '1'
          : '0',
        Validators.required,
      ],
      birthdate: [
        this.newAccountValues.birthdate
          ? this.newAccountValues.birthdate
          : runner && runner.birthdate
          ? runner.birthdate * 1000
          : '',
        Validators.required,
      ],
    });
  }

  nextStep(step: string = this.welcomeStep) {
    this.doneSteps++;
    if (this.welcomeSteps[step] && this.welcomeSteps[step].next !== false) {
      if (this.welcomeSteps[this.welcomeSteps[step].next].display === true) {
        this.welcomeStep = this.welcomeSteps[step].next;
        if (this.welcomeStep == 'done') {
          this.dialogRef.disableClose = false;
        }
      } else {
        this.nextStep(this.welcomeSteps[step].next);
      }
    } else {
      this.close();
    }
  }

  submitWelcome() {
    if (this.welcomeForm.valid) {
      this.disableButton('save');
      let runnerToUpdate = { ...this.welcomeForm.value };
      this.eventService.updateRunner(this._runner.nid, runnerToUpdate).subscribe(r => {
        this.nextStep();
      });
    }
  }

  enableButton(type: String) {
    if (type == 'save') {
      this.saveButtonDisabled = false;
      this.saveText = 'SAVE';
    }
  }

  disableButton(type: String) {
    if (type == 'save') {
      this.saveButtonDisabled = true;
      this.saveText = 'SAVING';
    }
  }

  close() {
    this.dialogRef.close();
  }
}
