<header class="form-title">{{ pathFormComponent?.formGroup?.value?.title || 'NEW_PATH' | transloco }}</header>
<ng-container *ngIf="user && user.uid && (user.uid !== 0 || user.uid !== '0'); else pleaseLogin">
  <ng-container>
    <form [formGroup]="pathForm">
      <div class="form-container" [class.loading]="loading$ | async">
        <bux-path-form [raid]="raid" #pathFormComponent> </bux-path-form>

        <div class="spinner-container" *ngIf="loading$ | async">
          <mat-spinner strokeWidth="3" class="spinner-indicator"></mat-spinner>
        </div>
      </div>
      <div class="actions-container">
        <button mat-button (click)="onCancelClick()">{{ 'CANCEL' | transloco }}</button>
        <button
          class="confirm-button"
          mat-raised-button
          [disabled]="(loading$ | async) || !pathForm.valid"
          (click)="onOkClick()"
        >
          {{ ((loading$ | async) ? 'SAVING' : 'SAVE') | transloco }}
        </button>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-template #pleaseLogin>
  <div class="message">{{ 'PLEASE_LOGIN_FEATURE' | transloco }}</div>
  <button mat-button (click)="close()">{{ 'CLOSE' | transloco }}</button>
</ng-template>
