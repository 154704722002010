import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BetrailUxFormsService } from '../../../betrail-ux-forms.service';
import { ITimer } from '@betrail-libs/shared/interfaces/interfaces';
import { ALL_COUNTRIES_CODES } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'bux-timer-form',
  templateUrl: './timer-form.component.html',
  styleUrls: ['./timer-form.component.scss', '../common-form.scss'],
})
export class TimerFormComponent implements OnInit {
  timer: ITimer;
  countries = ALL_COUNTRIES_CODES.map(c => {
    return { key: c, name: 'COUNTRY_' + c };
  });
  showErrorMessage: boolean = false;
  errorMessage: string = '';
  addImageButton: ElementRef;
  addImageButtonDisabled: boolean = false;

  constructor(private betrailUxFormsService: BetrailUxFormsService, private eventService: EventService) {}

  @Input() formGroup: UntypedFormGroup;

  @ViewChild('addImageButton', { static: true }) updatedImage: any = 'Pas encore dimage chargée';

  ngOnInit() {
    if (!this.formGroup) {
      this.formGroup = this.betrailUxFormsService.buildTimerForm();
    }
  }

  addImage(file: File): void {
    this.showErrorMessage = false;

    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size < 2000000) {
        this.addImageButtonDisabled = true;
        this.getBase64(file).then(data => {
          try {
            this.eventService.postDrupalImage(file.name, JSON.stringify(data), {}).subscribe(r => {
              this.updatedImage = r;
              this.addImageButtonDisabled = false;
              this.formGroup.patchValue({ logo: r.fid });
            });
          } catch (error) {
            this.addImageButtonDisabled = false;
          }
        });
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'IMAGE_MAX_SIZE_EXCEEDED_ERROR';
        //this.openDialog('Le fichier soumis est trop grand... A vrai dire, il ne peut guère dépasser les 2 Mo.', 'Aie !');
      }
    } else {
      this.showErrorMessage = true;
      this.errorMessage = 'IMAGE_INCORRECT_TYPE_ERROR';
      //this.openDialog('Bon, en fait... seules les images de type JPG, JPEG ou PNG sont autorisées.', 'Oups !');
    }
  }

  removeImage(): void {
    this.updatedImage = '';
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  patchFormValues(timer: ITimer) {
    //TODO : move to service
    this.timer = timer;

    this.formGroup.patchValue({
      id: timer.id,
      title: timer.title,
      country: timer.country,
      email: timer.email,
      phone: timer.phone,
      postal_code: timer.postal_code,
      place: timer.place,
      website: timer.website,
      default_results_page: timer.default_results_page,
      dont_exist: timer.dont_exist === 1,
      logo: timer.logo,
    });
  }
}
