import { IRunner } from '../../../../shared/interfaces/interfaces';

export enum AuthActionTypes {
  LOGIN = '[User] Login',
  LOGIN_DIALOG = '[User] Login dialog',
  LOGIN_SUCCEED = '[User] Login Complete',
  LOGIN_FAILED = '[User] Login Failed',
  CREATE_USER = '[User] create user',
  UPDATE_USER = '[User] update user',
  CREATE_USER_SUCCESS = '[User] create user success',
  CREATE_USER_FAILED = '[User] create user failed',
  LOGOUT = '[User] Logout',
  LOGOUT_SUCCEED = '[User] Logout Succeed',
  REFRESH_TOKEN = '[User] Refresh token',
  TOKEN_ERROR = '[User] Token ERROR',
  CONNECT = '[User] Connect',
  RETRIEVE_USER = '[User] Loading User',
  RETRIEVE_PASSWORD = '[User] Retrieve Password',
  RETRIEVE_PASSWORD_SUCCESS = '[User] Retrieve Password ok',
  RETRIEVE_PASSWORD_FAILED = '[User] Retrieve Password failed',
  RETRIEVE_USER_SUCCESS = '[User] Loading User success',
  RETRIEVE_USER_FAILED = '[User] Loading User failled',
  LOAD_USER_NOTIFICATIONS = '[User] Load User Notifications',
  UPDATE_NOTIF_STATUS = '[User] Update notification status',
  UPDATE_USER_PROFILE_DATA = '[User] Update user Profile Data',
  MASQUERADE = '[User] Masquerade user',
  COMPLETE_RUNNER_DATA = '[User] Complete runner data',
}

export interface LoginCred {
  user: string;
  password: string;
}

export class LoginDialogAction {
  static readonly type = AuthActionTypes.LOGIN_DIALOG;

  constructor(public formType: string, public runner?: IRunner) {}
}

export class LoginAction {
  static readonly type = '[User] Login';
  constructor(public loginCredential: LoginCred, public activateRunner?: boolean) {}
}
export class UpdateCartAction {
  static readonly type = '[Basket] update items';
  constructor(public items: any[]) {}
}

export class LoginSuccedAction {
  static readonly type = AuthActionTypes.LOGIN_SUCCEED;

  constructor(public payload: any) {}
}

export class LoginFailedAction {
  static readonly type = AuthActionTypes.LOGIN_FAILED;

  constructor(public payload?: {}) {}
}

export class LogoutAction {
  static readonly type = AuthActionTypes.LOGOUT;
  public payload: {};
}

export class MasqueradeAction {
  static readonly type = AuthActionTypes.MASQUERADE;
  constructor(public uid: number) {}
}

export class RetrieveAction {
  static readonly type = AuthActionTypes.RETRIEVE_USER;
  constructor(public activateRunner?: boolean) {}
}
export class RetrievePasswordAction {
  static readonly type = AuthActionTypes.RETRIEVE_PASSWORD;
  constructor(public payload: { name: string }) {}
}

export class RetrieveSucceedAction {
  static readonly type = AuthActionTypes.RETRIEVE_USER_SUCCESS;
  constructor(public payload: { body: any }) {}
}

export class UpdateUserAction {
  static readonly type = AuthActionTypes.UPDATE_USER;
  constructor(public payload: { user: any }) {}
}

export class RetrieveFailedAction {
  static readonly type = AuthActionTypes.RETRIEVE_USER_FAILED;
  public payload: {};
}

export class CreateUserAction {
  static readonly type = AuthActionTypes.CREATE_USER;
  constructor(public formData: any) {}
}
export class CreateUserSuccessAction {
  static readonly type = AuthActionTypes.CREATE_USER_SUCCESS;
}
export class CreateUserFailedAction {
  static readonly type = AuthActionTypes.CREATE_USER_FAILED;
  constructor(public error: any) {}
}
export class LoadUserNotificationsAction {
  static readonly type = AuthActionTypes.LOAD_USER_NOTIFICATIONS;
  constructor(public error: any) {}
}

export class UpdateUserProfileData {
  static readonly type = AuthActionTypes.UPDATE_USER_PROFILE_DATA;
  constructor(public userProfile: any) {}
}

export class UpdateNotificationStatusAction {
  static readonly type = AuthActionTypes.UPDATE_NOTIF_STATUS;
  constructor(public noid: number, public status: string) {}
}

export class CompleteAuthRunnerData {
  static readonly type = AuthActionTypes.COMPLETE_RUNNER_DATA;
  constructor(
    public data: {
      firstname: string;
      lastname: string;
      place: string;
      postal_code: string;
      country: string;
      nationality: string;
      gender: number;
      birthdate: number;
    },
  ) {}
}
