<div class="premium-subscription-preview">
  <div class="premium-subscription-title">
    {{ 'PREMIUM_TYPE_' + subscription.premium_type.toLocaleUpperCase() | transloco }}
    <span class="light">
      · {{ 'PRICE_PERIOD_PAYMENT_' + subscription.subscription_type.toUpperCase() | transloco }}</span
    >
  </div>
  <div class="premium-subscription-period">
    {{ 'FROM' | transloco }} {{ subscription.start_date | date }} {{ 'TO' | transloco }} {{ subscription.end_date | date
    }}
  </div>
  <div class="premium-subscription-trial-period" *ngIf="subscription.included_trial_period > 0">
    {{ 'INCLUDED' | transloco }} : {{ subscription.included_trial_period }} {{ 'FREE_TRIAL_DAYS' | transloco }} ({{
    subscription.start_date | date }} - {{ subscription.projected_payment_date | date }})
  </div>
  <div class="actions">
    <div *ngIf="subscription.status === 0 || subscription.status === 4">
      <p class="text-center font-bold">{{ 'DETECTED_PENDING_SUB_TXT' | transloco }}</p>
      <p class="italic opacity-60 text-center font-bold">
        {{ 'PREM_DISC_WHY_PREMIUM_SUB3-1' | transloco }} {{ 'PREM_DISC_WHY_PREMIUM_SUB3-2' | transloco }} {{
        'PREM_DISC_WHY_PREMIUM_SUB3-3' | transloco }}
      </p>
      <div class="flex flex-wrap justify-center">
        <button
          mat-raised-button
          color="primary"
          class="!mt-[15px]"
          (click)="go(subscription.id)"
          *ngIf="options?.showActions === true"
        >
          {{ 'ADD_PAYMENT_METHOD' | transloco }}
        </button>

        <button
          mat-button
          class="!mt-[15px]"
          *ngIf="options?.isFromDialog === true && subscription.status === 4"
          (click)="remindLater.emit()"
        >
          {{ 'REMIND_ME_LATER' | transloco }}
        </button>

        <button mat-button color="warn" class="!mt-[15px]" (click)="deleteSubscription(subscription.id)">
          {{ 'CANCEL_SUBSCRIPTION' | transloco }}
        </button>
      </div>
    </div>
    <div *ngIf="subscription.status !== 0 && subscription.status !== 4">
      <span>{{ 'SUBSCRIPTION_STATUS_' + subscription.status | transloco }}</span>
      <button *ngIf="subscription.status === 3" mat-button [routerLink]="['/', 'premium', 'subscribe']">Retour</button>
    </div>
  </div>
</div>
