import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from '@betrail-libs/auth-state';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private authStateService: AuthStateService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authStateService
      .hasRole(DrupalRole.Administrator)
      .pipe(map(result => result || this.router.createUrlTree(['/home'])));
  }
}
