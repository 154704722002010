<ng-container *ngIf="(sortedSponsors$ | async) && (sortedSponsors$ | async).length > 0; else notYetLoaded">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'RUNNER_SELECT_SPONSOR' | transloco }}</mat-label>
    <mat-select [(value)]="selected">
      <ng-container *ngFor="let sponsor of (sortedSponsors$ | async)">
        <mat-option
          *ngIf="(sponsor?.data?.id && sponsor.data.id !== spid && filterSponsors(sponsor.data.id))"
          [value]="sponsor"
        >
          <div class="list-item-container" (click)="selectSponsor(sponsor)">
            <img *ngIf="sponsor.image; else noImg" [src]="sponsor.image" class="list-small-img" />
            <ng-template #noImg>
              <div class="list-small-img noImg"></div>
            </ng-template>
            {{ sponsor.data.name }}
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</ng-container>
<ng-template #notYetLoaded>
  <div class="spinner-container">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</ng-template>
