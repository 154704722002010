<div class="dialog-container">
  <header class="dialog-header">
    <h3>{{ 'PREMIUM_DIALOG_TITLE' | transloco }}</h3>
    <!-- <button class="btnClose" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button> -->
  </header>

  <section class="dialog-body">
    <p *ngIf="nbPremium === 0" class="placeholder">{{ 'PREMIUM_PLACEHOLDER' | transloco }}</p>
    <p *ngIf="nbPremium > 0" class="countdown">{{ nbPremium }} {{ 'PREM_DISC_ALREADY_PREMIUM' | transloco }}</p>
    <p class="trial-msg">{{ 'PREMIUM_FREE_TRIAL' | transloco }}</p>
    <div>
      <a class="btn-continue" mat-raised-button (click)="closeDialog()">{{ 'CONTINUE_ON_BETRAIL' | transloco }}</a>
      <a class="btn-discover" mat-raised-button (click)="closeDialog()" [routerLink]="['/', 'premium', 'discover']"
        >{{ 'DISCOVER_PREMIUM' | transloco }}</a
      >
    </div>
  </section>
</div>
