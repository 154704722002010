<div id="left-bar" [class.open]="open" [class.closed]="!open" ngClass.lt-md="small" ngClass.gt-md="large">
  <div id="close" *ngIf="open" (click)="toggleMenu(!open)">
    <button mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div id="logo"><span [routerLink]="['']" class="logo"></span></div>
  <nav class="menu" ngClass.gt-md="minified">
    <div class="section first">
      <ul>
        <li>
          <a
            *ngIf="isHomePage$ | async; else notHomePage"
            mat-button
            (click)="closeMenuIfSmall()"
            (click)="scrollToTop()"
            [title]="'MENU_HOME' | transloco"
            ><mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_HOME' | transloco }}">home</mat-icon
            ><span class="menu-item-text">{{ 'MENU_HOME' | transloco }}</span></a
          >
          <ng-template #notHomePage>
            <a mat-button (click)="closeMenuIfSmall()" [routerLink]="['/', 'home']" [title]="'MENU_HOME' | transloco"
              ><mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_HOME' | transloco }}">home</mat-icon
              ><span class="menu-item-text">{{ 'MENU_HOME' | transloco }}</span></a
            >
          </ng-template>
        </li>

        <li *ngIf="(isAdmin$ | async) || (isOrga$ | async)">
          <a
            *ngIf="(isAdmin$ | async) && selectedTrailAlias; else myOrga"
            mat-button
            (click)="organizerOpenMenu()"
            [routerLink]="['/', 'organizer', selectedTrailAlias, 'dashboard']"
            routerLinkActive="selected"
          >
            <mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_MY_ORGANIZER_SPACE' | transloco }}"
              >supervisor_account </mat-icon
            ><span class="menu-item-text">{{ 'MENU_MY_ORGANIZER_SPACE' | transloco }}</span>
          </a>
          <ng-template #myOrga>
            <a
              mat-button
              (click)="organizerOpenMenu()"
              [routerLink]="['/', 'organizer', 'my-organization']"
              [disabled]="(isOrga$ | async) === false && !selectedTrailAlias"
              [style.pointer-events]="(isOrga$ | async) === false && !selectedTrailAlias ? 'none' : 'auto'"
              routerLinkActive="selected"
            >
              <mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_MY_ORGANIZER_SPACE' | transloco }}"
                >supervisor_account </mat-icon
              ><span class="menu-item-text">{{ 'MENU_MY_ORGANIZER_SPACE' | transloco }}</span>
            </a>
          </ng-template>
        </li>
      </ul>
    </div>
    <div class="section second">
      <ul>
        <li *ngIf="isLogged$ | async">
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['runner', 'my-page', 'news-feed']"
            routerLinkActive="selected"
            [title]="'NEWS_FEED' | transloco"
          >
            <mat-icon matTooltipPosition="right" matTooltip="{{ 'NEWS_FEED' | transloco }}">art_track</mat-icon>
            <span class="menu-item-text">{{ 'NEWS_FEED' | transloco }}</span>
          </a>
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="
              '' +
              ('TRAIL_CALENDAR_URL' | transloco) +
              '/' +
              ('COUNTRY_URL_' + ((defaultCountry$ | async) || 'ALL') | transloco)
            "
            title="{{ 'TRAIL_CALENDAR' | transloco }} {{ 'COUNTRY_' + (defaultCountry$ | async) | transloco }}"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
            ><mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_CALENDAR' | transloco }}">date_range</mat-icon
            ><span class="menu-item-text">{{ 'MENU_CALENDAR' | transloco }}</span></a
          >
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['rankings']"
            routerLinkActive="selected"
            [title]="'MENU_RANKINGS' | transloco"
          >
            <mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_RANKINGS' | transloco }}"
              >format_list_numbered</mat-icon
            ><span class="menu-item-text">{{ 'MENU_RANKINGS' | transloco }}</span>
          </a>
        </li>
        <li *ngIf="isLogged$ | async">
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['calendar', 'last-results']"
            routerLinkActive="selected"
            [title]="'RUNNER_LAST_RESULTS' | transloco"
          >
            <mat-icon
              matTooltipPosition="right"
              matTooltip="{{ 'LAST_ENCODED_RESULTS_TITLE' | transloco }}"
              svgIcon="results"
            ></mat-icon>
            <span class="menu-item-text">{{ 'RUNNER_LAST_RESULTS' | transloco }}</span>
          </a>
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['blog']"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
            [title]="'MENU_BLOG' | transloco"
            ><mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_BLOG' | transloco }}">library_books</mat-icon
            ><span class="menu-item-text">{{ 'MENU_BLOG' | transloco }}</span></a
          >
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['timer']"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
            [title]="'MENU_TIMERS' | transloco"
            ><mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_TIMERS' | transloco }}">alarm</mat-icon
            ><span class="menu-item-text">{{ 'MENU_TIMERS' | transloco }}</span></a
          >
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['/', 'page', 'faq']"
            routerLinkActive="selected"
            title="FAQ"
            ><mat-icon matTooltipPosition="right" matTooltip="FAQ">help</mat-icon
            ><span class="menu-item-text">FAQ</span></a
          >
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['/', 'page', 'shop']"
            routerLinkActive="selected"
            [title]="'MENU_SHOP' | transloco"
            ><mat-icon svgIcon="tshirt" matTooltipPosition="right" matTooltip="{{ 'MENU_SHOP' | transloco }}"></mat-icon
            ><span class="menu-item-text">{{ 'MENU_SHOP' | transloco }}</span></a
          >
        </li>
      </ul>
    </div>

    <div class="section last">
      <ul>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="'MENU_CRITERIA_URL' | transloco"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
            [title]="'MENU_CRITERIA' | transloco"
            ><mat-icon matTooltipPosition="right" matTooltip="{{ 'MENU_CRITERIA' | transloco }}">check_circle</mat-icon>
            <span class="menu-item-text">{{ 'MENU_CRITERIA' | transloco }}</span></a
          >
        </li>
        <li>
          <a
            mat-button
            (click)="closeMenuIfSmall()"
            [routerLink]="['/', 'page', 'contact']"
            routerLinkActive="selected"
            title="Contact"
            ><mat-icon matTooltipPosition="right" matTooltip="Contact">email</mat-icon
            ><span class="menu-item-text">Contact</span></a
          >
        </li>
      </ul>
    </div>
  </nav>
</div>
<ng-container *ngIf="trail$ | async; let trail; else: defaultOrganizerMenu">
  <div
    class="side-menu {{ organizerSideMenuStatus }}"
    [class.menuOpen]="open"
    ngClass.gt-md="default-open"
    ngClass.lt-lg="default-closed"
  >
    <left-bar-trail-menu
      [trail]="trail"
      [selectedEvent]="event$ | async"
      [user]="user"
      (closePanelEvent)="organizerCloseMenu()"
      (setPanelToDefaultStatusEvent)="organizerSetMenuToDefaultStatus()"
    ></left-bar-trail-menu>
  </div>
</ng-container>
<ng-template #defaultOrganizerMenu>
  <ng-container
    *ngIf="
      user &&
      user?.organizations &&
      user?.organizations?.length == 1 &&
      (user?.organizations)[0].organization &&
      (user?.organizations)[0].organization?.trails &&
      (user?.organizations)[0].organization?.trails?.length == 1
    "
  >
    <div
      class="side-menu {{ organizerSideMenuStatus }}"
      [class.menuOpen]="open"
      ngClass.gt-md="default-open"
      ngClass.lt-lg="default-closed"
    >
      <left-bar-trail-menu
        [trail]="((user?.organizations)[0].organization?.trails)[0]"
        [user]="user"
        (closePanelEvent)="organizerCloseMenu()"
        (setPanelToDefaultStatusEvent)="organizerSetMenuToDefaultStatus()"
      ></left-bar-trail-menu>
    </div>
  </ng-container>
</ng-template>
