export class LoadLangs {
  static readonly type = '[I18n] Load langs';
}

export class LoadBundle {
  static readonly type = '[I18n] Load Bundle';
  constructor(public lang: string) {}
}

export class UseLang {
  static readonly type = '[I18n] Use Lang';
  constructor(public lang: string) {}
}

export class SetUrlLang {
  static readonly type = '[I18n] Set Url Lang';
  constructor(public lang: string) {}
}

export class LoadMissingBundle {
  static readonly type = '[I18n] Load Missing Bundle';
}

export class AddI18nKey {
  static readonly type = '[I18n] ADD Key';
  constructor(public key: string) {}
}

export class updateAllTranslations {
  static readonly type = '[I18n] Update all langs for translation';
  constructor(public key: string, public data: { [lang: string]: string }) {}
}
export class UpdateTrad {
  static readonly type = '[I18n] update Key';
  constructor(public key: string, public lang: string, public value: string) {}
}

export class ImportFile {
  static readonly type = '[I18n] import File';
  constructor(public file: any) {}
}
