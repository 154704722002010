<ng-container [formGroup]="formGroup">
  <div class="path-form" fxLayout="row wrap" fxLayoutAlign="space-between">
    <!--<div class="subtitle" fxFlex="100"><strong>{{ "" | transloco}}Généralités</strong></div>-->
    <input style="display: none" type="text" name="fakeusernamerememberedra" />
    <input style="display: none" type="text" name="fakeemailrememberedra" />
    <input style="display: none" type="password" name="fakepasswordrememberedra" />

    <mat-form-field class="title" appearance="fill" fxFlex="100" fxFlex.gt-sm="100%">
      <mat-icon class="suffixIcon" matSuffix>format_quote</mat-icon>
      <mat-label>{{ 'PATH_TITLE' | transloco }}</mat-label>
      <input matInput #titleField formControlName="title" autocomplete="new-password" />
    </mat-form-field>

    <buttons-toggle
      fxFlex="100"
      formControlName="type"
      [options]="((isAdmin$ | async) || (isEncoder$ | async)) ? typeOptionsAdmin : typeOptionsOrga"
      [c]="$any(formGroup.controls.type)"
      label="PATH_TYPE"
      [required]="true"
      (changed)="typeChanged($event)"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="marked"
      [c]="$any(formGroup.controls.marked)"
      [options]="toggleOptions"
      label="PATH_MARKED"
      [required]="true"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="private_sections"
      [c]="$any(formGroup.controls.private_sections)"
      [options]="privateSectionsOptions"
      label="PATH_PRIVATE_SECTIONS"
      [required]="true"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="fallback"
      [c]="$any(formGroup.controls.fallback)"
      [options]="toggleOptions"
      label="PATH_FALLBACK"
      [required]="true"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="display"
      [c]="$any(formGroup.controls.display)"
      [options]="displayOptions"
      label="PATH_DISPLAY"
      [required]="true"
      [message]="forceDisplay ? 'PATH_FORCEDISPLAY_WARNING' : ''"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="downloadable"
      [c]="$any(formGroup.controls.downloadable)"
      [options]="toggleOptions"
      label="PATH_DOWNLOADABLE"
      [required]="true"
      [message]="forceDownload ? 'PATH_FORCEDOWNLOAD_WARNING' : ''"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="zoomable"
      [c]="$any(formGroup.controls.zoomable)"
      [options]="toggleOptions"
      label="PATH_ZOOMABLE"
      [required]="true"
    ></buttons-toggle>

    <buttons-toggle
      fxFlex="100"
      formControlName="show_background"
      [c]="$any(formGroup.controls.show_background)"
      [options]="toggleOptions"
      label="PATH_SHOW_BACKGROUND"
      [required]="true"
    ></buttons-toggle>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="100">
      <mat-icon class="suffixIcon" matSuffix>keyboard_tab</mat-icon>
      <mat-label>{{ 'DISTANCE' | transloco }} (km)</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 25.42"
        formControlName="distance_meters"
        step="0.50"
        [max]="99999"
        [min]="0"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>vertical_align_bottom</mat-icon>
      <mat-label>{{ 'RACE_MIN_ALT' | transloco }} ({{ 'RACE_ALT_UNIT' | transloco }})</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 800"
        formControlName="min_altitude"
        inputmode="numeric"
        pattern="-*[0-9]*"
        [max]="99999"
        [min]="-1000"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>vertical_align_top</mat-icon>
      <mat-label>{{ 'RACE_MAX_ALT' | transloco }} ({{ 'RACE_ALT_UNIT' | transloco }})</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 800"
        formControlName="max_altitude"
        inputmode="numeric"
        pattern="-*[0-9]*"
        [max]="99999"
        [min]="-1000"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>show_chart</mat-icon>
      <mat-label>{{ 'RACE_ELEVATION' | transloco }}</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 800"
        formControlName="pos_elevation"
        inputmode="numeric"
        pattern="[0-9]*"
        [max]="99999"
        [min]="0"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>show_chart</mat-icon>
      <mat-label>{{ 'RACE_NEG_ELEVATION' | transloco }}</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 800"
        formControlName="neg_elevation"
        inputmode="numeric"
        pattern="[0-9]*"
        [max]="99999"
        [min]="0"
        digitOnly
      />
    </mat-form-field>
  </div>
</ng-container>
