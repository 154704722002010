<event-header [trail]="trail"></event-header>
<div class="default-form centeredAlign">
  <h2 class="centeredAlign">Revendiquer la page</h2>
  <div class="message title">Votre compte {{ (user$ | async)?.mail }} n'est pas encore un compte ORGANISATEUR.</div>
  <div class="message title">
    <strong>Que voulez-vous faire ?</strong>
  </div>

  <button class="bt-button orange" mat-button (click)="linkAccount()">Continuer avec mon compte actuel</button>
  <div class="option-explanation">
    Votre compte actuel deviendra un compte COUREUR & ORGANISATEUR et recevra les droits sur la course "{{ trail.title
    }}".
  </div>

  <div class="space-divider center-align">
    <span class="text">Ou alors</span>
  </div>

  <button class="bt-button orange" mat-button (click)="newAccount()">Créer un nouveau compte</button>
  <div class="option-explanation">
    Vous créerez un nouveau compte avec une <strong>autre adresse email</strong>. Attention, choisir cette option vous
    déconnectera de votre compte actuel.
  </div>
</div>
