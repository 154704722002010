<div>
  <div class="filter-container" ngClass.lt-sm="small">
    <button mat-icon-button (click)="close()" class="closeButton">
      <mat-icon>close</mat-icon>
    </button>
    <div class="filter-content">
      <h3>Tri rapide</h3>
      <div class="filter-sort">
        <button *ngFor="let params of resultSortParams" mat-raised-button (click)="selectResultSortType(params)">
          {{'RESULT_SORT_'+params | transloco}}
        </button>
      </div>
      <h3>Personnaliser le tri</h3>
      <mat-slide-toggle class="example-margin" (change)="chooseSortType($event)" [checked]="isDesc">
        {{isDesc ? "Croissant" : "Décroissant"}}
      </mat-slide-toggle>
    </div>
  </div>
  <div class="filter-footer">
    <button class="right" mat-raised-button color="accent" (click)="saveSortType()">
      {{ 'FILTER_APPLY' | transloco }}
    </button>
  </div>
</div>
