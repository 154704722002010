import { AfterViewInit, Component, OnDestroy, Type } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStateService } from '@betrail-libs/auth-state';
import { Select } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EventService } from '@betrail-libs/trail-data-state';
import { ActiveOrdersMessageComponent } from '../active-orders-message/active-orders-message.component';
import { ChooseDefaultCountryComponent } from '../choose-default-country/choose-default-country.component';
import { LoginDialogService } from '../login-dialog/login-dialog.service';
import { NgxsAuthState } from 'libs/front/auth-state/src/ngxs/auth.state';
import { AppParamsState } from '@betrail-libs/app-params-state';

@UntilDestroy()
@Component({
  selector: 'user-params-manager',
  templateUrl: './user-params-manager.component.html',
  styleUrls: ['./user-params-manager.component.css'],
})
export class UserParamsManagerComponent implements AfterViewInit, OnDestroy {
  snackBarState: any;
  notifier = new ReplaySubject(1);
  activeOrders$: Observable<any>;

  @Select(NgxsAuthState.user) user$!: Observable<any>;

  constructor(
    private authStateService: AuthStateService,
    private eventService: EventService,
    private snackBar: MatSnackBar,
    private dialogService: LoginDialogService,
  ) {}
  @Select(AppParamsState['defaultCountry']) defaultCountry$!: Observable<any>;

  openSnackBar(component: Type<any>, duration = null) {
    setTimeout(() => {
      this.snackBarState = this.snackBar.openFromComponent(component, {
        duration: duration || 999999 * 1000,
        panelClass: ['snackbar-style'],
      });
    }, 0);
  }

  ngAfterViewInit() {
    this.defaultCountry$.pipe(takeUntil(this.notifier)).subscribe(dc => {
      if (dc == undefined || dc == '') {
        this.openSnackBar(ChooseDefaultCountryComponent);
      } else {
        this.snackBarState && this.snackBarState.dismiss();
        this.notifier.next(undefined);
        this.notifier.complete();
      }
    });

    this.authStateService
      .isLogged()
      .pipe(untilDestroyed(this))
      .subscribe(logged => {
        if (logged) {
          this.eventService
            .getUserOrders()
            .pipe(
              map(orders => {
                if (orders && orders.length > 0) {
                  return orders.filter(o => o && o.status === 'new');
                } else {
                  return [];
                }
              }),
            )
            .subscribe(activeOrders => {
              if (activeOrders && activeOrders.length > 0) {
                this.openSnackBar(ActiveOrdersMessageComponent);
              }
            });
        }
      });
  }

  ngOnDestroy(): void {}
}
