import { Component, OnChanges, forwardRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bt-birthdate-select',
  templateUrl: './birthdate-select.component.html',
  styleUrls: ['./birthdate-select.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BirthdateSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => BirthdateSelectComponent), multi: true },
  ],
})
export class BirthdateSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  currentLang: any;

  years = [];
  day;
  year;
  month;
  isDisable = false;

  date: Date;
  constructor(translation: TranslocoService, private _changeDetectorRef: ChangeDetectorRef) {
    for (let i = 1920; i < 2017; i++) {
      this.years.push(i);
    }
    this.currentLang = translation.getActiveLang();

    translation.langChanges$.pipe(untilDestroyed(this)).subscribe((lang: string) => {
      this.currentLang = lang;
      // TODO This as a workaround.
      this._changeDetectorRef.markForCheck();
    });
  }

  propagateChange: any = () => {};
  propagateTouch: any = () => {};
  validateFn: any = () => {};
  ngOnChanges(changes): void {}

  getMonthName(val) {
    const d = new Date();
    d.setDate(5);
    d.setMonth(val - 1);
    return d;
  }

  writeValue(obj): void {
    let date;
    if (!(obj instanceof Date)) {
      date = new Date(obj);
    } else {
      date = obj;
    }
    this.date = date;
    this.day = date.getDate();
    this.month = date.getMonth() + 1;
    this.year = date.getFullYear();
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisable = isDisabled;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }

  changeValue() {
    this.date.setFullYear(this.year);
    this.date.setMonth(this.month - 1);
    this.date.setDate(this.day);
    this.propagateChange(this.date);
  }

  ngOnDestroy() {}
}
