<div class="max-w-sm">
  <h2 class="text-lg text-center !mt-2">{{ data.title | transloco }}</h2>
  <hr />
  <div class="p-4">
    <p class="my-4 text-center" [innerHtml]="data.message | transloco: data.replacements"></p>
    <div class="flex justify-between">
      <button mat-raised-button color="warn" (click)="closeDialog(false)">{{ 'CANCEL' | transloco }}</button>
      <button mat-raised-button color="accent" (click)="closeDialog(true)">{{ 'CONFIRM' | transloco }}</button>
    </div>
  </div>
</div>
