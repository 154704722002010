import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Trail } from '@betrail-libs/shared/interfaces/interfaces';
import { Organization } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-create-orga',
  templateUrl: './create-orga.component.html',
  styleUrls: ['./create-orga.component.scss', '../../common.scss'],
})
export class CreateOrgaComponent {
  /** Members **/
  previousStep: string;
  organizationForm: UntypedFormGroup;
  trail: Trail;
  get form() {
    return this.organizationForm.controls;
  }

  /** Inputs **/

  /** Outputs **/

  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();

  /** Contructor **/

  constructor(private formBuilder: UntypedFormBuilder) {
    this.buildOrgaForm();
  }

  buildOrgaForm() {
    this.organizationForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [this.trail && this.trail.organizer_email ? this.trail.organizer_email : '', Validators.required],
      legal_form: ['', Validators.required],
      web_site: [this.trail && this.trail.website ? this.trail.website : ''],
      facebook_page: [this.trail && this.trail.facebook ? this.trail.facebook : ''],
      phone: [this.trail && this.trail.organizer_phone ? this.trail.organizer_phone : ''],
    });
  }

  /** Page events **/

  /** Click events **/

  cancel() {
    this.cancelled.emit();
  }

  validate() {
    this.saved.emit(this.buildOrganization());
  }

  /** Private methods **/

  buildOrganization(): Organization {
    const newOrganization = new Organization();

    newOrganization.name = this.getFormValue('name');
    newOrganization.email = this.getFormValue('email');
    newOrganization.legal_form = this.getFormValue('legal_form');
    newOrganization.web_site = this.getFormValue('web_site');
    newOrganization.facebook_page = this.getFormValue('facebook_page');
    newOrganization.phone = this.getFormValue('phone');
    //newOrganization.description = this.getFormValue('description');

    return newOrganization;
  }

  getFormValue(name: string): string {
    let value = '';

    if (this.organizationForm.get(name)) {
      value = this.organizationForm.get(name).value;
    }

    return value;
  }
}
