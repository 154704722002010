import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-race-event-from-predicted-msg',
  templateUrl: './race-event-from-predicted-msg.component.html',
})
export class RaceEventFromPredictedMsgComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { alias: string; name: string; year: number }) {}
}
