export class LoadTimers {
  static readonly type = '[Timers] Load all timers';
}

export class LoadTimer {
  static readonly type = '[Timers] Load timer by alias';
  constructor(public alias: string | number, public force: boolean = false) {}
}

export class LoadTimerById {
  static readonly type = '[Timers] Load timer by id';
  constructor(public id: number | string) {}
}

export class SetSelectedCountries {
  static readonly type = '[Timers] Set selected countries';
  constructor(public countries: string[]) {}
}

export class SetDisplayType {
  static readonly type = '[Timers] Set display type';
  constructor(public type: string) {}
}
