import { Race } from '@betrail-libs/shared/interfaces/race.model';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { Trail } from '@betrail-libs/shared/interfaces/interfaces';

export class EditRaceDialogData {
  race: Race;
  event: IEvent;
  trail: Trail;
  multiple?: boolean;
}

/*
datesInfos : {
    several_days : boolean,
    dates : number[]
}



*/
