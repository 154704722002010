<div class="trail-menu-container">
  <div id="close" *ngIf="true" (click)="closePanel()">
    <button mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="trail-menu-header-photo">
    <div
      *ngIf="_trail && (_type === 'trail' || _type === 'event'); else defaultTitle"
      class="trail-menu-header-photo-image"
      [style.background-image]="_trail?.photo?.large_image | sanitizeStyleUrl"
    >
      &nbsp;
    </div>
  </div>
  <ng-template #defaultTitle>
    <div class="organizer-space-title">{{ 'ORGANIZER_SPACE' | transloco }}</div>
  </ng-template>
  <bux-sliding-panel [level]="_type">
    <ng-container>
      <ng-container *slidingPanelItem>
        <div class="menu-container" *ngIf="user?.organizations?.length > 0">
          <div class="trail-menu-title">
            <h2>{{ 'MY_TRAILS' | transloco }}</h2>
          </div>
          <ng-container *ngFor="let organization of user?.organizations">
            <ng-container *ngFor="let tr of organization?.trails">
              <ng-container *ngIf="!!tr">
                <div class="trail-menu-trail-overview" (click)="loadData(tr?.alias)">
                  <div class="trail-title">{{ tr.title }}</div>
                  <div
                    class="trail-menu-trail-overview-photo-trail"
                    [style.background-image]="tr.photo?.small_image | sanitizeStyleUrl"
                  ></div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <div slidingPanelBack class="back-link">
            <a class="link" (click)="closePanel()">
              <span class="arrow"><i class="left"></i></span><span class="label">{{ 'CLOSE' | transloco }}</span>
            </a>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *slidingPanelItem>
      <div class="menu-container" *ngIf="_trail?.alias">
        <div class="trail-menu-title">
          <h2>
            {{ _trail?.title }}
            <div class="public-page-link" *ngIf="_trail && _trail?.events && _trail?.events?.length > 0">
              <a [routerLink]="['/', 'race', _trail?.alias, (_trail?.events)[0]?.alias]">View public page</a>
            </div>
          </h2>
        </div>
        <div class="trail-menu-items">
          <ul>
            <li class="menu-item">
              <a
                class="link"
                (click)="setPanelToDefaultStatus()"
                [routerLink]="['/', 'organizer', _trail?.alias, 'dashboard']"
                routerLinkActive="selected"
                >{{ 'OVERVIEW' | transloco }}</a
              >
            </li>
            <li class="menu-item">
              <a
                class="link"
                (click)="setPanelToDefaultStatus()"
                [routerLink]="['/', 'organizer', _trail?.alias, 'edit']"
                routerLinkActive="selected"
                >{{ 'EDIT_TRAIL_INFORMATIONS' | transloco }}</a
              >
            </li>
            <li class="menu-item">
              <a
                class="link"
                (click)="setPanelToDefaultStatus()"
                [routerLink]="['/', 'organizer', _trail?.organization?.id, 'edit-organization']"
                routerLinkActive="selected"
                >{{ 'EDIT_ORGANIZATION_INFORMATIONS' | transloco }}</a
              >
            </li>
            <div class="menu-subtitle">
              <span class="label">{{ 'EVENTS' | transloco }}</span>
            </div>
            <li
              slidingPanelNext
              *ngFor="let ev of _trail?.events"
              class="menu-item menu-sub-item link"
              (click)="selectEvent(ev)"
            >
              <a class="link">
                <!--[routerLink]="['/', 'organizer', trail?.alias, ev?.alias]"-->
                <span class="link-label">{{ ev?.date * 1000 | date: 'yyyy' }}</span><i class="right"></i
              ></a>
            </li>
            <li class="menu-item">
              <div
                class="link flex justify-center items-center"
                (click)="setPanelToDefaultStatus()"
                [routerLink]="['/', 'calendar', 'add', _trail?.alias]"
                routerLinkActive="selected"
              >
                <mat-icon>playlist_add</mat-icon>&nbsp;
                <span class="no-underline">{{ 'RACE_ADD_EDITION' | transloco }}</span>
              </div>
            </li>
          </ul>
        </div>

        <div slidingPanelBack class="back-link">
          <a class="link" (click)="goToMyTrails()">
            <span class="arrow"><i class="left"></i></span><span class="label">{{ 'MY_TRAILS' | transloco }}</span>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *slidingPanelItem>
      <div class="menu-container" *ngIf="_trail?.alias && _selectedEvent?.alias">
        <div class="trail-menu-title">
          <h2>
            {{ _trail?.title }}
            <div class="year">{{ _selectedEvent?.date * 1000 | date: 'yyyy' }}</div>
          </h2>
        </div>
        <div class="trail-menu-items">
          <ul>
            <li>
              <div class="menu-item">
                <a
                  class="link"
                  (click)="setPanelToDefaultStatus()"
                  [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'edit']"
                  routerLinkActive="selected"
                  >{{ 'EDIT_EDITION' | transloco }} {{ _selectedEvent?.date * 1000 | date: 'yyyy' }}</a
                >
              </div>
            </li>
            <li>
              <div class="menu-item">
                <a
                  class="link"
                  (click)="setPanelToDefaultStatus()"
                  [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'edit-distances']"
                  routerLinkActive="selected"
                  >{{ 'EDIT_RACES' | transloco }} {{ _selectedEvent?.date * 1000 | date: 'yyyy' }}</a
                >
              </div>
            </li>
            <ng-container *ngIf="_selectedEvent?.date * 1000 > now || !!_selectedEvent?.signupData">
              <div class="menu-subtitle">
                <span class="label"> {{ 'ONLINE_REGISTRATIONS' | transloco }} <span class="new">NEW</span> : </span>
              </div>
              <li>
                <div class="menu-item">
                  <a
                    class="link"
                    (click)="setPanelToDefaultStatus()"
                    [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'edit-registration-form']"
                    routerLinkActive="selected"
                    ><span class="step">1</span>{{ 'SIGNUP_FORM_CREATION' | transloco }}</a
                  >
                </div>
              </li>
              <!--<li>
                <div class="menu-item">
                  <a
                    class="link"
                    (click)="setPanelToDefaultStatus(true)"
                    [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'virtual-wallet']"
                    routerLinkActive="selected"
                    ><span class="step">2</span>{{ 'VIRTUAL_WALLET' | transloco }}</a
                  >
                </div>
              </li>-->
              <li>
                <div class="menu-item">
                  <a
                    class="link"
                    (click)="setPanelToDefaultStatus()"
                    [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'publish-registration-form']"
                    routerLinkActive="selected"
                    ><span class="step">2</span>{{ 'PUTTING_ON_LINE' | transloco }}</a
                  >
                </div>
              </li>
              <li>
                <div class="menu-item">
                  <a
                    class="link"
                    (click)="setPanelToDefaultStatus()"
                    [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'registrations-dashboard']"
                    routerLinkActive="selected"
                    ><span class="step">3</span>{{ 'MANAGE_REGISTRATIONS' | transloco }}</a
                  >
                </div>
              </li>
              <li>
                <div class="menu-item">
                  <a
                    class="link"
                    (click)="setPanelToDefaultStatus()"
                    [routerLink]="['/', 'organizer', _trail?.alias, _selectedEvent?.alias, 'exports-and-transfers']"
                    routerLinkActive="selected"
                    ><span class="step">4</span>{{ 'FINANCE_AND_BANKWIRES' | transloco }}</a
                  >
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div slidingPanelBack class="back-link">
          <a class="link">
            <!-- [routerLink]="['/', 'organizer', trail?.alias, 'dashboard']"-->
            <span class="arrow"><i class="left"></i></span><span class="label">{{ 'BACK' | transloco }}</span>
          </a>
        </div>
      </div>
    </ng-container>
  </bux-sliding-panel>
</div>
