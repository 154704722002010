import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlidingPanelService {
  private nextSubject = new Subject();
  public next$ = this.nextSubject.asObservable();

  private backSubject = new Subject();
  public back$ = this.backSubject.asObservable();

  back() {
    this.backSubject.next(undefined);
  }

  next() {
    this.nextSubject.next(undefined);
  }
}
