import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService } from '@betrail-libs/auth-state';
import { CreateNewSponsor, LoadAllSponsors } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ALL_COUNTRIES_CODES } from '@betrail-libs/shared/utils';
import { ImageUploadService } from '../../common/images/image-upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@UntilDestroy()
@Component({
  selector: 'app-sponsor-form-dialog',
  templateUrl: './sponsor-form-dialog.component.html',
  styleUrls: ['./sponsor-form-dialog.component.scss'],
})
export class SponsorFormDialogComponent {
  user$ = this.authState.getUser();
  sponsorForm: UntypedFormGroup = this.resetForm();
  countries = ALL_COUNTRIES_CODES;
  formValid = false;
  imageData: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<SponsorFormDialogComponent>,
    private fb: UntypedFormBuilder,
    private store: Store,
    private authState: AuthStateService,
    private imgService: ImageUploadService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService,
  ) {}

  resetForm() {
    return this.fb.group(
      {
        name: '',
        country: '',
        link: '',
        linkEnabled: false,
        picture: '',
      },
      {
        validators: this.validateSponsor(),
      },
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addImage(file: Event | File): void {
    if ('size' in file) {
      if (file.size < 2000000) {
        const imgObservable = this.imgService.getImageObservableFromFile(file);
        imgObservable.subscribe(d => {
          this.imageData = d;
          this.sponsorForm.patchValue({
            picture: d,
          });
        });
      } else {
        this.snackbar.open(this.transloco.translate('ERROR_FILE_TOO_LARGE'), 'OK');
      }
    }
  }

  saveSponsor() {
    const form = { ...this.sponsorForm.value };
    const sponsor = {
      name: form.name,
      country: form.country,
      link: form.link,
      linkEnabled: form.linkEnabled === true ? 1 : 0,
      picture: this.imageData,
    };
    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      this.store.dispatch(new CreateNewSponsor(sponsor, this.data.role, user));
      this.store.dispatch(new LoadAllSponsors());
      this.closeDialog();
      alert('Sponsor correctement ajouté à la liste !');
    });
  }

  validateSponsor(this: SponsorFormDialogComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = [];
      const name = control.get('name').value;
      const picture = control.get('picture').value;

      if (name == '') error.push('nameError');
      if (picture == '') error.push('imageError');

      const errors = {};
      for (let i = 0; i < error.length; i++) {
        errors[error[i]] = true;
      }
      error.length === 0 ? (this.formValid = true) : (this.formValid = false);
      return error.length == 0 ? null : errors;
    };
  }
}
