// import { SimplebarAngularModule } from 'simplebar-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, isPlatformServer, NgOptimizedImage } from '@angular/common';
import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  Injectable,
  NgModule,
  NO_ERRORS_SCHEMA,
  PLATFORM_ID,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
  MatLuxonDateModule,
} from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthStateModule } from '@betrail-libs/auth-state';
import { TranslocoModule } from '@ngneat/transloco';
import { BtButtonComponent } from './components/bt-button/bt-button.component';
import { BtPageComponent } from './components/bt-page/bt-page.component';
import { BtSimpleCardComponent } from './components/bt-simple-card/bt-simple-card.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { PlaceComponent } from './components/place/place.component';
// import { Ng2OdometerModule } from 'ng2-odometer';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { SafePipeModule } from 'safe-pipe';
import { BackgroundUriService } from './backgroundUri.service';
import { BetrailUxFormsService } from './betrail-ux-forms.service';
import { BrowserOnlyDirective } from './browser-only.directive';
import { CapitalizePipe } from './capitalize.pipe';
import { AccountMatchingCheckComponent } from './components/account-matching-check/account-matching-check.component';
import { ActiveOrdersMessageComponent } from './components/active-orders-message/active-orders-message.component';
import { AdMySportsBannerComponent } from './components/ad-my-sports-banner/ad-my-sports-banner.component';
import { AddToCalendarButtonComponent } from './components/add-to-calendar-button/add-to-calendar-button.component';
import { AnnouncementPopUpComponent } from './components/announcement-pop-up/announcement-pop-up.component';
import { BannerDisplayerComponent } from './components/banner-displayer/banner-displayer.component';
import { BannerComponent } from './components/banner/banner.component';
import { BasicAlertDialogComponent } from './components/basic-alert-dialog/basic-alert-dialog.component';
import { BasicConfirmDialogComponent } from './components/basic-confirm-dialog/basic-confirm-dialog.component';
import { BirthdateSelectComponent } from './components/birthdate-select/birthdate-select.component';
import { BtCardComponent } from './components/bt-card/bt-card.component';
import { ButtonsToggleComponent } from './components/buttons-toggle/buttons-toggle.component';
import { ChooseDefaultCountryComponent } from './components/choose-default-country/choose-default-country.component';
import { CountdownBtnComponent } from './components/countdown-btn/countdown-btn.component';
import { CountdownDialogComponent } from './components/countdown-dialog/countdown-dialog.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { DateCardComponent } from './components/date-card/date-card.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { EventHeaderComponent } from './components/event-header/event-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerFormComponent } from './components/forms/banner-form/banner-form.component';
import { EventFormComponent } from './components/forms/event-form/event-form.component';
import { OrganizationFormComponent } from './components/forms/organization-form/organization-form.component';
import { PathFormComponent } from './components/forms/path-form/path-form.component';
import { TimerFormComponent } from './components/forms/timer-form/timer-form.component';
import { TrailFormComponent } from './components/forms/trail-form/trail-form.component';
import { GoogleAdBannerComponent } from './components/google-ad-banner/google-ad-banner.component';
import { GpxProfileViewerComponent } from './components/gpx-profile-viewer/gpx-profile-viewer.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { LastClaimedRunnerBlockComponent } from './components/last-claimed-runner-block/last-claimed-runner-block.component';
import { LastResultBlockComponent } from './components/last-result-block/last-result-block.component';
import { LeftBarTrailMenuComponent } from './components/left-bar-trail-menu/left-bar-trail-menu.component';
import { LeftBarComponent } from './components/left-bar/left-bar.component';
import { MasqueradeComponent } from './components/masquerade/masquerade.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { NextRegistrationEventBlockComponent } from './components/next-registration-event-block/next-registration-event-block.component';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';
import { PathPreviewComponent } from './components/path-preview/path-preview.component';
import { PremiumButtonComponent } from './components/premium-button/premium-button.component';
import { PremiumSubscriptionDialogComponent } from './components/premium-subscription-dialog/premium-subscription-dialog.component';
import { PremiumSubscriptionPreviewComponent } from './components/premium-subscription-preview/premium-subscription-preview.component';
import { ProfilePreviewComponent } from './components/profile-preview/profile-preview.component';
import { RaceSearchComponent } from './components/race-search/race-search.component';
import { RegistrationStepsComponent } from './components/registration-steps/registration-steps.component';
import { RouteNameDisplayerComponent } from './components/route-name-displayer/route-name-displayer.component';
import { RunnerPreviewBlockComponent } from './components/runner-preview-block/runner-preview-block.component';
import { SearchPlaceComponent } from './components/search-place/search-place.component';
import { SearchRunnerTrailComponent } from './components/search-runner-trail/search-runner-trail.component';
import { SearchSponsorComponent } from './components/search-sponsor/search-sponsor.component';
import { SlidingPanelBackDirective } from './components/sliding-panel/sliding-panel-back.directive';
import { SlidingPanelItemElementDirective } from './components/sliding-panel/sliding-panel-item-element.directive';
import { SlidingPanelItemDirective } from './components/sliding-panel/sliding-panel-item.directive';
import { SlidingPanelNextDirective } from './components/sliding-panel/sliding-panel-next.directive';
import { SlidingPanelComponent } from './components/sliding-panel/sliding-panel.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { TimerSelectorComponent } from './components/timer-selector/timer-selector.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { UserNotificationComponent } from './components/user-notification/user-notification.component';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { UserParamsManagerComponent } from './components/user-params-manager/user-params-manager.component';
import { WeeksSelectorComponent } from './components/weeks-selector/weeks-selector.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { WelcomeStepAvatarComponent } from './components/welcomesteps/welcome-step-avatar/welcome-step-avatar.component';
import { WelcomeStepConfirmEmailComponent } from './components/welcomesteps/welcome-step-confirm-email/welcome-step-confirm-email.component';
import { WelcomeStepRegionComponent } from './components/welcomesteps/welcome-step-region/welcome-step-region.component';
import { WelcomeStepResultsCheckComponent } from './components/welcomesteps/welcome-step-results-check/welcome-step-results-check.component';
import { WelcomeStepResultsClaimingTutoComponent } from './components/welcomesteps/welcome-step-results-claiming-tuto/welcome-step-results-claiming-tuto.component';
import { WelcomeStepRunnerDataComponent } from './components/welcomesteps/welcome-step-runner-data/welcome-step-runner-data.component';
import { WelcomeStepsComponent } from './components/welcomesteps/welcome-steps/welcome-steps.component';
import { FormatLevelPipe } from './formatLevel.pipe';
import { HourMinuteSecondsPipe } from './formatTime.pipe';
import { GetResultImagePipe } from './get-result-image.pipe';
import { InitialsFromTitlePipe } from './initials-from-title.pipe';
import { LuxonDatePipe } from './luxonDate.pipe';
import { NgLetModule } from './ngLet';
import { NumberToSymbolsPipe } from './number-to-symbols.pipe';
import { SanitizeStyleUrlPipe } from './sanitize-style-url.pipe';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { SsrOnlyDirective } from './ssr-only.directive';
import { ToAgePipe } from './to-age.pipe';
import { ToDrupalImageUrlPipe } from './to-drupal-image-url.pipe';
import { ToHmsPipe } from './to-hms.pipe';
import { ToRelativeDatePipe } from './to-relative-date.pipe';
import { TrimInputDirective } from './trim-input.directive';
import { PremiumStampComponent } from './components/premium-stamp/premium-stamp.component';
import { StickyFooterComponent } from './components/sticky-footer/sticky-footer.component';

@Injectable()
export class PwaService {
  asToUpdate = false;

  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar, private appRef: ApplicationRef) {}

  listenToUpdate() {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyTwoMinutes$ = interval(120 * 1000);
    const everyTwoMinutesAppIsStable$ = concat(appIsStable$, everyTwoMinutes$);

    everyTwoMinutesAppIsStable$.subscribe(() => this.swUpdate.isEnabled && this.swUpdate.checkForUpdate());

    this.swUpdate.available.subscribe(event => {
      this.asToUpdate = true;
      const snack = this.snackbar.open('Update Available', 'Reload', { duration: 600000 });

      snack.onAction().subscribe(() => {
        window.location.reload();
      });
    });
  }
}

const MatModules = [
  DragDropModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatInputModule,
  MatIconModule,
  MatMenuModule,
  MatListModule,
  MatDialogModule,
  MatDividerModule,
  FlexLayoutModule,
  MatProgressBarModule,
  ReactiveFormsModule,
  FormsModule,
  MatTabsModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatLuxonDateModule,
  MatAutocompleteModule,
  ScrollingModule,
  MatIconModule,
  MatListModule,
  FlexLayoutModule,
  MatIconModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatSortModule,
  MatTableModule,
  MatCardModule,
  MatStepperModule,
  MatPaginatorModule,
  MatSidenavModule,
  CdkTableModule,
  MatSelectModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatRadioModule,
  MatSnackBarModule,
  NgxMaterialTimepickerModule,
  MatBadgeModule,
  // SimplebarAngularModule
];
@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    ...MatModules,
    RouterModule,
    SafePipeModule,
    AuthStateModule,
    InfiniteScrollModule,
    SlickCarouselModule,
    DigitOnlyModule,
    NgLetModule,
    NgOptimizedImage,
  ],
  declarations: [
    TopBarComponent,
    LeftBarComponent,
    LoginDialogComponent,
    PlaceComponent,
    ToHmsPipe,
    ToAgePipe,
    HourMinuteSecondsPipe,
    FormatLevelPipe,
    MasqueradeComponent,
    ToDrupalImageUrlPipe,
    SanitizeStyleUrlPipe,
    SanitizeUrlPipe,
    LuxonDatePipe,
    LeftBarComponent,
    BtCardComponent,
    ErrorDialogComponent,
    EventFormComponent,
    CountryFlagComponent,
    TrailFormComponent,
    BirthdateSelectComponent,
    ImageUploadComponent,
    BannerDisplayerComponent,
    BannerComponent,
    EventHeaderComponent,
    AddToCalendarButtonComponent,
    RunnerPreviewBlockComponent,
    LastResultBlockComponent,
    LastClaimedRunnerBlockComponent,
    AccountMatchingCheckComponent,
    DateCardComponent,
    NextRegistrationEventBlockComponent,
    UserParamsManagerComponent,
    ChooseDefaultCountryComponent,
    NextRegistrationEventBlockComponent,
    BrowserOnlyDirective,
    SsrOnlyDirective,
    SearchRunnerTrailComponent,
    RaceSearchComponent,
    BtButtonComponent,
    BtPageComponent,
    BtSimpleCardComponent,
    SlidingPanelComponent,
    SlidingPanelItemDirective,
    SlidingPanelItemElementDirective,
    SlidingPanelNextDirective,
    SlidingPanelBackDirective,
    SearchPlaceComponent,
    RegistrationStepsComponent,
    ActiveOrdersMessageComponent,
    SocialLinksComponent,
    OrganizationFormComponent,
    MessageDialogComponent,
    GpxProfileViewerComponent,
    LeftBarTrailMenuComponent,
    FooterComponent,
    NotificationMessageComponent,
    ToRelativeDatePipe,
    RouteNameDisplayerComponent,
    GoogleAdBannerComponent,
    BannerFormComponent,
    ButtonsToggleComponent,
    WeeksSelectorComponent,
    TimerSelectorComponent,
    TimerFormComponent,
    UserNotificationComponent,
    UserNotificationsComponent,
    PathPreviewComponent,
    ProfilePreviewComponent,
    PathFormComponent,
    WelcomeStepsComponent,
    WelcomeStepConfirmEmailComponent,
    WelcomeStepAvatarComponent,
    WelcomeStepRunnerDataComponent,
    WelcomeStepRegionComponent,
    WelcomeStepResultsCheckComponent,
    WelcomeStepResultsClaimingTutoComponent,
    WelcomeDialogComponent,
    CountdownDialogComponent,
    CountdownBtnComponent,
    SearchSponsorComponent,
    TrimInputDirective,
    BasicConfirmDialogComponent,
    BasicAlertDialogComponent,
    PremiumSubscriptionPreviewComponent,
    PremiumSubscriptionDialogComponent,
    AnnouncementPopUpComponent,
    CapitalizePipe,
    GetResultImagePipe,
    NumberToSymbolsPipe,
    InitialsFromTitlePipe,
    PremiumButtonComponent,
    AdMySportsBannerComponent,
    PremiumStampComponent,
    StickyFooterComponent,
  ],
  exports: [
    ...MatModules,
    NgLetModule,
    TopBarComponent,
    LeftBarComponent,
    BtCardComponent,
    PlaceComponent,
    EventFormComponent,
    TrailFormComponent,
    ToHmsPipe,
    ToAgePipe,
    HourMinuteSecondsPipe,
    FormatLevelPipe,
    ToDrupalImageUrlPipe,
    SanitizeStyleUrlPipe,
    SanitizeUrlPipe,
    ToRelativeDatePipe,
    LuxonDatePipe,
    TrimInputDirective,
    CountryFlagComponent,
    TranslocoModule,
    BirthdateSelectComponent,
    // Ng2OdometerModule,
    ImageUploadComponent,
    InfiniteScrollModule,
    BannerDisplayerComponent,
    EventHeaderComponent,
    AddToCalendarButtonComponent,
    RunnerPreviewBlockComponent,
    LastResultBlockComponent,
    LastClaimedRunnerBlockComponent,
    AccountMatchingCheckComponent,
    DateCardComponent,
    NextRegistrationEventBlockComponent,
    UserParamsManagerComponent,
    ChooseDefaultCountryComponent,
    NextRegistrationEventBlockComponent,
    BrowserOnlyDirective,
    SsrOnlyDirective,
    SearchRunnerTrailComponent,
    RaceSearchComponent,
    BtButtonComponent,
    BtPageComponent,
    BtSimpleCardComponent,
    SlidingPanelComponent,
    SlidingPanelItemDirective,
    SlidingPanelItemElementDirective,
    SlidingPanelBackDirective,
    SlidingPanelNextDirective,
    DigitOnlyModule,
    SearchPlaceComponent,
    RegistrationStepsComponent,
    ActiveOrdersMessageComponent,
    SocialLinksComponent,
    OrganizationFormComponent,
    MessageDialogComponent,
    GpxProfileViewerComponent,
    FooterComponent,
    NotificationMessageComponent,
    RouteNameDisplayerComponent,
    BannerFormComponent,
    ButtonsToggleComponent,
    WeeksSelectorComponent,
    TimerSelectorComponent,
    TimerFormComponent,
    PathPreviewComponent,
    ProfilePreviewComponent,
    PathFormComponent,
    WelcomeStepsComponent,
    WelcomeStepConfirmEmailComponent,
    WelcomeStepAvatarComponent,
    CountdownBtnComponent,
    SearchSponsorComponent,
    BasicConfirmDialogComponent,
    BasicAlertDialogComponent,
    PremiumSubscriptionPreviewComponent,
    AnnouncementPopUpComponent,
    CapitalizePipe,
    GetResultImagePipe,
    NumberToSymbolsPipe,
    InitialsFromTitlePipe,
    PremiumButtonComponent,
    AdMySportsBannerComponent,
    PremiumStampComponent,
    StickyFooterComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    BetrailUxFormsService,
    BackgroundUriService,
    /* { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, */
    PwaService,
    /* { provide: DateAdapter, useClass: CustomDateAdapter }, */
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { firstDayOfWeek: 1 } },
  ],
})
export class BetrailUxModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    const svgUrl = 'assets/Icons/loader-default.svg';
    // domain and port for SSR in this example is static. Use i.e. environment files to use appropriate dev/prod domain:port
    const domain = isPlatformServer(platformId) ? 'http://localhost:5000/' : '';

    this.matIconRegistry.addSvgIcon(
      `trophy`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/trophy.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `trophyNegate`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/trophy_negate.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `results`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/results.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `resultsNegate`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/results_negate.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `addToCalendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/add_to_calendar.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `editCalendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/edit_calendar.svg'),
    );
    this.matIconRegistry.addSvgIcon(`go`, this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/go.svg'));
    this.matIconRegistry.addSvgIcon(
      `label`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/label.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `loop2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/loop-2.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `night2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/night.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `excel`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/excel.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      `runner`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/runner.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `offer`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/offer.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `stats`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/stats.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `safety`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/safety.svg'),
    );
    this.matIconRegistry.addSvgIcon(`go`, this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/go.svg'));
    this.matIconRegistry.addSvgIcon(
      `label`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/label.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `night`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/night.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `tshirt`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/tshirt.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `facebookLight`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/facebook_logo.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `instagramLight`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + './assets/instagram_logo.svg'),
    );
  }
}
