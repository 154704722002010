import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UsersService } from '@betrail-libs/auth-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Store } from '@ngxs/store';
import { SetConfirmationLater } from '@betrail-libs/app-params-state';

@UntilDestroy()
@Component({
  selector: 'welcome-step-confirm-email',
  templateUrl: './welcome-step-confirm-email.component.html',
  styleUrls: ['./welcome-step-confirm-email.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepConfirmEmailComponent implements OnDestroy {
  @Input() user;
  buttonText = 'RESEND_CONFIRMATION_EMAIL';
  buttonDisabled = false;

  @Output() next = new EventEmitter();

  constructor(private userService: UsersService, private store: Store) {}

  reSendConfirmationEmail() {
    this.buttonText = 'SENDING_CONFIRMATION_EMAIL';
    this.buttonDisabled = true;
    this.userService
      .validateEmailResend()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.buttonText = 'RESEND_CONFIRMATION_EMAIL';
        this.buttonDisabled = false;
      });
  }

  nextStep() {
    this.store.dispatch(new SetConfirmationLater(true));
    this.next.emit();
  }

  ngOnDestroy() {}
}
