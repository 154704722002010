import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-confirm-dialog',
  templateUrl: './basic-confirm-dialog.component.html',
})
export class BasicConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; replacements?: unknown },
    private dialogRef: MatDialogRef<BasicConfirmDialogComponent>,
  ) {}

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }
}
