<button
  tabindex="-1"
  class="closeButton"
  mat-icon-button
  *ngIf="fullscreen && !dialogRef.disableClose"
  (click)="close()"
>
  <mat-icon aria-label="Close Dialog">close</mat-icon>
</button>
<div class="form">
  <div
    class="login-form"
    *ngIf="(isLoadingUser$ | async) === true || formType === 'waiting'; else loginBox"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div>{{ 'LOADING' | transloco }}</div>
  </div>
  <ng-template #loginBox>
    <ng-container [ngSwitch]="formType">
      <!-- * USER ALREADY LOGGED IN MSG * -->

      <div class="login-form" *ngSwitchCase="'loggedIn'" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
        {{ 'ALREADY_LOGGED_IN' | transloco }}
      </div>

      <!-- * EXISTING USER LOGIN * -->

      <div class="login-form" *ngSwitchCase="'login'" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
        <h2 class="centeredAligned">{{ 'CONNECTION' | transloco }}</h2>
        <mat-form-field appearance="fill" color="secondary">
          <mat-icon class="suffixIcon" matSuffix>account_circle</mat-icon>
          <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
          <input tabindex="0" matInput #user type="text" placeholder="you@domaine.com" />
        </mat-form-field>
        <mat-form-field appearance="fill" color="secondary">
          <mat-icon class="suffixIcon" matSuffix>lock</mat-icon>
          <mat-label tabindex="1">{{ 'YOUR_PASSWORD' | transloco }}</mat-label>
          <input
            matInput
            #pass
            type="password"
            placeholder="Mot de passe"
            (keydown.enter)="loginWithUsername(user.value, pass.value)"
          />
        </mat-form-field>
        <span class="passwordToggle" (click)="displayPasswordToggle()"
          ><mat-icon class="eye">{{ passwordToggleIcon }}</mat-icon>{{ passwordToggleText | transloco }}</span
        >

        <div class="bottom">
          <div *ngIf="error" class="error">{{ error | transloco }}</div>
          <button
            [disabled]="loginButtonDisabled"
            mat-raised-button
            (click)="loginWithUsername(user.value, pass.value)"
            class="email"
          >
            {{ loginText | transloco }}
          </button>
          <a class="link" (click)="forgottenPassword()">{{ 'FORGOTTEN_PASS_CLICK_HERE' | transloco }}</a>
        </div>

        <p class="message centeredAligned">
          {{ 'NO_BETRAIL_ACCOUNT_YET' | transloco }}<br />
          <a (click)="registerForm()">{{ 'CREATE_AN_ACCOUNT' | transloco }}</a>
        </p>
      </div>

      <!-- * PASSWORD RECOVERY * -->
      <div
        class="login-form"
        *ngSwitchCase="'forgottenPassword'"
        fxLayout="column"
        fxLayoutAlign="centeredAligned stretch"
      >
        <h2 class="centeredAligned">{{ 'FORGOTTEN_PASS' | transloco }}</h2>
        <p class="highlight">{{ 'PLEASE_ENTER_EMAIL' | transloco }}</p>
        <p>{{ 'WELL_SEND_NEW_CONNECTION_DATA' | transloco }}</p>
        <mat-form-field appearance="fill" color="secondary">
          <mat-icon class="suffixIcon" matSuffix>account_circle</mat-icon>
          <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
          <input
            tabindex="0"
            matInput
            #email
            type="text"
            placeholder="you@domaine.com"
            (keydown.enter)="sendNewPassword(email.value)"
          />
        </mat-form-field>
        <div class="bottom">
          <div *ngIf="error" class="error">{{ error | transloco }}</div>
          <button
            [disabled]="newPasswordButtonDisabled"
            mat-raised-button
            (click)="sendNewPassword(email.value)"
            class="email"
          >
            {{ 'SEND_NEW_PASSWORD' | transloco }}
          </button>
          <a class="link" (click)="loginForm()">{{ 'BACK_TO_CONNECTION_FORM' | transloco }}</a>
        </div>
      </div>

      <div
        *ngIf="formType == 'newPasswordSent'"
        class="login-form"
        fxLayout="column"
        fxLayoutAlign="centeredAligned stretch"
      >
        <h2 class="centeredAligned">{{ 'PASSWORD_SENT' | transloco }}</h2>
        <p class="highlight">
          {{ 'WS_CHECK_YOUR_EMAILS' | transloco }} : {{ 'NEW_PASS_SENT_TO' | transloco | lowercase }} <br />
          {{ newPasswordEmail }}
        </p>
        <p>{{ 'YOU_CAN_CONNECT_WITH_NEW_PASS' | transloco }}</p>
        <p>{{ 'OLD_PASS_DELETED' | transloco }}</p>
        <div class="bottom"><a class="link" (click)="loginForm()">{{ 'BACK_TO_CONNECTION_FORM' | transloco }}</a></div>
      </div>

      <!-- * NEW USER CREATION * -->

      <div *ngSwitchCase="'register'">
        <h2 class="centeredAligned">{{ 'CREATE_RUNNER_ACCOUNT' | transloco }}</h2>
        <form
          (submit)="submitRegistration()"
          [formGroup]="registrationForm"
          class="register-form"
          fxLayout="column"
          fxLayoutAlign="centeredAligned stretch"
        >
          <div class="centeredAligned as" *ngIf="getRunner(); let runner">
            {{ 'CREATE_ACCOUNT_LINKED_TO' | transloco: {title: runner?.display_title ? runner?.display_title :
            runner?.title} }}
          </div>
          <mat-form-field appearance="fill" color="secondary">
            <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
            <mat-icon class="suffixIcon" matSuffix>email</mat-icon>
            <input matInput #email type="text" placeholder="Votre adresse email" formControlName="mail" />
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field class="field" appearance="fill" color="secondary" fxFlex="48">
              <mat-icon class="suffixIcon" matSuffix>person</mat-icon>
              <mat-label>{{ 'RUNNER_FIRSTNAME' | transloco }}</mat-label>
              <input
                matInput
                id="firstname"
                type="text"
                placeholder="Prénom"
                formControlName="firstname"
                label="Prénom"
              />
            </mat-form-field>
            <mat-form-field appearance="fill" color="secondary" fxFlex="48">
              <mat-icon class="suffixIcon" matSuffix>person</mat-icon>
              <mat-label>{{ 'RUNNER_LASTNAME' | transloco }}</mat-label>
              <input matInput type="text" placeholder="Nom" formControlName="lastname" />
            </mat-form-field>
          </div>

          <div>
            <span class="label">{{ 'RUNNER_BIRTHDATE' | transloco }} *</span>
            <div>
              <birthdate-select formControlName="birthdate"></birthdate-select>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
              <mat-form-field appearance="fill" color="secondary" fxFlex="48">
                <mat-label>{{ 'RUNNER_GENDER' | transloco }}</mat-label>
                <mat-select formControlName="gender">
                  <mat-option value="MALE">{{ 'RUNNER_MALE' | transloco }}</mat-option>
                  <mat-option value="FEMALE">{{ 'RUNNER_FEMALE' | transloco }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" color="secondary" fxFlex="48">
                <mat-label>{{ 'RUNNER_PLACE' | transloco }}</mat-label>
                <input matInput type="text" placeholder="Localité" formControlName="place" />
              </mat-form-field>
            </div>
          </div>

          <mat-form-field appearance="fill" color="secondary">
            <mat-icon class="suffixIcon" matSuffix>lock</mat-icon>
            <mat-label>{{ 'USER_CREATE_NEW_PASSWORD' | transloco }}</mat-label>
            <input matInput #password type="password" placeholder="Your new password" formControlName="pass" />
          </mat-form-field>

          <mat-checkbox fxFlex="100" formControlName="cgu"
            ><div class="checkbox">
              {{ 'I_ACCEPT_THE_PLURAL' | transloco }}
              <a target="_blank" href="../assets/docs/Terms of Service.pdf">{{ 'BETRAIL_CGU' | transloco }}</a>
            </div></mat-checkbox
          >
          <mat-checkbox fxFlex="100" formControlName="charte"
            ><div class="checkbox">
              {{ 'I_ACCEPT_THE_FEMALE' | transloco }}
              <a target="_blank" href="../assets/docs/Politique vie privée et traitements des données v1.01.pdf"
                >{{ 'BETRAIL_CHARTER' | transloco }}</a
              >
            </div></mat-checkbox
          >
          <!-- <mat-checkbox fxFlex="100" formControlName="newsletter"
            ><div class="checkbox">{{ 'MAIL_ACCEPT_NEWSLETTER' | transloco }}</div></mat-checkbox
          > -->
          <div *ngIf="error" class="error">{{ error | transloco }}</div>
          <button
            [disabled]="registerButtonDisabled || !registrationForm.value.cgu || !registrationForm.value.charte || registrationForm.invalid"
            type="submit"
            mat-raised-button
            class="email"
          >
            {{ registerText | transloco }}
          </button>
        </form>

        <p class="message centeredAligned">
          {{ 'ALREADY_HAVE_ACCOUNT' | transloco }} <a (click)="loginForm()">{{ 'LOG_IN' | transloco }}</a>
        </p>
      </div>

      <div *ngSwitchDefault></div>
    </ng-container>
  </ng-template>
</div>
