<div class="path-download-warning-container" *ngIf="path" fxLayout="column" fxLayoutAlign="center center">
  <h3>{{ 'WARNING' | transloco }}</h3>
  <p class="warning" *ngIf="+path?.private_sections === 1">{{ 'PATH_WARNING_PRIVATE_SECTIONS' | transloco }}</p>

  <p class="warning" *ngIf="+path?.private_sections === 2">{{ 'PATH_WARNING_MAYBE_PRIVATE_SECTIONS' | transloco }}</p>

  <p class="warning">{{ 'PATH_DOWNLOAD_DEFAULT_WARNING' | transloco }}</p>

  <div class="buttons" fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="30px">
    <button mat-button class="bt-button light" (click)="close()">{{ 'CANCEL' | transloco }}</button>

    <a
      mat-button
      [disabled]="path?.downloadable !== 1 && path?.permissions?.roles.administrator !== true"
      class="bt-button dark"
      target="_blank"
      href="{{ baseUrl }}/race/path/{{ path?.id }}/file"
      (click)="close()"
      ><mat-icon>directions_run</mat-icon>{{ 'PATH_DOWNLOAD_GPX' | transloco }}</a
    >
  </div>
</div>
