<ng-container [formGroup]="trailFormGroup">
  <div fxLayout="column" fxLayoutAlign="space-between" class="trail-form">
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <input style="display: none" id="username" type="text" name="fakeusernameremembered" />
    <input style="display: none" id="mail" type="text" name="fakemailremembered" />
    <input style="display: none" id="email" type="text" name="fakeemailremembered" />
    <input style="display: none" id="password" type="password" name="fakepasswordremembered" />

    <mat-form-field appearance="fill">
      <mat-label>{{ 'TRAIL_NAME' | transloco }}</mat-label>
      <input matInput placeholder="Name of the race" formControlName="title" autocomplete="new-password" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'COUNTRY' | transloco }}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countries" [value]="country.key"
          >{{ country.key }} - {{ country.name | transloco }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div
      *ngIf="(isAdmin$ | async) || (isEncoder$ | async)"
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap.gt-sm="1em"
      class="event-form"
    >
      <mat-form-field
        appearance="fill"
        fxFlex="100%"
        fxFlex.gt-sm="47.5%"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <mat-label>{{ 'OTHER_USED_COUNTRIES' | transloco }}</mat-label>
        <input
          matInput
          placeholder="Ex. : CH,IT"
          formControlName="other_used_countries"
          pattern="([A-Z]{2})+(,([A-Z]{2})+)?(,([A-Z]{2})+)?(,([A-Z]{2})+)?"
        />
        <mat-hint>( = {{ 'ADDITIONAL_TRAIL_COUNTRIES_HINT' | transloco }})</mat-hint>
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        fxFlex="100%"
        fxFlex.gt-sm="47.5%"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <mat-label>{{ 'NEAR_COUNTRIES' | transloco }}</mat-label>
        <input
          matInput
          placeholder="Ex.: LU,FR,DE"
          formControlName="near_countries"
          pattern="([A-Z]{2})+(,([A-Z]{2})+)?(,([A-Z]{2})+)?(,([A-Z]{2})+)?"
        />
        <mat-hint>( = {{ 'ADDITIONAL_TRAIL_COUNTRIES_HINT' | transloco }})</mat-hint>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap.gt-sm="1em" class="event-form">
      <div fxFlex="100%" fxFlex.gt-sm="47.5%" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'POSTAL_CODE' | transloco }}</mat-label>
          <input matInput placeholder="Ex.: 5660 ou 59216" formControlName="postal_code" />
        </mat-form-field>
      </div>

      <div fxFlex="100%" fxFlex.gt-sm="47.5%" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'PLACE' | transloco }}</mat-label>
          <input matInput placeholder="Place where the race begins" formControlName="place" />
        </mat-form-field>
      </div>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'RACE_WEBSITE' | transloco }}</mat-label>
      <input matInput placeholder="http://www..." formControlName="website" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'FACEBOOK_PAGE' | transloco }}</mat-label>
      <input matInput placeholder="https://www.facebook.com/..." formControlName="facebook" />
    </mat-form-field>

    <strong class="label">{{ 'COVER_PICTURE' | transloco }} : </strong>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="trailImage"
      [style.backgroundImage]="updatedImage.medium_image || trail?.photo?.medium_image | sanitizeStyleUrl"
    >
      <div>
        <button
          class="addImageButton"
          tdFileDrop
          #addImageButton
          mat-raised-button
          (click)="fileInput.click(); $event.stopPropagation()"
          (fileDrop)="addImage($any($event))"
          [disabled]="addImageButtonDisabled"
        >
          <mat-icon>image</mat-icon>&nbsp;
          <ng-container *ngIf="trail?.photo?.medium_image || updatedImage.medium_image; else newImage">
            {{ 'CHOOSE_AN_OTHER_PICTURE' | transloco }}</ng-container
          >
          <ng-template #newImage>{{ 'CHOOSE_A_PICTURE' | transloco }}</ng-template>
        </button>

        <button *ngIf="updatedImage.medium_image" class="removeImageButton" mat-icon-button (click)="removeImage()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
    <p class="error-message" *ngIf="showErrorMessage">{{ errorMessage | transloco }}</p>
    <em>{{ 'ONLY_PICTURES_ALLOWED_MESSAGE' | transloco }}.</em>
    <br /><br />

    <mat-form-field appearance="fill">
      <mat-label>{{ 'ORGANIZER' | transloco }}</mat-label>
      <input
        matInput
        placeholder="Name or the race organizer"
        formControlName="organizer"
        autocomplete="new-password"
      />
    </mat-form-field>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap.gt-sm="1em" class="event-form">
      <div fxFlex="100%" fxFlex.gt-sm="47.5%" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="fill" [class.invalid-email]="watchEmail(trailFormGroup.value.organizer_email)">
          <mat-label>{{ 'ORGANIZER_EMAIL' | transloco }}</mat-label>
          <!--readonly hack to disable autoffil by browsers-->
          <input
            matInput
            placeholder="{{ 'ORGANIZER_EMAIL' | transloco }}"
            formControlName="organizer_email"
            autocomplete="abdk8655xxxz4cc5s4ff"
            readonly="readonly"
            onfocus="javascript: this.removeAttribute('readonly')"
          />
          <p *ngIf="watchEmail(trailFormGroup.value.organizer_email)" class="error-message">Email invalide !</p>
        </mat-form-field>
      </div>

      <div fxFlex="100%" fxFlex.gt-sm="47.5%" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'ORGANIZER_PHONE_NUMBER' | transloco }}</mat-label>
          <input matInput placeholder="+32 ..." formControlName="organizer_phone" />
        </mat-form-field>
      </div>
    </div>
    <ng-container *isEncoder>
      <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-checkbox color="primary" fxFlex="100" formControlName="closed"
          >{{ 'TRAIL_CLOSED' | transloco }}
        </mat-checkbox>
      </div>
    </ng-container>

    <ng-container *isAdmin>
      <mat-form-field appearance="fill" style="margin-top: 1.5em">
        <mat-label>{{ 'GPX_OPTIONS' | transloco }}*</mat-label>
        <mat-select formControlName="gpx_option">
          <mat-option *ngFor="let option of optionsGPX" [value]="option.value"
            >{{ option.text | transloco }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div>
      <input #fileInput hidden type="file" (click)="$event.stopPropagation()" (change)="addImage(fileInput.files[0])" />
    </div>
  </div>
</ng-container>
