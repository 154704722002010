<ng-container *ngIf="isCoveredCountry; else noFlag">
  <img
    *ngIf="_country"
    [src]="'assets/img/'+_country+'.jpg'"
    [height]="size"
    [width]="size"
    class="countryFlag"
    [style.min-width]="size + 'px'"
  >
</ng-container>
<ng-template #noFlag>
  <span class="countryFlag text-[0.6em] font-normal">{{ _country }}</span>
</ng-template>
