import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from '@betrail-libs/trail-data-state';
import { LoadSignupRegistrationWithRunner } from '@betrail-libs/trail-data-state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-cancel-registration-dialog',
  templateUrl: './cancel-registration-dialog.component.html',
  styleUrls: ['./cancel-registration-dialog.component.scss', '../../common-orga.scss'],
})
export class CancelRegistrationDialogComponent {
  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<CancelRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
  ) {}

  save() {
    //TODO: formValue to RegistrationValue
    this.eventService.removeRegistration(this.data.registration._id).subscribe(() => {
      this.store.dispatch(new LoadSignupRegistrationWithRunner(this.data.registration._id));
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }
}
