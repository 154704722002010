import { Component, Input } from '@angular/core';
import { ALL_CALENDAR_COUNTRIES, FRANCE_OVERSEAS } from '@betrail-libs/shared/utils';

@Component({
  selector: 'country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.css'],
})
export class CountryFlagComponent {
  _country = '';
  isCoveredCountry = true;

  @Input()
  set country(country: string) {
    this.isCoveredCountry =
      ALL_CALENDAR_COUNTRIES.includes(country) || FRANCE_OVERSEAS.map(c => c.iso2).includes(country);
    if (country) {
      this._country = country.toUpperCase();
    } else {
      this._country = '';
    }
  }
  @Input() size = 14;
}
