import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EventService } from '@betrail-libs/trail-data-state';
import { IPremiumRefPrice } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-premium-change-subscription-dialog',
  templateUrl: './premium-change-subscription-dialog.component.html',
  styleUrls: ['./premium-change-subscription-dialog.component.scss'],
})
export class PremiumChangeSubscriptionDialogComponent {
  prices$ = this.eventService.getPublicPremiumPrices();

  selected: IPremiumRefPrice;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<PremiumChangeSubscriptionDialogComponent>,
    private eventService: EventService,
  ) {}

  closeDialog(value: boolean | IPremiumRefPrice) {
    this.dialogRef.close(value);
  }
}
