<event-header [trail]="trail"></event-header>
<div>
  <h2 class="center-align">{{ 'CREATE_ORGANIZATION' | transloco }}</h2>
  <form class="default-form" [formGroup]="organizationForm">
    <div class="message title">
      La page "{{ trail?.title }}" <br />
      sera liée à cette organisation.
    </div>
    <div class="message title">
      Une <strong>organisation</strong> peut être une association, un club, une société ou simplement un groupe de
      personnes. Plusieurs trails peuvent être liés à la même organisation.
    </div>

    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'ORGANIZATION_NAME' | transloco }}*</mat-label>
        <mat-icon class="suffixIcon" matSuffix>people</mat-icon>
        <input
          matInput
          #name
          type="text"
          placeholder="{{ 'ORGANIZATION_NAME_EXAMPLE' | transloco }}"
          formControlName="name"
        />
      </mat-form-field>
      <div class="field-explanation">Exemple: Association du Trail des Bouchons</div>
    </div>

    <div>
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-icon class="suffixIcon" matSuffix>account_balance</mat-icon>
        <mat-label>{{ 'LEGAL_FORM' | transloco }}*</mat-label>
        <mat-select matInput type="text" formControlName="legal_form">
          <mat-option value="NATURAL">{{ 'LEGAL_NATURAL' | transloco }}</mat-option>
          <mat-option value="ORGANIZATION_BE_ASBL">{{ 'ORGANIZATION_BE_ASBL' | transloco }}</mat-option>
          <mat-option value="ORGANIZATION_FR_LOI_1901">{{ 'ORGANIZATION_FR_LOI_1901' | transloco }}</mat-option>
          <mat-option value="ORGANIZATION_COMPANY">{{ 'ORGANIZATION_COMPANY' | transloco }}</mat-option>
          <mat-option value="ORGANIZATION_NO_LEGAL_FORM">{{ 'ORGANIZATION_NO_LEGAL_FORM' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="interlabel">Données de contact</div>

    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
        <mat-icon class="suffixIcon" matSuffix>email</mat-icon>
        <input
          trimInput
          matInput
          #email
          type="text"
          placeholder="{{ 'YOUR_EMAIL' | transloco }}"
          formControlName="email"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'PHONE' | transloco }}</mat-label>
        <mat-icon class="suffixIcon" matSuffix>phone</mat-icon>
        <input matInput #email type="text" placeholder="{{ 'YOUR_PHONE' | transloco }}" formControlName="phone" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'WEB_SITE' | transloco }}</mat-label>
        <mat-icon class="suffixIcon" matSuffix>web</mat-icon>
        <input matInput #email type="text" placeholder="{{ 'YOUR_WEB_SITE' | transloco }}" formControlName="web_site" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'FACEBOOK_PAGE' | transloco }}</mat-label>
        <mat-icon class="suffixIcon" matSuffix>public</mat-icon>
        <input
          matInput
          #email
          type="text"
          placeholder="{{ 'YOUR_FACEBOOK_PAGE' | transloco }}"
          formControlName="facebook_page"
        />
      </mat-form-field>
    </div>

    <div class="actions-container full-width flex-container space-between">
      <button mat-button type="button" class="bt-button" (click)="cancel()">{{ 'BACK' | transloco }}</button>
      <button [disabled]="!organizationForm.valid" class="bt-button orange" mat-raised-button (click)="validate()">
        {{ 'SAVE' | transloco }}
      </button>
    </div>
  </form>
</div>
