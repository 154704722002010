import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AuthStateService } from '@betrail-libs/auth-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { AppParamsState } from '@betrail-libs/app-params-state';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'calendar-filter-geolocation',
  templateUrl: './calendar-filter-geolocation.component.html',
  styleUrls: ['./calendar-filter-geolocation.component.scss'],
})
export class CalendarFilterGeolocationComponent implements OnInit, OnDestroy {
  geolocationPosition: number[];
  distance = 20;
  userLocation: { place: any; coords: any[] };
  value: any;
  summary: string;
  postalfr = [];
  user$ = this.authState.getUser();

  @Select(AppParamsState['calendarSelectedCountries']) selectedCountries$!: Observable<string[]>;

  @Input() quickFilters;
  @Input()
  set _values(values) {
    if (values) {
      if (values[0]) {
        this.value = values[0];
      }
      if (values[1]) {
        this.distance = values[1];
      }
      if (values[2]) {
        this.summary = values[2];
      }
    }
  }

  @Output() geolocationFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();
  @Output() apply = new EventEmitter();

  constructor(private authState: AuthStateService) {}

  changeValue(value) {
    this.value = value;
    this.geolocationFilter.emit([this.value, this.distance, this.summary]);
    //this.updateSummary.emit(this.summary);
  }

  setPlace(placeSearchResult) {
    if (placeSearchResult && placeSearchResult.geolocation) {
      this.summary = placeSearchResult.localite;
      this.value = placeSearchResult.geolocation.split(',');
      this.changeValue(this.value);
      //this.updateSummary.emit(this.summary);
      //this.apply.emit();
    }
  }

  activeQuickFilter(values, summary) {
    this.summary = summary;
    this.changeValue(values);
    this.apply.emit();
  }

  setDistance(value) {
    this.distance = value;
    this.geolocationFilter.emit([this.value, this.distance, this.summary]);
  }

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe(u => {
      if (u?.runner?.geo_lat) {
        this.userLocation = {
          place: u?.runner?.place,
          coords: [u?.runner?.geo_lat, u?.runner?.geo_lon],
        };
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
