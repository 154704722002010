import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'error-dialog',
  template: `
    <div class="text-center flex flex-col justify-center items-center">
      <h2 mat-dialog-title class="mt-3">{{ data.title | transloco }}</h2>
      <hr />
      <mat-dialog-content>
        <strong>{{ errMsg | transloco }}</strong>
        <br /><br />
        <i>(Error code {{ errCode }})</i>
      </mat-dialog-content>
      <mat-dialog-actions class="!mb-0">
        <button mat-raised-button mat-dialog-close>OK</button>
      </mat-dialog-actions>
    </div>
  `,
})
export class ErrorDialogComponent implements OnInit {
  errCode: string | number;
  errMsg: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; error: unknown },
  ) {}

  ngOnInit() {
    this.setError(this.data.error);
  }

  setError(e: any) {
    if (e instanceof HttpErrorResponse) {
      this.errCode = e.status;
      this.errMsg = e.statusText;
    } else {
      this.errCode = e.error?.statusCode || e.status || e.error?.code || e.code || 500;
      this.errMsg = e.error?.message || e.statusText || e.error?.error || e.message || 'Unknown error';
    }
  }
}
