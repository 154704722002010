import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppParamsState, SetCalendarFilterSummary } from '@betrail-libs/app-params-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FilterElement } from '../filterElement.model';

@UntilDestroy()
@Component({
  selector: 'calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent implements OnInit {
  @Input() name: string;
  @Input() type: string;
  @Input() filteredElements$: Observable<FilterElement[]>;
  @Input() defaultElements$: Observable<FilterElement[]>;
  @Input() options: { any };
  @Input() unit: string;
  @Input() status: boolean;
  @Input() displayed = true;
  @Input() quickFilters;
  @Output() filter = new EventEmitter();
  @Output() setFilterStatus = new EventEmitter();
  value: any;
  @Input() description: string;
  //nextSummary: string;
  //summary;

  summaries: string[];
  cancelable = true;

  @Select(AppParamsState['calendarFiltersSummaries']) summaries$!: Observable<any>;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<CalendarFilterComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.name = data.name;
    this.type = data.type;
    this.filteredElements$ = data.filteredElements;
    this.defaultElements$ = data.defaultElements;
    this.value = data.value;
    this.description = data.description;
    this.quickFilters = data.quickFilters;
    this.unit = data.unit;
    this.status = data.status;
    this.cancelable = data.cancelable;
  }

  ngOnInit() {
    this.summaries$.pipe(untilDestroyed(this)).subscribe(s => {
      if (s && s[this.name]) {
        this.summaries = s[this.name];
      }
    });
  }

  emitFilter(event) {
    this.value = event;
  }

  updateSummary(event) {
    if (event != '' && event != undefined) {
      this.store.dispatch(new SetCalendarFilterSummary(this.name, event));
    }
  }

  changeDisplay() {
    if (this.displayed) {
      this.displayed = false;
    } else {
      this.displayed = true;
    }
  }

  close() {
    if (this.displayed) {
      this.displayed = false;
    }
    this.dialogRef.close();
  }

  apply() {
    this.filter.emit(this.value);
    this.setFilterStatus.emit(true);
    this.data.filter(this.value);
    this.data.setFilterStatus(true);
    this.dialogRef.close({ filter: this.value, status: true });
  }

  disapply() {
    this.data.setFilterStatus(false);
    this.close();
  }
}
