<header class="text-center mb-[20px]">
  <h2 class="text-lg">{{ isNew ? 'Créer un nouveau pop-up' : 'Modifier le pop-up' }}</h2>
</header>
<form [formGroup]="popUpForm">
  <div
    class="flex flex-col h-[400px] overflow-y-auto p-[15px] bg-slate-50 border-solid border border-slate-200 shadow-inner"
  >
    <h3 class="text-base mt-[5px]">Cible</h3>
    <div class="flex justify-between">
      <mat-form-field appearance="outline" color="accent" class="w-[48%]">
        <mat-label>Personnes concernées</mat-label>
        <mat-select formControlName="target">
          <mat-option *ngFor="let target of targets" [value]="target"
            >{{ 'POPUP_TARGET_' + target | transloco }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent" class="w-[48%]">
        <mat-label>Pays concerné(s)</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of countries" [value]="country"
            >{{ (country === 'ALL' || country === 'OTHERS' ? 'COUNTRIES_' : 'COUNTRY_') + country | transloco
            }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <em class="text-xs text-gray-600 text-center">NB : un seul pop-up ciblant tout le monde peut être actif à la fois</em>

    <h3 class="text-base">Type</h3>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Type de pop-up</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of types" [value]="type">{{ 'POPUP_TYPE_' + type | transloco }}</mat-option>
      </mat-select>
    </mat-form-field>

    <h3 class="text-base">Contenu</h3>
    <mat-accordion class="mb-[15px]">
      <mat-expansion-panel *ngFor="let lang of langs">
        <mat-expansion-panel-header>
          <country-flag [country]="lang"></country-flag>&nbsp; {{ lang | uppercase }} &nbsp;
          <mat-error *ngIf="popUpForm.controls?.['title' + lang]?.errors || popUpForm.controls?.['text' + lang]?.errors"
            >* requis</mat-error
          >
        </mat-expansion-panel-header>
        <div class="flex flex-col">
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Titre du pop-up</mat-label>
            <input matInput [formControlName]="'title' + lang" />
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Texte du pop-up</mat-label>
            <textarea matInput [formControlName]="'text' + lang"></textarea>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <h3 class="text-base">Image &nbsp;<span class="font-light italic text-sm">(facultatif)</span></h3>
    <div class="w-full flex flex-col items-center">
      <input
        #imgInput
        hidden
        type="file"
        accept=".jpeg,.jpg,.png"
        (click)="$event.stopPropagation()"
        (change)="addImage(imgInput.files[0])"
      />
      <button
        tdFileDrop
        mat-raised-button
        class="w-[220px]"
        (click)="imgInput.click(); $event.stopPropagation()"
        (fileDrop)="addImage($event)"
      >
        <mat-icon>image</mat-icon>&nbsp; Ajouter une image
      </button>
      <img *ngIf="image" [src]="image" class="w-[300px] h-[300px] m-[20px] rounded-[10px] object-cover" />
      <ng-container *ngIf="!image">
        <img
          *ngIf="data?.popup?.hasImage; else noImage"
          [src]="api_uri + '/image/pop-up/' + data.popup.id"
          class="w-[300px] h-[300px] m-[20px] rounded-[10px] object-cover"
        />
        <ng-template #noImage>
          <div class="w-[300px] h-[300px] m-[20px] rounded-[10px] bg-slate-400"></div>
        </ng-template>
      </ng-container>
    </div>

    <h3 class="text-base">Suppléments &nbsp;<span class="font-light italic text-sm">(facultatif)</span></h3>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Lien complémentaire</mat-label>
      <input matInput formControlName="link" placeholder="https://..." />
    </mat-form-field>
  </div>

  <div class="mt-[14px] w-[320px] sm:w-[420px] flex justify-between flex-wrap items-center">
    <button
      mat-raised-button
      color="accent"
      class="!m-[15px]"
      [disabled]="popUpForm.invalid"
      (click)="createOrEditPopUp()"
    >
      {{ isNew ? 'Créer pop-up' : 'Enregistrer les modifications' }}
    </button>
    <button mat-raised-button color="warn" class="!m-[15px]" mat-dialog-close>Annuler</button>
  </div>
</form>
