<ng-container [ngSwitch]="formType">
  <div class="login-form" *ngSwitchCase="'forgottenPassword'" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
    <h2 class="centeredAligned">Mot de passe oublié ?</h2>
    <p class="highlight">Veuillez entrer votre adresse email.</p>
    <p>Nous vous enverrons un message avec de nouvelles informations de connexion.</p>
    <mat-form-field appearance="outline">
      <mat-icon class="suffixIcon" matSuffix>account_circle</mat-icon>
      <mat-label>Adresse email</mat-label>
      <input
        tabindex="0"
        matInput
        #email
        type="text"
        placeholder="you@domaine.com"
        (keydown.enter)="sendNewPassword(email.value)"
      />
    </mat-form-field>
    <div class="bottom">
      <div *ngIf="error" class="error">{{ error }}</div>
      <button
        [disabled]="newPasswordButtonDisabled"
        mat-raised-button
        (click)="sendNewPassword(email.value)"
        class="email"
      >
        {{ 'SEND_NEW_PASSWORD' | transloco }}
      </button>
      <a class="link" (click)="goToLoginForm()">Retour au formulaire de connexion</a>
    </div>
  </div>

  <div *ngSwitchCase="'newPasswordSent'" class="login-form" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
    <h2 class="centeredAligned">Mot de passe envoyé !</h2>
    <p class="highlight">
      Consultez vos emails : un nouveau mot de passe a été envoyé à l'adresse <br />
      {{ newPasswordEmail }}
    </p>
    <p>Vous pourrez directement vous connecter avec ce nouveau mot de passe.</p>
    <p>Votre ancien mot de passe a été supprimé.</p>
    <div class="bottom"><a class="link" (click)="goToLoginForm()">Retour au formulaire de connexion</a></div>
  </div>
</ng-container>
