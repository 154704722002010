import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { AuthStateService, IUserState } from '@betrail-libs/auth-state';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { DrupalRole, IResult, IRunner } from '@betrail-libs/shared/interfaces/interfaces';
import { asRoleId } from '@betrail-libs/shared/utils';
import { EventService, loadResultsForRaceId, loadResultsForRunnerId } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinct, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-remove-result-form',
  templateUrl: './remove-result-form.component.html',
  styleUrls: ['./remove-result-form.component.scss'],
})
export class RemoveResultFormComponent implements OnInit {
  step = 1;
  chosenOption?: 'bib_transfer' | 'homonymous' | 'new_runner';
  responseTxt: 'WORKING' | 'ERROR_RETRY' | 'DONE' = 'WORKING';
  bibRunnerKnown = true;
  runnerIsNew = false;
  runnerName?: string;
  runner?: IRunner;
  user?: IUserState;
  closeText: 'CANCEL' | 'CLOSE' = 'CANCEL';

  isAdmin$ = this.authState.hasRole(DrupalRole.Administrator);
  isEncoder$ = this.authState.hasRole(DrupalRole.Encoder);

  optionForm: FormGroup<{
    option: FormControl<'bib_transfer' | 'homonymous' | 'new_runner'>;
    knownRunner: FormControl<boolean>;
    runnerName: FormControl<string>;
  }> = this.fb.group({
    option: [this.chosenOption || 'bib_transfer', Validators.required],
    knownRunner: [true, Validators.requiredTrue],
    runnerName: [''],
  });

  @ViewChild('searchElem', { read: MatAutocompleteTrigger }) searchElem: MatAutocompleteTrigger;
  @ViewChild('searchElem') searchNativeElem: ElementRef<HTMLInputElement>;

  searchValue = new Subject<string>();
  searchAutocomplete: Observable<{ runners: IRunner[]; events: IEvent[]; runner: IRunner | undefined }> =
    this.searchValue.pipe(
      debounceTime(150),
      distinct(),
      switchMap(value =>
        value ? this.eventService.searchRunners(value) : of({ runners: [], runner: undefined, events: [] }),
      ),
    );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { result: IResult; type: 'runner' | 'race'; runnerId: number | string },
    // type 'runner' = from runner components, type 'race' = from the race components (used to reload results)
    private authState: AuthStateService,
    private fb: FormBuilder,
    private eventService: EventService,
    private store: Store,
    private dialogRef: MatDialogRef<RemoveResultFormComponent>,
  ) {}

  ngOnInit() {
    this.authState
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(u => {
        this.user = u;
      });
  }

  confirm() {
    const userName = this.user.runner?.display_title || this.user.runner?.title || this.user.name;
    const isAdmin = asRoleId(this.user, DrupalRole.Administrator) || asRoleId(this.user, DrupalRole.Encoder);

    if (this.step === 1) {
      this.chosenOption = this.optionForm.controls.option.value;
      if (this.chosenOption == 'homonymous') {
        this.eventService
          .giveResultToHomonymous(this.data.result, { name: userName, isAdmin })
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            this.displayConfirmation(response);
          });
      } else if (this.chosenOption == 'new_runner') {
        this.eventService
          .giveResultToHomonymous(this.data.result, { name: userName, isAdmin }, true)
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            this.displayConfirmation(response);
          });
      }
      this.step = 2;
    } else if (this.step === 2) {
      this.bibRunnerKnown = this.optionForm.controls.knownRunner.value;

      if (this.bibRunnerKnown && this.runner) {
        this.eventService
          .giveResultToAnotherRunner(this.data.result, this.runner, { name: userName, isAdmin })
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            this.displayConfirmation(response);
          });
      } else {
        this.eventService
          .giveResultToAnonymous(this.data.result, { name: userName, isAdmin })
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            this.displayConfirmation(response);
          });
      }
      this.step = 3;
    }
  }

  displayConfirmation(response?: { body: { nid?: number | string }; nid?: number | string }) {
    if (response?.body?.nid || response?.nid) {
      this.responseTxt = 'DONE';
      this.closeText = 'CLOSE';
      if (this.data.type && this.data.type === 'race') {
        this.store.dispatch(new loadResultsForRaceId(this.data.result.race.id, true));
      } else if (this.data.type && this.data.type == 'runner') {
        this.store.dispatch(new loadResultsForRunnerId(+this.data.runnerId));
      }
    } else {
      this.responseTxt = 'ERROR_RETRY';
    }
  }

  setRunnerKnown(change: MatRadioChange) {
    this.bibRunnerKnown = change.value;
  }

  onSearchChange(value: string) {
    this.searchValue.next(value);
    this.runner = undefined;
  }

  onSearchSelect(value: string | { _source: IRunner }) {
    if (typeof value === 'string') {
      this.runnerIsNew = true;
    } else {
      this.runnerIsNew = false;
      this.searchNativeElem.nativeElement.value = value._source.title;
      this.runnerName = value._source.title;
      this.runner = value._source;
    }
  }

  searchClose() {
    this.searchElem.closePanel();
  }

  cancel(event: Event) {
    event.preventDefault();
    this.dialogRef.close('Cancel');
  }
}
