import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { UsersService, UpdateUserAction } from '@betrail-libs/auth-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'welcome-step-results-claiming-tuto',
  templateUrl: './welcome-step-results-claiming-tuto.component.html',
  styleUrls: ['./welcome-step-results-claiming-tuto.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepResultsClaimingTutoComponent implements OnDestroy {
  _user: any;
  userParams: any = {};
  buttonDisabled: boolean = false;
  buttonText: string = 'UNDERSTOOD';

  constructor(private usersService: UsersService, private store: Store) {}

  @Input('user') set user(u) {
    this._user = u;
    this.userParams = {};
    if (this._user?.userDrupal?.userParams) {
      this.userParams = JSON.parse(this._user.userDrupal.userParams);
    }
  }

  @Output() next = new EventEmitter();

  nextStep() {
    this.userParams.resultsChecked = true;
    let u = {
      field_user_params: { und: [{ value: JSON.stringify(this.userParams) }] },
    };
    this.buttonDisabled = true;
    this.buttonText = 'LOADING';
    this.usersService
      .updateUser(this._user.uid, u)
      .pipe(untilDestroyed(this))
      .subscribe(updatedUser => {
        this.store.dispatch(new UpdateUserAction({ user: updatedUser }));
        this.next.emit();
        this.buttonDisabled = false;
        this.buttonText = 'UNDERSTOOD';
      });
  }

  ngOnDestroy() {}
}
