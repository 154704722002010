import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { VALUES_RESULT_SORT_PARAMS } from '@betrail-libs/shared/utils';
import {
  SetResultSortDate,
  SetResultSortDistance,
  SetResultSortPerformance,
  SetResultSortPoints,
  SetResultSortRankingCourse,
  SetResultSortTime,
  SetResultSortTrailName,
  SetResultSortPercentage,
  SetResultSortPosition,
  SetResultSortDesc,
  AppParamsState,
} from '@betrail-libs/app-params-state';

@UntilDestroy()
@Component({
  selector: 'result-sort-dialog',
  templateUrl: './result-sort-dialog.component.html',
  styleUrls: ['./result-sort-dialog.component.scss'],
})
export class ResultSortDialog implements OnInit, OnDestroy {
  @Select(AppParamsState['resultSortDate']) date$!: Observable<boolean>;
  @Select(AppParamsState['resultSortDistance']) distance$!: Observable<boolean>;
  @Select(AppParamsState['resultSortPerformance']) performance$!: Observable<boolean>;
  @Select(AppParamsState['resultSortPoints']) points$!: Observable<boolean>;
  @Select(AppParamsState['resultSortRankingCourse']) rankingCourse$!: Observable<boolean>;
  @Select(AppParamsState['resultSortTime']) time$!: Observable<boolean>;
  @Select(AppParamsState['resultSortTrailName']) trailName$!: Observable<boolean>;
  @Select(AppParamsState['resultSortPercentage']) percentage$!: Observable<boolean>;
  @Select(AppParamsState['resultSortPosition']) position$!: Observable<boolean>;
  @Select(AppParamsState['resultSortDesc']) desc$!: Observable<boolean>;

  resultSortParams = VALUES_RESULT_SORT_PARAMS;
  isDesc: boolean;

  constructor(private store: Store, public dialogRef: MatDialogRef<ResultSortDialog, any>) {}

  close() {
    this.dialogRef.close();
  }

  selectResultSortType(sortType) {
    switch (sortType) {
      case 'DATE':
        this.store.dispatch(new SetResultSortDate());
        break;
      case 'DISTANCE':
        this.store.dispatch(new SetResultSortDistance());
        break;
      case 'PERFORMANCE':
        this.store.dispatch(new SetResultSortPerformance());
        break;
      case 'POINTS':
        this.store.dispatch(new SetResultSortPoints());
        break;
      case 'RANK':
        this.store.dispatch(new SetResultSortRankingCourse());
        break;
      case 'TIME':
        this.store.dispatch(new SetResultSortTime());
        break;
      case 'TRAIL_NAME':
        this.store.dispatch(new SetResultSortTrailName());
        break;
      case 'PERCENTAGE':
        this.store.dispatch(new SetResultSortPercentage());
        break;
      case 'POSITION':
        this.store.dispatch(new SetResultSortPosition());
        break;
    }
    this.close();
  }

  chooseSortType(event) {
    this.isDesc = event.checked;
  }

  saveSortType() {
    this.store.dispatch(new SetResultSortDesc(this.isDesc));
    this.close();
  }

  ngOnInit() {
    this.desc$.subscribe(res => (this.isDesc = res));
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {}
}
