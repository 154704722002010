<section>
  <div class="dialog-header">
    <h3>
      <ng-container *ngIf="data.result?.tag; else addTag">{{ 'EDIT_TAG' | transloco }}</ng-container>
      <ng-template #addTag>{{ 'ADD_TAG' | transloco }}</ng-template>
    </h3>
  </div>
  <div class="dialog-content">
    <h4>{{ trailInfo }}</h4>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'SELECT_TAG' | transloco }}</mat-label>
      <mat-select [formControl]="selectedTag">
        <mat-option value="{{ 'NO_TAG' | transloco }}">{{ 'NO_TAG' | transloco }}</mat-option>
        <mat-option *ngFor="let tag of tags" [value]="tag.key">
          {{ tag.name }} ({{ 'TAG_' + tag.key | transloco }})
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="btn-container">
    <button mat-raised-button color="warn" mat-dialog-close>{{ 'CANCEL' | transloco }}</button>
    <button mat-raised-button color="accent" (click)="saveTag()">{{ 'SAVE' | transloco }}</button>
  </div>
</section>
