import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  uid: number;
}

@Component({
  selector: 'dialog-masquarad-dialog',
  templateUrl: './masquerade.component.html',
})
export class MasqueradeComponent {
  constructor(public dialogRef: MatDialogRef<MasqueradeComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  putUid(runner) {
    this.data.uid = runner._source.uid;
  }
}
