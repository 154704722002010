import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Result } from '@betrail-libs/shared/interfaces/result.model';
import { EventService, TrailDataService } from '@betrail-libs/trail-data-state';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

type RaceMin = {
  alias: string;
  canceled: 0 | 1 | null;
  distance: number;
  id: number;
  race_type: string;
  timing_type: string;
  title: string;
};

@UntilDestroy()
@Component({
  selector: 'app-edit-result-admin-dialog',
  templateUrl: './edit-result-admin-dialog.component.html',
})
export class EditResultAdminDialogComponent {
  genders = { 0: this.t.translate('GENDER_M'), 1: this.t.translate('GENDER_F') };
  resultForm!: FormGroup;
  distances$!: Observable<RaceMin[]>;
  selectedRace?: RaceMin;
  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { result: Result; eventId: number | string },
    private dialogRef: MatDialogRef<EditResultAdminDialogComponent>,
    private fb: FormBuilder,
    private eventService: EventService,
    private trailData: TrailDataService,
    private t: TranslocoService,
  ) {
    this.distances$ = this.eventService.getEventDistances(data.eventId);

    const timeInSec = data.result.result_seconds;
    const h = Math.floor(timeInSec / 3600);
    const m = Math.floor((timeInSec % 3600) / 60);
    const s = Math.floor((timeInSec % 3600) % 60);

    this.resultForm = this.fb.group({
      gender: data.result.gender,
      distance: data.result.raid,
      hours: [h],
      minutes: [m],
      seconds: [s, Validators.required],
    });
  }

  selectRace(event: MatOptionSelectionChange, selected: RaceMin) {
    if (event.isUserInput) {
      this.selectedRace = selected;
    }
  }

  saveModifications() {
    this.saving = true;
    const formValue = this.resultForm.value;

    const h = formValue?.hours ?? 0;
    const m = formValue?.minutes ?? 0;
    const s = formValue?.seconds ?? 0;
    const timeInSec = h * 3600 + m * 60 + s;
    const hms = `${h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`;

    const result = {
      ...this.data.result,
      gender: formValue.gender,
      result_seconds: timeInSec,
      result_milliseconds: timeInSec * 1000,
      result_hms: hms,
    };

    const edited: { gender?: string; distance?: string; time?: string } = {};
    if (this.data.result.gender !== formValue.gender) {
      edited['gender'] = `${this.genders[this.data.result.gender]} => ${this.genders[formValue.gender]}`;
    }
    if (this.data.result.raid !== formValue.distance) {
      edited['distance'] = `${this.data.result.race.distance}km => ${this.selectedRace.distance}km`;
    }
    if (this.data.result.result_seconds !== timeInSec) {
      edited['time'] = `${this.data.result.result_hms} => ${hms}`;
    }

    const newDistance =
      formValue.distance !== result.raid ? { raid: formValue.distance, title: this.selectedRace.title } : {};

    this.trailData
      .editResultData(result, edited, newDistance)
      .pipe(untilDestroyed(this))
      .subscribe({
        complete: () => {
          this.saving = false;
          this.dialogRef.close();
        },
        error: err => {
          alert(JSON.stringify(err));
        },
      });
  }
}
