import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
  // Sentry.init({
  //   dsn: "https://1ea7466797514741929fa3ce5d9ae949@o947766.ingest.sentry.io/5897238",
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: ["www.betrail.run"],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //   ],
}

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
