<style>
  mat-dialog-actions {
    flex-direction: row-reverse;
  }
  mat-form-field {
    margin-top: 20px;
    width: 100%;
  }
</style>

<h1 mat-dialog-title>MASQUERADE</h1>
<div mat-dialog-content>
  <bux-search-runner-trail
    [searchRunner]="true"
    [link]="false"
    [discreet]="true"
    (optionSelected)="putUid($event)"
  ></bux-search-runner-trail>
  <mat-form-field appearance="fill">
    <mat-label>uid</mat-label>
    <input matInput [(ngModel)]="data.uid" />
  </mat-form-field>
</div>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data.uid" cdkFocusInitial>Ok</button>
  <button mat-button (click)="onNoClick()">Annuler</button>
</mat-dialog-actions>
