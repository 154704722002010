<ng-container *ngIf="trail">
  <ng-container *ngIf="trail?.photo?.medium_image; let image; else: defaultImage">
    <div class="pictureContainer">
      <div class="trailPicture" [style.background]="image | sanitizeStyleUrl"></div>
    </div>
  </ng-container>
  <ng-template #defaultImage> <div class="trailPicture"></div> </ng-template>
  <div class="trail">
    <div class="trail_name">{{ trail?.title }}</div>
  </div>
</ng-container>

<ng-container *ngIf="event">
  <ng-container *ngIf="event?.trail?.photo?.medium_image; let image; else: defaultImage">
    <div class="pictureContainer">
      <div class="trailPicture" [style.background]="image | sanitizeStyleUrl"></div>
    </div>
  </ng-container>
  <ng-template #defaultImage> <div class="trailPicture"></div> </ng-template>
  <div class="trail">
    <div class="trail_name">{{ event?.event_name || event?.trail?.title }}</div>
    <div class="trail_date">{{ event?.date * 1000 | date }}</div>
  </div>
</ng-container>
