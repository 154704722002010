import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initialsFromTitle',
})
export class InitialsFromTitlePipe implements PipeTransform {
  transform(title: string): string {
    const words = title.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0) + words[0].charAt(1);
    } else if (words.length === 2) {
      return words[0].charAt(0) + words[1].charAt(0);
    } else {
      return words[0].charAt(0) + words[words.length - 1].charAt(0);
    }
  }
}
