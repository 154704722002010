<div>
  <div class="content">
    <header class="form-title">{{ 'CONFIRMATION' | transloco }}</header>
    <div class="form-content my-4">{{ 'CONFIRM_CANCEL_FORM_ITEM' | transloco }}</div>
  </div>

  <div class="actions-container">
    <button mat-button class="button" (click)="cancel()">{{ 'CANCEL' | transloco }}</button>

    <button class="button color" mat-raised-button (click)="confirm()">{{ 'CONFIRM' | transloco }}</button>
  </div>
</div>
