import { TranslocoService } from '@ngneat/transloco';
import { UpdateNotificationStatusAction } from '@betrail-libs/auth-state';
import { Component, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { INotif } from '@betrail-libs/shared/interfaces/interfaces';
import { Store } from '@ngxs/store';


@Component({
  selector: 'user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent implements OnChanges {
  @Input('notif') set not(notif: INotif) {
    if (notif && notif.url) {
      this.notif = { ...notif, url: this.addLangToUrl(notif.url), date: notif?.date * 1000 };
    }
  }
  @Input() status: string;
  @Input() open: boolean;
  autoRead: NodeJS.Timeout;
  diff: number;
  notif: INotif;

  constructor(private store: Store, private translation: TranslocoService, @Inject('appsConfig') private config: { [key: string]: string | boolean },) {}

  addLangToUrl(url) {
    if (url) {
      const lang = this.translation.getActiveLang();
      return url.replace(this.config.betrail, this.config.betrail + lang);
    }
  }

  changeStatus($event, status: string) {
    $event.stopPropagation();
    this.store.dispatch(new UpdateNotificationStatusAction(this.notif.id, status));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open && this.open === true && this.status == 'new') {
      this.autoRead = setTimeout(() => {
        this.changeNotificationStatus(this.notif.id, 'read');
      }, 5000);
    } else if (changes.open && this.open === false) {
      clearTimeout(this.autoRead);
    }
  }

  changeNotificationStatus(id, status) {
    if (this.status == 'new') {
      this.store.dispatch(new UpdateNotificationStatusAction(id, status));
    }
  }
}
