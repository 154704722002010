import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share, map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient, @Inject('BETRAIL2_NODE_API') private betrail2NodeApi: string) {}

  config = this.http.get<{ langAvailable: string[] }>(this.betrail2NodeApi + '/config').pipe();

  tradArray = this.config.pipe(
    switchMap(({ langAvailable }) =>
      forkJoin(
        langAvailable.map(lang => {
          return this.http
            .get<string>(`${this.betrail2NodeApi}/config/i18n/${lang}`)
            .pipe(map(val => ({ lang: lang, trad: val })));
        }),
      ),
    ),
    map((v: any[]) => {
      const tradArray = [];
      for (const vv of v) {
        const trads = (<any>vv).trad;
        for (const key of Object.keys(trads)) {
          const index = tradArray.findIndex(item => key === item.key);
          if (index > -1) {
            tradArray[index][(<any>vv).lang] = trads[key];
          } else {
            tradArray.push({ key, [(<any>vv).lang]: trads[key] });
          }
        }
      }
      return tradArray;
    }),
    share(),
  );

  updateAllTranslations(key: string, data: { [lang: string]: string }) {
    return this.http.put(`${this.betrail2NodeApi}/config/i18n/update-all-langs/${key}`, data);
  }

  updateTrad({ key, value, lang }): any {
    return this.http.put(`${this.betrail2NodeApi}/config/i18n/${lang}/${key}`, { value });
  }
  getBundle(lang: any) {
    return this.http.get<{ [KEY: string]: string }>(`${this.betrail2NodeApi}/config/i18n/${lang}`);
  }

  importFile(file): any {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.betrail2NodeApi}/config/i18n/import-xls`, formData);
  }

  getLanguage() {
    return 'fr-BE';
  }
}
