import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-runner-followed-dialog',
  template: `
    <div class="max-w-md">
      <h2 class="text-lg text-center !mt-2">{{ 'CONFIRMATION' | transloco }}</h2>
      <hr />
      <div class="p-4">
        <p class="my-4 text-center">{{ 'FOLLOW_RUNNER_CONFIRMATION' | transloco: { name: data.runnerName } }}</p>
        <div class="flex flex-col 3sm:flex-row justify-between pt-4 3sm:min-w-[376px]">
          <button
            mat-raised-button
            mat-dialog-close
            color="accent"
            class="w-full 3sm:w-44 !font-medium"
            [routerLink]="['/', 'runner', 'my-page', 'news-feed']"
          >
            {{ 'GO_TO_NEWSFEED' | transloco }}
          </button>
          <button
            mat-raised-button
            mat-dialog-close
            color="primary"
            class="w-full 3sm:w-44 !font-medium !mt-4 3sm:!mt-0"
            [routerLink]="['/', 'runner', 'my-page', 'news-feed', 'settings']"
            [queryParams]="{ runner: runnerAlias }"
          >
            {{ 'ADD_TO_A_GROUP' | transloco }}
          </button>
        </div>
        <div class="flex justify-center mt-4">
          <button mat-button mat-dialog-close color="warn">{{ 'CLOSE' | transloco }}</button>
        </div>
      </div>
    </div>
  `,
})
export class RunnerFollowedDialogComponent {
  runnerAlias: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { runnerName: string }) {
    this.runnerAlias = data.runnerName.replace(/ /g, '_').toLowerCase();
  }
}
