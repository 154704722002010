import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import produce from 'immer';
import { tap } from 'rxjs';
import { TrackingApiService } from './tracking-api.service';
import {
  ChangeTrackingOverride,
  HandleGroupRunnerLink,
  HandleTrackingGroupSettings,
  LoadEliteLevels,
  LoadTrackedRunnerResults,
  SetNewsFeedFilter,
  SetUserData,
  SetUserFollowedElites,
  TrackRunner,
  UntrackRunner,
} from './tracking.action';
import { TrackingStateModel } from './tracking.model';
import { RankingsStateService } from 'apps/betrail2/src/app/rankings-page/services/rankings-state.service';

@State<TrackingStateModel>({
  name: 'tracking',
  defaults: {
    eliteLevels: [],
    elitesTrackings: [],
    loadingResults: false,
    results: {},
    resultsOffset: 0,
    runnerTrackings: [],
    trackingGroups: [],
    userTrackings: [],
    selectedFilter: undefined,
    userData: {},
  },
})
@Injectable()
export class TrackingState {
  constructor(private api: TrackingApiService, private store: Store, private rankingService: RankingsStateService) {}

  @Selector()
  public static userData(state: TrackingStateModel) {
    return state.userData;
  }

  @Selector()
  public static loadingResults(state: TrackingStateModel) {
    return state.loadingResults;
  }

  @Selector()
  public static trackedResults(state: TrackingStateModel) {
    return Object.values(state.results).sort((a, b) => b.created - a.created);
  }

  @Selector()
  public static runnerTrackings(state: TrackingStateModel) {
    return state.runnerTrackings;
  }

  @Selector()
  public static eliteLevels(state: TrackingStateModel) {
    return state.eliteLevels;
  }

  @Selector()
  public static elitesTrackings(state: TrackingStateModel) {
    return state.elitesTrackings;
  }

  @Selector()
  public static trackingGroups(state: TrackingStateModel) {
    return state.trackingGroups;
  }

  @Selector()
  public static userTrackings(state: TrackingStateModel) {
    return state.userTrackings;
  }

  @Selector()
  public static selectedFilter(state: TrackingStateModel) {
    return state.selectedFilter;
  }

  @Action(SetUserData)
  setUserData(ctx: StateContext<TrackingStateModel>, action: SetUserData) {
    ctx.setState(
      produce((draft: TrackingStateModel) => {
        draft.userData.uid = action.uid !== 0 ? action.uid : undefined;
        draft.userData.ruid = action.ruid !== 0 ? action.ruid : undefined;
        draft.userData.isPremium = action.isPremium;
        draft.runnerTrackings = action.trackedRunners;
        draft.elitesTrackings = action.trackedElites;
        draft.trackingGroups = action.trackingGroups;
        draft.userTrackings = action.userTrackings.filter(t => t.user.id !== action.uid);
        draft.resultsOffset = 0;
        draft.results = {};
      }),
    );
    this.store.dispatch(new LoadTrackedRunnerResults());
  }

  @Action(TrackRunner)
  trackRunner(ctx: StateContext<TrackingStateModel>, action: TrackRunner) {
    const state = ctx.getState();
    if (action.isAdmin || (state.userData.uid && state.userData.uid === action.data.uid)) {
      return this.api.createRunnerTracking(action.data).pipe(
        tap(result => {
          ctx.setState(
            produce((draft: TrackingStateModel) => {
              draft.runnerTrackings.push(result.runner);
              if (result.user) {
                draft.runnerTrackings.push(result.user);
              }
              draft.results = {};
              draft.resultsOffset = 0;
            }),
          );
          this.store.dispatch(new LoadTrackedRunnerResults());
        }),
      );
    }
  }

  @Action(UntrackRunner)
  untrackRunner(ctx: StateContext<TrackingStateModel>, action: UntrackRunner) {
    const state = ctx.getState();
    if (state.userData.uid && state.userData.uid === action.data.uid) {
      return this.api.unfollowRunner(action.data).pipe(
        tap(() => {
          ctx.setState(
            produce((draft: TrackingStateModel) => {
              draft.runnerTrackings = draft.runnerTrackings.filter(t => t.ruid !== action.data.ruid);
              draft.results = {};
              draft.resultsOffset = 0;
            }),
          );
          this.store.dispatch(new LoadTrackedRunnerResults());
        }),
      );
    }
  }

  @Action(LoadTrackedRunnerResults)
  loadTrackedRunnerResults(ctx: StateContext<TrackingStateModel>, action: LoadTrackedRunnerResults) {
    const state = ctx.getState();
    if (state.userData.uid && !state.loadingResults) {
      ctx.setState(
        produce((draft: TrackingStateModel) => {
          draft.loadingResults = true;
        }),
      );
      return this.api
        .getTrackedRunnersResults(state.userData.uid, action.nb, state.resultsOffset, state.selectedFilter)
        .pipe(
          tap(results => {
            ctx.setState(
              produce((draft: TrackingStateModel) => {
                draft.resultsOffset += results.length;
                for (const result of results) {
                  draft.results[result.id] = result;
                }
                draft.loadingResults = false;
              }),
            );
          }),
        );
    }
  }

  @Action(LoadEliteLevels)
  loadEliteLevels(ctx: StateContext<TrackingStateModel>, action: LoadEliteLevels) {
    const state = ctx.getState();
    if (state.userData.uid && (state.eliteLevels.length === 0 || action.forceReload)) {
      return this.api.getEliteLevels().pipe(
        tap(levels => {
          ctx.setState(
            produce((draft: TrackingStateModel) => {
              draft.eliteLevels = levels;
            }),
          );
        }),
      );
    }
  }

  @Action(SetUserFollowedElites)
  setUserFollowedElites(ctx: StateContext<TrackingStateModel>, action: SetUserFollowedElites) {
    const state = ctx.getState();
    if (state.userData.uid) {
      return this.api.setUserEliteLevels({ uid: state.userData.uid, selected: action.selected }).pipe(
        tap(userElites => {
          ctx.setState(
            produce((draft: TrackingStateModel) => {
              draft.elitesTrackings = userElites;
              draft.results = {};
              draft.resultsOffset = 0;
            }),
          );
          this.store.dispatch(new LoadTrackedRunnerResults());
        }),
      );
    }
  }

  @Action(HandleTrackingGroupSettings)
  handleTrackingGroupSettings(ctx: StateContext<TrackingStateModel>, action: HandleTrackingGroupSettings) {
    const state = ctx.getState();
    if (state.userData.uid && state.userData.uid === action.uid) {
      return this.api.handleTrackingGroups(action.uid, action.data).pipe(
        tap(groups => {
          ctx.setState(
            produce((draft: TrackingStateModel) => {
              draft.trackingGroups = groups;
            }),
          );
        }),
      );
    }
  }

  @Action(HandleGroupRunnerLink)
  handleGroupRunnerLink(ctx: StateContext<TrackingStateModel>, action: HandleGroupRunnerLink) {
    const state = ctx.getState();
    if (state.userData.uid && state.userData.uid === action.uid) {
      return this.api.handleRunnerGroupLink(action.data).pipe(
        tap(res => {
          ctx.setState(
            produce((draft: TrackingStateModel) => {
              const group = draft.trackingGroups.find(g => g.id === res.tgid);
              if (group) {
                if (action.data.activate) {
                  group.runners.push({ ruid: res.ruid });
                } else {
                  group.runners = group.runners.filter(r => r.ruid !== action.data.ruid);
                }
                draft.results = {};
                draft.resultsOffset = 0;
              }
            }),
          );
          this.rankingService.resetGroupRankings(action.data.tgid);
        }),
      );
    }
  }

  @Action(SetNewsFeedFilter)
  setNewsFeedFilter(ctx: StateContext<TrackingStateModel>, action: SetNewsFeedFilter) {
    ctx.setState(
      produce((draft: TrackingStateModel) => {
        draft.selectedFilter = action.filter;
        draft.results = {};
        draft.resultsOffset = 0;
      }),
    );
  }

  @Action(ChangeTrackingOverride)
  changeTrackingOverride(ctx: StateContext<TrackingStateModel>, action: ChangeTrackingOverride) {
    return this.api.changeTrackingOverride(action.data).pipe(
      tap(results => {
        ctx.setState(
          produce((draft: TrackingStateModel) => {
            for (const res of results) {
              const userTracking = draft.userTrackings.find(t => t.id === res.id);
              if (userTracking) {
                userTracking.override = res.override;
              }
            }
          }),
        );
      }),
    );
  }
}
