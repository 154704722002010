import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { formatDate } from '@betrail-libs/shared/utils';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient, @Inject('appsConfig') private config: any) {}

  masquerade(uid: number) {
    return this.http.post(this.config.nodejsApi + '/user/masquerade', { uid }, { withCredentials: true });
  }

  getImageStyle(fid: string, style: string): Observable<string> {
    return this.http.get<any>(`${this.config.nodejsApi}/image/${fid}/${style}`).pipe(map(r => r.body));
  }

  getAvatarUrl(avatar) {
    return this.getImageStyle(avatar || 'default', 'avatar');
  }

  updateUser(uid: number | string, user: any): any {
    return this.http.put(this.config.nodejsApi + '/user/' + uid, user, { withCredentials: true });
  }

  updateUserMailPreferences(uid: string, mailData: { acceptNewsletter: boolean; acceptNotifications: boolean }) {
    return this.http.put(`${this.config.nodejsApi}/user/mailData/${uid}`, mailData);
  }

  deprecated_updateUser(uid, user: any): any {
    return this.http.put(this.config.betrailApi + '/user/' + uid, user, { withCredentials: true });
  }

  retrievePassword(name: string): any {
    return this.http.post(this.config.nodejsApi + '/user/request_new_password', { name }, { withCredentials: true });
  }

  deprecated_retrievePassword(name: string): any {
    return this.http.post(this.config.betrailApi + '/user/request_new_password', { name }, { withCredentials: true });
  }

  validateEmail(uid, hash) {
    return this.http.get<{ body: { hash: 'ok' } }>(this.config.nodejsApi + `/user/validate/${uid}/${hash}`);
  }

  validateEmailResend() {
    return this.http.get<{ body: {} }>(this.config.nodejsApi + `/user/validate/resend`);
  }

  login(user, password): Observable<any> {
    if (user === undefined) {
      throw { errorMsg: 'User no defined' };
    }
    if (password === undefined) {
      throw { errorMsg: 'User no defined' };
    }
    const headers = new HttpHeaders();
    // headers.append('X-Requested-With', 'XMLHttpRequest');
    return this.http.post(
      this.config.nodejsApi + '/user/login',
      { username: user, password: password },
      { headers: headers, withCredentials: true },
    );
  }

  deprecated_login(user, password): Observable<any> {
    if (user === undefined) {
      throw { errorMsg: 'User no defined' };
    }
    if (password === undefined) {
      throw { errorMsg: 'User no defined' };
    }
    const headers = new HttpHeaders();
    // headers.append('X-Requested-With', 'XMLHttpRequest');
    return this.http.post(
      this.config.betrailApi + '/user/login',
      { username: user, password: password },
      { headers: headers, withCredentials: true },
    );
  }

  logout(): Observable<any> {
    return this.http.post(
      this.config.nodejsApi + '/user/logout',
      {},
      {
        withCredentials: true,
      },
    );
  }

  getUserFromSession(activateRunner?: boolean) {
    return this.http.get(this.config.nodejsApi + '/user/profile' + (activateRunner ? '/activate' : ''), {
      withCredentials: true,
    });
  }

  getAccountType(email) {
    return this.http.post<any[]>(this.config.nodejsApi + '/user/accountType', { email });
  }

  sendNewPassword(email) {
    return this.http.put(this.config.nodejsApi + '/user/newPassword', { email });
  }

  createLightAccount(data: {
    email: string;
    pass: string;
    firstname: string;
    lastname: string;
    language: string;
    cgu: number;
    charte: number;
    newsletter: number;
  }) {
    const newAccount = {
      mail: data.email,
      pass: data.pass,
      language: data.language,
      firstname: data.firstname,
      lastname: data.lastname,
      field_cgu: {
        und: [
          {
            value: data.cgu,
          },
        ],
      },
      field_charte: {
        und: [
          {
            value: data.charte,
          },
        ],
      },
    };
    return this.http.post(this.config.nodejsApi + '/user/lightAccount', newAccount);
  }

  // TODO : remove this ? it is not used but is it useful ?
  /* updateLightAccountPassword(uid, data) {
    const account = {
      mail: data.email,
      language: data.language,
      field_cgu: {
        und: [
          {
            value: data.cgu,
          },
        ],
      },
      field_charte: {
        und: [
          {
            value: data.charte,
          },
        ],
      },
    };
    return this.http.post(this.config.nodejsApi + `/user/lightAccount/${uid}/update`, account);
  } */

  createOrgaUser(newOrgaAccount) {
    const newAccount = {
      mail: newOrgaAccount.email,
      pass: newOrgaAccount.password,
      language: newOrgaAccount.language,
      field_cgu: {
        und: [
          {
            value: newOrgaAccount.cgu,
          },
        ],
      },
      field_charte: {
        und: [
          {
            value: newOrgaAccount.charte,
          },
        ],
      },
      field_nom: {
        und: [
          {
            value: newOrgaAccount.lastname || 'X',
            format: null,
            safe_value: newOrgaAccount.lastname || 'X',
          },
        ],
      },
      field_prenom: {
        und: [
          {
            value: newOrgaAccount.firstname || 'X',
            format: null,
            safe_value: newOrgaAccount.firstname || 'X',
          },
        ],
      },
    };
    return this.http.post(this.config.nodejsApi + '/user/orgaAccount', newAccount);
  }

  createUser(registrationData: any): Observable<any> {
    let formattedBirthdate = undefined;

    if (registrationData.birthdate != undefined) {
      let birthdate = new Date(registrationData.birthdate);
      let month = birthdate.getMonth() + 1;
      formattedBirthdate = birthdate.getFullYear() + '-' + month + '-' + birthdate.getDate();
    }
    let logs = {
      cgu: [
        {
          date: +new Date(),
          value: registrationData.cgu,
          version: '1.0',
        },
      ],
      charte: [
        {
          date: +new Date(),
          value: registrationData.charte,
          version: '1.0',
        },
      ],
      newsletter: [
        {
          date: +new Date(),
          value: registrationData.newsletter,
        },
      ],
    };
    let data: any = {
      mail: registrationData.mail,
      pass: registrationData.pass,
      name: registrationData.firstname + '.' + registrationData.lastname,
      field_nom: { und: [{ value: registrationData.lastname }] },
      field_prenom: { und: [{ value: registrationData.firstname }] },
      /*field_code_postal: { und: [{ value: registrationData.postal }] },
      field_localite: { und: [{ value: registrationData.city }] },
      field_code_pays: { und: [{ value: registrationData.country }] },
      field_nationalite: { und: [{ value: registrationData.nationalite }] },*/
      field_id_coureur: { und: [{ value: registrationData.ruid }] },
      //field_coureur: { und: [{ target_id: registrationData.ruid }] },
      field_cgu: { und: [{ value: registrationData.cgu }] },
      field_charte: { und: [{ value: registrationData.charte }] },
      field_recevoir_les_newsletters: { und: [{ value: registrationData.newsletter }] },
      field_logs: { und: [{ value: JSON.stringify(logs) }] },
    };

    if (formattedBirthdate != undefined) {
      data.field_birthdate = { und: [{ value: formattedBirthdate }] };
    }

    if (formattedBirthdate != undefined) {
      data.field_birthdate = { und: [{ value: formattedBirthdate }] };
    }

    /*if(registrationData.runner && registrationData.runner.id != undefined) {
      data['field_coureur'] = { und: [{ target_id: registrationData.runner.id }] };
    }*/
    const user = this.http.post(this.config.nodejsApi + '/user', data);
    // 1. faire le post sur le nodejsApi
    //user.pipe(switchMap(u => {
    //return this.login(registrationData.mail, registrationData.pass);
    //return user;
    //return this.http.put(this.config.betrailInternApi + '/runner/' + registrationData.ruid, runnerInfo);
    //}));

    let runnerData = {
      firstname: registrationData.firstname,
      lastname: registrationData.lastname,
      place: registrationData.place,
      gender: registrationData.gender == 'FEMALE' || +registrationData.gender == 1 ? 1 : 0,
      birthdate: formatDate(new Date(registrationData.birthdate)),
    };

    return user.pipe(
      switchMap((user: any) => {
        let runnerId = user?.field_id_coureur?.und?.[0]?.value;

        if (runnerId) {
          return this.http
            .put<any>(`${this.config.nodejsApi}/runner/${runnerId}/drupal`, {
              id: runnerId,
              data: { ...runnerData, userId: user.uid },
            })
            .pipe(map(r => r.body));
        }
        return;
      }),
    );
  }

  getUserByUid(uid: number, data) {
    const user = this.http.post(this.config.nodejsApi + '/user/getUserByUid/' + uid, data);
  }

  getUserNotifications() {
    return this.http.get<any>(this.config.nodejsApi + '/notif/all', { withCredentials: true }).pipe(map(r => r?.body));
  }

  updateNotificationStatus(noid: number, status: string) {
    return this.http
      .put<any>(this.config.nodejsApi + '/notif/update', { data: { noid, status } }, { withCredentials: true })
      .pipe(map(r => r.body));
  }
}
