import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'toRelativeDate',
})
export class ToRelativeDatePipe implements PipeTransform {
  constructor(private translate: TranslocoService) {}
  transform(value: any, args?: any): any {
    //TODO: use luxon to get relative date

    const today = moment();
    const yesterday = moment().add(-1, 'day');
    const yearsAgo = moment().add(-4, 'day');

    if (moment(value).isSame(today, 'D')) {
      const ago = this.todayDifference(moment(value));
      return this.translate.translate('DIFF_AGO_' + ago.type.toUpperCase(), ago);
    } else if (moment(value).isSame(yesterday, 'D')) {
      return this.translate.translate('YESTERDAY');
    } else {

    /*else if (moment(value).isSameOrAfter(yearsAgo)) {
      return this.translate.translate(moment(value).lang("en").format('dddd').toUpperCase());
    }*/
      return moment(value).format('DD/MM');
    }
  }

  todayDifference(date) {
    let diff = moment().diff(date, 'days');
    let type = 'DAYS';

    if (diff === 0) {
      diff = moment().diff(date, 'hours');
      type = 'HOURS';
    }

    if (diff === 0) {
      diff = moment().diff(date, 'minutes');
      type = 'MINUTES';
    }

    return { diff, type };
  }
}
