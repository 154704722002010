import { Component, OnInit } from '@angular/core';
import { DialogResult } from '../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../common/dialog/dialogResultStatus';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-form-item-dialog',
  templateUrl: './confirm-delete-form-item-dialog.component.html',
  styleUrls: ['./confirm-delete-form-item-dialog.component.scss', '../../common-orga.scss'],
})
export class ConfirmDeleteFormItemDialogComponent {
  constructor(private dialogRef: MatDialogRef<ConfirmDeleteFormItemDialogComponent>) {}

  /** Page events **/

  /** Click events **/
  cancel() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  confirm() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Ok;

    this.dialogRef.close(dialogResult);
  }
}
