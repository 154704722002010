import {
  Component,
  OnInit,
  ContentChildren,
  QueryList,
  ElementRef,
  ViewChildren,
  AfterViewInit,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SlidingPanelItemDirective } from './sliding-panel-item.directive';
import { SlidingPanelItemElementDirective } from './sliding-panel-item-element.directive';
import { SlidingPanelService } from './sliding-panel.service';
import { AnimationPlayer, AnimationFactory, animate, AnimationBuilder, style } from '@angular/animations';
import { PlatformServiceService } from '../../platform-service.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bux-sliding-panel',
  templateUrl: './sliding-panel.component.html',
  styleUrls: ['./sliding-panel.component.scss'],
})
export class SlidingPanelComponent implements OnInit, AfterViewInit, OnChanges {
  /** Members **/

  private itemWidth: number;
  @Input() currentSlide = 0;
  @Input() level;
  //private player: AnimationPlayer;
  slidingPanelWrapperStyle = {};
  levels = ['orga', 'trail', 'event'];

  @ContentChildren(SlidingPanelItemDirective) items: QueryList<SlidingPanelItemDirective>;
  @ViewChildren(SlidingPanelItemElementDirective, { read: ElementRef }) private itemsElements: QueryList<ElementRef>;
  @ViewChild('slidingPanel', { static: true }) private slidingPanel: ElementRef;

  /** Inputs **/

  @Input() timing = '250ms ease-in';

  /** Outputs **/

  /** Constructor **/

  constructor(
    private slidingPanelService: SlidingPanelService,
    private builder: AnimationBuilder,
    private pps: PlatformServiceService,
  ) {}

  /** Page events **/

  ngOnInit() {
    this.slidingPanelService.next$.pipe(untilDestroyed(this)).subscribe(() => this.next());
    this.slidingPanelService.back$.pipe(untilDestroyed(this)).subscribe(() => this.back());
    this.slideToSelectedLevel();
    if (this.level == 1) {
      this.next();
    }
    if (this.level == 2) {
      this.next();
      this.next();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['level'] || changes['items']) {
      this.slideToSelectedLevel();
    }
  }

  slideToSelectedLevel() {
    if (this.currentSlide == 0 && this.level == 'event') {
      this.next();
      this.next();
    }
    if (this.currentSlide == 0 && this.level == 'trail') {
      this.next();
    }
    if (this.currentSlide == 1 && this.level == 'orga') {
      this.back();
    }
    if (this.currentSlide == 2 && this.level == 'orga') {
      this.back();
      this.back();
    } else if (this.currentSlide == 2 && this.level == 'trail') {
      this.back();
    }
  }

  ngAfterViewInit() {
    if (this.pps.isBrowserRendering()) {
      setTimeout(() => {
        this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;
        this.slidingPanelWrapperStyle = {
          width: `${this.itemWidth}px`,
        };
        this.slideToSelectedLevel();
      });
    }
  }

  /** Click events **/

  /** Private methods **/
  private buildAnimation(offset) {
    return this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
  }

  back() {
    if (this.currentSlide === 0) return;

    this.currentSlide = (this.currentSlide - 1 + this.items.length) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;

    this.animatePanel(offset);

    this.level = this.levels[this.currentSlide];

    // const myAnimation: AnimationFactory = this.buildAnimation(offset);
    // this.player = myAnimation.create(this.slidingMenu.nativeElement);
    // this.player.play();

    // this.evaluateBackButtonVisibility();
  }

  next() {
    if (this.items && this.currentSlide + 1 === this.items.length) return;

    if (this.items) {
      this.currentSlide = (this.currentSlide + 1) % this.items.length;
      const offset = this.currentSlide * this.itemWidth;
      this.animatePanel(offset);

      this.level = this.levels[this.currentSlide];
      // const myAnimation: AnimationFactory = this.buildAnimation(offset);

      // this.player = myAnimation.create(this.slidingPanel.nativeElement);
      // this.player.play();
    }
  }

  private animatePanel(offset: number) {
    const myAnimation: AnimationFactory = this.buildAnimation(offset);

    let player = myAnimation.create(this.slidingPanel.nativeElement);
    player.play();
  }
}
