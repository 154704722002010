<button tabindex="-1" class="closeButton" mat-icon-button (click)="close()">
  <mat-icon aria-label="Close Dialog">close</mat-icon>
</button>
<div class="form">
  <div fxLayout="row" fxLayoutAlign="center center">
    <welcome-steps
      class="welcomeStepsContainer"
      [welcomeSteps]="welcomeSteps"
      [runner]="_runner"
      (changed)="setWelcomeStatus($event)"
      [newValues]="newAccountValues"
      [isLightUser]="isLightUser"
    ></welcome-steps>
    <div class="backgroundImage"></div>
  </div>
</div>
