<form class="login-form" [formGroup]="organizationForm" class="form login-form">
  <h2 class="centeredAligned">{{ 'CREATE_ORGA_ACCOUNT' | transloco }}</h2>
  <div class="message">
    Ce compte sera ensuite lié à l'organisation <br />
    du trail "{{ trail?.title }}"
  </div>
  <div class="form-content" fxLayout="column" fxLayoutAlign="centeredAligned stretch">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Prénom</mat-label>
      <mat-icon class="suffixIcon" matSuffix>person</mat-icon>
      <input matInput #firstname type="text" placeholder="Prénom" formControlName="firstname" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Nom</mat-label>
      <mat-icon class="suffixIcon" matSuffix>person</mat-icon>
      <input matInput #lastname type="text" placeholder="Nom" formControlName="lastname" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Email</mat-label>
      <mat-icon class="suffixIcon" matSuffix>email</mat-icon>
      <input trimInput matInput #email type="text" placeholder="Votre adresse email" formControlName="email" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-icon class="suffixIcon" matSuffix>lock</mat-icon>
      <mat-label tabindex="1">Créer un nouveau mot de passe</mat-label>
      <input matInput #pass type="password" placeholder="Mot de passe" formControlName="password" />
    </mat-form-field>

    <!-- <span class="passwordToggle" (click)="displayPasswordToggle()">
        <mat-icon class="eye">{{ passwordToggleIcon }}</mat-icon>{{ passwordToggleText | transloco }}
      </span> -->

    <mat-form-field appearance="fill" class="full-width">
      <mat-icon class="suffixIcon" matSuffix>lock</mat-icon>
      <mat-label tabindex="1">Confirmer le mot de passe</mat-label>
      <input
        matInput
        #pass
        type="password"
        placeholder="Confirmer votre mot de passe"
        formControlName="confirmPassword"
      />
    </mat-form-field>

    <div class="pass-error">
      <div *ngIf="form.confirmPassword.errors" class="invalid-feedback">
        <!-- <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div> -->
        <div *ngIf="form.confirmPassword.errors.mustMatch">Les mots de passe doivent correspondre</div>
      </div>
    </div>

    <div>
      <mat-checkbox color="primary" fxFlex="100" formControlName="cgu">
        <div class="checkbox">
          J'accepte les
          <a target="_blank" href="../assets/docs/Terms of Service.pdf">Conditions Générales d'Utilisation</a>
        </div>
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox color="primary" fxFlex="100" formControlName="charte">
        <div class="checkbox">
          J'accepte la
          <a target="_blank" href="../assets/docs/Politique vie privée et traitements des données v1.01.pdf"
            >Politique de respect de la vie privée et d’utilisation des données à caractère personnel</a
          >
        </div>
      </mat-checkbox>
    </div>
  </div>
  <div class="bottom">
    <button mat-button type="button" (click)="cancel()">{{ 'CANCEL' | transloco }}</button>
    <button class="bt-button orange" [disabled]="!organizationForm.valid" mat-raised-button (click)="validate()">
      {{ 'CREATE_ACCOUNT' | transloco }}
    </button>
    <div class="message">Déjà un compte Betrail ? <a (click)="goToLoginForm()">Connectez-vous</a></div>
  </div>
</form>
