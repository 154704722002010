import { Injectable } from '@angular/core';
import { RouterState } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BetrailUxService {
  @Select(RouterState.url) public url: Observable<string>;
}
