import { Component, EventEmitter, Output } from '@angular/core';
import { UsersService } from '@betrail-libs/auth-state';

@Component({
  selector: 'app-send-new-password',
  templateUrl: './send-new-password.component.html',
  styleUrls: ['./send-new-password.component.scss', '../../common.scss'],
})
export class SendNewPasswordComponent {
  constructor(private usersService: UsersService) {}

  formType = 'forgottenPassword';
  newPasswordButtonDisabled: boolean;
  newPasswordEmail: any;
  error: string;

  @Output() goToLoginFormClicked: EventEmitter<any> = new EventEmitter<any>();

  goToLoginForm() {
    this.goToLoginFormClicked.emit();
  }

  sendNewPassword(email) {
    this.newPasswordButtonDisabled = true;
    this.usersService.sendNewPassword(email).subscribe(
      user => {
        if (user['uid'] && user['uid'] > 0) {
          this.newPasswordButtonDisabled = false;
          this.newPasswordEmail = email;
          this.formType = 'newPasswordSent';
        } else {
          this.newPasswordButtonDisabled = false;
          this.error = 'Une erreur est survenue. Veuillez réessayer.';
        }
      },
      error => {
        if (error == "TypeError: Cannot read property 'uid' of null") {
          error = "Aucun compte lié à cette adresse n'a été trouvé.";
        }
        this.newPasswordButtonDisabled = false;
        this.error = error;
      },
    );
  }
}
