<div>
  <div class="filter-container" ngClass.lt-sm="small" [class.displayed]="displayed === true">
    <button mat-icon-button class="closeFilter" (click)="close()">
      <mat-icon class="!mb-[8px]">close</mat-icon>
    </button>
    <div class="filter-content">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'numberRange'">
          <calendar-filter-number-range
            [sliderMaxVal]="unit === 'm+' ? 10000 : unit ==='km' ? 250 : 120"
            [quickFilters]="quickFilters"
            [unit]="unit"
            [filteredElements$]="filteredElements$"
            (numberFilter)="emitFilter($event)"
            (updateSummary)="updateSummary($event)"
            (apply)="apply()"
            [_values]="data.value | async"
            [description]="description"
          ></calendar-filter-number-range>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <calendar-filter-text
            [filteredElements]="filteredElements$ | async"
            (textFilter)="emitFilter($event)"
            (updateSummary)="updateSummary($event)"
            (apply)="apply()"
            [description]="description"
            >></calendar-filter-text
          >
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <calendar-filter-checkboxes
            [quickFilters]="quickFilters"
            [filteredElements]="filteredElements$ | async"
            (checkboxesFilter)="emitFilter($event)"
            (updateSummary)="updateSummary($event)"
            (apply)="apply()"
            [cancelable]="data.cancelable"
            [_values]="data.value | async"
          ></calendar-filter-checkboxes>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          <calendar-filter-dates
            [quickFilters]="quickFilters"
            [filteredElements]="filteredElements$ | async"
            (datesFilter)="emitFilter($event)"
            (updateSummary)="updateSummary($event)"
            (apply)="apply()"
            [_values]="data.value | async"
          ></calendar-filter-dates>
        </ng-container>
        <ng-container *ngSwitchCase="'region'">
          <calendar-filter-region-department
            [filteredElements]="filteredElements$ | async"
            [value]="data.value"
            [country]="data.value?.country"
            [region]="data.value?.region | async"
            [department]="data.value?.department | async"
            (regionFilter)="emitFilter($event)"
            (updateSummary)="updateSummary($event)"
            (apply)="apply()"
          ></calendar-filter-region-department>
        </ng-container>
        <ng-container *ngSwitchCase="'geolocation'">
          <calendar-filter-geolocation
            [quickFilters]="quickFilters"
            (geolocationFilter)="emitFilter($event)"
            (updateSummary)="updateSummary($event)"
            (apply)="apply()"
            [_values]="data.value | async"
          ></calendar-filter-geolocation>
        </ng-container>
      </ng-container>
      <div class="filter-actions" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em">
        <!-- TODO: Chack if woorking :

             *ngIf="(status | async) && (cancelable === undefined || cancelable === true); else other"
            -->
        <button
          mat-button
          *ngIf="status && (cancelable === undefined || cancelable === true); else other"
          (click)="disapply()"
        >
          {{ 'FILTER_DISABLE' | transloco }}
        </button>
        <button
          class="right"
          mat-raised-button
          color="accent"
          (click)="apply()"
          [disabled]="type === 'checkbox' && (value && value?.length < 1)"
        >
          {{ 'FILTER_APPLY' | transloco }}
        </button>
        <ng-template #other>
          <div>&nbsp;</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
