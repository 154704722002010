<header class="form-title">{{ race.title }}</header>
<ng-container *ngIf="user && user.uid && +user.uid !== 0; else pleaseLogin">
  <ng-container *ngIf="formActivated === true; else warning">
    <form [formGroup]="raceForm">
      <div class="form-container" [class.loading]="loading$ | async">
        <bux-race-form #raceFormComponent [datesInfos]="datesInfos" [verified]="+race.verification_status === 4" [multiple]="data.multiple">
        </bux-race-form>

        <div class="spinner-container" *ngIf="loading$ | async">
          <mat-spinner strokeWidth="3" class="spinner-indicator"></mat-spinner>
        </div>
      </div>
      <div class="actions-container">
        <div class="textarea-label" [class.red]="!raceForm.get('message').valid">{{ 'MODO_MESSAGE' | transloco }}</div>
        <textarea
          class="moderator-message"
          rows="2"
          matInput
          appearance="outline"
          formControlName="message"
          placeholder="{{ 'UPDATE_INFO_WHY' | transloco }}"
        ></textarea>
        <div
          class="flex"
          [style.justify-content]="race.id !== 'NEW' && ((isAdmin$ | async) || (isEncoder$ | async) || (isOrganizer$ | async)) ? 'space-between' : 'flex-end'"
        >
          <button
            *ngIf="race.id !== 'NEW' && ((isAdmin$ | async) || (isEncoder$ | async) || (isOrganizer$ | async))"
            mat-button
            color="warn"
            (click)="removeDistance(race?.hasResults)"
          >
            {{ 'DELETE_THIS_DISTANCE' | transloco }}
          </button>
          <div>
            <button mat-button (click)="onCancelClick()">{{ 'CANCEL' | transloco }}</button>
            <button
              class="confirm-button"
              mat-raised-button
              [disabled]="(loading$ | async) || !raceForm.valid || !raceForm.dirty"
              (click)="onOkClick()"
            >
              {{ ((loading$ | async) ? 'SAVING' : 'SAVE') | transloco }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-template #pleaseLogin>
  <div class="message">{{ 'PLEASE_LOGIN_FEATURE' | transloco }}</div>
  <button mat-button (click)="close()">{{ 'CLOSE' | transloco }}</button>
</ng-template>

<ng-template #warning>
  <div class="message">
    <div innerHTML="{{ 'FORMS_WARNING' | transloco }}"></div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>
        <button mat-raised-button (click)="goToForm()">{{ 'UNDERSTOOD' | transloco }}</button>
      </p>
    </div>
  </div>
</ng-template>
