<div class="quickFilters">
  <strong>{{ 'FILTER_QUICK_FILTERS' | transloco }}</strong>
  <ng-container *ngFor="let quickFilter of quickFilters">
    <ng-container *ngIf="quickFilter.title !== 'empty_space'; else emptySpace">
      <span class="quickFilter" (click)="activeQuickFilter(quickFilter.values, quickFilter.title)"
        >{{ quickFilter.title | transloco }}</span
      >
    </ng-container>
    <ng-template #emptySpace>&nbsp;&nbsp;&nbsp;</ng-template>
  </ng-container>
</div>
<div class="fullFilter">
  <strong>{{'FILTER_CUSTOMIZE_FILTER' | transloco}}</strong>
  <div fxLayout="row">
    <div fxFlex="50" *ngFor="let element of elements; let i = index">
      {{ element.label | transloco }} {{ element.value | date: 'dd/MM/yyyy' : 'UTC00' }}

      <mat-calendar
        ref-{{element.name}}
        [startAt]="element.value"
        [(selected)]="element.value"
        (selectedChange)="changeValues()"
        [minDate]="minDate"
        [maxDate]="maxDate"
      ></mat-calendar>
    </div>
  </div>
</div>
