import { Component, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { EventService } from '@betrail-libs/trail-data-state';
import { Store } from '@ngxs/store';
import { loadOtherResultsForRunnerId } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'app-delete-other-result-dialog',
  templateUrl: './delete-other-result-dialog.component.html',
  styleUrls: ['./delete-other-result-dialog.component.css'],
})
export class DeleteOtherResultDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteOtherResultDialogComponent>,
    private eventService: EventService,
    private focusMonitor: FocusMonitor,
    private store: Store,
  ) {}

  result = this.data.otherResult;

  ngAfterViewInit() {
    this.focusMonitor.stopMonitoring(document.getElementById('cancelBtn'));
    this.focusMonitor.stopMonitoring(document.getElementById('deleteBtn'));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteOtherResult() {
    this.eventService.deleteOtherResultById(this.result).subscribe(r => {
      this.store.dispatch(new loadOtherResultsForRunnerId(this.result.ruid));
      this.closeDialog();
    });
  }
}
