<div>
  <input #fileInput hidden type="file" (click)="$event.stopPropagation()" (change)="addImage(fileInput.files[0])" />
</div>
<div>
  <button
    class="addImageButton"
    tdFileDrop
    #addImageButton
    mat-raised-button
    (click)="fileInput.click(); $event.stopPropagation()"
    (fileDrop)="addImage($any($event))"
    [disabled]="addImageButtonDisabled"
  >
    <mat-icon>image</mat-icon>&nbsp;
    <ng-container *ngIf="source?.medium_image || updatedImage?.medium_image; else: newImage"
      >{{ buttonText ? buttonText : 'Modifier' }}</ng-container
    >
    <ng-template #newImage>{{ buttonText ? buttonText : 'Ajouter une photo' }}</ng-template>
  </button>
</div>
