<form [formGroup]="sponsorForm" class="sponsor-form">
  <div class="form-title">
    <h3>Ajout d'un nouveau sponsor</h3>
  </div>

  <div class="sponsor-inputs">
    <div class="sponsor-data">
      <mat-form-field appearance="fill" color="accent">
        <mat-icon class="suffixIcon" matSuffix>description</mat-icon>
        <mat-label>{{ 'NAME_GENERIC' | transloco }}</mat-label>
        <input matInput placeholder="{{ 'NAME_GENERIC' | transloco }}" formControlName="name" />
        <p *ngIf="sponsorForm.hasError('nameError')" class="formError">{{ 'ERROR_MISSING_FIELD' | transloco }}</p>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-icon class="suffixIcon" matSuffix>flag</mat-icon>
        <mat-label>{{ 'COUNTRY' | transloco }}</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
            >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-icon class="suffixIcon" matSuffix>language</mat-icon>
        <mat-label>{{ 'RACE_WEBSITE' | transloco }}</mat-label>
        <input matInput type="url" placeholder="{{ 'RACE_WEBSITE' | transloco }}" formControlName="link" />
      </mat-form-field>

      <ng-container *ngIf="data.role === 'admin'">
        <mat-checkbox class="checkbox" color="accent" formControlName="linkEnabled" [labelPosition]="'before'">
          Autoriser lien clickable (Admin)
        </mat-checkbox>
      </ng-container>
    </div>

    <div class="sponsor-image">
      <div>
        <input
          #fileInput
          hidden
          type="file"
          accept=".jpeg,.jpg,.png"
          (click)="$event.stopPropagation()"
          (change)="addImage(fileInput.files[0])"
        />
      </div>
      <div>
        <button
          class="addImageButton"
          tdFileDrop
          #addImageButton
          mat-raised-button
          (click)="fileInput.click(); $event.stopPropagation()"
          (fileDrop)="addImage($event)"
        >
          <mat-icon>image</mat-icon>&nbsp; Upload image
        </button>
      </div>

      <p class="advisedSize">{{ 'SPONSOR_ADVISED_IMG_SIZE' | transloco }}</p>
      <img *ngIf="imageData" [src]="imageData" class="uploaded-img" />
      <div *ngIf="!imageData" class="uploaded-img"></div>
      <p *ngIf="sponsorForm.hasError('imageError'); else imageAdded" class="formError imgErr">
        {{ 'IMAGE_REQUIRED' | transloco }}
      </p>
      <ng-template #imageAdded>
        <p class="formError imgErr imgAdded">{{ 'IMAGE_ADDED' | transloco }}</p>
      </ng-template>
    </div>
  </div>

  <div class="btnContainer">
    <button color="primary" mat-raised-button (click)="closeDialog()">{{ 'CANCEL' | transloco }}</button>
    <button type="submit" color="accent" mat-raised-button (click)="saveSponsor()" [disabled]="!formValid">
      {{ 'SAVE' | transloco }}
    </button>
  </div>
</form>
