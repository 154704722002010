import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NouisliderComponent } from 'ng2-nouislider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterNumberElement } from '../filterElement.model';

@UntilDestroy()
@Component({
  selector: 'calendar-filter-number-range',
  templateUrl: './calendar-filter-number.component.html',
  styleUrls: ['./calendar-filter-number.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class CalendarFilterNumberComponent implements OnInit {
  elements: FilterNumberElement[];
  debouncer = new BehaviorSubject<number[]>([]);
  values = new BehaviorSubject<number[]>([]);
  range = new BehaviorSubject<any>({});

  slider = combineLatest([this.values, this.range]).pipe(
    map(([values, range]) => {
      if (this.mySlider && this.mySlider.slider) {
        this.mySlider.slider.set(values);
        this.mySlider.slider.updateOptions({
          range: range,
        });
      }
      return this.mySlider && this.mySlider.slider;
    }),
  );

  /* ⚠️ slider not show on ssr */
  @ViewChild('slider') public mySlider: NouisliderComponent;

  @Input() set sliderMaxVal(sliderMaxVal: number) {
    this.sliderConfig = {
      behaviour: 'drag',
      connect: true,
      start: [0, sliderMaxVal],
      keyboard: true, // same as [keyboard]="true"
      step: sliderMaxVal === 10000 ? 100 : 1,
      tooltips: true,
      range: {
        min: 0,
        max: sliderMaxVal,
      },
    };
  }
  @Input() quickFilters;
  @Input() unit!: string;
  @Input() description: string;
  @Input() filteredElements$: Observable<any> = new BehaviorSubject([]);
  @Input() set _values(values) {
    if (values && values[0] != 0 && values[1] != 0) {
      this.sliderConfig.start = [values[0], values[1]];
      this.values.next([values[0], values[1]]);
    }
  }

  @Output() numberFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();
  @Output() apply = new EventEmitter();

  sliderConfig: any;

  ngOnInit() {
    this.slider.pipe(untilDestroyed(this)).subscribe();
    this.filteredElements$.pipe(untilDestroyed(this)).subscribe(filteredElements => {
      if (filteredElements && filteredElements.length > 0) {
        const values = [+filteredElements[0].value, +filteredElements[filteredElements.length - 1].value];
        this.changeValues(values);
        const range = {
          min: [filteredElements[0].minAndMax[0]],
          max: [filteredElements[filteredElements.length - 1].minAndMax[1]],
        };
        this.range.next(range);
        this.elements = filteredElements;
      }
    });
  }

  getSummary(values, range) {
    let max = values[1];
    if (values[1] == range.max) {
      max = values[1] + '+';
    }
    const summary = values[0] + '-' + max + ' ' + this.unit;
    return summary;
  }

  changeValue(value, elementName) {
    if (value > -1) {
      this.elements.find(e => e.name == elementName).value = value;
      //this.numberFilter.emit(this.elements.map(e => e.value));
      this.changeValues(this.elements.map(e => e.value));
    }
  }

  changeValues(values) {
    if (Math.min(...values) > -1) {
      this.numberFilter.emit(values);
      this.values.next(values);
      this.updateSummary.emit(this.getSummary(values, this.range));
    }
  }

  activeQuickFilter(value, summary) {
    this.changeValues(value);
    this.numberFilter.emit(value);
    this.updateSummary.emit(summary);
    this.apply.emit();
  }
}
