<section>
  <div id="map" style="height: 350px; width: 500px">
    <mat-icon class="center-marker">gps_fixed</mat-icon>
  </div>
  <div class="bg-[rgba(0,0,0,0.02)] text-[rgba(0,0,0,0.6)] rounded-sm my-4 p-2 text-center">{{ data.trail.title }}</div>
  <div class="flex justify-between">
    <mat-form-field appearance="fill" class="w-60">
      <mat-label>{{ 'LATITUDE' | transloco }}</mat-label>
      <input matInput placeholder="Latitude" [(ngModel)]="latitude" disabled />
      <mat-hint>{{ 'INITIAL_LATITUDE' | transloco }} : {{ data.trail.man_lat || data.trail.geo_lat }}</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-60">
      <mat-label>{{ 'LONGITUDE' | transloco }}</mat-label>
      <input matInput placeholder="Longitude" [(ngModel)]="longitude" disabled />
      <mat-hint>{{ 'INITIAL_LONGITUDE' | transloco }} : {{ data.trail.man_lon || data.trail.geo_lon }}</mat-hint>
    </mat-form-field>
  </div>
  <div class="flex justify-between p-2 pt-4">
    <button mat-raised-button mat-dialog-close color="warn" class="w-28">{{ 'CANCEL' | transloco }}</button>
    <button mat-raised-button color="accent" class="w-28" (click)="saveGeolocation()" [disabled]="saving">
      <ng-container *ngIf="!saving; else spinner">{{ 'CONFIRM' | transloco }}</ng-container>
      <ng-template #spinner>
        <mat-spinner diameter="20" color="accent" class="m-auto"></mat-spinner>
      </ng-template>
    </button>
  </div>
</section>
