import { Directive, HostListener } from '@angular/core';
import { SlidingPanelService } from './sliding-panel.service';

@Directive({
  selector: '[slidingPanelBack]',
})
export class SlidingPanelBackDirective {
  constructor(private slidingPanelService: SlidingPanelService) {}

  @HostListener('click') onClick() {
    this.slidingPanelService.back();
  }
}
