import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unfollow-runner-dialog',
  template: `
    <div class="max-w-sm">
      <h2 class="text-lg text-center !mt-2">{{ 'RUNNER_ALREADY_FOLLOWED' | transloco }}</h2>
      <hr />
      <div class="p-4">
        <p class="my-4 text-center">{{ 'CONFIRM_UNFOLLOW_RUNNER_2' | transloco: { name: data.runnerName } }}</p>
        <div class="flex justify-between pt-4">
          <button mat-raised-button (click)="closeDialog(false)">{{ 'CANCEL' | transloco }}</button>
          <button mat-raised-button color="warn" (click)="closeDialog(true)">{{ 'UNFOLLOW' | transloco }}</button>
        </div>
      </div>
    </div>
  `,
})
export class UnfollowRunnerDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { runnerName: string },
    private dialogRef: MatDialogRef<UnfollowRunnerDialogComponent>,
  ) {}

  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }
}
