import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventService } from '@betrail-libs/trail-data-state';
import { ALL_COUNTRIES_CODES, VALUES_RACE_TYPE, VALUES_RUN_TYPE } from '@betrail-libs/shared/utils';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { AuthStateService } from '@betrail-libs/auth-state';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditOtherResultDialogData } from './EditOtherResultDialogData';
import { DatePipe } from '@angular/common';
import { loadOtherResultsForRunnerId } from '@betrail-libs/trail-data-state';
import { TrailDataState } from '@betrail-libs/trail-data-state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'result-other-edit-dialog-form',
  templateUrl: './result-other-edit-dialog-form.component.html',
  styleUrls: ['./result-other-edit-dialog-form.component.scss'],
  providers: [DatePipe],
})
export class EditResultDialogComponent implements OnInit {
  @ViewChild('runtypeinput') searchElement: ElementRef;
  @Select(TrailDataState.selectSelectedRunnerId) runner$!: Observable<any>;

  /* Members  */
  isFind = false;
  isToCreate = this.data.otherResult.trail ? false : true;
  isEventSelected = true;
  isRaceSelected = true;
  isOtherEventSelected: boolean = this.data.otherResult.evid ? false : true;
  isOtherRaceSelected: boolean = this.data.otherResult.raid ? false : true;
  trail: any = this.data.otherResult.trail ? this.data.otherResult.trail : null;
  events: any;
  eventsOptions: any;
  races: any;
  racesOptions: any;
  selectedTrailTitile = '';
  selectedEvent: any;
  selectedRace: any = this.data.otherResult.race ? this.data.otherResult.race : null;
  isSaved = false;
  user: any;
  runnerId: any;
  oRes = this.data.otherResult;
  isOtherRunType: boolean =
    this.oRes.run_type === 'trail' || this.oRes.run_type === 'road_race' || this.oRes.run_type === 'nature_race'
      ? false
      : true;

  d = this.oRes.result_seconds;
  h = Math.floor(this.d / 3600);
  m = Math.floor((this.d % 3600) / 60);
  s = Math.floor((this.d % 3600) % 60);

  otherResultForm: UntypedFormGroup = this.resetForm();
  isDnf: boolean = this.oRes.result_type == 1 ? true : false;
  isSpec: boolean = this.oRes.result_type == 0 ? false : true;
  saveButtonDisabled = false;

  raceTypeValues = VALUES_RACE_TYPE;
  countries = ALL_COUNTRIES_CODES;
  runTypes = VALUES_RUN_TYPE;
  resultTypes = [];

  isNotBetrail = !this.oRes.trid ? true : false;
  isOtherDistance = this.oRes.trid && this.oRes.ev_year && this.oRes.distance ? true : false;
  isNewEdition = this.oRes.trid && !this.oRes.ev_year && this.oRes.distance ? true : false;
  isOfficialTrail = this.oRes.trid && !this.oRes.ev_year && !this.oRes.distance ? true : false;

  displayModifBox1 = this.isNotBetrail ? true : false;
  displayModifBox2 = this.isOtherDistance || this.isNotBetrail ? true : false;
  displayModifBox3 = this.isNewEdition || this.isOtherDistance || this.isNotBetrail ? true : false;

  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  years = [];

  evDate = this.oRes.ev_date ? this.oRes.ev_date.split('/') : null;
  day = this.evDate ? parseInt(this.evDate[0]) : null;
  month = this.evDate ? parseInt(this.evDate[1]) : null;

  constructor(
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private store: Store,
    private authState: AuthStateService,
    private dialogRef: MatDialogRef<EditResultDialogComponent>,
    private el: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: EditOtherResultDialogData,
    public datepipe: DatePipe,
  ) {}

  ngOnInit() {
    this.loadUser();
    this.getYears();
    this.oRes.raid ? this.getRaceResults(this.oRes.raid) : (this.resultTypes = [0, 1, 2, 3]);
    if (this.oRes.country) this.otherResultForm.get('country').setValue(this.oRes.country);
    this.runner$.subscribe(val => {
      this.runnerId = val;
    });
    if (this.evDate) {
      this.otherResultForm.get('day').setValue(this.day);
      this.otherResultForm.get('month').setValue(this.month);
    }
  }

  resetForm() {
    return this.fb.group(
      {
        title: [this.oRes.title],
        country: [this.oRes.country],
        zipcode: [this.oRes.zipcode],
        location: [this.oRes.location],
        run_type: [this.oRes.run_type],

        day: [this.day],
        month: [this.month],
        year: [this.oRes.ev_year],

        distance: [this.oRes.distance],
        height_difference: [this.oRes.height_difference],
        race_type: [this.oRes.race_type],
        night_race: [this.oRes.night_race],

        result_type: [this.oRes.result_type, Validators.required],
        position: [this.oRes.position],
        total_finishers: [this.oRes.total_finishers],
        hours: [this.h],
        minutes: [this.m],
        seconds: [this.s],
        //time:[this.datepipe.transform(new Date(this.oRes.result_milliseconds), 'HH:mm:ss')],
        distance_dnf: [this.oRes.distance_dnf],
      },
      { validators: this.validateDistance() },
    );
  }

  getYears() {
    const y = new Date().getFullYear();
    for (let i = 1920; i <= y; i++) {
      this.years.push(i);
    }
    this.years.reverse();
  }

  getRaceResults(raid) {
    this.eventService.getRaceResultsCount(raid).subscribe(r => {
      if (r.race === 0) {
        this.resultTypes = [0, 1, 2, 3];
      } else this.resultTypes = [1, 2, 3];
    });
  }

  async loadUser() {
    this.authState
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe(u => {
        this.user = u;
        this.otherResultForm.patchValue({
          country: u?.runner?.nationality || '',
        });
      });
  }

  onTrailChoice(v) {
    this.isEventSelected = false;
    this.isOtherEventSelected = false;
    this.isOtherRaceSelected = false;
    this.isRaceSelected = false;
    //show create event form or create race form
    if (v.add_course) {
      this.isOtherRunType = false;
      this.isToCreate = true;
      this.selectedTrailTitile = v.trail_title;
    } else {
      this.selectedTrailTitile = v.trail_title_original;
      this.isToCreate = false;
      this.isEventSelected = false;
      this.isRaceSelected = false;
    }
    if (!this.isToCreate) {
      this.eventService.getTrail(v.trail_alias).subscribe(r => {
        this.trail = r;
        this.events = r.events;
        this.eventsOptions = this.events.map(event => {
          return {
            label: event.alias,
            value: event.alias,
          };
        });
        this.eventsOptions.push({
          label: 'OTHER',
          value: 'other',
        });
        //show next when find
        this.isFind = true;
      });
    } else {
      this.isEventSelected = true;
      this.isRaceSelected = true;
      //show next when find
      this.isFind = true;
    }
  }

  sortEventsOptions(array) {
    let sortedArr;
    if (array !== undefined) {
      sortedArr = array.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
    }
    return sortedArr;
  }

  onEventChoice(v) {
    this.isEventSelected = true;
    //check if other button pressed
    if (v == 'other') {
      this.isOtherEventSelected = true;
      this.isRaceSelected = true;
      this.resultTypes = [0, 1, 2, 3];
      this.otherResultForm.patchValue({ result_type: 0 });
    } else {
      this.isOtherEventSelected = false;
      this.isOtherRaceSelected = false;
      this.selectedEvent = this.events.find(e => e.alias === v);
      this.races = this.selectedEvent.races;
      this.racesOptions = this.races
        .map(race => {
          const res = {
            label: this.removeIdFromLabel(race.alias),
            value: race.alias,
          };
          if (race.race_type !== 'solo') {
            res['info'] = 'RACE_TYPE_' + race.race_type;
          }
          return res;
        })
        .sort((a, b) => b.label.split('k')[0] - a.label.split('k')[0]);
      this.racesOptions.push({
        label: 'OTHER',
        value: 'other',
      });
    }
  }

  removeIdFromLabel(label) {
    if (label.includes('-')) {
      const arr = label.split('-');
      if (arr.length === 2) {
        if (/^\d+$/.test(arr[0])) {
          return arr[1];
        } else if (/^\d+$/.test(arr[1])) {
          return arr[0];
        } else return arr[0] + '-' + arr[1];
      } else if (arr.length === 3) return arr[0] + '-' + arr[2];
    } else return label;
  }

  onRaceChoice(v) {
    if (v == 'other') {
      this.isOtherRaceSelected = true;
      this.resultTypes = [0, 1, 2, 3];
      this.otherResultForm.patchValue({ result_type: 0 });
      this.isDnf = false;
      this.isSpec = false;
    } else {
      this.isOtherRaceSelected = false;
      //if race selected remove finisher result from choices
      this.resultTypes = [1, 2, 3];
      this.isDnf = true;
      this.isSpec = true;
      this.selectedRace = this.races.find(e => e.alias === v);
      if (this.otherResultForm.value.result_type === 0) this.otherResultForm.patchValue({ result_type: 1 });
    }
    this.isRaceSelected = true;
  }

  onResultTypeChoice(event) {
    if (event.value === 1) this.isDnf = true;
    else this.isDnf = false;
    if (event.value === 0) this.isSpec = false;
    else this.isSpec = true;
  }

  onOtherRunTypeChoice() {
    this.isOtherRunType = true;
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  saveOtherResult() {
    if (!this.otherResultForm.invalid && (this.isRaceSelected || this.isToCreate)) {
      const form = { ...this.otherResultForm.value };
      let time = null;
      if (form.hours !== '' || form.minutes !== '' || form.seconds !== '') {
        time = form.hours * 3600 + form.minutes * 60 + form.seconds;
      }
      const d = form.day >= 10 ? form.day : '0' + form.day;
      const m = form.month >= 10 ? form.month : '0' + form.month;

      const otherResult = { ...this.oRes };

      otherResult.title = form.title;
      otherResult.run_type = form.run_type;
      otherResult.country = form.country;
      otherResult.zipcode = form.zipcode;
      otherResult.location = form.location;

      otherResult.ev_year = form.year;
      otherResult.ev_date = d + '/' + m;
      otherResult.date = Date.UTC(form.year, form.month - 1, form.day) / 1000;

      otherResult.distance = form.distance;
      otherResult.race_type = form.race_type;
      otherResult.height_difference = form.height_difference;
      otherResult.night_race = form.night_race;

      otherResult.result_type = form.result_type;
      otherResult.result_seconds = time;
      otherResult.result_milliseconds = time * 1000;
      otherResult.position = form.position;
      otherResult.total_finishers = form.total_finishers;
      otherResult.distance_dnf = form.distance_dnf;

      this.eventService.UpdatetOtherResultsById(otherResult).subscribe(r => {
        this.isSaved = true;
        if (this.user?.runner?.id) {
          this.store.dispatch(new loadOtherResultsForRunnerId(this.runnerId));
        }
        this.closeDialog();
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  validateDistance(this: EditResultDialogComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = [];

      //const title = control.get('title').value;
      const runType = control.get('run_type').value;
      const country = control.get('country').value;
      const zipcode = control.get('zipcode').value;
      const location = control.get('location').value;

      //const date = control.get('date').value;
      const day = control.get('day').value;
      const month = control.get('month').value;
      const year = control.get('year').value;

      const distance = control.get('distance').value;
      const raceType = control.get('race_type').value;
      const heightDifference = control.get('height_difference').value;
      const nightRace = control.get('night_race').value;

      const resultType = control.get('result_type').value;
      const hours = control.get('hours').value;
      const minutes = control.get('minutes').value;
      const seconds = control.get('seconds').value;

      const position = control.get('position').value;
      const totalFinishers = control.get('total_finishers').value;

      //general fields
      if (resultType === 0 && position !== '' && totalFinishers !== '') {
        if (parseInt(position) > parseInt(totalFinishers)) error.push('positionError');
      }
      if ((resultType === 0 && hours === '') || minutes === '' || seconds === '') error.push('timeError');
      //event fields
      if (this.isToCreate || this.isOtherEventSelected) {
        if (day === '' || month === '' || year === '') error.push('dateError');
      }
      //race fields
      if (this.isToCreate || this.isOtherRaceSelected || this.isOtherEventSelected) {
        if (distance === '') error.push('distanceError');
        else if (distance === 0) error.push('distanceZeroError');
      }
      //trail fields
      if (this.isToCreate) {
        if (zipcode === '') error.push('zipcodeError');
        if (location === '') error.push('locationError');
        if (runType === '' || runType === undefined || runType === null) error.push('runTypeError');
      }
      //distance_dnf not grater than distance
      if (control && control.get('distance') && control.get('distance_dnf')) {
        const distance = parseInt(control.get('distance').value);
        const distanceDnf = control.get('distance_dnf').value;
        if (distanceDnf !== '') {
          if (parseInt(distanceDnf) > distance) error.push('scoreError');
        }
      }

      if (control && control.get('distance_dnf')) {
        if (this.selectedRace && this.selectedRace?.distance) {
          if (this.selectedRace?.distance < parseInt(control.get('distance_dnf').value)) {
            error.push('scoreError');
          }
        }
      }

      const errors = {};
      for (let i = 0; i < error.length; i++) {
        errors[error[i]] = true;
      }
      return error.length === 0 ? null : errors;
    };
  }
}
