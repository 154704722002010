import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult } from '../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../common/dialog/dialogResultStatus';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TranslationsService } from '../../translations.service';

@Component({
  selector: 'app-edit-translations-dialog',
  templateUrl: './edit-translations-dialog.component.html',
  styleUrls: ['./edit-translations-dialog.component.scss', '../../common-orga.scss'],
})
export class EditTranslationsDialogComponent implements OnInit {
  /** Members **/
  languages = ['fr', 'nl', 'en'];
  editTranslationForm: UntypedFormGroup;
  key: string;

  /** Inputs **/

  /** Outputs **/

  /** Constructor **/

  constructor(
    private dialogRef: MatDialogRef<EditTranslationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translationsService: TranslationsService,
    private fb: UntypedFormBuilder,
  ) {
    this.key = this.data;
    this.editTranslationForm = this.createGroup();
  }

  /** Page events **/

  ngOnInit() {}

  /** Click events **/
  cancel() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  confirm() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Ok;
    dialogResult.data = this.editTranslationForm.value;

    const newTranslations = this.languages.map(language => {
      return {
        language: language,
        value: this.editTranslationForm.get(language).value,
      };
    });

    this.translationsService.updateTranslations(this.key, newTranslations).subscribe(translationsResult => {});

    this.dialogRef.close(dialogResult);
  }

  /** Private methods **/
  createGroup(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});

    this.languages.forEach(language => {
      formGroup.addControl(
        language,
        new UntypedFormControl(this.translationsService.traductionsDictionary[language][this.key]),
      );
    });

    return formGroup;
  }
}
