import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from '../../../common/dialog/dialogResult';
import { DialogResultStatus } from '../../../common/dialog/dialogResultStatus';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IFormItem } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'add-registration-form-item-dialog',
  templateUrl: './add-registration-form-item-dialog.component.html',
  styleUrls: ['./add-registration-form-item-dialog.component.scss', '../../common-orga.scss'],
})
export class AddRegistrationFormItemDialogComponent {
  /** Members **/
  newFormItem: IFormItem;
  newItemForm: UntypedFormGroup;
  inputFieldTypes = [
    {
      name: 'freeText',
      key: 'FIELD_TYPE_INPUT',
      type: 'INPUT',
    },
    {
      name: 'checkbox',
      key: 'FIELD_TYPE_CHECKBOX',
      type: 'CHECKBOX',
    },
    /*{
      'name': 'country',
      'key': 'COUNTRY_FIELD',
      'type': 'country'
    },*/
    {
      name: 'select',
      key: 'FIELD_TYPE_SELECT',
      type: 'SELECT',
    },
    /*{
      'name': 'phoneNumber',
      'key': 'PHONE_NUMBER_FIELD',
      'type': 'PHONE'
    },*/
    /*{
      'name': 'message',
      'key': 'MESSAGE_FIELD',
      'type': 'MESSAGE'
    }*/
  ];

  /** Inputs **/

  /** Outputs **/

  /** Constructor **/

  constructor(private dialogRef: MatDialogRef<AddRegistrationFormItemDialogComponent>, private fb: UntypedFormBuilder) {
    this.newItemForm = this.fb.group({
      inputFieldType: [this.inputFieldTypes[0], Validators.required],
      name: ['', Validators.required],
    });
  }

  /** Page events **/

  /** Click events **/

  cancel() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Cancel;

    this.dialogRef.close(dialogResult);
  }

  confirm() {
    const dialogResult = new DialogResult();

    dialogResult.status = DialogResultStatus.Ok;
    dialogResult.data = this.newItemForm.value;

    this.dialogRef.close(dialogResult);
  }

  /** Private methods **/
}
