import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppParamsState } from '@betrail-libs/app-params-state';
import { AuthStateService } from '@betrail-libs/auth-state';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { DrupalRole, Trail } from '@betrail-libs/shared/interfaces/interfaces';
import { isAdmin } from '@betrail-libs/shared/utils';
import { TrailDataState } from '@betrail-libs/trail-data-state';
import { BetrailUxService } from '@betrail/ux';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformServiceService } from '../../platform-service.service';

@Component({
  selector: 'left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
})
export class LeftBarComponent implements OnInit {
  organizerSideMenuStatus: string = 'closed';
  selectedTrailAlias?: string;

  isHomePage$?: Observable<boolean>;
  isLogged$ = this.authService.isLogged();
  isAdmin$ = this.authService.hasRole(DrupalRole.Administrator);
  isOrga$ = this.authService.hasRole(DrupalRole.EventOrganizer);

  @Input() open: boolean = false;
  @Input() user: any;
  @Output() toggle = new EventEmitter();
  @Output() toggleOrganizerMenu = new EventEmitter();

  @Select(AppParamsState.defaultCountry) defaultCountry$!: Observable<any>;
  @Select(TrailDataState.selectedTrail) trail$!: Observable<Trail>;
  @Select(TrailDataState.selectedEvent) event$!: Observable<IEvent>;
  @Select(TrailDataState.selectedType) type$!: Observable<string>;

  constructor(
    public pps: PlatformServiceService,
    private authService: AuthStateService,
    private betrailUxService: BetrailUxService,
  ) {}

  ngOnInit() {
    this.isHomePage$ = this.betrailUxService.url.pipe(
      map(url => {
        if (url?.startsWith('/race')) {
          this.selectedTrailAlias = url.split('/')[2];
        } else {
          this.selectedTrailAlias = undefined;
        }
        return url?.startsWith('/home');
      }),
    );
  }

  scrollToTop() {
    let elem = document.getElementById('home-title');
    elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  isNotLarge() {
    if (this.pps.isBrowserRendering && window.screen.width > 1280) {
      return false;
    } else {
      return true;
    }
  }

  toggleMenu(value?: boolean) {
    if (value != undefined) {
      this.open = value;
    } else {
      this.open = !this.open;
    }
    this.toggle.emit(this.open);
  }

  closeMenuIfSmall() {
    if (this.isNotLarge()) {
      this.toggleMenu();
    }
  }

  organizerSideMenuToggle() {
    if (this.organizerSideMenuStatus === 'open') {
      this.organizerCloseMenu();
    } else {
      this.organizerOpenMenu();
    }
  }

  organizerCloseMenu() {
    this.organizerSideMenuStatus = 'closed';
    this.toggleOrganizerMenu.emit(false);
  }

  organizerOpenMenu() {
    this.organizerSideMenuStatus = 'open';
    this.toggleOrganizerMenu.emit(true);
    this.closeMenuIfSmall();
  }

  organizerSetMenuToDefaultStatus() {
    this.organizerSideMenuStatus = 'default';
  }
}
