<ng-container [formGroup]="formGroup">
  <div class="race-form" fxLayout="row wrap" fxLayoutAlign="space-between">
    <input style="display: none" type="text" name="fakeusernamerememberedra" />
    <input style="display: none" type="text" name="fakeemailrememberedra" />
    <input style="display: none" type="password" name="fakepasswordrememberedra" />

    <mat-form-field class="title" appearance="fill" fxFlex="100" fxFlex.gt-sm="100%">
      <mat-icon class="suffixIcon" matSuffix>format_quote</mat-icon>
      <mat-label>{{ 'RACE_NAME' | transloco }} ({{ 'OPTIONAL' | transloco }})</mat-label>
      <input
        matInput
        #titleField
        placeholder="Ex.: Distance-découverte"
        formControlName="race_name"
        autocomplete="new-password"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="100">
      <!--*ngIf="several_days"-->
      <mat-label>{{ 'CHOOSE_A_DAY' | transloco }}</mat-label>
      <mat-select formControlName="date" required>
        <mat-option *ngFor="let date of availableDates" [value]="date.value">{{ date.displayValue }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div
      *ngIf="verified === true"
      class="warning-verified"
      fxFlex="100"
      innerHTML="{{ 'RACE_VERIFIED_WARNING' | transloco }}"
    ></div>

    <div
      *ngIf="(isAdmin$ | async) || (isEncoder$ | async) || (showOrgaOptions$ | async)"
      fxFlex="100"
      style="margin-bottom: 15px"
    >
      <mat-slide-toggle
        color="primary"
        fxLayout="row"
        fxLayoutAlign="end center"
        formControlName="canceled"
        fxFlex.lt-md="100"
      >
        {{ 'RACE_CANCELED' | transloco }}
      </mat-slide-toggle>
    </div>

    <ng-container *ngIf="!multiple; else oneLoopDistance">
      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>keyboard_tab</mat-icon>
        <mat-label>{{ 'DISTANCE' | transloco }} ({{ 'RACE_DISTANCE_UNIT' | transloco }})</mat-label>
        <input
          [disabled]="verified === true"
          #distanceField
          matInput
          type="number"
          step="1"
          placeholder="Ex.: 21"
          formControlName="distance"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="999"
          [min]="1"
          digitOnly
        />
      </mat-form-field>
    </ng-container>
    <ng-template #oneLoopDistance>
      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>keyboard_tab</mat-icon>
        <mat-label>{{ 'ONE_LOOP_DISTANCE_DECIMAL' | transloco }} ({{ 'RACE_DISTANCE_UNIT' | transloco }})</mat-label>
        <input
          [disabled]="verified === true"
          #distanceField
          matInput
          type="number"
          step="0.01"
          placeholder="Ex.: 21.52"
          formControlName="distance"
          pattern="[0-9]*(\.[0-9]{0,2})?"
          [max]="999"
          [min]="1"
        />
      </mat-form-field>
    </ng-template>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>show_chart</mat-icon>
      <mat-label
        >{{ (multiple ? 'ONE_LOOP_ELEVATION' : 'RACE_ELEVATION') | transloco }} ({{ 'RACE_ELEVATION_UNIT' | transloco
        }})</mat-label
      >
      <input
        matInput
        type="number"
        placeholder="Ex.: 510"
        formControlName="elevation"
        inputmode="numeric"
        step="10"
        pattern="[0-9]*"
        [min]="0"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>filter_1</mat-icon>
      <mat-label>{{ 'RACE_AVAILABLE_BIBS' | transloco }}</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 250"
        formControlName="max_runners"
        inputmode="numeric"
        pattern="[0-9]*"
        [max]="9999"
        [min]="1"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>fastfood</mat-icon>
      <mat-label>{{ (multiple ? 'ONE_LOOP_REFRESHMENTS' : 'RACE_REFRESHMENTS_EXCEPT_ARRIVAL') | transloco }}</mat-label>
      <input
        matInput
        type="number"
        placeholder="Ex. : 3"
        formControlName="refreshments"
        inputmode="numeric"
        pattern="[0-9]*"
        [max]="99"
        [min]="0"
        digitOnly
      />
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>access_time</mat-icon>
      <mat-label>{{ 'RACE_DEPARTURE_TIME' | transloco }} ({{ 'RACE_DEPARTURE_TIME_UNIT' | transloco }})</mat-label>
      <input
        matInput
        [ngxTimepicker]="picker"
        placeholder="Ex.: 8:30"
        formControlName="departure_time"
        [format]="24"
        enableKeyboardInput="true"
        minutesGap="5"
        readonly
      />
      <ngx-material-timepicker #picker [theme]="darkTheme"></ngx-material-timepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
      <mat-icon class="suffixIcon" matSuffix>euro_symbol</mat-icon>
      <mat-label>{{ 'RACE_PRICE_MIN' | transloco }}</mat-label>
      <input matInput type="number" step="1.00" placeholder="Ex.: 7.60" formControlName="price" />
    </mat-form-field>

    <mat-slide-toggle
      color="primary"
      fxLayout="row"
      fxLayoutAlign="start center"
      formControlName="night"
      fxFlex.lt-md="100"
    >
      {{ 'RACE_NIGHT' | transloco }}
    </mat-slide-toggle>

    <mat-divider *ngIf="multiple" class="w-full"></mat-divider>

    <div *ngIf="multiple" fxFlex="100" class="mt-2 pb-2">
      <mat-form-field class="title" appearance="fill" fxFlex="100" fxFlex.gt-sm="100%">
        <mat-icon class="suffixIcon" matSuffix>numbers</mat-icon>
        <mat-label>{{ 'LAPS_TO_GENERATE' | transloco }}</mat-label>
        <input
          matInput
          placeholder="ex: 1,2,3,4,6,7,8,12,16,17,18,21,24,25,36"
          formControlName="laps"
          pattern="[0-9]+(,[0-9]+)+"
        />
        <mat-hint>( = {{ 'CREATE_MULTIPLE_LOOPS_HINT' | transloco }})</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="race-form" fxLayout="row wrap" fxLayoutAlign="space-between">
    <div class="subtitle" (click)="toggle()" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
      <strong>{{ 'MORE_DETAILS' | transloco }}</strong>
      <button mat-mini-fab class="more-toggle" color="primary">
        <mat-icon *ngIf="toggleMore === false">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="toggleMore === true">keyboard_arrow_up</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="toggleMore === true">
      <div fxFlex="100">
        <mat-slide-toggle
          color="primary"
          *isEncoder
          fxLayout="row"
          fxLayoutAlign="start center"
          formControlName="trailcup_only"
          fxFlex.lt-md="100"
        >
          {{ 'RACE_TRAILCUP_ONLY' | transloco }}
        </mat-slide-toggle>
      </div>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>nature_people</mat-icon>
        <mat-label>{{ 'RACE_OFFROAD_ESTIMATED' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex.: 76"
          formControlName="offroad"
          inputmode="numeric"
          pattern="[0-9]*"
          max="100"
          min="0"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>nature_people</mat-icon>
        <mat-label>{{ 'RACE_TRAIL_ESTIMATED' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex.: 21"
          formControlName="trail"
          inputmode="numeric"
          pattern="[0-9]*"
          max="100"
          min="0"
        />
      </mat-form-field>

      <div fxFlex="100">
        <mat-slide-toggle
          *ngIf="(isAdmin$ | async) || (isEncoder$ | async)"
          color="primary"
          fxLayout="row"
          fxLayoutAlign="start center"
          formControlName="offroad_certified"
          fxFlex.lt-md="100"
        >
          {{ 'RACE_OFFROAD_CERTIFIED' | transloco }}
        </mat-slide-toggle>
      </div>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>vertical_align_bottom</mat-icon>
        <mat-label>{{ 'RACE_MIN_ALT' | transloco }} ({{ 'RACE_ALT_UNIT' | transloco }})</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex. : 800"
          formControlName="altitude_min"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="99999"
          [min]="0"
          digitOnly
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>vertical_align_top</mat-icon>
        <mat-label>{{ 'RACE_MAX_ALT' | transloco }} ({{ 'RACE_ALT_UNIT' | transloco }})</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex. : 1650"
          formControlName="altitude_max"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="99999"
          [min]="0"
          digitOnly
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>landscape</mat-icon>
        <mat-label>{{ 'RACE_UTMB_POINTS' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="Ex. : 2"
          formControlName="utmb_points"
          inputmode="numeric"
          pattern="[0-9]*"
          [max]="99"
          [min]="0"
          digitOnly
        />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>euro_symbol</mat-icon>
        <mat-label>{{ 'RACE_PRICE_MAX' | transloco }}</mat-label>
        <input matInput type="number" step="1.00" placeholder="Ex.: 9.00" formControlName="onsite_price" />
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>360</mat-icon>
        <mat-label>{{ 'RACE_RACE_TYPE' | transloco }}</mat-label>
        <mat-select formControlName="race_type" [disabled]="multiple">
          <mat-option value="solo">{{ 'RACE_TYPE_solo' | transloco }}</mat-option>
          <mat-option value="team_2">{{ 'RACE_TYPE_team_2' | transloco }}</mat-option>
          <mat-option value="team_3">{{ 'RACE_TYPE_team_3' | transloco }}</mat-option>
          <mat-option value="team_4">{{ 'RACE_TYPE_team_4' | transloco }}</mat-option>
          <mat-option value="team_5">{{ 'RACE_TYPE_team_5' | transloco }}</mat-option>
          <mat-option value="relay">{{ 'RACE_TYPE_relay' | transloco }}</mat-option>
          <mat-option value="multistage">{{ 'RACE_TYPE_multistage' | transloco }}</mat-option>
          <mat-option value="multistage_duo">{{ 'RACE_TYPE_multistage_duo' | transloco }}</mat-option>
          <mat-option value="multistage_team">{{ 'RACE_TYPE_multistage_team' | transloco }}</mat-option>
          <mat-option value="clock">{{ 'RACE_TYPE_clock' | transloco }}</mat-option>
          <mat-option value="stop">{{ 'RACE_TYPE_stop' | transloco }}</mat-option>
          <mat-option value="walk">{{ 'RACE_TYPE_walk' | transloco }}</mat-option>
          <mat-option value="nordic_walking">{{ 'RACE_TYPE_nordic_walking' | transloco }}</mat-option>
          <mat-option value="canicross">{{ 'RACE_TYPE_canicross' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>360</mat-icon>
        <mat-label>{{ 'RACE_ROUTE_TYPE' | transloco }}</mat-label>
        <mat-select [(value)]="selectedLoopType" [disabled]="multiple">
          <mat-option [value]="0" (click)="handleNbOfLoops(0)">{{ 'RACE_ROUTE_TYPE_0' | transloco }}</mat-option>
          <mat-option [value]="1" (click)="handleNbOfLoops(1)">{{ 'RACE_ROUTE_TYPE_1' | transloco }}</mat-option>
          <mat-option [value]="2" (click)="handleNbOfLoops(2)">{{ 'RACE_ROUTE_TYPE_LASTMAN' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>timer</mat-icon>
        <mat-label>{{ 'RACE_TIMING_TYPE' | transloco }}</mat-label>
        <mat-select formControlName="timing_type">
          <mat-option value="non">{{ 'RACE_TIMING_NONE' | transloco }}</mat-option>
          <mat-option value="puces">{{ 'RACE_TIMING_CHIPS' | transloco }}</mat-option>
          <mat-option value="manuel">{{ 'RACE_TIMING_MANUAL' | transloco }}</mat-option>
          <mat-option value="suivi">{{ 'RACE_TIMING_TRACKING' | transloco }}</mat-option>
          <mat-option value="suivi_gps">{{ 'RACE_TIMING_TRACKING_GPS' | transloco }}</mat-option>
          <mat-option value="no_result">{{ 'RACE_TIMING_NO_RESULT' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="showNumberOfLoop" appearance="fill" fxFlex="100" fxFlex.gt-sm="47.5">
        <mat-icon class="suffixIcon" matSuffix>loop</mat-icon>
        <mat-label>{{ 'NUMBER_OF_LOOP' | transloco }}</mat-label>
        <input matInput type="number" step="1" formControlName="route_type" />
      </mat-form-field>

      <div fxFlex="100">
        <mat-form-field class="title" appearance="fill" fxFlex="100" fxFlex.gt-sm="100%">
          <mat-icon class="suffixIcon" matSuffix svgIcon="results"></mat-icon>
          <mat-label>{{ 'RACE_OFFICIAL_RESULTS_LINK' | transloco }}</mat-label>
          <input
            matInput
            #titleField
            placeholder="https://..."
            formControlName="official_ranking_url"
            autocomplete="new-password"
          />
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-sm="100%">
        <mat-label>{{ 'COMMENT' | transloco }}</mat-label>
        <textarea
          matInput
          #comment
          placeholder="..."
          formControlName="comment"
          [maxlength]="commentMaxLength"
        ></textarea>
        <mat-hint align="start">{{ 'N_CHARACTER_MAX' | transloco : { n: commentMaxLength } }}</mat-hint>
        <mat-hint align="end">{{ comment.value.length }} / {{ commentMaxLength }}</mat-hint>
      </mat-form-field>

      <div fxFlex="100" class="clear">&nbsp;</div>

      <!--<ng-container *isEncoder>
        <mat-divider></mat-divider>
        <div class="subtitle unclickable width-100">
          <strong>{{ 'RACE_PATH' | transloco }}</strong>
          <gpx-upload (gpxUploaded)="addPathId($event)" [raid]="formGroup.value.id"></gpx-upload>
        </div>
      </ng-container>
    </ng-container> -->
    </ng-container>
  </div>
</ng-container>
