import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { BlogModel } from './blog.model';
import { tap, first } from 'rxjs/operators';
import { LoadArticles, LoadArticle } from './blog.action';
import { EventService } from '@betrail-libs/trail-data-state';
import { Injectable } from '@angular/core';
import produce from 'immer';

@State<BlogModel>({
  name: 'blog',
  defaults: {
    articles: [],
    selectedArticle: undefined,
    currentOffset: 0,
  },
})
@Injectable()
export class BlogState {
  constructor(private store: Store, private eventService: EventService) {}

  @Selector()
  static articles(state: BlogModel): any[] {
    return Object.keys(state.articles)
      .map(key => state.articles[key])
      .sort((a, b) => b.created - a.created);
  }

  @Selector()
  static article(state: BlogModel) {
    return articleId => {
      return state.articles[articleId];
    };
  }

  @Selector()
  static selectedArticle(state: BlogModel) {
    if (state.selectedArticle) {
      return state.articles[state.selectedArticle];
    }
  }

  @Action(LoadArticles)
  loadArticles(ctx: StateContext<BlogModel>, action: LoadArticles) {
    return this.eventService.getArticles({ ...action, offset: ctx.getState().currentOffset }).pipe(
      first(),
      tap(articles => {
        ctx.setState(
          produce(draft => {
            //Object.keys(articles).map(key => draft.articles[key] = articles[key]);
            draft.articles = { ...draft.articles, ...articles };
            draft.currentOffset += action.length;
          }),
        );
      }),
    );
  }

  @Action(LoadArticle)
  loadArticle(ctx: StateContext<BlogModel>, action: LoadArticle) {
    if (ctx.getState().articles[action.id] == undefined) {
      return this.eventService.getArticle(action.id).pipe(
        first(),
        tap(article => {
          ctx.setState(
            produce(draft => {
              draft.articles[action.id] = article;
              draft.selectedArticle = action.id;
            }),
          );
        }),
      );
    } else {
      ctx.setState(
        produce(draft => {
          draft.selectedArticle = action.id;
        }),
      );
      return;
    }
  }
}
