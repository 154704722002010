import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { IFormItem } from '@betrail-libs/shared/interfaces/form-item.model';
import { ALL_COUNTRIES_CODES } from '@betrail-libs/shared/utils';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'bt-runner-form',
  templateUrl: 'runner-form.component.html',
  styleUrls: ['runner-form.component.scss'],
})
export class RunnerFormComponent implements OnChanges {
  confirm = false;
  confirmText: string;

  states = [
    { Name: 'Belgium', Code: 'BE' },
    { Name: 'France', Code: 'FR' },
    { Name: 'Germany', Code: 'DE' },
    { Name: 'Luxembourg', Code: 'LU' },
    { Name: 'Netherlands', Code: 'NL' },
  ];

  countries = ALL_COUNTRIES_CODES;

  @Input() type: 'runner' | 'orga' | 'furtherModification' = 'runner';
  @Input() public formGroup: UntypedFormGroup;
  @Input() formFields;
  @Input() distances;
  @Input() sqlDistances;
  @Input() registerType: string;
  @Input() legalUrl: string;
  @Input() trailTitle: string;

  @Output() registerTypeChanged = new EventEmitter<string>();
  @Output() waitingListRaceAlias = new EventEmitter<string>();

  constructor(private translate: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sqlDistances || changes.distances) {
      if (this.distances && this.sqlDistances) {
        this.distances = this.distances
          .map(d => {
            const sqlDistance = this.sqlDistances.find(r => +r.id === +d.id);
            if (sqlDistance) {
              let fullName = '';
              const fullNameArray = [];
              if (sqlDistance.distance > 0) {
                fullName += sqlDistance.distance + 'km';
                fullNameArray.push(sqlDistance.distance + 'km');
              }
              if (sqlDistance.race_type && sqlDistance.race_type != 'solo') {
                fullName += ' ' + this.translate.translate('RACE_TYPE_' + sqlDistance.race_type);
                fullNameArray.push('RACE_TYPE_' + sqlDistance.race_type);
              }
              if (sqlDistance.timing_type && sqlDistance.timing_type == 'non') {
                fullName += ' ' + this.translate.translate('RACE_NO_TIMING');
                fullNameArray.push('RACE_NO_TIMING');
              }
              if (sqlDistance.night && +sqlDistance.night == 1) {
                fullName += ' ' + this.translate.translate('RACE_NIGHT');
                fullNameArray.push('RACE_NIGHT');
              }
              return { ...d, fullName, fullNameArray, alias: sqlDistance.alias };
            }
            return { ...d };
          })
          .filter(d => d && d.fullName && d.fullNameArray);
      }
    }
  }

  registerTypeChange(event) {
    this.registerTypeChanged.emit(event.value);
  }

  goToWaitingList(event: MatOptionSelectionChange, waitingListAllowed: boolean, alias: string) {
    if (event.isUserInput && waitingListAllowed) {
      this.waitingListRaceAlias.emit(alias);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class RunnerFormService {
  constructor(private fb: UntypedFormBuilder) {}

  addRunnerFormControls(runnerForm: UntypedFormGroup, formItems: IFormItem[]) {
    if (formItems) {
      for (let i = 0, len = formItems.length; i < len; i++) {
        const formItem = formItems[i];

        const newControl = this.fb.control('', formItem.fieldConfig.required ? [Validators.required] : []);

        runnerForm.addControl(formItem.name, newControl);
      }
    }
    runnerForm.addControl('messageToOrganizer', this.fb.control(''));
  }

  initBaseRunnerForm(initialValues: any = null) {
    const runnerForm = this.fb.group({
      distance: ['', Validators.required],
    });

    // if (formItems) {
    //   for (let i = 0, len = formItems.length; i < len; i++) {
    //     const formItem = formItems[i];

    //     let newControl = this.fb.control(
    //       (initialValues && initialValues[formItem.name] ? '' : formItem.fieldConfig.defaultValue),
    //       formItem.fieldConfig.required ? [Validators.required] : []
    //       //(extraFields && extraFields[field.name]) || field.defaultValue,
    //       //field.required ? [Validators.required] : []
    //     )

    //     runnerForm.addControl(formItem.name, newControl)
    //   }

    runnerForm.addControl('swornStatement', this.fb.control(false, [Validators.requiredTrue]));
    runnerForm.addControl('allowMailContact', this.fb.control(false, [Validators.requiredTrue]));
    runnerForm.addControl('confirmLegal', this.fb.control(false, [Validators.requiredTrue]));

    // }

    return runnerForm;
  }

  createRunnerFormGroup(registration, extraFieldDefnition) {
    const formGroup = this.fb.group({
      status: status,
      distance: [registration?.distance || '', Validators.required],
      mail: [registration?.mail || '', Validators.required],
    });

    if (extraFieldDefnition) {
      extraFieldDefnition.forEach(field => {
        formGroup.addControl(
          field.name,
          this.fb.control(
            registration && (registration[field.name] !== null || registration[field.name] !== undefined)
              ? registration[field.name]
              : field.defaultValue,
            field.required ? [Validators.required] : [],
          ),
        );
      });
    }

    formGroup.addControl('messageToOrganizer', this.fb.control(registration.messageToOrganizer || ''));

    return formGroup;
  }
}

@Pipe({
  name: 'formFieldSort',
  pure: false,
})
export class FormFieldSortPipe implements PipeTransform {
  transform(formItems: IFormItem[]): IFormItem[] {
    if (formItems) {
      return formItems.sort((a, b) => {
        return a.order - b.order;
      });
    }
    return formItems;
  }
}
