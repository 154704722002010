<div class="search-container">
  <input
    class="searchBar"
    #searchElem
    [matAutocomplete]="auto"
    (click)="$any($event).target.setSelectionRange(0, $any($event).target.value.length)"
    (keyup)="onSearchChange($any($event).target.value)"
    class="search bt-rounded"
    placeholder="{{ placeholder | transloco }}"
    [class.open]="isOpen"
    spellcheck="false"
  />

  <div id="searchPanel">
    <mat-autocomplete
      (optionSelected)="onSearchSelect($event.option.value)"
      #auto="matAutocomplete"
      connectedTo="#searchElem"
      autoActiveFirstOption="true"
      (opened)="setIsOpenParam(true)"
      (closed)="setIsOpenParam(false)"
    >
      <div id="close">
        <button mat-icon-button (click)="searchClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="searchAutocomplete | async; let auto; else noResult">
        <ng-container *ngIf="auto && auto.length > 0; else noResult">
          <mat-optgroup label="{{ 'PLACES' | transloco }}">
            <ng-container *ngFor="let place of auto">
              <mat-option
                *ngIf="
                  !availableCountries ||
                  availableCountries?.length == 0 ||
                  availableCountries?.indexOf(place?.pays) > -1
                "
                [value]="place"
              >
                <span class="place">
                  <place
                    class="eventPlace"
                    [place]="place?.localite"
                    [country]="place?.pays"
                    [postal]="place?.cp"
                    [section]="place?.departement || place?.province"
                  ></place>
                </span> </mat-option
            ></ng-container>
          </mat-optgroup>
        </ng-container>
      </ng-container>
      <ng-template #noResult>
        <ng-container *ngIf="isOpen == true">
          <mat-optgroup label="{{ 'HOME_NORESULT' | transloco }} {{ searchValue | async }}">
            <mat-option> {{ 'SEARCH_RETRY' | transloco }} </mat-option>
          </mat-optgroup>
        </ng-container>
      </ng-template>
    </mat-autocomplete>
  </div>
</div>
