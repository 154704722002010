<form
  (submit)="submit()"
  [formGroup]="formGroup"
  class="stepContainer"
  fxLayout="column"
  fxLayoutAlign="centeredAligned stretch"
>
  <h2 class="centeredAligned">{{ 'WS_COMPLETE_YOUR_IDENTITY' | transloco }}</h2>
  <div class="message centeredAligned">
    <ng-container *ngIf="isLightUser; else fullAccountText"> {{ 'LIGHT_USER_IDENTITY_MSG' | transloco }} </ng-container>
    <ng-template #fullAccountText> {{ 'WS_IDENTITY_EXPLANATION' | transloco }} </ng-template>
  </div>
  <mat-form-field appearance="fill" color="secondary" fxFlex="100" *ngIf="!_runner?.firstname">
    <mat-label>{{ 'RUNNER_FIRSTNAME' | transloco }}</mat-label>
    <mat-icon class="suffixIcon" matSuffix>user</mat-icon>
    <input matInput type="text" placeholder="Firstname" formControlName="firstname" />
  </mat-form-field>

  <mat-form-field appearance="fill" color="secondary" fxFlex="100" *ngIf="!_runner?.lastname">
    <mat-label>{{ 'RUNNER_LASTNAME' | transloco }}</mat-label>
    <mat-icon class="suffixIcon" matSuffix>user</mat-icon>
    <input matInput type="text" placeholder="Lastname" formControlName="lastname" />
  </mat-form-field>

  <mat-form-field appearance="fill" color="secondary" *ngIf="_runner?.gender == undefined">
    <mat-icon class="suffixIcon" matSuffix>wc</mat-icon>
    <mat-label>{{ 'RUNNER_GENDER' | transloco }}</mat-label>
    <mat-select formControlName="gender">
      <mat-option value="0">{{ 'RUNNER_MALE' | transloco }}</mat-option>
      <mat-option value="1">{{ 'RUNNER_FEMALE' | transloco }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" color="secondary" *ngIf="!_runner?.nationality">
    <mat-icon class="suffixIcon" matSuffix>flag</mat-icon>
    <mat-label>{{ 'RUNNER_NATIONALITY' | transloco }}</mat-label>
    <mat-select formControlName="nationality">
      <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
        >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <div class="flex justify-center items-center mt-4 mb-6">
    <mat-label class="mr-6">Genre *</mat-label>
    <mat-radio-group formControlName="gender" required>
      <mat-radio-button [value]="0" class="mr-4">Homme</mat-radio-button>
      <mat-radio-button [value]="1">Femme</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="!validBirthdate">
    <mat-label>{{ 'RUNNER_BIRTHDATE' | transloco }}*</mat-label>
    <div class="mt-5">
      <birthdate-select formControlName="birthdate"></birthdate-select>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-between stretch">
    <mat-form-field appearance="fill" color="secondary" fxFlex="100" fxFlex.gt-sm="48" *ngIf="!_runner?.postal_code">
      <mat-icon class="suffixIcon" matSuffix>place</mat-icon>
      <mat-label>{{ 'RUNNER_POSTAL_CODE' | transloco }}</mat-label>
      <input matInput type="text" placeholder="Code Postal" formControlName="postal" />
    </mat-form-field>
    <mat-form-field appearance="fill" color="secondary" fxFlex="100" fxFlex.gt-sm="48" *ngIf="!_runner?.place">
      <mat-label>{{ 'RUNNER_PLACE' | transloco }}</mat-label>
      <mat-icon class="suffixIcon" matSuffix>place</mat-icon>
      <input matInput type="text" placeholder="Localité" formControlName="place" />
    </mat-form-field>
  </div>
  <mat-form-field appearance="fill" color="secondary" *ngIf="!_runner?.country">
    <mat-label>{{ 'RUNNER_COUNTRY' | transloco }}</mat-label>
    <mat-icon class="suffixIcon" matSuffix>place</mat-icon>
    <mat-select formControlName="country">
      <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
        >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <div class="buttonContainer">
    <button
      [disabled]="!formGroup.valid"
      type="submit"
      class="bt-button blue"
      [disabled]="saveButtonDisabled"
      mat-raised-button
    >
      {{ saveText | transloco }}
    </button>
  </div>
</form>
