<div class="content">
  <header class="form-title">{{ 'EDIT_REGISTRATION_DATA' | transloco }}</header>

  <div class="form-content overflow-y-scroll max-h-[80vh]">
    <bt-runner-form
      [type]="data.type"
      trailTitle="data.id || data._id"
      [distances]="data.routes"
      [sqlDistances]="data.sqlRaces"
      [formFields]="data.formFields"
      [formGroup]="data.runnerForm"
    ></bt-runner-form>
    <div class="actions-container" fxLayout="row" fxLayoutAlign="space-between">
      <button class="button" mat-button (click)="close()">{{ 'CANCEL' | transloco }}</button>
      <button class="button color" mat-raised-button (click)="save(data.runnerForm)">{{ 'SAVE' | transloco }}</button>
    </div>
  </div>
</div>
