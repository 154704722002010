import { Component, Input, ViewChild, Output, EventEmitter, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'search-place',
  templateUrl: './search-place.component.html',
  styleUrls: ['./search-place.component.scss'],
})
export class SearchPlaceComponent implements AfterViewInit, AfterViewChecked {
  @Input() placeholder = 'PLACE_OR_ZIPCODE';
  @Input() discreet: boolean = false;
  @Input() availableCountries: string[] = [];
  @Input() defaultText: string;

  @ViewChild('searchElem', { read: MatAutocompleteTrigger, static: true }) searchElem: MatAutocompleteTrigger;
  @ViewChild('searchElem', { static: true }) searchNativeElem;

  @Output() placeSelected = new EventEmitter();

  setFocus: boolean = true;
  searchStatus: boolean = false;
  searchValue = new Subject<any>();
  searchAutocomplete = this.searchValue.pipe(
    debounceTime(120),
    switchMap(value => (value ? this.eventService.searchPlaces(value) : of({}))),
  );

  currentSearchValue: any;

  defaultCountry: string;
  justSelected = false;
  isOpen = false;

  constructor(private eventService: EventService) {}

  ngAfterViewInit(): void {
    if (this.defaultText) {
      this.searchNativeElem.nativeElement.value = this.defaultText;
    }
  }

  ngAfterViewChecked(): void {
    if (this.searchNativeElem && this.searchNativeElem.nativeElement) {
      if (this.setFocus === true) {
        this.searchNativeElem.nativeElement.focus();
        if (this.defaultText) {
          this.searchNativeElem.nativeElement.setSelectionRange(0, this.searchNativeElem.nativeElement.value.length);
        }
      }
    }
  }

  onSearchChange(value) {
    this.setFocus = false;
    if (value != this.currentSearchValue) {
      this.searchValue.next(value.trim());
      this.currentSearchValue = value.trim();
    }
    if (value != '' && this.justSelected == false) {
      this.searchElem.openPanel();
      this.isOpen = true;
    } else {
      this.searchElem.closePanel();
      this.isOpen = false;
      if (this.justSelected == true) {
        this.justSelected = false;
      }
    }
  }

  onSearchSelect(value) {
    this.setFocus = false;
    this.searchStatus = false;
    this.justSelected = true;
    if (value.geolocation) {
      this.placeSelected.emit(value);
      this.searchNativeElem.nativeElement.value = value.cp + ' ' + value.localite;
    }
    this.searchClose();
  }

  toggleSearch() {
    this.searchNativeElem.nativeElement.focus();
    this.searchStatus = !this.searchStatus;
    if (this.searchStatus == true) {
      this.searchNativeElem.nativeElement.setSelectionRange(0, this.searchNativeElem.nativeElement.value.length);
      this.searchElem.openPanel();
    }
  }

  searchClose() {
    this.searchElem.closePanel();
    this.searchStatus = false;
  }

  setIsOpenParam(val) {
    this.isOpen = val;
  }
}
