<header class="form-title">{{ 'DELETE_OTHER_RESULT' | transloco }}</header>

<section>
  <p>{{ 'CONFIRM_DELETE_OTHER_RESULT' | transloco }}</p>

  <div class="buttonContainer">
    <button type="submit" class="button" id="cancelBtn" mat-raised-button (click)="closeDialog()">
      {{ 'CANCEL' | transloco }}
    </button>
    <button type="submit" class="button" id="deleteBtn" mat-raised-button (click)="deleteOtherResult()">
      {{ 'DELETE' | transloco }}
    </button>
  </div>
</section>
