<top-bar
  [user]="user$ | async"
  [runner]="runner$ | async"
  (logout)="logout()"
  (login)="login()"
  (createAccount)="createAccount()"
  (toggle)="toggleLeftBar($event)"
  [isLoading]="loading"
></top-bar>
<left-bar
  [user]="user$ | async"
  (toggle)="toggleLeftBar($event)"
  (toggleOrganizerMenu)="toggleOrganizerLeftBar($event)"
  [open]="leftBarStatus"
></left-bar>
<div
  class="content"
  ngClass.gt-md="leftBarPush large"
  [class.leftBarPush]="isVeryLarge() ? true : false"
  ngClass.lt-lg="leftBarCover"
  ngClass.lt-sm="small"
  ngClass.lt-md="smallBar"
  [class.closedLeftBar]="leftBarStatus === false"
  [class.openedLeftBar]="leftBarStatus === true"
  [class.openedOrganizerLeftBar]="organizerLeftBarStatus === true"
  [class.closedOrganizerLeftBar]="organizerLeftBarStatus === false"
>
  <div>
    <div class="global-page-container">
      <app-ad-my-sports-banner
        [hide]="hideCampaignBanner || (bannerHidden$ | async)"
        [url]="url$ | async"
        (closeBanner)="hideCampaignBanner = true"
      ></app-ad-my-sports-banner>
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
  <ng-container *ngIf="url$ | async; let url">
    <bux-countdown-btn
      *ngIf="!url.includes('calendrier') && !url.includes('calendar') && !url.includes('news-feed')"
      [user]="user$ | async"
    ></bux-countdown-btn>
  </ng-container>

  <bux-footer [userLang]="userLang"></bux-footer>
  <user-params-manager *browserOnly></user-params-manager>
</div>
