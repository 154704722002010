import { OTHER_COUNTRIES } from '@betrail-libs/shared/utils';
export class QuickFilters {
  public dateQuickFilters = ['FILTER_DATE_BEFORE_2013', 'FILTER_DATE_LAST_YEAR', 'FILTER_DATE_LAST_3_YEARS'];

  public countryQuickFilters = [
    /* {
      values: ALL_CALENDAR_COUNTRIES,
      title: 'COUNTRIES_ALL'
    }, */
    {
      values: ['BE'],
      title: 'COUNTRY_BE',
    },
    {
      values: ['FR'],
      title: 'COUNTRY_FR',
    },
    {
      values: ['NL'],
      title: 'COUNTRY_NL',
    },
    {
      values: ['LU'],
      title: 'COUNTRY_LU',
    },
    {
      values: ['BE', 'NL', 'LU'],
      title: 'COUNTRY_BENELUX',
    },
    {
      values: OTHER_COUNTRIES,
      title: 'COUNTRIES_OTHERS',
    },
  ];

  public countrySelect = ['FR', 'BE', 'NL', 'LU'];

  public runTypesSelect = ['betrail_result', 'perso_result_trail', 'perso_result_wild', 'perso_result_road'];

  public runTypeQuickFilters = [
    {
      values: ['betrail_result'],
      title: 'betrail_result',
    },
    {
      values: ['betrail_result', 'perso_result_trail'],
      title: 'betrail_and_trail',
    },
    {
      values: ['perso_result_trail', 'perso_result_wild', 'perso_result_road'],
      title: 'other_result',
    },
  ];

  public categorySelect = [
    'FILTER_TRAIL_STT',
    'FILTER_TRAIL_S',
    'FILTER_TRAIL_L',
    'FILTER_TRAIL_ULTRA_S',
    'FILTER_TRAIL_ULTRA_XL',
    'LABEL_BETRAIL',
  ];

  public categoryQuickFilters = [
    {
      values: ['FILTER_TRAIL_S', 'FILTER_TRAIL_L'],
      title: 'FILTER_CHALLENGE_SHORT',
    },
    {
      values: ['ULTRA_S', 'ULTRA_XL'],
      title: 'FILTER_CHALLENGE_ULTRA',
    },
  ];

  public raceTypesSelect = [
    {
      values: ['solo', 'solo'],
      title: 'solo',
    },
    {
      values: ['duo', 'team_2'],
      title: 'duo',
    },
    {
      values: ['team_3', 'team_3'],
      title: 'team_3',
    },
    {
      values: ['team_4', 'team_5'],
      title: 'TEAM_4_AND_MORE',
    },
    {
      values: ['relay', 'relay'],
      title: 'relay',
    },
    {
      values: ['multistage', 'multistage'],
      title: 'multistage',
    },
    {
      values: ['multistage_duo', 'multistage_duo'],
      title: 'multistage_duo',
    },
    {
      values: ['multistage_team', 'multistage_team'],
      title: 'multistage_team',
    },
    {
      values: ['clock', 'clock'],
      title: 'clock',
    },
    {
      values: ['stop', 'stop'],
      title: 'stop',
    },
    {
      values: ['nordic_walking', 'nordic_walking'],
      title: 'nordic_walking',
    },
    {
      values: ['canicross', 'canicross'],
      title: 'canicross',
    },
  ];

  public raceTypeQuickFilters = [
    {
      values: ['solo', 'multistage'],
      title: 'solo',
    },
    {
      values: ['duo', 'team_2', 'team_3', 'team_4', 'team_5', 'multistage_duo', 'multistage_team'],
      title: 'team',
    },
    {
      values: ['multistage', 'multistage_duo', 'multistage_team'],
      title: 'multistage',
    },
    {
      values: ['relay'],
      title: 'relay',
    },
    {
      values: ['clock'],
      title: 'clock',
    },
    {
      values: [
        'duo',
        'team_2',
        'team_3',
        'team_4',
        'team_5',
        'multistage',
        'multistage_duo',
        'multistage_team',
        'relay',
        'clock',
        'stop',
        'walk',
        'nordic_walking',
        'canicross',
      ],
      title: 'ALL_BUT_SOLO',
    },
  ];

  public distanceQuickFilters = [
    {
      values: [0, 10],
      title: 'KV (-10k)',
    },
    {
      values: [7, 15],
      title: 'Short (7-15k)',
    },
    {
      values: [14, 31],
      title: 'Semi (14-31k)',
    },
    {
      values: [28, 63],
      title: 'Maratrail (28-63k)',
    },
    {
      values: [54, 120],
      title: '50 Miles (54-120k)',
    },
    {
      values: [107, 240],
      title: '100 Miles (107-240k)',
    },
    {
      values: [214, 500],
      title: '200 Miles (214k+)',
    },
  ];

  public elevationQuickFilters = [
    {
      values: [0.1, 20],
      title: 'FILTER_FLAT',
    },
    {
      values: [20, 35],
      title: 'FILTER_NORMAL',
    },
    {
      values: [35, 60],
      title: 'FILTER_HARD',
    },
    {
      values: [60, 120],
      title: 'FILTER_VERY_HARD',
    },
    {
      values: [120, 500],
      title: 'FILTER_KV',
    },
  ];

  public dropQuickFilters = [
    {
      values: [1, 500],
      title: '- 500',
    },
    {
      values: [500, 1000],
      title: '500 - 1000',
    },
    {
      values: [1000, 2500],
      title: '1000 - 2500',
    },
    {
      values: [2500, 5000],
      title: '2500 - 5000',
    },
    {
      values: [5000, 10000],
      title: '5000 - 10000',
    },
    {
      values: [10000, 99999],
      title: '+ 10000',
    },
  ];
}
