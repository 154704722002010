import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Trail } from '@betrail-libs/shared/interfaces/interfaces';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'create-orga-account',
  templateUrl: './create-orga-account.component.html',
  styleUrls: ['./create-orga-account.component.scss', '../../common.scss'],
})
export class CreateOrgaAccountComponent {
  /** Members **/

  organizationForm: UntypedFormGroup;
  submitted = false;
  passwordToggleText = 'SHOW_PASSWORD';
  passwordToggleIcon = 'visibility_off';
  trail: Trail;

  get form() {
    return this.organizationForm.controls;
  }

  /** Inputs **/

  /** Outputs **/

  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToLoginFormClicked: EventEmitter<any> = new EventEmitter<any>();

  /** Constructor **/

  constructor(private formBuilder: UntypedFormBuilder) {
    this.organizationForm = this.formBuilder.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: [''],
        cgu: [false, Validators.requiredTrue],
        charte: [false, Validators.requiredTrue],
      },
      {
        validators: MustMatch('password', 'confirmPassword'),
      },
    );
  }

  /** Page events **/

  /** Click events **/

  displayPasswordToggle() {}

  cancel() {
    this.cancelled.emit();
  }

  validate() {
    this.submitted = true;
    this.saved.emit(this.buildOrgaAccountCredentials());
  }

  /** Private methods **/

  buildOrgaAccountCredentials() {
    const credentials = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      language: '',
      cgu: false,
      charte: false,
    };

    credentials.firstname = this.getFormValue('firstname');
    credentials.lastname = this.getFormValue('lastname');
    credentials.email = this.getFormValue('email');
    credentials.password = this.getFormValue('password');
    credentials.language = 'fr';
    credentials.cgu = this.getFormValue('cgu');
    credentials.charte = this.getFormValue('charte');

    return credentials;
  }

  getFormValue(name: string): any {
    let value = '';

    if (this.organizationForm.get(name)) {
      value = this.organizationForm.get(name).value;
    }

    return value;
  }

  goToLoginForm() {
    this.goToLoginFormClicked.emit();
  }
}
