import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IEvent } from '@betrail-libs/shared/interfaces/event.model';
import { Trail } from '@betrail-libs/shared/interfaces/interfaces';
import { isAdmin, userIsOrganizerOfThisAlias } from '@betrail-libs/shared/utils';
import { TrailDataService } from '@betrail-libs/trail-data-state';
import { first } from 'rxjs';

@Component({
  selector: 'left-bar-trail-menu',
  templateUrl: './left-bar-trail-menu.component.html',
  styleUrls: ['./left-bar-trail-menu.component.scss'],
})
export class LeftBarTrailMenuComponent implements OnInit {
  _trail: Trail;
  _selectedEvent: IEvent;
  _type: string;

  @Input('trail') set trail(trail: Trail) {
    if (trail && trail.alias) {
      if ((this.user && userIsOrganizerOfThisAlias(this.user, trail.alias)) || isAdmin(this.user)) {
        this._trail = { ...trail };
        this._type = 'trail';
        this.level = 1;
      }
    } else {
      delete this._trail;
      this._type = 'orga';
      this.level = 0;
    }
  }

  @Input('selectedEvent') set selectedEvent(selectedEvent: IEvent) {
    if (selectedEvent && selectedEvent.trail && selectedEvent.trail.alias) {
      if (this.user && userIsOrganizerOfThisAlias(this.user, selectedEvent.trail.alias)) {
        this._selectedEvent = { ...selectedEvent };
        this._type = 'event';
        this.level = 2;
      }
    } else {
      delete this._selectedEvent;
      this._type = 'orga';
      this.level = 0;
    }
  }
  @Input() user;

  level: number = 0;
  now = new Date().getTime();

  @Output() closePanelEvent = new EventEmitter();

  @Output() setPanelToDefaultStatusEvent = new EventEmitter();

  constructor(private trailData: TrailDataService) {}

  closePanel() {
    this.closePanelEvent.emit(true);
  }

  goToMyTrails() {
    this._type = 'orga';
    this.level = 0;
  }

  setPanelToDefaultStatus() {
    this.setPanelToDefaultStatusEvent.emit(true);
  }

  selectEvent(e) {
    this._selectedEvent = e;
  }

  changeType(type) {
    this._type = type;
    this.level = 1;
  }

  ngOnInit() {
    if (this._type == 'trail') {
      this.level = 1;
    } else if (this._type == 'event') {
      this.level = 2;
    }
  }

  loadData(trailAlias) {
    this.trailData
      .loadTrailByAlias(trailAlias, true)
      .pipe(first())
      .subscribe(trail => {
        this.trail = trail;
      });
    this.changeType('trail');
  }
}
