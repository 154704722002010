import { Component, OnInit, OnChanges, forwardRef, Input } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'birthdate-select',
  templateUrl: './birthdate-select.component.html',
  styleUrls: ['./birthdate-select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BirthdateSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => BirthdateSelectComponent), multi: true },
  ],
})
export class BirthdateSelectComponent implements ControlValueAccessor, OnChanges {
  _defaultDay: number;
  _defaultMonth: number;
  _defaultYear: number;
  day;
  year;
  month;

  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  getMonthName(val) {
    let d = new Date();
    d.setDate(15);
    d.setHours(12);
    d.setMonth(val - 1);
    return d;
  }

  years = [];
  isDisable = false;

  date: Date;
  constructor() {
    let date = new Date();
    for (let i = 1920; i <= date.getFullYear(); i++) {
      this.years.push(i);
    }
    this.years.reverse();
  }

  propagateChange: any = () => {};
  propagateTouch: any = () => {};
  validateFn: any = () => {};
  ngOnChanges(changes): void {}

  writeValue(obj): void {
    let date;
    if (!(obj instanceof Date)) {
      date = new Date(obj);
    } else {
      date = obj;
    }
    this.date = date;
    this.day = date.getDate();
    this.month = date.getMonth() + 1;
    this.year = date.getFullYear();
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisable = isDisabled;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }

  changeValue() {
    this.date.setFullYear(this.year);
    this.date.setMonth(this.month - 1);
    this.date.setDate(this.day);
    this.propagateChange(this.date);
  }
}
