import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-premium-subscription-dialog',
  templateUrl: './premium-subscription-dialog.component.html',
})
export class PremiumSubscriptionDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PremiumSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close() {
    this.dialogRef.close();
  }

  postponeNextReminder() {
    const now = new Date();
    const tomorrowMorning = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 5, 0, 0, 0);
    localStorage.setItem('premiumReminder_' + this.data.user_id, '' + tomorrowMorning.getTime());
    this.close();
  }
}
