import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Organization, Trail } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss', '../common.scss'],
})
export class ConfirmationComponent {
  @Input() trail: Trail;
  @Input() organization: Organization;

  @Output() closeClicked = new EventEmitter();

  close() {
    this.closeClicked.emit();
  }
}
