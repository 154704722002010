<section>
  <h3>{{ 'CHANGE_SUBSCRIPTION' | transloco }}</h3>
  <hr />
  <ng-container *ngIf="prices$ | async; let prices; else loading">
    <div class="subscription-selection-box">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'SELECT_A_PRICE' | transloco }}</mat-label>
        <mat-select [(value)]="selected">
          <ng-container *ngFor="let price of prices">
            <mat-option *ngIf="data.subscription.paymentType !== price.subscription_type" [value]="price">
              {{ price.price_name | transloco }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <div *ngIf="selected" class="selection-info">
        <span>{{ 'YOU_SELECTED' | transloco }} :</span>
        <br />
        <mat-card class="info-price">
          <span>{{ selected.price_name | transloco }}</span>
          <span class="subtitle">{{ selected.price_comment | transloco }}</span>
          <span class="price">
            {{ selected.price_amount / 100 | number: '0.2-2' }} / {{ 'PRICE_PERIOD_' +
            selected.subscription_type.toUpperCase() | transloco }}
          </span>
        </mat-card>
      </div>
    </div>

    <div class="btn-container">
      <button mat-raised-button color="warn" (click)="closeDialog(false)">{{ 'CANCEL' | transloco }}</button>
      <button mat-raised-button color="accent" (click)="closeDialog(selected)" [disabled]="!selected">
        {{ 'CONFIRM' | transloco }}
      </button>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="loading-msg">
      <em>{{ 'LOADING' | transloco }}</em>
    </div>
  </ng-template>
</section>
