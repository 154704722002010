<div>
  <div>
    <header class="form-title">{{ 'EDIT_TRANSLATIONS' | transloco }}</header>
  </div>

  <form [formGroup]="editTranslationForm" class="form-content">
    <div *ngFor="let language of languages">
      <div class="language">{{language}}</div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{'LABEL' | transloco}}</mat-label>
          <input matInput type="text" [formControlName]="language" />
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="actions-container">
    <button mat-button class="button" (click)="cancel()">{{ 'CANCEL' | transloco }}</button>

    <button class="button color" mat-raised-button (click)="confirm()">{{ 'SAVE' | transloco }}</button>
  </div>
</div>
