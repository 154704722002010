import { Component, Directive, Inject, OnInit, Optional, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService, LoginCred, RetrieveAction, UsersService } from '@betrail-libs/auth-state';
import { Organization, Trail, UserOrganization } from '@betrail-libs/shared/interfaces/interfaces';
import { EventService, LoadTrailForAlias } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { ChooseOrganizationToLinkComponent } from '../forms/choose-organization-to-link/choose-organization-to-link.component';
import { CreateOrgaAccountComponent } from '../forms/create-orga-account/create-orga-account.component';
import { CreateOrgaComponent } from '../forms/create-orga/create-orga.component';
import { LoginComponent } from '../forms/login/login.component';
import { SendNewPasswordComponent } from '../forms/send-new-password/send-new-password.component';
import { LinkOrCreateComponent } from '../link-or-create/link-or-create.component';

const headers = { Accept: 'application/json; charset=utf-8' };

@Directive({
  selector: '[dynamic-content]',
})
export class DynamicContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@UntilDestroy()
@Component({
  selector: 'app-organizer-account-dialog',
  templateUrl: './organizer-account-dialog.component.html',
  styleUrls: ['./organizer-account-dialog.component.scss'],
})
export class OrganizerAccountDialogComponent implements OnInit {
  private skipConnection = false;
  private skipGetOrgaByUser = false;
  private userId: number;
  private trailId: number;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  trail: Trail;
  organization: Organization;
  user: any;

  @ViewChild(DynamicContentDirective, { static: true }) dynamicContent: DynamicContentDirective;

  constructor(
    private dialogRef: MatDialogRef<OrganizerAccountDialogComponent>,
    @Inject('BETRAIL2_NODE_API') public betrail2NodeApi: string,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private authState: AuthStateService,
    private usersService: UsersService,
    private store: Store,
    private eventService: EventService,
  ) {
    if (data) {
      this.trailId = data.id;
      this.trail = data;
    }
  }

  ngOnInit() {
    this.loadingSubject.next(true);
    //
    // The first step is to check if the user is already logged in or not
    //
    this.authState
      .getUser()
      .pipe(take(1))
      .subscribe(user => {
        if (user && user.uid) {
          //
          // store user id to re-use it to create the link between orga and user
          //
          this.user = user;
          this.userId = +user.uid;
          //
          // The user is logged, we must now check if his account is already linked to an organization.
          // But first, check the "skipGetOrgaByUser". If it's true, it means that we are coming from a new user orga account
          // creation. We don't need to see if he has an organization, it will always be false because it's a new one.
          // we change directly go to the create orga component
          //
          if (this.skipGetOrgaByUser) {
            this.loadCreateOrgaComponent('createOrgaAccount');
          } else {
            this.getOrgaByUserId(user.uid)
              .pipe(take(1))
              .subscribe((userOrgaResult: UserOrganization[]) => {
                if (userOrgaResult && userOrgaResult.length > 0) {
                  //
                  // The user is already linked to one organization at elast.
                  // the next step is to choose the organisation to link
                  //
                  this.loadChooseOrganisationToLinkComponent();
                  this.loadingSubject.next(false);
                } else {
                  //
                  // That user is not yet linked to an organization
                  // He can choose if he wants to link his account to that orga
                  // or if he wants to create a new account
                  //
                  this.loadConfirmLinkOrNewAccount();
                }
              });
          }
        } else {
          if (this.skipConnection) {
            //TODO : handle back button if needed ?
            this.loadCreateOrgaAccountComponent('');
          } else {
            //
            // The user is not logged, the loadComponent is created and loaded in the dynamic-content directive.
            // From there, he can :
            // - create a new account
            // - log into the application
            //
            this.loadCreateOrgaAccountComponent('');
            this.loadingSubject.next(false);
          }
        }
      });
  }

  private linkTrailToOrga(organizationId: number) {
    this.loadingSubject.next(true);
    this.sendUpdateTrailRequest(this.trailId, organizationId).then(result => {
      //
      // The trail has been updated with organization_id
      // Show confirmation message
      //
      this.reload();
      this.loadConfirmationComponent();
      this.loadingSubject.next(false);
    });
  }

  private loadChooseOrganisationToLinkComponent() {
    const componentRef = this.loadComponent(ChooseOrganizationToLinkComponent);
    const componentInstance = componentRef.instance as ChooseOrganizationToLinkComponent;
    componentInstance.trail = this.trail;

    componentInstance.cancelClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.dialogRef.close();
    });

    componentInstance.goToCreateOrgaFormClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadCreateOrgaComponent('chooseOrganisationToLink');
    });

    componentInstance.confirmLinkToOrgaClicked.pipe(untilDestroyed(this)).subscribe(orga => {
      this.organization = orga;
      const organizationId = orga?.id || orga;
      this.linkTrailToOrga(organizationId);
    });
  }

  private loadConfirmationComponent() {
    const componentRef = this.loadComponent(ConfirmationComponent);
    const componentInstance = componentRef.instance as ConfirmationComponent;
    componentInstance.trail = this.trail;
    componentInstance.organization = this.organization;

    componentInstance.closeClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private loadSendNewPasswordComponent() {
    const componentRef = this.loadComponent(SendNewPasswordComponent);
    const componentInstance = componentRef.instance as SendNewPasswordComponent;

    componentInstance.goToLoginFormClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadConnectionComponent();
    });
  }

  private loadConfirmLinkOrNewAccount() {
    const componentRef = this.loadComponent(LinkOrCreateComponent);
    const componentInstance = componentRef.instance as LinkOrCreateComponent;

    componentInstance.trail = this.trail;

    componentInstance.linkAccountClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadCreateOrgaComponent('confirmLinkOrNewAccount');
    });

    componentInstance.newAccountClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.skipConnection = true;
      //
      //
      //
      this.authState.logout();

      //
      // call the creation process
      //
      this.loadCreateOrgaAccountComponent(''); //confirmLinkOrNewAccount
    });

    this.loadingSubject.next(false);
  }

  private loadConnectionComponent() {
    const componentRef = this.loadComponent(LoginComponent);
    const componentInstance = componentRef.instance as LoginComponent;
    componentInstance.trail = this.trail;

    componentInstance.noAccountClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadCreateOrgaAccountComponent('connection');
    });

    componentInstance.sendNewPasswordClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadSendNewPasswordComponent();
    });

    componentInstance.loginClicked.pipe(untilDestroyed(this)).subscribe((credentials: LoginCred) => {
      this.loadingSubject.next(true);
      this.authState.login(credentials);
    });

    this.loadingSubject.next(false);
  }

  private loadCreateOrgaComponent(previousStep: string) {
    const componentRef = this.loadComponent(CreateOrgaComponent);
    const componentInstance = componentRef.instance as CreateOrgaComponent;
    componentInstance.trail = this.trail;
    componentInstance.buildOrgaForm();

    componentInstance.cancelled.pipe(untilDestroyed(this)).subscribe(() => {
      if (previousStep === 'confirmLinkOrNewAccount') {
        this.loadConfirmLinkOrNewAccount();
      } else if (previousStep === 'createOrgaAccount') {
        this.loadCreateOrgaAccountComponent('connection');
      } else if (previousStep === 'chooseOrganisationToLink') {
        this.loadChooseOrganisationToLinkComponent();
      }
    });

    componentInstance.saved.pipe(untilDestroyed(this)).subscribe(newOrganization => {
      //
      // show loading spinner
      //
      this.loadingSubject.next(true);

      this.createOrganization(newOrganization).then(newOrganizationresult => {
        //
        //
        //
        this.linkTrailToOrga(newOrganizationresult.id);
        this.organization = newOrganizationresult;
      });
    });

    this.loadingSubject.next(false);
  }

  private loadCreateOrgaAccountComponent(previousStep: string, showBackButton: boolean = true) {
    const componentRef = this.loadComponent(CreateOrgaAccountComponent);
    const componentInstance = componentRef.instance as CreateOrgaAccountComponent;
    componentInstance.trail = this.trail;

    componentInstance.cancelled.pipe(untilDestroyed(this)).subscribe(() => {
      if (previousStep === 'confirmLinkOrNewAccount') {
        this.loadConfirmLinkOrNewAccount();
      } else if (previousStep === 'connection') {
        this.loadConnectionComponent();
      } else {
        this.dialogRef.close();
      }
    });

    componentInstance.goToLoginFormClicked.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadConnectionComponent();
    });

    componentInstance.saved.pipe(untilDestroyed(this)).subscribe(orgaCredentials => {
      this.loadingSubject.next(true);

      this.usersService.createOrgaUser(orgaCredentials).subscribe({
        next: (orgaAccountResult: any) => {
          //
          // indicate we need to skip the check
          //
          this.skipGetOrgaByUser = true;
          this.authState.login({ user: orgaCredentials.email, password: orgaCredentials.password });
          //
          // a new orga account has been created,
          // store user id locally
          //
          this.userId = orgaAccountResult.uid;
          //
          // continue process, show create orga component
          //
          this.loadCreateOrgaComponent('createOrgaAccount');
        },
        error: err => {
          alert('ERROR: ' + JSON.stringify(err));
        },
      });
    });
  }

  //
  // dynamic loading of components within the dynamic-content directive
  //
  private loadComponent(component: Type<any>) {
    const viewContainerRef = this.dynamicContent.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef.createComponent(component);
  }

  private getOrgaByUserId(userId: string) {
    return this.eventService.getOrgaByUserId(userId);
  }

  //
  // Create the link between the organization to the user
  //
  private async createUserOrganization(newOrganization) {
    const userOrganization = new UserOrganization();

    userOrganization.organization_id = newOrganization.id;
    userOrganization.user_id = this.userId;

    return await this.sendCreateUserOrgaRequest(userOrganization);
  }

  //
  // Create organization
  //
  private async createOrganization(newOrganization: Organization) {
    const newOrganizationResult = await this.sendCreateOrgaRequest(newOrganization);
    //
    // The organization has been created.
    // Now, we must create the link between that new organization and the user.
    //
    this.createUserOrganization(newOrganizationResult);
    return await newOrganizationResult;
  }

  private async sendUpdateTrailRequest(trailId: number, organizationId: number) {
    const data = {
      trailId: trailId,
      organizationId: organizationId,
      user: this.user,
    };
    return await firstValueFrom(this.eventService.linkOrganizationToTrail(data));
  }

  private async sendCreateOrgaRequest(organization: Organization) {
    return await firstValueFrom(this.eventService.createOrganization(organization));
  }

  private async sendCreateUserOrgaRequest(userOrganization: UserOrganization) {
    return await firstValueFrom(this.eventService.createUserOrganization(userOrganization));
  }

  //
  // Reload user after linking it to an organization
  //

  private reload() {
    this.store.dispatch(new RetrieveAction());
    this.store.dispatch(new LoadTrailForAlias(this.trail.alias));
  }
}
