import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminStateService } from '@betrail-libs/admin-state';
import { EPopUpTarget, EPopUpType, IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';
import { TranslocoService } from '@ngneat/transloco';
import { ImageUploadService } from '../../../../../common/images/image-upload.service';
import { REGEX_LINK_VALIDATION } from '@betrail-libs/shared/utils';

@Component({
  selector: 'app-pop-up-form-dialog',
  templateUrl: './pop-up-form-dialog.component.html',
  styleUrls: ['./pop-up-form-dialog.component.scss'],
})
export class PopUpFormDialogComponent {
  api_uri = this.config.nodejsApi;
  countries = ['ALL', 'BE', 'FR', 'NL', 'LU', 'OTHERS'];
  types = Object.values(EPopUpType);
  targets = Object.values(EPopUpTarget);
  langs = ['Fr', 'Nl', 'En'];
  isNew = true;
  popUpForm: UntypedFormGroup = this.setPopUpForm();
  image?: string;

  constructor(
    @Inject('appsConfig') private config: { [key: string]: string | boolean },
    @Inject(MAT_DIALOG_DATA) public data: { popup: IPopUpData | undefined },
    private dialogRef: MatDialogRef<PopUpFormDialogComponent>,
    private snackbar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private adminService: AdminStateService,
    private imgService: ImageUploadService,
    private transloco: TranslocoService,
  ) {}

  setPopUpForm() {
    if (this.data.popup?.id) {
      this.isNew = false;
    }
    return this.fb.group({
      target: [this.data?.popup?.target || EPopUpTarget.all],
      country: [this.data?.popup?.country || 'ALL'],
      type: [this.data?.popup?.type || '', Validators.required],
      titleFr: [this.data?.popup?.titleFr || '', Validators.required],
      titleNl: [this.data?.popup?.titleNl || '', Validators.required],
      titleEn: [this.data?.popup?.titleEn || '', Validators.required],
      textFr: [this.data?.popup?.textFr || '', Validators.required],
      textNl: [this.data?.popup?.textNl || '', Validators.required],
      textEn: [this.data?.popup?.textEn || '', Validators.required],
      link: [
        this.data?.popup?.link || '',
        Validators.pattern(REGEX_LINK_VALIDATION),
      ],
      image: [this.data?.popup?.image || ''],
    });
  }

  addImage(file: Event | File): void {
    if ('size' in file) {
      if (file.size < 200000) {
        const imgObservable = this.imgService.getImageObservableFromFile(file);
        imgObservable.subscribe(d => {
          this.image = d;
          this.popUpForm.patchValue({
            image: d,
          });
        });
      } else {
        this.snackbar.open(this.transloco.translate('ERROR_FILE_TOO_LARGE_200K'), 'OK');
      }
    }
  }

  createOrEditPopUp() {
    const popUpData = { ...this.popUpForm.value };
    if (this.isNew) {
      this.adminService.createNewPopup(popUpData);
    } else {
      popUpData.id = this.data.popup.id;
      this.adminService.editPopupData(popUpData);
    }
    this.dialogRef.close();
  }
}
