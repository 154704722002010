import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';
import { AdminStateService } from '@betrail-libs/admin-state';

@Component({
  selector: 'app-announcement-pop-up',
  templateUrl: './announcement-pop-up.component.html',
})
export class AnnouncementPopUpComponent {
  apiUri = this.config.nodejsApi;
  openedAt = Date.now();

  constructor(
    @Inject('appsConfig') private config: { [key: string]: string | boolean },
    @Inject(MAT_DIALOG_DATA) public data: { popup: IPopUpData; lang: 'Fr' | 'Nl' | 'En'; userId?: number },
    private adminService: AdminStateService,
  ) {}

  createPopupLog(clickLink = false) {
    // * if data.userId is undefined, this means that the popup was opened by an admin as preview
    // * if data.userId === -1, this means that the popup was opened by a user that is not logged in
    // => in both cases, we don't create a popup log
    if (this.data.userId && this.data.userId > 0) {
      const closedAt = Date.now();
      this.adminService.createPopupLog(this.data.userId, this.data.popup.id, clickLink, closedAt - this.openedAt);
    }
  }
}
