import { Injectable } from '@angular/core';
import { ALL_CALENDAR_COUNTRIES, ALL_COUNTRIES_CODES, COVERED_COUNTRIES } from '@betrail-libs/shared/utils';
import { EventService } from '@betrail-libs/trail-data-state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import produce from 'immer';
import { first, tap } from 'rxjs/operators';
import { LoadTimer, LoadTimerById, LoadTimers, SetDisplayType, SetSelectedCountries } from './timers.action';
import { TimersModel } from './timers.model';

@State<TimersModel>({
  name: 'timers',
  defaults: {
    timers: {},
    selectedTimer: undefined,
    selectedCountries: ALL_CALENDAR_COUNTRIES,
    displayType: 'list',
  },
})
@Injectable()
export class TimersState {
  constructor(private store: Store, private eventService: EventService) {}

  @Selector()
  static allTimers(state: TimersModel): any[] {
    return Object.keys(state.timers)
      .map(key => state.timers[key])
      .sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
  }

  @Selector()
  static filteredTimers(state: TimersModel): unknown[] {
    return Object.keys(state.timers)
      .map(key => state.timers[key])
      .filter(t => state.selectedCountries.indexOf(t.country) > -1)
      .sort(function (a, b) {
        if (a.title.toUpperCase() < b.title.toUpperCase()) {
          return -1;
        }
        if (a.title.toUpperCase() > b.title.toUpperCase()) {
          return 1;
        }
        return 0;
      });
  }

  @Selector()
  static timer(state: TimersModel) {
    return timerAlias => {
      return state.timers[timerAlias];
    };
  }

  @Selector()
  static displayType(state: TimersModel) {
    return state.displayType;
  }

  @Selector()
  static selectedCountry(state: TimersModel) {
    const countries = state.selectedCountries;
    if (countries.length == 1) {
      return countries[0];
    } else if (countries.length === ALL_COUNTRIES_CODES.length) {
      return '';
    } else if (countries.length === ALL_COUNTRIES_CODES.filter(c => !COVERED_COUNTRIES.includes(c)).length) {
      return 'OTHERS';
    }
    return '';
  }

  @Selector()
  static selectedCountries(state: TimersModel) {
    return state.selectedCountries;
  }

  @Selector()
  static selectedTimer(state: TimersModel) {
    if (state.selectedTimer) {
      return state.timers[state.selectedTimer];
    }
  }

  @Action(LoadTimers)
  loadTimers(ctx: StateContext<TimersModel>, action: LoadTimers) {
    return this.eventService.getAllTimers().pipe(
      first(),
      tap(timers => {
        ctx.setState(
          produce(draft => {
            timers.map(t => (draft.timers[t.alias] = t));
          }),
        );
      }),
    );
  }

  @Action(LoadTimer)
  loadTimer(ctx: StateContext<TimersModel>, action: LoadTimer) {
    //if (ctx.getState().timers[action.alias] == undefined || action.force === true) {
    return this.eventService.getTimer(action.alias).pipe(
      first(),
      tap(t => {
        ctx.setState(
          produce(draft => {
            draft.timers[action.alias] = t;
            draft.selectedTimer = '' + action.alias;
          }),
        );
      }),
    );
  }

  @Action(LoadTimerById)
  loadTimerById(ctx: StateContext<TimersModel>, action: LoadTimerById) {
    return this.eventService.getTimer(action.id).pipe(
      first(),
      tap((t: any) => {
        ctx.setState(
          produce(draft => {
            draft.timers[t.alias] = t;
            draft.selectedTimer = t.alias;
          }),
        );
      }),
    );
  }

  @Action(SetSelectedCountries)
  setSelectedCountries(ctx: StateContext<TimersModel>, action: SetSelectedCountries) {
    ctx.setState(
      produce(draft => {
        draft.selectedCountries = action.countries;
      }),
    );
  }

  @Action(SetDisplayType)
  setDisplayType(ctx: StateContext<TimersModel>, action: SetDisplayType) {
    ctx.setState(
      produce(draft => {
        draft.displayType = action.type;
      }),
    );
  }
}
