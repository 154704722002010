import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { getDDMMYYYYStringFromDateObject } from '@betrail-libs/shared/utils';

@Component({
  selector: 'calendar-filter-dates',
  templateUrl: './calendar-filter-dates.component.html',
  styleUrls: ['./calendar-filter-dates.component.scss'],
})
export class CalendarFilterDatesComponent {
  elements: { name: string; label: string; value?: Date }[];
  minDate = new Date(2008, 0, 2);
  maxDate = new Date(2032, 0, 1);

  @Input()
  set filteredElements(filteredElements: { name: string; label: string; value?: Date | number }[]) {
    const elements = [];
    for (const elem of filteredElements) {
      const val = typeof elem?.value === 'number' ? new Date(elem.value) : elem?.value;
      elements.push({ ...elem, value: val });
    }
    this.elements = elements;
  }
  @Input()
  set _values(values: (Date | number)[]) {
    this.elements[0].value = typeof values[0] === 'number' ? new Date(values[0]) : values[0];
    this.elements[1].value = typeof values[1] === 'number' ? new Date(values[1]) : values[1];
  }
  @Input() quickFilters: { title: string; values: Date[] }[];

  @Output() datesFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();
  @Output() apply = new EventEmitter();

  @ViewChild('calendarDateStart') public dateStartCalendar;
  @ViewChild('calendarDateEnd') public dateEndCalendar;

  getSummary(values: Date[]) {
    let summary = '';
    if (values) {
      const from = values[0] ? new Date(values[0]) : null;
      const to = values[1] ? new Date(values[1]) : null;

      if (from && to) {
        summary = getDDMMYYYYStringFromDateObject(from);
        summary += ' > ';
        summary += getDDMMYYYYStringFromDateObject(to);
      } else if (from) {
        summary = getDDMMYYYYStringFromDateObject(from);
      } else if (to) {
        summary += getDDMMYYYYStringFromDateObject(to);
      }
    }
    return summary;
  }

  changeValues() {
    const values = [
      this.elements.find(el => el.name == 'calendarDateStart')['value'],
      this.elements.find(el => el.name == 'calendarDateEnd')['value'],
    ];
    this.datesFilter.emit(values);
    const summary = this.getSummary(values);
    this.updateSummary.emit(summary);
  }

  activeQuickFilter(values: Date[], summary: string) {
    this.datesFilter.emit(values);
    this.updateSummary.emit(summary);
    this.apply.emit();
  }
}
