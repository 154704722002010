import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IEliteLevel,
  INewsFeedGroup,
  INewsFeedParams,
  IResult,
  IRunnerTracking,
  ITrackedRunner,
} from '@betrail-libs/shared/interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class TrackingApiService {
  private readonly apiUri = this.config.nodejsApi + '/tracking';

  constructor(@Inject('appsConfig') private config: any, private http: HttpClient) {}

  createRunnerTracking(data: { uid: number; ruid: number; created_at: Date }) {
    return this.http.post<{ runner: ITrackedRunner; user?: ITrackedRunner }>(`${this.apiUri}/new`, data);
  }

  setUserEliteLevels(data: { uid: number; selected: { [nationality: string]: number } }) {
    return this.http.post<{ nationality: string; elid: number }[]>(`${this.apiUri}/elites`, data);
  }

  unfollowRunner(data: { uid: number; ruid: number }) {
    return this.http.put<IRunnerTracking>(`${this.apiUri}/unfollow`, data);
  }

  getTrackedRunnersResults(uid: number, toTake = 10, offset = 0, filter?: INewsFeedParams) {
    return this.http.get<IResult[]>(`${this.apiUri}/${uid}/results/${toTake}/${offset}`, { params: filter });
  }

  getEliteLevels() {
    return this.http.get<IEliteLevel[]>(`${this.apiUri}/elites`);
  }

  handleTrackingGroups(uid: number, data: INewsFeedGroup[]) {
    return this.http.post<{ id: number; name: string; priority: number; runners?: { ruid: number }[] }[]>(
      `${this.apiUri}/groups/${uid}`,
      data,
    );
  }

  handleRunnerGroupLink(data: { activate: boolean; tgid: number; ruid: number }) {
    return this.http.post<{ tgid: number; ruid: number }>(`${this.apiUri}/group/runner`, data);
  }

  changeTrackingOverride(data: { rtid: number; override: boolean }[]) {
    return this.http.put<{ id: number; override: 0 | 1 }[]>(`${this.apiUri}/override`, { data /* rtid, override */ });
  }
}
