import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { EventService } from '@betrail-libs/trail-data-state';

@UntilDestroy()
@Component({
  selector: 'bux-countdown-dialog',
  templateUrl: './countdown-dialog.component.html',
  styleUrls: ['./countdown-dialog.component.scss'],
})
export class CountdownDialogComponent {
  constructor(private dialogRef: MatDialogRef<CountdownDialogComponent>, private eventService: EventService) {}

  nbPremium = 0;

  ngOnInit(): void {
    this.getRunnerData();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async getRunnerData() {
    let data$ = this.eventService.getAllPremiumRunners();
    data$.pipe(untilDestroyed(this)).subscribe(d => {
      // get nb total of premium
      this.nbPremium = d.body[0].length;
    });
  }
}
