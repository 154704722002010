<div class="stepContainer">
  <h2 class="centeredAligned">{{ 'WS_ADD_AVATAR' | transloco }}</h2>
  <div class="message centeredAligned">{{ 'WS_AVATAR_EXPLANATION' | transloco }}</div>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="avatar mat-elevation-z3"
    [style.backgroundImage]="updatedAvatar?.medium_image || runner?.photo?.medium_image | sanitizeStyleUrl"
  >
    <image-upload
      [source]="runner?.photo"
      [addto]="{ nid: runner?.id, type: 'avatar' }"
      (imageUploaded)="changeAvatar($event)"
      buttonText="{{ buttonText | transloco }}"
    >
    </image-upload>
  </div>
  <div class="buttonContainer">
    <button [disabled]="!runner?.photo?.id" class="bt-button blue" mat-raised-button (click)="nextStep()">
      {{ 'NEXT' | transloco }}
    </button>
    <div class="later" (click)="nextStep()">{{ 'WS_DO_IT_LATER' | transloco }}</div>
  </div>
</div>
