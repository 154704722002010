<div class="birthlabel">{{'BIRTHDATE' | transloco}} *</div>
<div class="flex-container" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
  <mat-form-field appearance="fill" class="example-full-width" fxFlex="33">
    <mat-select
      required
      floatPlaceholder="never"
      [placeholder]="'DAY' | transloco"
      [(ngModel)]="day"
      [disabled]="isDisable"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let val of days" [value]="val"> {{val}} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="example-full-width" fxFlex="33">
    <mat-select
      required
      floatPlaceholder="never"
      [placeholder]="'MONTH' | transloco"
      [(ngModel)]="month"
      [disabled]="isDisable"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let val of months" [value]="val">
        {{ getMonthName(val) | date: 'LLLL':undefined:currentLang }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="example-full-width" fxFlex="33">
    <mat-select
      required
      floatPlaceholder="never"
      [placeholder]="'YEAR' | transloco"
      [disabled]="isDisable"
      [(ngModel)]="year"
      (selectionChange)="changeValue()"
    >
      <mat-option *ngFor="let val of years" [value]="val"> {{val}} </mat-option>
    </mat-select>
  </mat-form-field>
</div>
