<div class="quickFilters" [class.smaller]="country === 'BE'">
  <strong>{{ 'FILTER_QUICK_FILTERS' | transloco }}</strong>
  <div class="france-map">
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 345 382"
      enable-background="new 0 0 345 382"
      xml:space="preserve"
    >
      <ng-container *ngFor="let region of regions">
        <path
          *ngIf="region.path !== 'none'"
          fill="#e8a30c"
          [ngClass]="{ selected: value.region === region.code }"
          [attr.d]="region.path"
          (click)="selectRegion(region.code)"
        ></path>
      </ng-container>
    </svg>
    <div *ngIf="country === 'FR'" class="flex justify-between mt-3">
      <div
        *ngFor="let oversea of overseas"
        class="bg-gray-300 p-1 rounded-2xl flex items-center cursor-pointer"
        (click)="selectOverseaDep(oversea.code)"
      >
        <span>{{ oversea.iso2 }}</span>&nbsp;
        <country-flag [country]="oversea.iso2"></country-flag>
      </div>
    </div>
  </div>
</div>
<div class="fullFilter">
  <strong>{{ 'FILTER_CUSTOMIZE_FILTER' | transloco }}</strong>
  <div fxLayout="row">
    <mat-form-field class="region-select" appearance="outline">
      <mat-label>{{ 'FILTER_REGION_SELECT' | transloco }}</mat-label>
      <mat-select [(value)]="value.region" (selectionChange)="changeValue($event.value, 'region')">
        <mat-option value="ALL">{{ 'REGIONS_ALL' | transloco }}</mat-option>
        <mat-option *ngFor="let region of regions" [value]="region.code">{{ region.title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" *ngIf="value?.region !== undefined && value?.region !== 'ALL'">
    <mat-form-field class="region-select" appearance="outline">
      <mat-label
        >{{ (country === 'FR' ? 'FILTER_DEPARTMENT_SELECT' : 'FILTER_PROVINCE_SELECT') | transloco }}</mat-label
      >
      <mat-select [(value)]="value.department" (selectionChange)="changeValue($event.value, 'department')">
        <mat-option value="ALL">{{ (country === 'FR' ? 'DEPARTMENTS_ALL' : 'PROVINCES_ALL') | transloco }}</mat-option>
        <mat-option *ngFor="let department of region_departments" [value]="department.code"
          >{{ department.code }} {{ department.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>
