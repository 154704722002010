<section class="max-w-sm p-3">
  <h1 class="text-xl text-center mt-0">{{ 'SELECT_RUNNER' | transloco }}</h1>

  <div class="my-[14px]">
    <bux-search-runner-trail
      [searchRunner]="true"
      [link]="false"
      [discreet]="true"
      (optionSelected)="selectRunner($event)"
    ></bux-search-runner-trail>

    <div *ngIf="selectedRunner" class="text-center text-lg mt-[14px]">
      {{ 'SELECTED' | transloco }} : {{ selectedRunner.display_title }}
    </div>
  </div>

  <div class="flex justify-between mt-6">
    <button mat-raised-button color="accent" (click)="linkRunnerToOrga()" [disabled]="!selectedRunner">
      {{ 'CONFIRM' | transloco }}
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>{{ 'CANCEL' | transloco }}</button>
  </div>
</section>
