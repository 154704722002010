import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-result-form',
  templateUrl: './edit-result-form.component.html',
  styleUrls: ['./edit-result-form.component.scss'],
})
export class EditResultFormComponent {
  constructor(public thisDialogRef: MatDialogRef<EditResultFormComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  close() {
    this.thisDialogRef.close('close');
  }
}
