import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadRunnerForAlias } from '@betrail-libs/trail-data-state';
import { IUserState, UpdateUserAction, UsersService } from '@betrail-libs/auth-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SetAvatarLater } from '@betrail-libs/app-params-state';

@UntilDestroy()
@Component({
  selector: 'welcome-step-avatar',
  templateUrl: './welcome-step-avatar.component.html',
  styleUrls: ['./welcome-step-avatar.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepAvatarComponent implements OnInit, OnDestroy {
  @Input() runner;
  private _user: IUserState;
  userParams: any = {};
  @Input('user') set user(u: IUserState) {
    this._user = u;
    this.userParams = {};
    if (this._user?.userDrupal?.userParams) {
      this.userParams = JSON.parse(this._user.userDrupal.userParams);
    }
  }

  updatedAvatar;
  buttonText;

  @Output() next = new EventEmitter();

  nextStep() {
    this.userParams.avatarLater = new Date().getTime();
    let u = {
      field_user_params: { und: [{ value: JSON.stringify(this.userParams) }] },
    };
    this.usersService
      .updateUser(this._user.uid, u)
      .pipe(untilDestroyed(this))
      .subscribe(updatedUser => {
        this.store.dispatch(new UpdateUserAction({ user: updatedUser }));
        this.store.dispatch(new SetAvatarLater(true));
        this.next.emit();
      });
  }

  constructor(private usersService: UsersService, private store: Store) {}

  ngOnInit() {
    this.buttonText = this.updatedAvatar || (this.runner && this.runner.avatar > 0) ? 'EDIT_AVATAR' : 'ADD_AVATAR';
  }

  changeAvatar(image) {
    this.updatedAvatar = image;
    this.buttonText = 'EDIT_AVATAR';
    this.store.dispatch(new LoadRunnerForAlias(this.runner.alias));
    this.next.emit();
  }

  ngOnDestroy() {}
}
