<ng-container [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="space-between" class="timer-form">
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <input style="display: none" id="username" type="text" name="fakeusernameremembered" />
    <input style="display: none" id="mail" type="text" name="fakemailremembered" />
    <input style="display: none" id="email" type="text" name="fakeemailremembered" />
    <input style="display: none" id="password" type="password" name="fakepasswordremembered" />

    <mat-form-field appearance="outline">
      <mat-icon class="suffixIcon" matSuffix>timer</mat-icon>
      <mat-label>{{ 'TIMER_NAME' | transloco }}*</mat-label>
      <input matInput placeholder="Name of the timer" formControlName="title" autocomplete="new-password" />
    </mat-form-field>

    <div class="timerLogo">
      <img class="timerLogoImg" [src]="updatedImage.max_100_height || timer?.photo?.max_100_height | sanitizeUrl" />

      <div class="timerLogoButtons">
        <button
          class="addImageButton"
          tdFileDrop
          #addImageButton
          mat-raised-button
          (click)="fileInput.click(); $event.stopPropagation()"
          (fileDrop)="addImage($any($event))"
          [disabled]="addImageButtonDisabled"
        >
          <mat-icon>image</mat-icon>&nbsp;
          <ng-container *ngIf="timer?.photo?.max_100_height || updatedImage.max_100_height; else newImage">
            {{ 'CHOOSE_ANOTHER_LOGO' | transloco }}</ng-container
          >
          <ng-template #newImage>{{ 'ADD_A_LOGO' | transloco }}</ng-template>
        </button>

        <button *ngIf="updatedImage.medium_image" class="removeImageButton" mat-icon-button (click)="removeImage()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
    <input #fileInput hidden type="file" (click)="$event.stopPropagation()" (change)="addImage(fileInput.files[0])" />
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between">
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-icon class="suffixIcon" matSuffix>location</mat-icon>
        <mat-label>{{ 'TIMER_POSTAL_CODE' | transloco }}*</mat-label>
        <input matInput formControlName="postal_code" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-icon class="suffixIcon" matSuffix>location</mat-icon>
        <mat-label>{{ 'TIMER_PLACE' | transloco }}*</mat-label>
        <input matInput formControlName="place" />
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'COUNTRY' | transloco }}*</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countries" [value]="country.key"
          >{{ country.key }} - {{ country.name | transloco }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-icon class="suffixIcon" matSuffix>location</mat-icon>
        <mat-label>{{ 'TIMER_EMAIL' | transloco }}*</mat-label>
        <input
          matInput
          trimInput
          formControlName="email"
          autocomplete="abdk8655x9c99e4cc5s4ff"
          readonly="readonly"
          onfocus="javascript: this.removeAttribute('readonly')"
        />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-icon class="suffixIcon" matSuffix>location</mat-icon>
        <mat-label>{{ 'TIMER_PHONE' | transloco }}*</mat-label>
        <input matInput formControlName="phone" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-icon class="suffixIcon" matSuffix>location</mat-icon>
        <mat-label>{{ 'TIMER_WEBSITE' | transloco }}*</mat-label>
        <input matInput formControlName="website" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-icon class="suffixIcon" matSuffix>location</mat-icon>
        <mat-label>{{ 'TIMER_DEFAULT_RESULTS_PAGE' | transloco }}*</mat-label>
        <input matInput formControlName="default_results_page" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-slide-toggle formControlName="dont_exist">{{ 'TIMER_DONT_EXIST' | transloco }}</mat-slide-toggle>
    </div>
  </div>
</ng-container>
