<form [formGroup]="formGroup" class="{{ type }}" #feedback="ngForm">
  <ng-container *ngIf="formFields | formFieldSort; let fields">
    <div class="form-content flex-container flex-start" fxLayout="column">
      <div *ngIf="type === 'runner' || type === 'orga'">
        <div class="title">
          <h3>{{ 'DISTANCE_CHOICE' | transloco }}</h3>
        </div>

        <mat-form-field appearance="outline" style="width: 100%" class="example-full-width">
          <mat-label>{{ 'DISTANCE' | transloco }}</mat-label>
          <mat-select
            formControlName="distance"
            required
            style="margin-top: 5px; margin-bottom: 5px"
            placeholder="{{ 'DISTANCE' | transloco }}"
          >
            <mat-option
              *ngFor="let distance of distances"
              [disabled]="((distance.maxAttendeeReached && !distance.waitingListAuthorized) || !distance.currentPrice) && type === 'runner'"
              [value]="distance.id"
              (onSelectionChange)="goToWaitingList($event, distance.maxAttendeeReached && distance.waitingListAuthorized, distance.alias)"
            >
              <ng-container *ngIf="distance.fullNameArray; else fn">
                <ng-container *ngFor="let el of distance.fullNameArray"> {{ el | transloco }} </ng-container>
              </ng-container>
              <ng-template #fn> {{ distance.fullName }} </ng-template>
              <ng-container *ngIf="distance.currentPrice && distance.currentPrice?.amount > 0">
                ({{ distance?.currentPrice?.amount / 100 | currency: 'EUR':'symbol' }})
              </ng-container>
              <ng-container *ngIf="distance.currentPrice && +distance.currentPrice?.amount === 0">
                ({{ 'FREE' | transloco }})
              </ng-container>
              <ng-container *ngIf="!distance.currentPrice">
                ({{ 'NO_VALID_PRICE_AVAILABLE' | transloco }})
              </ng-container>
              <!-- TODO : setting prince according to current price // available dates -->
              <ng-container *ngIf="distance.maxAttendeeReached && !distance.waitingListAuthorized">
                - SOLD OUT
              </ng-container>
              <ng-container *ngIf="distance.maxAttendeeReached && distance.waitingListAuthorized">
                - {{ 'WAITING_LIST' | transloco }}
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="type === 'runner'">
        <mat-button-toggle-group
          name="fontStyle"
          (change)="registerTypeChange($event)"
          aria-label="Font Style"
          [value]="registerType"
        >
          <mat-button-toggle value="REGISTER_MYSELF">{{ 'I_REGISTER_MYSELF' | transloco }}</mat-button-toggle>
          <mat-button-toggle value="REGISTER_SOMEONE_ELSE"
            >{{ 'I_REGISTER_SOMEONE_ELSE' | transloco }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>

      <div class="title">
        <h3>{{ 'CONTACT_INFORMATIONS' | transloco }}</h3>
      </div>

      <div *ngFor="let formField of fields">
        <div [ngSwitch]="formField.fieldConfig.type">
          <div *ngSwitchCase="'TEXT'" class="example-full-width" [innerHTML]="formField.key | transloco"></div>

          <!-- <ng-container *ngSwitchCase="'TEXT'">
          <mat-form-field appearance="outline" style=" width: 100%;" class="example-full-width">
            <mat-label>{{formField.key | transloco}}</mat-label>
            <input [formControlName]="formField.name" required matInput type="text"
              placeholder="{{formField.key | transloco}}" />
          </mat-form-field>
        </ng-container> -->

          <ng-container *ngSwitchCase="'EMAIL'">
            <mat-form-field appearance="outline" style="width: 100%" class="example-full-width">
              <mat-label>{{ formField.key | transloco }}</mat-label>
              <input
                [formControlName]="formField.name"
                required
                matInput
                type="email"
                placeholder="{{ formField.key | transloco }}"
              />
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'BIRTHDATE'">
            <bt-birthdate-select style="width: 100%" [formControlName]="formField.name"></bt-birthdate-select>
          </ng-container>

          <ng-container *ngSwitchCase="'GENDER'">
            <div style="text-align: start; padding-bottom: 15px">
              <div class="birthlabel">{{ 'GENDER' | transloco }} *</div>
              <mat-radio-group [formControlName]="formField.name">
                <mat-radio-button [value]="0"> {{ 'GENDER_M' | transloco }} </mat-radio-button>
                &nbsp;
                <mat-radio-button [value]="1"> {{ 'GENDER_F' | transloco }} </mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'COUNTRY'">
            <mat-form-field appearance="outline" style="width: 100%" class="example-full-width">
              <mat-label>{{ formField.key | transloco }}</mat-label>
              <mat-select
                required
                style="margin-top: 5px; margin-bottom: 5px"
                placeholder="{{ 'COUNTRY' | transloco }}"
                [formControlName]="formField.name"
              >
                <mat-option *ngFor="let countryCode of countries" [value]="countryCode"
                  >{{ countryCode }} - {{ 'COUNTRY_' + countryCode | transloco }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'INPUT'">
            <mat-form-field appearance="outline" style="width: 100%; margin-top: 15px" class="example-full-width">
              <mat-label>{{ formField.key | transloco }}</mat-label>
              <input
                [formControlName]="formField.name"
                matInput
                type="text"
                placeholder="{{ formField.key | transloco }}"
              />
            </mat-form-field>
          </ng-container>

          <mat-form-field
            appearance="outline"
            *ngSwitchCase="'SELECT'"
            style="width: 100%; margin-top: 15px"
            class="example-full-width"
          >
            <mat-label>{{ formField.key | transloco }}</mat-label>
            <mat-select [placeholder]="formField.key | transloco" [formControlName]="formField.name">
              <mat-option *ngFor="let option of formField.fieldConfig.options" [value]="option.val">
                {{ option.key | transloco }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <div *ngSwitchCase="'FREE_TEXT'" class="example-full-width" [innerHTML]="formField.key  | transloco">
        </div> -->

          <mat-radio-group
            *ngSwitchCase="'OPTIONS'"
            class="example-full-width"
            [name]="formField.name | transloco"
            [formControlName]="formField.name"
          >
            <mat-radio-button *ngFor="let option of formField.fieldConfig.options" [value]="option.val">
              {{ option.key | transloco }}</mat-radio-button
            >
          </mat-radio-group>

          <div *ngSwitchCase="'CHECKBOX'">
            <mat-checkbox
              class="example-full-width"
              [name]="formField.name | transloco"
              [formControlName]="formField.name"
              color="primary"
            ></mat-checkbox>
            <span
              style="text-align: left; display: block; width: 90%; float: right"
              [innerHtml]="formField.key | transloco"
            ></span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="type === 'runner'">
        <div class="title">
          <h3>{{ 'SIGNUP_MESSAGE_FOR_ORGANIZER' | transloco }}</h3>
        </div>
        <mat-form-field appearance="outline" fxFlex="100">
          <textarea
            rows="3"
            placeholder="{{ 'SIGNUP_WRITE_YOUR_MESSAGE_HERE' | transloco }}"
            matInput
            formControlName="messageToOrganizer"
          >
          </textarea>
        </mat-form-field>

        <div class="title">
          <h3>{{ 'LEGAL_NOTICE' | transloco }}</h3>
        </div>

        <mat-checkbox class="checkbox-item" formControlName="swornStatement">
          {{ 'SWORN_STATEMENT' | transloco }}
        </mat-checkbox>
        <mat-checkbox class="checkbox-item" formControlName="allowMailContact">
          <!-- {{ 'ALLOW_BETRAIL_CONTACT_ME_BY_MAIL' | transloco : {trailTitle : trailTitle} }} -->
          <span [innerHtml]="'ALLOW_BETRAIL_CONTACT_ME_BY_MAIL' | transloco: { trailTitle: trailTitle }"></span>
        </mat-checkbox>
        <mat-checkbox class="checkbox-item" formControlName="confirmLegal">
          <span
            *ngIf="legalUrl; else noLegalUrl"
            [innerHtml]="'CONFIRM_LEGAL_WITH_URL' | transloco: { legalUrl: legalUrl }"
          ></span>
          <ng-template #noLegalUrl>
            <span [innerHtml]="'CONFIRM_LEGAL' | transloco: { legalUrl: legalUrl }"></span>
          </ng-template>
        </mat-checkbox>
      </ng-container>
    </div>
  </ng-container>
  <!-- <div class="flex-container  flex-start" style="padding: 2em;" fxLayout="column">
    <mat-form-field style=" width: 100%;" class="example-full-width">
      <mat-select formControlName="distance" required style="margin-top: 5px;margin-bottom: 5px;"
        placeholder="{{'DISTANCE' | transloco}}">
        <mat-option *ngFor="let distance of distances" [value]="distance.id">{{ distance.name }}
          ({{distance.prices[0].amount/100 | currency:'EUR':'symbol'}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input formControlName="firstname" required matInput type="text" placeholder="{{'FIRSTNAME' | transloco}}" />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input formControlName="lastname" required matInput type="text" placeholder="{{'NAME' | transloco}}" />
    </mat-form-field>

    <bt-birthdate-select style=" width: 100%;" formControlName="birthdate"></bt-birthdate-select>

    <div style="text-align: start; padding-bottom: 15px; ">
      <div class="birthlabel">{{'GENDER' | transloco}} *</div>
      <mat-radio-group formControlName="gender">
        <mat-radio-button [value]="0">
          {{'GENDER_M' | transloco}}
        </mat-radio-button> &nbsp;
        <mat-radio-button [value]="1">
          {{'GENDER_F' | transloco}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field class="example-full-width">
      <input formControlName="mail" required matInput type="text" placeholder="{{'EMAIL' | transloco}}" />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input formControlName="address" required matInput type="text" placeholder="{{'ADRES' | transloco}}" />
    </mat-form-field>

    <div>
      <mat-form-field style=" width: 100%;" class="example-full-width">
        <mat-select required style="margin-top: 5px;margin-bottom: 5px;" placeholder="{{'COUNTRY' | transloco}}"
          formControlName="country">
          <mat-option *ngFor="let state of states" [value]="state.Code">{{ state.Name | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <bt-dynamic-form [formGroup]="formGroup.controls['extraFields']" [extraFields]="extraFields"></bt-dynamic-form>


  </div> -->
</form>
