import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sponsor } from '@betrail-libs/shared/interfaces/sponsor.model';
import { LoadAllSponsors, TrailDataState } from '@betrail-libs/trail-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'bux-search-sponsor',
  templateUrl: './search-sponsor.component.html',
  styleUrls: ['./search-sponsor.component.scss'],
})
export class SearchSponsorComponent implements OnInit {
  @Select(TrailDataState.allSponsors) allSponsors$: Observable<Sponsor[]>;
  @Select(TrailDataState.userSponsors) userSponsors$: Observable<any[]>;

  sortedSponsors$: Observable<Sponsor[]>;
  selected: {};
  alreadySponsoredIds = [];

  @Input() spid = 0;
  @Input() existingSponsor = null;

  @Output() selectedSponsor = new EventEmitter();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadAllSponsors());
    this.sortedSponsors$ = this.allSponsors$.pipe(
      tap(sponsors => {
        if (sponsors)
          sponsors.sort((a, b) => {
            if (a.data.name > b.data.name) return 1;
            else if (a.data.name < b.data.name) return -1;
            else return 0;
          });
        if (this.existingSponsor !== null) {
          this.selected = sponsors.find(sponsor => sponsor.data.id === this.existingSponsor.id);
        }
      }),
    );
    this.userSponsors$.pipe(untilDestroyed(this)).subscribe(sponsors => {
      if (sponsors && sponsors.length > 0) {
        for (const s of sponsors) {
          this.alreadySponsoredIds.push(s.data.id);
        }
      }
    });
  }

  reset() {
    this.alreadySponsoredIds = [];
    this.ngOnInit();
  }

  selectSponsor(sponsor) {
    this.selectedSponsor.emit(sponsor);
  }

  filterSponsors(id) {
    if (this.spid !== 0) {
      return true;
    } else {
      if (this.alreadySponsoredIds.includes(id)) return false;
      else return true;
    }
  }
}
