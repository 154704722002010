import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { I18nState } from '../i18nState/i18n.state';
import { Observable, forkJoin } from 'rxjs';
import { UpdateTrad } from '../i18nState/i18n.action';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  traductionsDictionary = {};
  languages = ['fr', 'nl', 'en'];

  @Select(I18nState.bundleArray) tradArray: Observable<any[]>;

  constructor(private store: Store) {
    this.initialize();
  }

  initialize() {
    this.languages.forEach(language => {
      this.traductionsDictionary[language] = {};
    });

    this.tradArray.subscribe(trads => {
      if (trads) {
        trads.map(trad => {
          this.languages.forEach(language => {
            this.traductionsDictionary[language][`${trad.key}`] = trad[language];
          });
        });
      }
    });
  }

  /*
  where translations is : [{
    language : '',
    value : ''
  }]
   */
  updateTranslations(key, translations) {
    const requests = [];

    translations.forEach(translation => {
      requests.push(this.store.dispatch(new UpdateTrad(key, translation.language, translation.value)));
    });

    return forkJoin(requests);
  }
}
