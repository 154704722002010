<result-form-header [result]="data.result"></result-form-header>
<div class="container">
  <div class="trail">
    <form class="form" (submit)="confirm()" [formGroup]="optionForm">
      <div>
        <ng-container *ngIf="step === 1">
          <div
            *ngIf="(isAdmin$ | async) || (isEncoder$ | async); else userFirstTitle"
            class="question"
            [innerHTML]="'ADMIN_ATTRIBUTE_RESULT_TO_ANOTHER_RUNNER' | transloco"
          ></div>
          <ng-template #userFirstTitle>
            <div class="question" [innerHTML]="'TELL_US_WHY_THIS_RESULT_IS_NOT_YOURS' | transloco"></div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="step === 2 && chosenOption === 'bib_transfer'">
          <div
            *ngIf="(isAdmin$ | async) || (isEncoder$ | async); else userSecondTitle"
            class="question"
            [innerHTML]="'ADMIN_DO_YOU_KNOW_THE_BIB_OWNER' | transloco"
          ></div>
          <ng-template #userSecondTitle>
            <div class="question" [innerHTML]="'DO_YOU_KNOW_THE_BIB_OWNER' | transloco"></div>
          </ng-template>
        </ng-container>

        <div class="radios" *ngIf="step === 1">
          <mat-radio-group formControlName="option">
            <mat-radio-button value="bib_transfer">
              <ng-container *ngIf="(isAdmin$ | async) || (isEncoder$ | async); else userFirstOption">
                {{ 'ADMIN_CHANGE_RUNNER_EXISTING' | transloco }}
              </ng-container>
              <ng-template #userFirstOption>{{ 'SOMEONE_ELSE_RAN_WITH_MY_BIB' | transloco }}</ng-template>
            </mat-radio-button>
            <mat-radio-button value="homonymous">
              <ng-container *ngIf="(isAdmin$ | async) || (isEncoder$ | async); else userSecondOption">
                {{ 'ADMIN_CHANGE_RUNNER_HOMONYMOUS' | transloco }}
              </ng-container>
              <ng-template #userSecondOption>{{ 'THIS_RESULT_BELONGS_TO_HOMONYMOUS' | transloco }}</ng-template>
            </mat-radio-button>
            <!-- only admin / encoder option -->
            <ng-container *ngIf="(isAdmin$ | async) || (isEncoder$ | async)">
              <mat-radio-button value="new_runner">{{ 'ADMIN_CHANGE_RUNNER_TO_NEW' | transloco }}</mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>

        <div *ngIf="step === 2">
          <div *ngIf="chosenOption === 'homonymous' || chosenOption === 'new_runner'">
            <ng-container *ngTemplateOutlet="doneOrWorking"></ng-container>
          </div>

          <div *ngIf="chosenOption === 'bib_transfer'">
            <mat-radio-group formControlName="knownRunner" (change)="setRunnerKnown($event)">
              <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1em">
                <mat-radio-button [value]="true">{{ 'YES' | transloco }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ 'NO' | transloco }}</mat-radio-button>
              </div>
            </mat-radio-group>
            <div class="search" *ngIf="bibRunnerKnown">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'LASTNAME_FIRSTNAME' | transloco }}</mat-label>
                <input
                  class="searchBox"
                  fxFlex="100"
                  matInput
                  [placeholder]="'RACE_RUNNER_NAME' | transloco"
                  formControlName="runnerName"
                  #searchElem
                  [matAutocomplete]="auto"
                  (keyup)="onSearchChange($any($event).target.value)"
                  required
                />
              </mat-form-field>
              <mat-autocomplete
                (optionSelected)="onSearchSelect($any($event).option.value)"
                #auto="matAutocomplete"
                connectedTo="#searchElem"
              >
                <div id="close">
                  <button mat-icon-button (click)="searchClose()"><mat-icon>close</mat-icon></button>
                </div>
                <ng-container *ngIf="searchAutocomplete | async; let auto">
                  <ng-container *ngIf="(auto.runners && auto.runners.length > 0) || auto.runner">
                    <ng-container *ngFor="let runner of auto.runners">
                      <mat-option *ngIf="+data.runnerId !== +runner._id" [value]="runner">
                        <div class="search-item">
                          <div
                            *ngIf="runner?._source?.avatar; else defaultImg"
                            class="image-runner"
                            [style.background]="runner?._source?.avatar | sanitizeStyleUrl"
                          ></div>
                          <ng-template #defaultImg>
                            <div class="image-runner default">
                              <mat-icon>person</mat-icon>
                            </div>
                          </ng-template>
                          <div class="runner-info">
                            <span>{{ runner?._source.display_title }}</span>
                            <span class="runnerId"
                              >ID {{ runner?._source.ruid }} / {{ 'LEVEL' | transloco }} : {{ runner?._source?.bt_level
                              / 100 }}</span
                            >
                          </div>
                        </div>
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-autocomplete>
              <div
                *ngIf="!runner; else toRunner"
                class="description"
                innerHTML="{{ 'WRITE_FIRST_LETTERS_AND_CHOOSE' | transloco }}"
              ></div>
              <ng-template #toRunner>
                <div class="description">{{ 'RESULT_WILL_BE_ATTRIBUTED_TO' | transloco }} : {{ runner.title }}.</div>
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="step === 3">
          <ng-container *ngTemplateOutlet="doneOrWorking"></ng-container>
        </div>
      </div>

      <div class="actions" fxLayout="row" fxLayoutGap="0.7em" fxLayoutAlign="center">
        <button class="button light-button" mat-button (click)="cancel($event)">{{ closeText | transloco }}</button>
        <button
          *ngIf="step < 3 || (step === 2 && chosenOption === 'bib_transfer')"
          type="submit"
          [disabled]="step === 2 && bibRunnerKnown && runner === undefined"
          class="button"
          mat-raised-button
        >
          {{ 'CONTINUE' | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>
<ng-template #doneOrWorking>
  <div class="know" *ngIf="responseTxt === 'DONE'; else working">
    <mat-icon class="green">check</mat-icon> {{ 'DONE' | transloco }}
  </div>
</ng-template>
<ng-template #working>
  <em class="block py-8">{{ responseTxt | transloco }}</em>
</ng-template>
