<div class="fullFilter" *ngFor="let element of filteredElements">
  <strong>{{element.name | transloco}}</strong>
  <em *ngIf="description && description !== ''">{{description | transloco}}</em>
  <input
    matInput
    [value]="element.value | async"
    [placeholder]="element.label | transloco"
    #searchElem
    (keyup)="changeText($any($event).target.value)"
    class="filter-box"
  />
</div>
