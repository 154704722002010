import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RankingOptions } from '@betrail-libs/shared/interfaces/ranking-options.model';
import { Sponsor } from '@betrail-libs/shared/interfaces/sponsor.model';
import { RankingsStateService } from '../../../../rankings-page/services/rankings-state.service';

@Component({
  selector: 'app-edit-ranking-dialog',
  templateUrl: './edit-ranking-dialog.component.html',
  styleUrls: ['./edit-ranking-dialog.component.scss'],
})
export class EditRankingDialogComponent {
  editRankingForm: UntypedFormGroup = this.setForm();
  selectedSponsor: Sponsor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public options: RankingOptions,
    private dialogRef: MatDialogRef<EditRankingDialogComponent>,
    private rankingService: RankingsStateService,
    private fb: UntypedFormBuilder,
  ) {}

  setForm() {
    return this.fb.group({
      rankingName: [this.options.rankingName, Validators.required],
      sponsorName: [this.options.sponsorName],
      sponsor: [this.options.sponsor],
    });
  }

  selectSponsor(sponsor: Sponsor) {
    this.selectedSponsor = sponsor;
  }

  updateRanking() {
    const form = { ...this.editRankingForm.value };
    const rankingOptions = {
      ...this.options,
      rankingName: form.rankingName,
      sponsorName: form.sponsorName,
      spid: this.selectedSponsor ? this.selectedSponsor.data.id : this.options.sponsor ? this.options.sponsor.id : null,
    };
    this.rankingService.updateRankingOptions(rankingOptions);
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
