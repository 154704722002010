import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { LoginDialogComponent } from './login-dialog.component';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { IRunner } from '@betrail-libs/shared/interfaces/interfaces';
import {
  LoginAction,
  CreateUserAction,
  RetrievePasswordAction,
  CreateUserFailedAction,
  LoginFailedAction,
  IUserState,
} from '@betrail-libs/auth-state';
import { Actions, ofActionDispatched } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class LoginDialogService {
  isOpen = false;
  doNotClose = false;
  dialogRef: MatDialogRef<LoginDialogComponent, any>;
  mobile = false;
  userParams: any = {};

  constructor(public dialog: MatDialog, mediaMatcher: MediaMatcher, private actions$: Actions) {
    this.actions$.pipe(ofActionDispatched(CreateUserFailedAction)).subscribe(({ error }) => {
      this.displayError(error.statusText);
      this.enableButton('register');
    });

    this.actions$.pipe(ofActionDispatched(LoginFailedAction)).subscribe(() => {
      this.displayError('La connexion a échoué. Veuillez réessayer.');
      this.enableButton('login');
    });

    CreateUserFailedAction;

    this.mobile = mediaMatcher.matchMedia('(max-width: 600px)').matches;
    mediaMatcher.matchMedia('(max-width: 600px)').onchange = ev => {
      this.mobile = ev.matches;
      if (this.isOpen) {
        this.dialogRef.componentInstance.fullscreen = this.mobile;
      }
    };
  }

  openDialog(formType: string, runner): Observable<any> {
    if (!this.isOpen) {
      this.dialogRef = this.dialog.open(LoginDialogComponent, {
        panelClass: 'login-dialog',
        minWidth: '350px',
        maxWidth: '100vw',
        data: { formType, runner },
      });
      this.isOpen = true;
      this.dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(() => {
          this.isOpen = false;
          this.dialogRef = undefined;
        });
    }
    this.dialogRef.componentInstance.formType = formType;
    this.dialogRef.componentInstance.runner = runner;
    this.dialogRef.componentInstance.fullscreen = this.mobile;
    return this.dialogRef.componentInstance.action;
  }

  closeDialog() {
    if (this.isOpen && !this.doNotClose) {
      this.dialogRef.close();
    }
  }

  forceCloseDialog() {
    this.doNotClose = false;
    this.closeDialog();
  }

  parseUserParams(user: IUserState) {
    if (user?.userDrupal?.userParams) {
      this.userParams = JSON.parse(user.userDrupal.userParams);
    }
  }

  enableButton(type) {
    if (this.isOpen) {
      if (this.isOpen) {
        this.dialogRef.componentInstance.enableButton(type);
      }
    }
  }

  displayError(error: string) {
    if (this.isOpen) {
      if (this.isOpen) {
        this.dialogRef.componentInstance.error = error;
      }
    }
  }

  @Dispatch()
  openLoginDialog = (formType: 'login' | 'register', runner?: IRunner) => {
    return this.openDialog(formType, runner).pipe(
      map((dialogResult: { loginData: any; loginType: 'email' | 'register' | 'retrievePassword' }) => {
        switch (dialogResult.loginType) {
          case 'email':
            return new LoginAction(
              {
                user: dialogResult.loginData.user,
                password: dialogResult.loginData.password,
              },
              true,
            );
          case 'register':
            return new CreateUserAction(dialogResult.loginData);
          case 'retrievePassword':
            return new RetrievePasswordAction({ name: dialogResult.loginData.user });
        }
      }),
    );
  };
}
