import {
  Component,
  Input,
  forwardRef,
  AfterViewInit,
  ViewEncapsulation,
  EventEmitter,
  Output,
  AfterViewChecked,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ButtonsToggleComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'buttons-toggle',
  templateUrl: './buttons-toggle.component.html',
  styleUrls: ['./buttons-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ButtonsToggleComponent implements ControlValueAccessor, AfterViewInit, AfterViewChecked, OnDestroy {
  // INPUTS
  @Input() defaultValue: string;
  @Input() multipleValuesAllowed: boolean = false;
  @Input() options: ButtonsToggleOption[] = [];
  @Input() label: string;
  @Input() required: boolean;
  @Input() style: string = 'default';
  @Output() btnSelected = new EventEmitter();
  @Input() message: string;

  //current form control input. helpful in validating and accessing form control
  @Input() c: UntypedFormControl = new UntypedFormControl();
  @Input() cName: string;

  // set true if we need not show the asterisk in red color
  @Input() optional: boolean = false;

  @Output() changed = new EventEmitter();

  //@Input() v:boolean = true; // validation input. if false we will not show error message.

  //The internal data model for form control value access
  innerValue: any = '';

  // errors for the form control will be stored in this array
  errors: Array<any> = ['This field is required'];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  //Lifecycle hook. angular.io for more info
  ngAfterViewInit() {
    // RESET the custom input form control UI when the form control is RESET
    this.innerValue = this.defaultValue ? this.defaultValue : '';
    this.c.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      // check condition if the form control is RESET
      if (this.c.value === '' || this.c.value === null || this.c.value === undefined) {
        this.innerValue = '';
      }
    });
  }

  ngOnDestroy(): void {
    this.changeDetectorRef.detach();
  }

  // event fired when input value is changed . later propagated up to the form control using the custom value accessor interface
  onChange(e: Event, value: any) {
    //set changed value
    this.innerValue = value;
    // propagate value into form control using control value accessor interface
    this.propagateChange(this.innerValue);
    //output value event
    this.btnSelected.emit(value);
    //reset errors
    this.errors = [];
    //setting, resetting error messages into an array (to loop) and adding the validation messages to show below the field area
    for (let key in this.c.errors) {
      if (this.c.errors.hasOwnProperty(key)) {
        if (key === 'required') {
          this.errors.push('This field is required');
        } else {
          this.errors.push(this.c.errors[key]);
        }
      }
    }
    this.changed.emit(value);
  }

  //get accessor
  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  //propagate changes into the custom form control
  propagateChange = (_: any) => {};

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this.innerValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {}
}

export interface ButtonsToggleOption {
  value: string | number;
  label: string;
  disabled?: boolean;
  style?: string;
  info?: string;
}
