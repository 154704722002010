import { Injectable } from '@angular/core';
import { ERankingCode, ERankingName } from '@betrail-libs/shared/interfaces/interfaces';
import { RankingOptions } from '@betrail-libs/shared/interfaces/ranking-options.model';
import { Store } from '@ngxs/store';
import {
  LoadAllRankingsOptions,
  LoadRankings,
  LoadRankingSummary,
  RemoveSponsorFromRanking,
  ResetGroupRankings,
  SetGroupParams,
  SetRankingCountry,
  UpdateRankingOptions,
} from './rankings.action';
import { RankingsState } from './rankings.state';

export const monthByRanking = {
  [ERankingCode.TRAILCUP]: 2,
  [ERankingCode.ULTRACUP]: 2,
  [ERankingCode.SCORE_TRAIL]: 6,
  [ERankingCode.SCORE_ULTRA]: 6,
};

export const rankingCodeFromName = {
  [ERankingName.LEVEL]: ERankingCode.LEVEL,
  [ERankingName.TRAILCUP]: ERankingCode.TRAILCUP,
  [ERankingName.ULTRACUP]: ERankingCode.ULTRACUP,
  [ERankingName.SCORE_TRAIL]: ERankingCode.SCORE_TRAIL,
  [ERankingName.SCORE_ULTRA]: ERankingCode.SCORE_ULTRA,
};

export const rankingNameFromCode = {
  [ERankingCode.LEVEL]: ERankingName.LEVEL,
  [ERankingCode.TRAILCUP]: ERankingName.TRAILCUP,
  [ERankingCode.ULTRACUP]: ERankingName.ULTRACUP,
  [ERankingCode.SCORE_TRAIL]: ERankingName.SCORE_TRAIL,
  [ERankingCode.SCORE_ULTRA]: ERankingName.SCORE_ULTRA,
};

@Injectable({ providedIn: 'root' })
export class RankingsStateService {
  getRanking$ = this.store.select(RankingsState.getRanking);
  getIsLoading$ = this.store.select(RankingsState.getIsLoading);
  rankingLoadings$ = this.store.select(RankingsState.rankingLoadings);
  rankingsOptions$ = this.store.select(RankingsState.allRankingsOptions);
  getRankingOptions$ = this.store.select(RankingsState.getRankingOptions);
  getRankingOptionsByCountry$ = this.store.select(RankingsState.getRankingOptionsByCountry);
  getRankingCountry$ = this.store.select(RankingsState.getRankingCountry);
  selectedRankingCountries$ = this.store.select(RankingsState.selectedRankingCountries);
  groupParams$ = this.store.select(RankingsState.groupParams);

  constructor(private store: Store) {}

  loadRankingSummary = (
    ranking: ERankingCode,
    type?: string,
    country?: string,
    params?: { userId: number; groupId: number },
    forceReload = false,
  ) => this.store.dispatch(new LoadRankingSummary(ranking, type, country, params, forceReload));

  loadAllRankings = (ranking: ERankingCode, gender: 'female' | 'scratch', type: string, country?: string) =>
    this.store.dispatch(new LoadRankings(ranking, gender, type, country));

  setRankingCountry = (ranking: ERankingCode, country: string) =>
    this.store.dispatch(new SetRankingCountry(ranking, country));

  getIfRankingIsLoading = (ranking: ERankingCode, country: string, gender: 'female' | 'scratch') => {
    const loading = this.store.selectSnapshot(RankingsState.rankingLoadings);
    return loading[ranking]?.[country + gender];
  };

  setGroupParams = (params?: { userId: number; groupId: number }) => this.store.dispatch(new SetGroupParams(params));

  getGroupParams = () => this.store.selectSnapshot(RankingsState.groupParams);

  loadAllRankingsOptions = () => this.store.dispatch(new LoadAllRankingsOptions());

  updateRankingOptions = (options: RankingOptions) => this.store.dispatch(new UpdateRankingOptions(options));

  removeSponsorFromRanking = (rankingId: string | number) =>
    this.store.dispatch(new RemoveSponsorFromRanking(rankingId));

  getCurrentRankingYear = (ranking: ERankingCode) => {
    const now = new Date();
    if (now.getMonth() < monthByRanking[ranking]) return now.getFullYear() - 1;
    else return now.getFullYear();
  };

  areAllRankingsOfSameCountry = () => {
    const selectedCountries = this.store.selectSnapshot(RankingsState.selectedRankingCountries);
    const country = Object.values(selectedCountries)[0];
    for (const type in selectedCountries) {
      if (selectedCountries[type] !== country) {
        return false;
      }
    }
    return true;
  };

  resetGroupRankings = (groupId: number) => this.store.dispatch(new ResetGroupRankings(groupId));
}
