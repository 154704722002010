import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'calendar-filter-checkboxes',
  templateUrl: './calendar-filter-checkboxes.component.html',
  styleUrls: ['./calendar-filter-checkboxes.component.scss'],
})
export class CalendarFilterCheckboxesComponent {
  elements: any[];
  values = new BehaviorSubject<string[]>([]);
  summary = this.values.pipe(map(values => this.getSummary(values)));

  @Input()
  set filteredElements(filteredElements: any[]) {
    const values = filteredElements.filter(el => el.value == true).map(el => el.name);
    this.elements = filteredElements;
    this.changeValues(values);
  }
  @Input()
  set _values(values) {
    if (values && values.length > 0) {
      this.values.next(values);
    }
  }
  @Input() quickFilters;
  @Input() description: string;
  @Input() cancelable = true;

  @Output() checkboxesFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();
  @Output() apply = new EventEmitter();

  getSummary(values) {
    let summary = '';
    if (this.elements != undefined) {
      summary = values.map(val => this.elements.find(e => e.name == val).label).join(', ');
    }
    return summary;
  }

  changeValue(value, elementName) {
    this.elements.find(e => e.name == elementName).value = value;
    this.changeValues(this.elements.filter(e => e.value == true).map(e => e.name));
  }

  changeValues(values) {
    this.values.next(values);
    this.checkboxesFilter.emit(values);
    let summary = this.getSummary(values);
    if (values.length === 2) {
      if (values.includes('s') && values.includes('l')) {
        summary = 'FILTER_CHALLENGE_SHORT';
      } else if (values.includes('ultra') && values.includes('ultra_xl')) {
        summary = 'FILTER_CHALLENGE_ULTRA';
      }
    }
    this.updateSummary.emit(summary);
  }

  isIncluded(elementName, values) {
    return values.includes(elementName);
  }

  activeQuickFilter(values, summary) {

    this.elements.map((el, i) => {
      if (values.includes(el.name)) {
        this.elements[i].value = true;
      } else {
        this.elements[i].value = false;
      }
    });
    this.values.next(values);
    this.checkboxesFilter.emit(values);
    if (values.length === 2) {
      if (values.includes('s') && values.includes('l')) {
        summary = 'FILTER_CHALLENGE_SHORT';
      } else if (values.includes('ultra') && values.includes('ultra_xl')) {
        summary = 'FILTER_CHALLENGE_ULTRA';
      }
    }
    this.updateSummary.emit(summary);
    this.apply.emit();
  }
}
