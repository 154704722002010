import { Injectable } from '@angular/core';
import { IAdminSettings } from '@betrail-libs/shared/interfaces/interfaces';
import { EPopUpStatus, EPopUpTarget, IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import produce from 'immer';
import { tap } from 'rxjs';
import { AdminApiService } from './admin-api.service';
import {
  AddViewToPopup,
  ChangePopupStatus,
  CheckForActivePopUp,
  CreateNewPopUp,
  CreatePopupLog,
  EditPopupData,
  LoadAllPopUps,
  LoadAllSettings,
  SaveAdminSetting,
} from './admin.action';

export interface AdminStateModel {
  popups: { [id: number]: IPopUpData };
  activePopups: number[];
  settings: { [type: string]: IAdminSettings };
}

@State<AdminStateModel>({
  name: 'admin',
  defaults: {
    popups: {},
    activePopups: [],
    settings: {},
  },
})
@Injectable()
export class AdminState {
  constructor(private adminApi: AdminApiService) {}

  ngxsOnInit(ctx: StateContext<AdminStateModel>) {
    ctx.dispatch([new CheckForActivePopUp(), new LoadAllSettings()]);
  }

  @Selector()
  public static allPopUps(state: AdminStateModel) {
    const allPopups = Object.keys(state.popups)
      .map(key => state.popups[key])
      .sort((a, b) => b.id - a.id);
    return [
      ...allPopups.filter(p => p.status !== EPopUpStatus.stopped),
      ...allPopups.filter(p => p.status === EPopUpStatus.stopped),
    ];
  }

  @Selector()
  public static activePopUps(state: AdminStateModel) {
    return state.activePopups.map(id => state.popups[id]).sort((a, b) => b.id - a.id);
  }

  @Selector()
  public static activePopupForAll(state: AdminStateModel) {
    const activePopups = state.activePopups.map(id => state.popups[id]);
    return activePopups.find(popup => popup.target === EPopUpTarget.all);
  }

  @Selector()
  public static settings(state: AdminStateModel) {
    return state.settings;
  }

  @Action(CreateNewPopUp)
  createNewPopup(ctx: StateContext<AdminStateModel>, action: CreateNewPopUp) {
    return this.adminApi.createPopup(action.newPopup).pipe(
      tap(popup => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.popups[popup.id] = popup;
          }),
        );
      }),
    );
  }

  @Action(LoadAllPopUps)
  loadAllPopups(ctx: StateContext<AdminStateModel>) {
    return this.adminApi.getAllPopUps().pipe(
      tap(popups => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            for (const popup of popups) {
              if (!draft.popups[popup.id]) {
                draft.popups[popup.id] = popup;
              }
            }
          }),
        );
      }),
    );
  }

  @Action(EditPopupData)
  editPopupData(ctx: StateContext<AdminStateModel>, action: EditPopupData) {
    return this.adminApi.editPopup(action.editedPopup).pipe(
      tap(popup => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.popups[action.editedPopup.id] = popup;
          }),
        );
      }),
    );
  }

  @Action(AddViewToPopup)
  addViewToPopup(ctx: StateContext<AdminStateModel>, action: AddViewToPopup) {
    return this.adminApi.addPopupView(action.popupId).pipe(
      tap(popup => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.popups[action.popupId] = popup;
          }),
        );
      }),
    );
  }

  @Action(CreatePopupLog)
  createPopupLog(ctx: StateContext<AdminStateModel>, action: CreatePopupLog) {
    return this.adminApi.createPopupLog({ ...action }).pipe(
      tap(popup => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.popups[action.popupId] = popup;
          }),
        );
      }),
    );
  }

  @Action(ChangePopupStatus)
  changePopupStatus(ctx: StateContext<AdminStateModel>, action: ChangePopupStatus) {
    return this.adminApi.changeStatusOfPopup(action.popupId, action.newStatus, action.oldStatus).pipe(
      tap(popup => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.popups[action.popupId] = popup;
            if (action.newStatus === EPopUpStatus.active) {
              draft.activePopups.push(action.popupId);
            } else if (draft.activePopups.includes(action.popupId)) {
              draft.activePopups = draft.activePopups.filter(id => id !== action.popupId);
            }
          }),
        );
      }),
    );
  }

  @Action(CheckForActivePopUp)
  checkForActivePopUp(ctx: StateContext<AdminStateModel>) {
    return this.adminApi.getActivePopups().pipe(
      tap(popups => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            if (popups?.length > 0) {
              for (const popup of popups) {
                draft.activePopups.push(popup.id);
                draft.popups[popup.id] = popup;
              }
            }
          }),
        );
      }),
    );
  }

  @Action(LoadAllSettings)
  loadAllSettings(ctx: StateContext<AdminStateModel>) {
    return this.adminApi.getAllAdminSettings().pipe(
      tap(settings => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.settings = settings;
          }),
        );
      }),
    );
  }

  @Action(SaveAdminSetting)
  saveAdminSetting(ctx: StateContext<AdminStateModel>, action: SaveAdminSetting) {
    return this.adminApi.saveAdminSetting(action.setting).pipe(
      tap(setting => {
        ctx.setState(
          produce((draft: AdminStateModel) => {
            draft.settings[action.setting.type] = setting;
          }),
        );
      }),
    );
  }
}
