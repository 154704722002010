import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  getImageObservableFromFile(file: File) {
    return new Observable((sub: Subscriber<string>) => {
      this.readFile(file, sub);
    });
  }

  readFile(file: File, sub: Subscriber<string | ArrayBuffer>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      sub.next(fileReader.result);
      sub.complete();
    };
    fileReader.onerror = err => {
      sub.error(err);
      sub.complete();
    };
  }
}
