import { Component, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { EventService } from '@betrail-libs/trail-data-state';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
  @ViewChild('addImageButton', { static: true })
  updatedImage: any = "Pas encore d'image chargée";
  addImageButton: ElementRef;
  addImageButtonDisabled: boolean = false;
  dialogResult: any;
  @Output() imageUploaded = new EventEmitter();
  @Input() source;
  @Input() addto: { type: string; nid: number; isRunner?: boolean };
  @Input() buttonText: string;

  constructor(public dialog: MatDialog, public eventService: EventService, private store: Store) {}

  addImage(file: File): void {
    if (file.size < 2000000) {
      if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
        this.addImageButtonDisabled = true;
        this.getBase64(file).then(data => {
          try {
            this.eventService.postDrupalImage(file.name, JSON.stringify(data), this.addto).subscribe(r => {
              //this.store.dispatch(new LoadRunnerForAlias(this.runner.alias));
              this.updatedImage = r;
              this.addImageButtonDisabled = false;
              this.imageUploaded.emit(this.updatedImage);
            });
          } catch (error) {
            this.addImageButtonDisabled = false;
          }
        });
      } else {
        this.openDialog('Bon, en fait... seules les images de type JPG, JPEG ou PNG sont autorisées.', 'Oups !');
      }
    } else {
      this.openDialog('Le fichier soumis est trop grand... A vrai dire, il ne peut guère dépasser les 2 Mo.', 'Aie !');
    }
  }

  removeImage(): void {
    this.updatedImage = '';
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  openDialog(error, title) {
    let dialogRef = this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { error, title },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result;
    });
  }
}
