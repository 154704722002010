import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthStateService, CompleteAuthRunnerData } from '@betrail-libs/auth-state';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';
import { Runner } from '@betrail-libs/shared/interfaces/runner.model';
import { COVERED_COUNTRIES, validRunnerBirthdate } from '@betrail-libs/shared/utils';
import { UpdateRunnerById } from '@betrail-libs/trail-data-state';
import { BetrailUxFormsService } from '@betrail/ux/betrail-ux-forms.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'welcome-steps',
  templateUrl: './welcome-steps.component.html',
  styleUrls: ['./welcome-steps.component.scss', '../welcome-steps-common.scss'],
})
export class WelcomeStepsComponent implements OnInit {
  department: string;
  region_departments: { code: string; title: string; region: string; url_alias: string }[];
  region: string;
  country: any;
  userParams: any = {};
  doneSteps: number = 0;
  registrationForm: UntypedFormGroup;
  runnerMissingDataFormGroup: UntypedFormGroup;
  welcomeStep: string = 'welcome';
  countries = COVERED_COUNTRIES;
  _runner: Runner;
  _user;

  user$ = this.authState.getUser();

  @Output() changed = new EventEmitter();
  @Input() welcomeSteps: any;
  @Input() newValues;
  @Input()
  set runner(runner: Runner) {
    this.fillRunnerMissingDataFormGroup(runner);
    this._runner = runner;
  }
  @Input() isLightUser = false;

  constructor(private store: Store, private formsService: BetrailUxFormsService, private authState: AuthStateService) {}

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      this._user = user;
      const isLightUser = user?.roles.filter(role => role.rid === DrupalRole.LightUser).length > 0;
      if (!this._user?.userDrupal?.userParams && !isLightUser) {
        this.addStep('defaultCountry');
      } else {
        this.userParams = JSON.parse(this._user?.userDrupal?.userParams || '{}');
        if ((!this.userParams.defaultCountry || !this.userParams.defaultDepartment) && !isLightUser) {
          this.addStep('defaultCountry');
        } else {
          this.deleteStep('defaultCountry');
        }
      }
    });
  }

  addStep(name) {
    // changing end step
    Object.keys(this.welcomeSteps).forEach(step => {
      if (this.welcomeSteps[step].next === 'done') {
        this.welcomeSteps[step].next = name;
      }
    });
    // adding a step
    this.welcomeSteps[name] = {
      next: 'done',
      display: true,
    };
  }

  deleteStep(name) {
    if (this.welcomeSteps) {
      if (this.welcomeSteps[name]) {
        // keeping the next step of deleted step
        let next = this.welcomeSteps[name].next;
        // deleting the step
        delete this.welcomeSteps[name];
        // replacing next step of previous step
        Object.keys(this.welcomeSteps).forEach(step => {
          if (this.welcomeSteps[step].next === name) {
            this.welcomeSteps[step].next = next;
          }
        });
      }
    }
  }

  fillRunnerMissingDataFormGroup(runner) {
    this.runnerMissingDataFormGroup = this.formsService.buildRunnerMissingDataForm(runner, this.newValues);
    let validBirthdate = false;
    if (runner && runner.birthdate) {
      validBirthdate = validRunnerBirthdate(runner.birthdate);
    }
    if (this.runnerMissingDataFormGroup.valid && validBirthdate) {
      this.deleteStep('identity');
    }
  }

  saveRunnerMissingData(data: {
    firstname: string;
    lastname: string;
    place: string;
    postal: string;
    country: string;
    nationality: string;
    gender: number;
    birthdate: Date;
  }) {
    this.store.dispatch(new UpdateRunnerById(this._runner.id.toString(), data));
    const runnerData = { ...data, birthdate: new Date(data.birthdate).getTime() / 1000, postal_code: data.postal };
    delete runnerData.postal;
    this.store.dispatch(new CompleteAuthRunnerData(runnerData));
    this.nextStep();
  }

  numberOfWelcomeSteps() {
    return (
      Object.keys(this.welcomeSteps)
        .map(key => this.welcomeSteps[key])
        .filter(s => s && s.display == true).length - 2
    );
  }

  nextStep(step: string = this.welcomeStep) {
    this.doneSteps++;
    if (this.welcomeSteps[step] && this.welcomeSteps[step]?.next !== false) {
      if (this.welcomeSteps[this.welcomeSteps[step]?.next]?.display === true) {
        this.welcomeStep = this.welcomeSteps[step]?.next;
        if (this.welcomeStep == 'done') {
        }
      } else {
        this.nextStep(this.welcomeSteps[step]?.next);
        this.changed.emit(this.welcomeSteps[step]?.next);
      }
    } else {
      this.done();
    }
  }

  done() {
    this.changed.emit('done');
  }

  close() {
    this.changed.emit('done');
  }
}
