<div class="content">
  <header class="form-title">{{ 'MESSAGE_FROM' | transloco }} {{ data?.firstname }} {{ data?.lastname}}</header>

  <div class="form-content">
    <p>{{ data?.messageToOrganizer }}</p>
    <strong>Répondre : </strong> par email à {{ data?.email || data?.mail }}
    <div class="actions-container" fxLayout="row" fxLayoutAlign="space-between">
      <button class="button" mat-button (click)="close()">{{ 'CLOSE' | transloco }}</button>
    </div>
  </div>
</div>
