import { Component, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic-premium-dialog',
  templateUrl: './basic-premium-dialog.component.html',
  styleUrls: ['./basic-premium-dialog.component.scss'],
})
export class BasicPremiumDialogComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: { text: string; replacements?: unknown };
      buttons: { text: string; id: string }[];
    },
    private dialogRef: MatDialogRef<BasicPremiumDialogComponent>,
    private focusMonitor: FocusMonitor,
    public router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.focusMonitor.stopMonitoring(document.getElementById('okBtn'));
    this.focusMonitor.stopMonitoring(document.getElementById('cancelBtn'));
    this.focusMonitor.stopMonitoring(document.getElementById('premiumBtn'));
    this.focusMonitor.stopMonitoring(document.getElementById('feedSettings'));
  }

  getBtnAction(btn: { text: string; id: string }) {
    if (btn.id === 'premiumBtn') {
      this.dialogRef.close();
      this.router.navigate(['/', 'premium', 'discover']);
    } else if (btn.id === 'feedSettings') {
      this.dialogRef.close();
      this.router.navigate(['/', 'runner', 'my-page', 'news-feed', 'settings']);
    } else {
      this.dialogRef.close();
    }
  }
}
