import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss'],
})
export class EventHeaderComponent {
  @Input() event;
  @Input() trail;
}
