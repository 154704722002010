<span class="place geolocation" *ngIf="latitude && longitude; else default">
  <span *ngIf="country" class="countryFlag" [style.background-image]="'url(/assets/img/'+country+'.jpg)'"></span>
  <span *ngIf="pc != undefined && pc != ''">{{ pc }}&nbsp;</span>
  <span class="place-name"
    ><a
      [routerLink]="
        '/' +
        ('TRAIL_CALENDAR_URL' | transloco) +
        '/' +
        ('COUNTRY_URL_' + (country || 'ALL') | transloco) +
        '/' +
        _uriPlace
      "
      [queryParams]="{ lat: latitude, lon: longitude, c: pc, d: country == 'FR' ? _department?.code || '' : '' }"
      title="{{ (country ? 'TRAIL_CALENDAR_' + country : 'TRAIL_CALENDAR') | transloco }} - {{
        'RACE_NEARBY' | transloco
      }} {{ _place | capitalize }}"
      >{{ _place | capitalize }}</a
    >
    <span *ngIf="_department && country == 'FR'"
      >&nbsp;(<a
        [routerLink]="
          '/' +
          ('TRAIL_CALENDAR_URL' | transloco) +
          '/' +
          ('COUNTRY_URL_' + (country || 'ALL') | transloco) +
          '/' +
          _department.url_alias
        "
        title="{{ 'TRAIL_CALENDAR' | transloco }} - {{ 'RACES_IN' | transloco }} {{ _department.title }}"
        >{{ _department?.code }}</a
      >)</span
    >
  </span>
  <span *ngIf="section" class="place-section">{{ section }}</span>
</span>

<ng-template #default>
  <span class="place">
    <span *ngIf="country" class="countryFlag" [style.background-image]="'url(/assets/img/'+country+'.jpg)'"></span>
    <span *ngIf="pc != undefined && pc != ''">{{ pc }}&nbsp;</span>
    <span class="place-name"
      >{{ _place | capitalize }} <span *ngIf="_department && country == 'FR'">({{ _department?.code }})</span>
    </span>
    <span *ngIf="section" class="place-section">{{ section }}</span>
  </span>
</ng-template>
