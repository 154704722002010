import { Injectable } from '@angular/core';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PlatformServiceService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  isSsr(): boolean {
    return !this.isBrowserRendering();
  }

  isBrowserRendering(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
