import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEliteLevel } from '@betrail-libs/shared/interfaces/interfaces';

@Component({
  selector: 'app-runner-elites-follow-dialog',
  templateUrl: './runner-elites-follow-dialog.component.html',
})
export class RunnerElitesFollowDialogComponent {
  elitesModels: { [nationality: string]: { [elid: number]: IEliteLevel } } = {};
  selectedValues = {
    ['BE']: 0,
    ['CH']: 0,
    ['FR']: 0,
    ['LU']: 0,
    ['NL']: 0,
    ['ALL']: 0,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { elitesByNationality: IEliteLevel[][]; userElites: { elid: number; nationality: string }[] },
    private dialogRef: MatDialogRef<RunnerElitesFollowDialogComponent>,
  ) {
    for (const elites of data.elitesByNationality) {
      for (const elite of elites) {
        if (!(elite.nationality in this.elitesModels)) {
          this.elitesModels[elite.nationality] = {};
        }
        this.elitesModels[elite.nationality][elite.id] = elite;
      }
      for (const userElite of data.userElites) {
        this.selectedValues[userElite.nationality] = userElite.elid;
      }
    }
  }

  saveElitePreferences() {
    this.dialogRef.close(this.selectedValues);
  }
}
