<div class="quickFilters" *ngIf="quickFilters && quickFilters.length > 0">
  <strong>{{'FILTER_QUICK_FILTERS' | transloco}}</strong>
  <ng-container *ngFor="let quickFilter of quickFilters">
    <span
      class="quickFilter"
      (click)="activeQuickFilter(quickFilter.values, quickFilter.title)"
      [class.active]="quickFilter.values === (values | async)"
      >{{quickFilter.title | transloco}}</span
    >
  </ng-container>
</div>
<div class="fullFilter">
  <strong>{{'FILTER_CUSTOMIZE_FILTER' | transloco}}</strong>
  <em *ngIf="description">{{description | transloco}}</em>
  <div *ngFor="let element of elements; let i=index">
    <div *ngIf="element.name === 'label'" class="special-separator">
      <strong>{{ 'FILTER_WITH_LABEL' | transloco }} (Benelux)</strong>
    </div>
    <mat-slide-toggle
      color="#E8A30C"
      [id]="element.name"
      [name]="element.name"
      [checked]="isIncluded(element.name, (values | async))"
      (change)="changeValue($event.checked, $event.source.id)"
      >{{element.label | transloco}}</mat-slide-toggle
    >
  </div>
</div>
