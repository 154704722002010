import { animate, animateChild, group, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminStateService } from '@betrail-libs/admin-state';
import { AuthStateService, IUserState } from '@betrail-libs/auth-state';
import { IoService } from '@betrail-libs/io-service';
import { EPopUpTarget, EPopUpType, IPopUpData } from '@betrail-libs/shared/interfaces/pop-up.model';
import { TrackingStateService } from '@betrail-libs/tracking-state';
import { LoginDialogService } from '@betrail/ux/components/login-dialog/login-dialog.service';
import { PremiumSubscriptionDialogComponent } from '@betrail/ux/components/premium-subscription-dialog/premium-subscription-dialog.component';
import { WelcomeDialogService } from '@betrail/ux/components/welcome-dialog/welcome-dialog.service';
import { PlatformServiceService } from '@betrail/ux/platform-service.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Store as NgxsStore, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { distinct, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppStateService } from './app-state.service';
import { DialogService } from './common/dialog/dialogService';
import { LoadBundle, UseLang } from './i18nState/i18n.action';
import { RankingsStateService } from './rankings-page/services/rankings-state.service';
// import { LoadAllRankingsOptions } from './rankings-page/rankings-data.action';

/* const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    group([
      style({
        opacity: 0,
      }),
      animate('0.3s', style({ opacity: 1 })),
      animateChild(),
    ]),
  ]),
]); */

@UntilDestroy()
@Component({
  selector: 'app-root',
  // animations: [routerTransition],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  envName = environment.envName;
  langs = ['fr', 'nl', 'en'];
  userLang?: string;
  leftBarStatus = this.isVeryLarge();
  organizerLeftBarStatus = false;
  loading = this.states.isLoading();
  user$ = this.authState.getUser();
  hideCampaignBanner = false;
  bannerHidden$ = this.adminService.getIfAdBannerIsHidden();
  date = new Date();
  runner$ = this.authState.getRunner();
  userSetup$ = this.authState
    .getUser()
    .pipe(
      untilDestroyed(this),
      tap(user => {
        const now = Date.now();
        // + check if user is premium and if subscription is active or if there is a payment reminder to show
        if (user?.subscriptions?.length > 0) {
          for (const subscription of user.subscriptions) {
            if (!user?.runner?.is_premium && subscription?.status === 4) {
              const reminder = localStorage.getItem('premiumReminder_' + user.uid);
              if (!reminder || +reminder < now) {
                this.dialog.open(PremiumSubscriptionDialogComponent, {
                  disableClose: false,
                  data: subscription,
                  closeOnNavigation: true,
                });
              }
              break;
            }
          }
        }
        // + check if the add campaign banner should be hidden
        this.hideCampaignBanner = user?.runner?.is_premium === 1 && user?.userProfile?.hide_other_banner;

        // + set or reset user data for runner tracking system
        this.trackingService.setUserData(
          +(user?.uid || 0),
          +(user?.runner?.id || 0),
          user?.runner?.is_premium === 1,
          user?.runnerTracking || [],
          (user?.eliteTracking || []).map(t => ({ elid: t.elid, nationality: t.eliteLevel.nationality })),
          user?.trackingGroups || [],
          user?.runner?.trackings || [],
        );
        // + logic for popup display
        const activePopups = this.adminService.getActivePopupsSnapShot();
        const l = this.translate.getActiveLang();
        const lang = (l.charAt(0).toUpperCase() + l.slice(1)) as 'Fr' | 'Nl' | 'En';

        if (!user) {
          // * user without account or not logged in
          const popupForAll = activePopups.find(p => p.target === EPopUpTarget.all);
          if (popupForAll) {
            const popupForAllRead = localStorage.getItem('popupForAllRead');
            // => if popup for all is not read yet, show it
            if (!popupForAllRead || +popupForAllRead !== popupForAll.id) {
              localStorage.setItem('popupForAllRead', '' + popupForAll.id);
              setTimeout(() => this.dialogService.showAnnouncementPopUp(popupForAll, lang, -1), 5000);
              this.adminService.addViewToPopup(popupForAll.id);
            }
          }
        } else {
          // * user with an account and logged in
          const userCreationDate = user?.created * 1000;
          const tenMinutes = 10 * 60 * 1000;
          const userPopupLogs = user.popupLogs;
          if (userCreationDate + tenMinutes < now) {
            // => if user was created more than 10 minutes ago, check if he already read a popup
            const showAddPopups = user?.runner?.is_premium !== 1 || !user?.userProfile?.hide_other_banner;
            if (userPopupLogs?.length > 0) {
              const lastReadDate = new Date(userPopupLogs[userPopupLogs.length - 1].createdAt).getTime();
              const twoDays = 2 * 24 * 60 * 60 * 1000;
              // => if last popup was read more than 2 days ago, show the most recent not read
              if (lastReadDate + twoDays < now) {
                const lastNotReadPopup = activePopups.find(p => !userPopupLogs.find(l => l.pdid === p.id));
                // => if a popup not read yet & is for user country & user allow adds OR popup is not add, show it
                if (
                  lastNotReadPopup &&
                  (showAddPopups ||
                    lastNotReadPopup?.type === EPopUpType.article ||
                    lastNotReadPopup?.type === EPopUpType.tips) &&
                  this.isPopUpForUserCountryAndAccount(user, lastNotReadPopup)
                ) {
                  setTimeout(() => this.dialogService.showAnnouncementPopUp(lastNotReadPopup, lang, +user.uid), 10000);
                  this.adminService.addViewToPopup(lastNotReadPopup.id);
                }
              }
            } else {
              // => if no popup read, show the most recent if it's not an add or if user allow adds
              const mostRecentPopup = activePopups?.[0];
              if (
                mostRecentPopup &&
                (showAddPopups ||
                  mostRecentPopup?.type === EPopUpType.article ||
                  mostRecentPopup?.type === EPopUpType.tips) &&
                this.isPopUpForUserCountryAndAccount(user, mostRecentPopup)
              ) {
                setTimeout(() => this.dialogService.showAnnouncementPopUp(mostRecentPopup, lang, +user.uid), 10000);
                this.adminService.addViewToPopup(mostRecentPopup.id);
              }
            }
          }
        }
      }),
      map(user => user?.language || 'fr'),
      distinct(),
    )
    .subscribe(lang => {
      this.userLang = lang;
      this.ngxsStore.dispatch([new LoadBundle(lang), new UseLang(lang)]);
    });

  @Select(RouterState.url) url$: Observable<string>;

  constructor(
    public ioService: IoService,
    public translate: TranslocoService,
    private ngxsStore: NgxsStore,
    private states: AppStateService,
    private authState: AuthStateService,
    public el: ElementRef,
    public pps: PlatformServiceService,
    public loginDialogService: LoginDialogService,
    public dialog: MatDialog,
    public welcomeDialogService: WelcomeDialogService,
    private dialogService: DialogService,
    private adminService: AdminStateService,
    private trackingService: TrackingStateService,
    private rankingService: RankingsStateService,
  ) {
    // - this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
  }

  ngOnInit() {
    this.updateLanguage();
    this.rankingService.loadAllRankingsOptions();
    this.translate.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateLanguage();
    });
  }

  isNotLarge() {
    if (this.pps.isBrowserRendering() && window.screen.width >= 1280) {
      return true;
    } else {
      return false;
    }
  }

  isVeryLarge() {
    if (this.pps.isBrowserRendering()) {
      const win = window;
      const doc = document;
      const docElem = doc.documentElement;
      const body = doc.getElementsByTagName('body')[0];
      const x = win.innerWidth || docElem.clientWidth || body.clientWidth;
      if (x > 1515) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  toggleLeftBar(value = undefined) {
    if (value != undefined) {
      this.leftBarStatus = value;
    } else {
      this.leftBarStatus = !this.leftBarStatus;
    }
  }

  toggleOrganizerLeftBar(value = undefined) {
    if (value != undefined) {
      this.organizerLeftBarStatus = value;
    } else {
      this.organizerLeftBarStatus = !this.organizerLeftBarStatus;
    }
  }

  isPopUpForUserCountryAndAccount(user: IUserState, popup: IPopUpData) {
    let rightCountry = false;
    let rightAccount = true;
    if (popup.target === EPopUpTarget.premium) {
      if (user?.runner?.is_premium !== 1) {
        rightAccount = false;
      }
    }
    if (user?.runner?.country === popup.country || popup.country === 'ALL') {
      rightCountry = true;
    } else if (popup.country === 'OTHERS') {
      if (
        user?.runner?.country !== 'BE' &&
        user?.runner?.country !== 'FR' &&
        user?.runner?.country !== 'NL' &&
        user?.runner?.country !== 'LU'
      ) {
        rightCountry = true;
      }
    }
    return rightCountry && rightAccount;
  }

  login() {
    this.loginDialogService.openLoginDialog('login');
  }

  createAccount() {
    this.loginDialogService.openLoginDialog('register');
  }

  logout() {
    this.authState.logout();
  }

  /**
   * Update the language in the lang attribute of the html element.
   */
  updateLanguage(): void {
    if (this.pps.isBrowserRendering()) {
      const lang = document.createAttribute('lang');
      lang.value = this.translate.getActiveLang();
      this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);
    }
  }
}
