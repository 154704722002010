import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountdownDialogComponent } from '../countdown-dialog/countdown-dialog.component';

@Component({
  selector: 'bux-countdown-btn',
  templateUrl: './countdown-btn.component.html',
  styleUrls: ['./countdown-btn.component.scss'],
})
export class CountdownBtnComponent {
  @Input() user: any;

  constructor(public dialog: MatDialog) {}

  openCountdown() {
    this.dialog.open(CountdownDialogComponent);
  }
}
