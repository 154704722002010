<span class="chooseDefaultCountry" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center">
    <span class="label">{{ 'PARAM_CHOOSE_COUNTRY' | transloco }}</span>
    <span class="buttons">
      <button mat-button (click)="selectDefaultCountry('BE')">BE</button>
      <button mat-button (click)="selectDefaultCountry('FR')">FR</button>
      <button mat-button (click)="selectDefaultCountry('NL')">NL</button>
      <button mat-button (click)="selectDefaultCountry('LU')">LU</button>
    </span>
  </div>
  <div class="description">{{ 'PARAM_COUNTRY_EXPLANATION' | transloco }}</div>
</span>
