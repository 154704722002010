<section class="max-w-sm">
  <header class="text-lg text-center mb-4">
    <h2 class="!mt-2">{{ data.title | transloco }}</h2>
    <hr />
  </header>
  <div class="p-4">
    <bux-search-runner-trail
      [searchRunner]="true"
      [link]="false"
      [discreet]="true"
      (optionSelected)="selectRunner($event)"
    ></bux-search-runner-trail>
    <div class="h-24 flex flex-col justify-center items-center my-4">
      <ng-container *ngIf="selectedRunner; else noRunnerSelected">
        <app-runner-table-visualizer [runner]="selectedRunner"></app-runner-table-visualizer>
        <em *ngIf="toExclude" class="text-material_warn my-2">{{ data.excludeMessage | transloco }}</em>
        <em *ngIf="trackingNotAllowed" class="text-material_warn my-2"
          >{{ 'TRACKING_NOT_ALLOWED_BY_USER' | transloco }}</em
        >
      </ng-container>
      <ng-template #noRunnerSelected>
        <em>{{ 'NO_RUNNER_SELECTED' | transloco }}</em>
      </ng-template>
    </div>
    <div class="flex justify-between">
      <button mat-raised-button color="warn" [mat-dialog-close]="null">{{ 'CANCEL' | transloco }}</button>
      <button
        mat-raised-button
        color="accent"
        (click)="closeAndReturnRunner()"
        [disabled]="!selectedRunner || toExclude || trackingNotAllowed"
      >
        {{ 'CONFIRM' | transloco }}
      </button>
    </div>
  </div>
</section>
