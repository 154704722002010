import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'result-form-header',
  templateUrl: './result-form-header.component.html',
  styleUrls: ['./result-form-header.component.scss'],
})
export class ResultFormHeaderComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  @Input() result;
}
