import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AppParamsState,
  SetResultFilterCategory,
  SetResultFilterCountry,
  SetResultFilterDate,
  SetResultFilterDateEnd,
  SetResultFilterDateStart,
  SetResultFilterDistance,
  SetResultFilterDrop,
  SetResultFilterElevation,
  SetResultFilterRaceType,
  SetResultFilterRunType,
  SetResultFilterSelectedCategory,
  SetResultFilterSelectedCountry,
  SetResultFilterSelectedDistance,
  SetResultFilterSelectedDrop,
  SetResultFilterSelectedElevation,
  SetResultFilterSelectedRaceType,
  SetResultFilterSelectedRunType,
} from '@betrail-libs/app-params-state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { NouisliderComponent } from 'ng2-nouislider';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuickFilters } from './QuickFilter';
import { ResultFilterData } from './ResultFilterData';

@UntilDestroy()
@Component({
  selector: 'result-filter-dialog',
  templateUrl: './result-filter-dialog.component.html',
  styleUrls: ['./result-filter-dialog.component.scss'],
  providers: [DatePipe],
})
export class ResultFilterDialogComponent implements OnInit {
  @Select(AppParamsState['resultFilterCountry']) country$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedCountry']) selectedCountry$!: Observable<string[]>;
  @Select(AppParamsState['resultFilterDistance']) distanceFilter$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedDistance']) selectedDistance$!: Observable<number[]>;
  @Select(AppParamsState['resultFilterElevation']) elevationFilter$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedElevation']) selectedElevation$!: Observable<number[]>;
  @Select(AppParamsState['resultFilterDrop']) dropFilter$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedDrop']) selectedDrop$!: Observable<number[]>;
  @Select(AppParamsState['resultFilterCategory']) category$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedCategory']) selectedCartegory$!: Observable<string[]>;
  @Select(AppParamsState['resultFilterDate']) date$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterDateStart']) dateStart$!: Observable<number | Date>;
  @Select(AppParamsState['resultFilterDateEnd']) dateEnd$!: Observable<number | Date>;
  @Select(AppParamsState['resultFilterRunType']) runType$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedRunType']) selectedRunType$!: Observable<string[]>;
  @Select(AppParamsState['resultFilterRaceType']) raceType$!: Observable<boolean>;
  @Select(AppParamsState['resultFilterSelectedRaceType']) selectedRaceType$!: Observable<string[]>;

  @ViewChild('slider') public mySlider: NouisliderComponent;
  sliderConfig = {
    behaviour: 'drag',
    connect: true,
    start: [
      0,
      this.data.filterDialogContent === 'distance' ? 250 : this.data.filterDialogContent === 'elevation' ? 120 : 10000,
    ],
    keyboard: true,
    step: this.data.filterDialogContent === 'drop' ? 100 : 1,
    tooltips: true,
    range: {
      min: 0,
      max:
        this.data.filterDialogContent === 'distance'
          ? 250
          : this.data.filterDialogContent === 'elevation'
          ? 120
          : 10000,
    },
  };
  values = new BehaviorSubject<number[]>([]);
  range = new BehaviorSubject<any>({});
  slider = combineLatest([this.values, this.range]).pipe(
    map(([values, range]) => {
      if (this.mySlider && this.mySlider.slider) {
        this.mySlider.slider.set(values);
        this.mySlider.slider.updateOptions({
          range: range,
        });
      }
      return this.mySlider && this.mySlider.slider;
    }),
  );

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ResultFilterDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: ResultFilterData,
    public datepipe: DatePipe,
  ) {}

  type: string;

  //Dates type
  dates: number[];
  dateFilters: any[];
  datesStart = 2013;
  dateStart: number | Date;
  dateEnd: number | Date;
  dateStartText: string;
  dateEndTet: string;
  minDate: Date;
  maxDate: Date;
  //Country type
  quickFilter: QuickFilters;
  countries: any[];
  countriesSelect: any[];
  countriesSelectValue: any[] = [];
  //Category type
  categories: any[];
  categoriesSelect: any[];
  categoriesSelectValues: any[] = [];
  //Run type (= result type)
  runTypes: any[];
  runTypesQuickFilters: any[];
  runTypesSelectValues: any[] = [];
  //Race type
  raceTypes: any[];
  raceTypesQuickFilters: any[];
  raceTypesSelectValues: any[] = [];
  //Distance type
  distanceTypes: any[];
  distanceTypesQuickFilters: any[];
  distanceTypesSelectValues: any[] = [];
  //Elevation type
  elevationTypes: any[];
  elevationTypesQuickFilters: any[];
  elevationTypesSelectValues: any[] = [];
  //Drop type
  dropTypes: any[];
  dropTypesQuickFilters: any[];
  dropTypesSelectValues: any[] = [];

  disableBtn = true;

  setDateShortcutFilter(filter: string) {
    const now = new Date();
    const y = now.getFullYear();
    const m = now.getMonth();
    const d = now.getDate();
    const offset = now.getTimezoneOffset() / 60;
    let start: any;
    let end: any;
    switch (filter) {
      case 'FILTER_DATE_BEFORE_2013':
        start = new Date(1970, 0, 1, -offset, 0, 0);
        end = new Date(2012, 11, 31, 23, 59, 59);
        break;
      case 'FILTER_DATE_LAST_YEAR':
        start = new Date(y - 1, m, d, 0, 0, 1);
        end = now;
        break;
      case 'FILTER_DATE_LAST_3_YEARS':
        start = new Date(y - 3, m, d, 0, 0, 1);
        end = now;
        break;
      default:
        start = new Date(y - 1, m, d, 0, 0, 1);
        end = now;
        break;
    }
    this.store.dispatch(new SetResultFilterDate(true));
    this.store.dispatch(new SetResultFilterDateStart(start));
    this.store.dispatch(new SetResultFilterDateEnd(end));
  }

  changeDistanceRange(values: number[]) {
    this.disableBtn = false;
    if (this.type === 'distance') {
      this.distanceTypesSelectValues = values;
    }
    if (this.type === 'elevation') {
      this.elevationTypesSelectValues = values;
    }
    if (this.type === 'drop') {
      this.dropTypesSelectValues = values;
    }
  }

  close() {
    this.dialogRef.close();
  }

  setQuickFilter(type: string, value: any) {
    if (type == 'date') {
      if (
        value === 'FILTER_DATE_BEFORE_2013' ||
        value === 'FILTER_DATE_LAST_YEAR' ||
        value === 'FILTER_DATE_LAST_3_YEARS'
      ) {
        this.setDateShortcutFilter(value);
      } else {
        const date = new Date().getTimezoneOffset() / 60;
        this.store.dispatch(new SetResultFilterDate(true));
        this.store.dispatch(new SetResultFilterDateStart(new Date(value, 0, 1, -date, 0, 0)));
        this.store.dispatch(new SetResultFilterDateEnd(new Date(value, 11, 31, 23, 59, 59)));
      }
    }
    if (type == 'country') {
      this.store.dispatch(new SetResultFilterCountry(true));
      this.store.dispatch(new SetResultFilterSelectedCountry(value));
    }
    if (type == 'category') {
      this.store.dispatch(new SetResultFilterCategory(true));
      this.store.dispatch(new SetResultFilterSelectedCategory(value));
    }
    if (type == 'runType') {
      this.store.dispatch(new SetResultFilterRunType(true));
      this.store.dispatch(new SetResultFilterSelectedRunType(value));
    }
    if (type == 'raceType') {
      this.store.dispatch(new SetResultFilterRaceType(true));
      this.store.dispatch(new SetResultFilterSelectedRaceType(value));
    }
    if (type === 'distance') {
      this.store.dispatch(new SetResultFilterDistance(true));
      this.store.dispatch(new SetResultFilterSelectedDistance(value));
    }
    if (type === 'elevation') {
      this.store.dispatch(new SetResultFilterElevation(true));
      this.store.dispatch(new SetResultFilterSelectedElevation(value));
    }
    if (type === 'drop') {
      this.store.dispatch(new SetResultFilterDrop(true));
      this.store.dispatch(new SetResultFilterSelectedDrop(value));
    }
    this.close();
  }

  setFilters(type: string) {
    if (type == 'date') {
      this.store.dispatch(new SetResultFilterDate(true));
      this.store.dispatch(new SetResultFilterDateStart(new Date(this.dateStart)));
      this.store.dispatch(new SetResultFilterDateEnd(new Date(this.dateEnd)));
    }
    if (type == 'country') {
      this.store.dispatch(new SetResultFilterCountry(true));
      this.store.dispatch(new SetResultFilterSelectedCountry(this.countriesSelectValue));
    }
    if (type == 'category') {
      this.store.dispatch(new SetResultFilterCategory(true));
      this.store.dispatch(new SetResultFilterSelectedCategory(this.categoriesSelectValues));
    }
    if (type == 'runType') {
      this.store.dispatch(new SetResultFilterRunType(true));
      this.store.dispatch(new SetResultFilterSelectedRunType(this.runTypesSelectValues));
    }
    if (type == 'raceType') {
      this.store.dispatch(new SetResultFilterRaceType(true));
      this.store.dispatch(new SetResultFilterSelectedRaceType(this.raceTypesSelectValues));
    }
    if (type === 'distance') {
      this.store.dispatch(new SetResultFilterDistance(true));
      this.store.dispatch(new SetResultFilterSelectedDistance(this.distanceTypesSelectValues));
    }
    if (type === 'elevation') {
      this.store.dispatch(new SetResultFilterElevation(true));
      this.store.dispatch(new SetResultFilterSelectedElevation(this.elevationTypesSelectValues));
    }
    if (type === 'drop') {
      this.store.dispatch(new SetResultFilterDrop(true));
      this.store.dispatch(new SetResultFilterSelectedDrop(this.dropTypesSelectValues));
    }
    this.close();
  }

  disableFilter(type: string) {
    if (type == 'date') {
      this.store.dispatch(new SetResultFilterDate(false));
    }
    if (type == 'country') {
      this.store.dispatch(new SetResultFilterCountry(false));
      this.store.dispatch(new SetResultFilterSelectedCountry([]));
    }
    if (type == 'category') {
      this.store.dispatch(new SetResultFilterCategory(false));
      this.store.dispatch(new SetResultFilterSelectedCategory([]));
    }
    if (type == 'runType') {
      this.store.dispatch(new SetResultFilterRunType(false));
      this.store.dispatch(new SetResultFilterSelectedRunType([]));
    }
    if (type == 'raceType') {
      this.store.dispatch(new SetResultFilterRaceType(false));
      this.store.dispatch(new SetResultFilterSelectedRaceType([]));
    }
    if (type === 'distance') {
      this.store.dispatch(new SetResultFilterDistance(false));
      this.store.dispatch(new SetResultFilterSelectedDistance([]));
    }
    if (type === 'elevation') {
      this.store.dispatch(new SetResultFilterElevation(false));
      this.store.dispatch(new SetResultFilterSelectedElevation([]));
    }
    if (type === 'drop') {
      this.store.dispatch(new SetResultFilterDrop(false));
      this.store.dispatch(new SetResultFilterSelectedDrop([]));
    }
    this.close();
  }

  ngOnInit() {
    this.type = this.data.filterDialogContent;
    if (this.type == 'date') {
      this.initDateType();
      this.setMinAndMaxDates();
    }
    if (this.type == 'country') this.initCountryType();
    if (this.type == 'category') this.initCategoryType();
    if (this.type == 'runType') this.initRunTypeType();
    if (this.type == 'raceType') this.initRaceType();
    if (this.type === 'distance') this.initDistanceType();
    if (this.type === 'elevation') this.initElevationType();
    if (this.type === 'drop') this.initDropType();
  }

  setMinAndMaxDates() {
    this.minDate = new Date(2008, 0, 2);
    this.maxDate = new Date(2032, 0, 1);
  }

  initDateType() {
    this.disableBtn = false;
    this.quickFilter = new QuickFilters();
    this.dateFilters = this.quickFilter.dateQuickFilters;
    const date = new Date().getUTCFullYear();
    const dates = [];
    for (let i = this.datesStart; i <= date; i++) {
      dates.push(i);
    }
    this.dates = dates;
    this.dateStart$.subscribe(val => {
      this.dateStart = val;
    });
    this.dateEnd$.subscribe(val => {
      this.dateEnd = val;
    });
  }

  initCountryType() {
    this.quickFilter = new QuickFilters();
    this.countries = this.quickFilter.countryQuickFilters;
    this.countriesSelect = this.quickFilter.countrySelect;
    this.selectedCountry$.subscribe(val => (this.countriesSelectValue = [...val]));
  }

  initCategoryType() {
    this.quickFilter = new QuickFilters();
    this.categories = this.quickFilter.categoryQuickFilters;
    this.categoriesSelect = this.quickFilter.categorySelect;
    this.selectedCartegory$.subscribe(val => (this.categoriesSelectValues = [...val]));
  }

  initRunTypeType() {
    this.quickFilter = new QuickFilters();
    this.runTypes = this.quickFilter.runTypesSelect;
    this.runTypesQuickFilters = this.quickFilter.runTypeQuickFilters;
    this.selectedRunType$.subscribe(val => (this.runTypesSelectValues = [...val]));
  }

  initRaceType() {
    this.quickFilter = new QuickFilters();
    this.raceTypes = this.quickFilter.raceTypesSelect;
    this.raceTypesQuickFilters = this.quickFilter.raceTypeQuickFilters;
    this.selectedRaceType$.subscribe(val => (this.raceTypesSelectValues = [...val]));
  }

  initDistanceType() {
    this.quickFilter = new QuickFilters();
    this.distanceTypesQuickFilters = this.quickFilter.distanceQuickFilters;
    this.selectedDistance$.subscribe(val => (this.distanceTypesSelectValues = [...val]));
    this.distanceTypesSelectValues?.length > 0
      ? (this.sliderConfig.start = this.distanceTypesSelectValues)
      : (this.sliderConfig.start = [0, 250]);
  }

  initElevationType() {
    this.quickFilter = new QuickFilters();
    this.elevationTypesQuickFilters = this.quickFilter.elevationQuickFilters;
    this.selectedElevation$.subscribe(val => (this.elevationTypesSelectValues = [...val]));
    this.elevationTypesSelectValues?.length > 0
      ? (this.sliderConfig.start = this.elevationTypesSelectValues)
      : (this.sliderConfig.start = [0, 120]);
  }

  initDropType() {
    this.quickFilter = new QuickFilters();
    this.dropTypesQuickFilters = this.quickFilter.dropQuickFilters;
    this.selectedDrop$.subscribe(val => (this.dropTypesSelectValues = [...val]));
    this.dropTypesSelectValues?.length > 0
      ? (this.sliderConfig.start = this.dropTypesSelectValues)
      : (this.sliderConfig.start = [0, 120]);
  }

  hasBeenSelected(type, value) {
    if (type == 'country') {
      const found = this.countriesSelectValue.findIndex(e => e == value);
      if (found == -1) return false;
      return true;
    } else if (type == 'category') {
      const found = this.categoriesSelectValues.findIndex(e => {
        if (e === 'STT' || e === 'ULTRA_S' || e === 'ULTRA_XL') {
          return 'FILTER_TRAIL_' + e === value;
        } else {
          return e === value;
        }
      });
      if (found == -1) return false;
      return true;
    } else if (type == 'runType') {
      const found = this.runTypesSelectValues.findIndex(e => e == value);
      if (found == -1) return false;
      return true;
    } else if (type == 'raceType') {
      const found0 = this.raceTypesSelectValues.findIndex(e => e == value[0]);
      if (found0 == -1) return false;
      const found1 = this.raceTypesSelectValues.findIndex(e => e == value[1]);
      if (found1 == -1) return false;
      return true;
    } else {
      return false;
    }
  }

  changeValueSelect(type, event, value) {
    this.disableBtn = false;
    if (type == 'country') {
      if (event.checked) {
        this.countriesSelectValue.push(value);
      } else {
        this.countriesSelectValue.splice(
          this.countriesSelectValue.findIndex(e => e == value),
          1,
        );
      }
    }
    if (type == 'category') {
      if (event.checked) {
        this.categoriesSelectValues.push(value);
      } else {
        this.categoriesSelectValues.splice(
          this.categoriesSelectValues.findIndex(e => e == value),
          1,
        );
      }
    }
    if (type == 'runType') {
      if (event.checked) {
        this.runTypesSelectValues.push(value);
      } else {
        this.runTypesSelectValues.splice(
          this.runTypesSelectValues.findIndex(e => e == value),
          1,
        );
      }
    }
    if (type == 'raceType') {
      if (event.checked) {
        this.raceTypesSelectValues.push(value[0]);
        if (value[1]) this.raceTypesSelectValues.push(value[1]);
      } else {
        this.raceTypesSelectValues.splice(
          this.raceTypesSelectValues.findIndex(e => e == value[0]),
          1,
        );
        if (value[1])
          this.raceTypesSelectValues.splice(
            this.raceTypesSelectValues.findIndex(e => e == value[1]),
            1,
          );
      }
    }
  }

  formatDate(date) {
    if (date) {
      return this.datepipe.transform(date, 'dd/MM/yyyy');
    }
    return '';
  }
}
