import { Injectable } from '@angular/core';
import { asRoleId, userIsOrganizerOfThisAlias } from '@betrail-libs/shared/utils';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DrupalRole, INotif } from './../../../shared/interfaces/interfaces';
import {
  LoginAction,
  LoginCred,
  LogoutAction,
  MasqueradeAction,
  UpdateCartAction,
  UpdateUserAction,
} from './ngxs/auth.actions';
import { Basket, IUserState, Runner } from './ngxs/auth.model';
import { AuthStateModel, NgxsAuthState } from './ngxs/auth.state';

interface StateWithAuth {
  ngxsAuthState: AuthStateModel;
}

@Injectable({
  providedIn: 'root',
  deps: [Store],
})
export class AuthStateService {
  @Select(NgxsAuthState.authState)
  public authState$;

  @Select(NgxsAuthState.userField('uid'))
  public uid$: Observable<number>;

  getUid = () => this.uid$;

  getUidSnapshot = () => this.store.selectSnapshot((state: StateWithAuth) => state.ngxsAuthState.user?.uid);

  geUserAfterLoading = () =>
    this.isLoading$.pipe(
      filter(status => status === false),
      switchMap(() => this.user$),
    );

  @Select(NgxsAuthState.isLogged)
  public logged$;

  isLogged = (): Observable<boolean> => this.logged$;

  @Select(NgxsAuthState.isLoading)
  public isLoading$: Observable<boolean>;

  @Select(RouterState.url)
  private url$: Observable<string>;

  isLoading = (): Observable<boolean> => this.isLoading$;

  @Select(NgxsAuthState.userField('avatar'))
  public avatar$;

  getAvatar = (): Observable<string> => this.avatar$;

  @Select(NgxsAuthState.user)
  public user$!: Observable<IUserState>;

  getUser = (): Observable<IUserState | undefined> => this.geUserAfterLoading();

  getRunner = (): Observable<Runner> => this.geUserAfterLoading().pipe(map(v => v?.runner));

  hasRole = (role: DrupalRole) => this.geUserAfterLoading().pipe(map(user => asRoleId(user, role)));

  hasRoleSnapshot = (role: DrupalRole) => asRoleId(this.getUserSnapshot(), role);

  hasOneOfRoles = (roles: DrupalRole[]) =>
    this.geUserAfterLoading().pipe(map(user => roles.some(role => asRoleId(user, role))));

  showAdds = () =>
    this.geUserAfterLoading().pipe(
      map(user => user?.runner?.is_premium !== 1 || !user?.userProfile?.hide_other_banner),
    );

  hasOrgaRight = () =>
    combineLatest([this.geUserAfterLoading(), this.url$]).pipe(
      map(([user, url]) => asRoleId(user, DrupalRole.VerifiedOrganizer) && userIsOrganizerOfThisAlias(user, url)),
    );

  isConnected = () => this.user$.pipe(map(user => user && user.runner && +user.runner.id > 0));

  isAdminEncoderOrTheOrganizer = () => {
    return combineLatest([
      this.hasOneOfRoles([DrupalRole.Administrator, DrupalRole.Encoder]),
      this.hasOrgaRight(),
    ]).pipe(map(([isAdminOrEncoder, isTheOrga]) => isAdminOrEncoder || isTheOrga));
  };

  isSuperAdmin = () => this.getUid().pipe(map((uid: number) => [3567, 6567, 78797].includes(uid)));

  @Select(NgxsAuthState.notifs)
  public notifs$!: Observable<INotif[]>;

  getNotifs = (): Observable<any> => this.notifs$;

  @Select(NgxsAuthState.notifsstatus)
  public notifsstatus$!: Observable<any>;

  getNotifsStatus = (): Observable<any> => this.notifsstatus$;

  @Select(NgxsAuthState.userField('username'))
  public username$;

  @Select(NgxsAuthState.basket)
  public basket$!: Observable<Basket>;

  @Dispatch()
  login = (credential: LoginCred) => new LoginAction(credential);

  @Dispatch()
  logout = () => new LogoutAction();

  @Select(NgxsAuthState.runnerAlias)
  runnerAlias$: Observable<string>;

  @Select(NgxsAuthState.allOrders)
  allOrders$: Observable<any>;

  getAllOrders() {
    return this.allOrders$;
  }

  @Dispatch()
  masquerade(uid: number) {
    return new MasqueradeAction(uid);
  }

  getUserName = (): Observable<string> => this.username$;

  emptyUserBasket = () => this.store.dispatch(new UpdateCartAction([]));

  updateUser = (user: Partial<any>) => this.store.dispatch(new UpdateUserAction({ user }));

  @Dispatch()
  updateCart = (items: any[]) => new UpdateCartAction(items); // this.store.dispatch(new UpdateUserAction({ user })); TODO:

  getImageStyleUrl = (fid: any, style: string) => undefined; //this.userService.getImageStyle(fid, style); TODO:

  getUserSnapshot = () => this.store.selectSnapshot((state: StateWithAuth) => state.ngxsAuthState.user);

  isLoggedInSnapshot = () => this.store.selectSnapshot((state: StateWithAuth) => state.ngxsAuthState.loggedIn);

  constructor(private store: Store) {}
}
