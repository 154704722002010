import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterElement } from '../filterElement.model';

@Component({
  selector: 'calendar-filter-text',
  templateUrl: './calendar-filter-text.component.html',
  styleUrls: ['./calendar-filter-text.component.scss'],
})
export class CalendarFilterTextComponent implements OnInit {
  summary: string;

  @Input() filteredElements: FilterElement[];
  @Input() value: string;
  @Input() description: string;

  @Output() textFilter = new EventEmitter();
  @Output() updateSummary = new EventEmitter();

  ngOnInit() {
    this.summary = this.value;
  }

  changeText(value) {
    this.textFilter.emit(value);
    const summary = '"' + value + '"';
    this.updateSummary.emit(summary);
  }
}
