import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { SettingsService } from '../settings.service';
import { tap, catchError, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import produce from 'immer';
import { KeyTranslations, I18nModel } from './i18n.model';
import {
  LoadBundle,
  AddI18nKey,
  UpdateTrad,
  LoadMissingBundle,
  UseLang,
  SetUrlLang,
  ImportFile,
  updateAllTranslations,
} from './i18n.action';
import { Injectable } from '@angular/core';

@State<I18nModel>({
  name: 'i18n',
  defaults: { avaibleLang: ['fr', 'nl', 'en'], loadedLang: [], bundles: {}, urlLang: undefined },
})
@Injectable()
export class I18nState {
  constructor(private settingService: SettingsService, private translate: TranslocoService) {}

  @Selector()
  static bundleArray(state: I18nModel): KeyTranslations[] {
    // let keyMap = new Map<string, { [LANG_KEY: string]: string }>();
    const keys = {};
    for (const lang of state.loadedLang) {
      for (const key of Object.keys(state.bundles[lang])) {
        const keyTranlsations = keys[key] || {};
        keyTranlsations[lang] = state.bundles[lang][key];
        keys[key] = keyTranlsations;
      }
    }
    const a: KeyTranslations[] = [];
    for (const key of Object.keys(keys)) {
      a.push({ key, ...keys[key] });
    }
    return a;
  }

  @Selector()
  static langs(state: I18nModel): string[] {
    return state.avaibleLang;
  }

  static getKeyTranslation(key: string) {
    return createSelector([I18nState], (state: I18nModel) => {
      return this.bundleArray(state).find(keyTr => keyTr.key === key);
    });
  }

  @Action(SetUrlLang)
  setUrlLang(ctx: StateContext<I18nModel>, action: SetUrlLang) {
    ctx.setState(
      produce((draft: I18nModel) => {
        draft.urlLang = action.lang;
      }),
    );
  }

  @Action(LoadBundle)
  loadBundle(ctx: StateContext<I18nModel>, action: LoadBundle) {
    ctx.getState().loadedLang;
    if (!ctx.getState().loadedLang.find(lang => action.lang === lang)) {
      ctx.setState(
        produce((draft: I18nModel) => {
          draft.loadedLang.push(action.lang);
        }),
      );
      return this.settingService.getBundle(action.lang).pipe(
        tap(bundle => {
          ctx.setState(
            produce((draft: I18nModel) => {
              if (draft.bundles === undefined) {
                draft.bundles = {};
              }
              draft.bundles[action.lang] = bundle;
            }),
          );
        }),
      );
    } else {
      return;
    }
  }

  @Action(UseLang)
  use(ctx: StateContext<I18nModel>, action: UseLang) {
    // force using url lang
    action.lang = ctx.getState().urlLang || action.lang;
    this.translate.setActiveLang(action.lang);
  }

  @Action(AddI18nKey)
  addKey(ctx: StateContext<I18nModel>, action: AddI18nKey) {
    const state = ctx.getState();
    ctx.setState(
      produce(ctx.getState(), (draft: I18nModel) => {
        draft.bundles.en[action.key] = '';
      }),
    );

    this.translate.setTranslation({ [action.key]: '' });
  }

  @Action(UpdateTrad)
  updateTrad(ctx: StateContext<I18nModel>, action: UpdateTrad) {
    //todo save on backend
    return this.settingService.updateTrad(action).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), (draft: I18nModel) => {
            draft.bundles[action.lang][action.key] = action.value;
          }),
        );
      }),
      map(() => ctx.dispatch(new UseLang(this.translate.getActiveLang()))),
      catchError(err => {
        throw new Error(err);
      }),
    );
  }

  @Action(updateAllTranslations)
  updateAllTranslations(ctx: StateContext<I18nModel>, action: updateAllTranslations) {
    return this.settingService.updateAllTranslations(action.key, action.data).pipe(
      tap(result => {
        this.translate.setTranslationKey(
          action.key,
          action.data[this.translate.getActiveLang()],
          this.translate.getActiveLang(),
        );
        return result;
      }),
      map(() => ctx.dispatch(new UseLang(this.translate.getActiveLang()))),
      catchError(err => {
        throw new Error(err);
      }),
    );
  }

  @Action(LoadMissingBundle)
  loadMissingBundle(ctx: StateContext<I18nModel>, action: LoadMissingBundle) {
    const state = ctx.getState();
    const missingLang = state.avaibleLang.filter(lang => !state.loadedLang.find(loadedLang => loadedLang === lang));
    return ctx.dispatch(missingLang.map(lang => new LoadBundle(lang)));
  }

  @Action(ImportFile)
  importFile(ctx: StateContext<I18nModel>, action: ImportFile) {
    return this.settingService
      .importFile(action.file)
      .pipe(tap(() => ctx.dispatch(ctx.getState().avaibleLang.map(lang => new LoadBundle(lang)))));
  }
}
