import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class BackgroundUriService {
  constructor(private sanitizer: DomSanitizer) {}

  getSafeStyle(uri: string) {
    try {
      return this.sanitizer.bypassSecurityTrustStyle(this._convertStyle(uri));
    } catch (err) {
      return '';
    }
  }

  getSafeUri(uri: string) {
    try {
      return uri ? this.sanitizer.bypassSecurityTrustUrl(uri) : undefined;
    } catch (err) {
      return undefined;
    }
  }

  private _convertStyle(uri: string) {
    return uri ? `url('${uri}')` : '';
  }
}
