import { Component } from '@angular/core';

@Component({
  selector: 'app-rankings-no-sub-dialog',
  template: `
    <div class="max-w-sm">
      <h2 class="text-lg text-center !mt-2">Filtre par abonnements</h2>
      <hr />
      <div class="p-4">
        <p class="mt-4 mb-8 text-center">{{ 'RANKING_NO_SUB_MSG' | transloco }}</p>
        <div class="flex justify-between">
          <button mat-raised-button color="accent" [routerLink]="['/', 'runner', 'my-page', 'news-feed', 'settings']">
            {{ 'SUBSCRIBE_TO_A_RUNNER' | transloco }}
          </button>
          <button mat-raised-button mat-dialog-close>{{ 'CLOSE' | transloco }}</button>
        </div>
      </div>
    </div>
  `,
})
export class RankingsNoSubDialogComponent {}
