<div class="stepContainer">
  <h2 class="centeredAligned">{{ 'WS_CONFIRM_EMAIL' | transloco }}</h2>
  <div class="message centeredAligned">
    {{ 'WS_EMAIL_CONFIRM_EXPLANATION' | transloco }} : <br />
    <p class="email">{{ user.mail }}</p>
  </div>
  <div class="message centeredAligned">
    {{ 'WS_EMAIL_DATE' | transloco }} : {{ user.created * 1000 | date }}. {{ 'WS_CLICK_ON_LINK_EMAIL' | transloco }}.
    <br />
    <br />{{ 'CONFIRMED_EMAIL_REQUIRED' | transloco }}.
  </div>
  <div class="message centeredAligned">
    <button [disabled]="buttonDisabled" (click)="reSendConfirmationEmail()" mat-button class="bt-button light-button">
      {{ buttonText | transloco }}
    </button>
  </div>
  <div class="buttonContainer">
    <button class="bt-button blue" (click)="nextStep()" mat-raised-button>{{ 'WS_OK_DONE' | transloco }}</button>
    <div class="later" (click)="nextStep()">{{ 'WS_DO_IT_LATER' | transloco }}</div>
  </div>
</div>
