<event-header [trail]="trail"></event-header>
<ng-container *ngIf="(user$ | async)?.organizations; let organizations">
  <div class="default-form">
    <h2 class="centeredAlign">{{ 'LINK_TO_ORGANIZATION' | transloco }}</h2>
    <div *ngIf="organizations.length === 0">
      <div class="message title">{{ 'ACCOUNT_NOT_LINKED_TO_ORGA' | transloco }}</div>
    </div>

    <div *ngIf="organizations.length === 1">
      <div
        class="message title"
        [innerHTML]="'LINK_RACE_TO_ORGA' | transloco: { trailTitle: trail?.title, orgaName: ((user$ | async)?.organizations)[0].name }"
      ></div>
      <div class="actions">
        <button class="bt-button orange" mat-raised-button (click)="confirmLinkToOrga(organizations[0])">
          {{ 'YES_I_CONFIRM' | transloco }}
        </button>
        <div class="alternative-option">
          <a (click)="goToCreateOrgaForm()">{{ 'NO_CREATE_NEW_ORGA' | transloco }}</a>
        </div>
      </div>
      <div class="actions bottom">
        <button mat-button class="bt-button m-0" (click)="cancel()">{{ 'CANCEL' | transloco }}</button>
      </div>
    </div>

    <div *ngIf="organizations.length > 1">
      <div class="message title" [innerHTML]="'CHOOSE_ORGA_FOR_TRAIL' | transloco: { trailTitle: trail?.title }"></div>

      <div class="options">
        <mat-radio-group (change)="setChosenOrganization($event)">
          <mat-radio-button *ngFor="let orga of organizations" [value]="orga?.id"> {{ orga?.name }} </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="actions bottom">
        <button mat-button class="bt-button m-0" (click)="cancel()">{{ 'CANCEL' | transloco }}</button>
        <button
          mat-button
          class="bt-button blue"
          (click)="confirmLinkToOrga(chosenOrganization)"
          [disabled]="chosenOrganization === undefined"
        >
          {{ 'CONFIRM' | transloco }}
        </button>
        <div class="additional-links">
          <a (click)="goToCreateOrgaForm()">{{ 'CREATE_NEW_ORGANIZATION' | transloco }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
