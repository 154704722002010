import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as utils from '@betrail-libs/shared/utils';
import { Race } from '@betrail-libs/shared/interfaces/race.model';
import { DateTime } from 'luxon';
import { AuthStateService } from '@betrail-libs/auth-state';
import { DrupalRole } from '@betrail-libs/shared/interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BetrailUxFormsService {
  constructor(private formBuilder: UntypedFormBuilder, private auth: AuthStateService) {}

  buildTrailForm() {
    let trailFormGroup = {
      id: [0],
      title: ['', Validators.required],
      image: [''],
      country: ['', Validators.required],
      postal_code: ['', Validators.required],
      place: ['', Validators.required],
      website: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      facebook: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      organizer: [''],
      organizer_email: [''],
      organizer_phone: [''],
      closed: [''],
      gpx_option: [0],
      near_countries: [''],
      other_used_countries: [''],
    };

    return this.formBuilder.group(trailFormGroup);
  }

  buildOrganizationForm() {
    let organizationFormGroup = {
      id: [0],
      name: ['', Validators.required],
      email: [''],
      legal_form: ['', Validators.required],
      phone: [''],
      mango_user_id: [''],
      web_site: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      facebook_page: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      description: [''],
    };

    return this.formBuilder.group(organizationFormGroup);
  }

  buildEventForm() {
    let eventFormGroup = {
      id: [0],
      event_name: [''],
      date: ['', Validators.required],
      end_date: [''],
      geo_lat: [''],
      geo_lon: [''],
      departure_address: [''],
      edition_number: [''],
      rules_url: [''],
      several_days: [''],
      prefill: [true],
      onsite_registration: [false],
      preregistration: [true],
      registration_url: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      registration_start_date: [],
      registration_end_date: [''],
      out_of_criteria: [''],
      canceled: [''],
      no_next_edition: [''],
      external_registration_allowed: [false],
      onsite_registration_start_time: [''],
      onsite_registration_end_time: [''],
      distances: [1],
      timer: [''],
    };
    utils.LIST_AMENITIES.map(amenity => {
      eventFormGroup[amenity] = [''];
    });

    return this.formBuilder.group(eventFormGroup);
  }

  buildRaceForm(id: string | number = 0, evid = undefined) {
    let raceFormGroup = {
      id: [id],
      date: [''],
      distance: ['', Validators.required],
      race_name: [''],
      elevation: ['', Validators.required],
      departure_time: [''],
      price: [''],
      offroad: [90, Validators.required],
      trail: [''],
      onsite_price: [''],
      refreshments: ['', Validators.required],
      utmb_points: [''],
      max_runners: [''],
      night: [''],
      trailcup_only: [''],
      offroad_certified: [''],
      altitude_min: [''],
      altitude_max: [''],
      route_type: [1],
      race_type: ['solo'],
      timing_type: ['puces'],
      country: [''],
      official_ranking_url: [''],
      paid: [''],
      canceled: [''],
      comment: [''],
    };
    if (evid !== undefined) {
      raceFormGroup['evid'] = [evid];
    }

    return this.formBuilder.group(raceFormGroup);
  }

  buildPathForm(id: string | number = 0) {
    let pathFormGroup = {
      id: [id],
      raid: [''],
      uid: [''],
      title: ['', Validators.required],
      type: ['', Validators.required],
      private_sections: [2, Validators.required],
      marked: [0, Validators.required],
      fallback: [0, Validators.required],
      display: ['all', Validators.required],
      downloadable: [1, Validators.required],
      zoomable: [1, Validators.required],
      show_background: [1, Validators.required],
      distance_meters: [''],
      pos_elevation: [''],
      neg_elevation: [''],
      min_altitude: [''],
      max_altitude: [''],
      path: [''],
      elevation: [''],
    };
    return this.formBuilder.group(pathFormGroup);
  }

  setRaceFormValues(race: Race, prefillForNextEdition = false) {
    const isAdmin = this.auth.hasRoleSnapshot(DrupalRole.Administrator);
    const isEncoder = this.auth.hasRoleSnapshot(DrupalRole.Encoder);
    const nowInSec = Math.floor(Date.now() / 1000);

    let raceDate;
    if (race.date > 0) {
      raceDate = DateTime.fromSeconds(race.date).toFormat('YYYY-mm-dd');
    }

    return this.formBuilder.group({
      id: prefillForNextEdition === true ? 0 : race.id,
      date: raceDate,
      distance: race.distance,
      race_name: race.race_name,
      elevation: race.elevation,
      departure_time: utils.toHMS(race.departure_time, 'hm'),
      price: race.price,
      offroad: [
        { value: race.offroad, disabled: !isAdmin && !isEncoder && !prefillForNextEdition },
        Validators.required,
      ],
      trail: [{ value: race.trail, disabled: !isAdmin && !isEncoder && !prefillForNextEdition }],
      onsite_price: race.onsite_price,
      refreshments: race.refreshments,
      utmb_points: race.utmb_points,
      max_runners: race.max_runners,
      night: race.night,
      trailcup_only: race.trailcup_only === 1 ? true : false,
      offroad_certified: race.offroad_certified,
      altitude_min: race.min_altitude,
      altitude_max: race.max_altitude,
      route_type: race.route_type || '1',
      race_type: race.race_type || 'solo',
      timing_type: race.timing_type || 'puces',
      country: race.country,
      official_ranking_url: prefillForNextEdition === true ? '' : race.official_ranking_url,
      paid: '',
      canceled: false,
      comment: { value: race.comment, disabled: !isAdmin && !isEncoder && nowInSec > race.date },
    });
  }

  buildBannerForm(id: string | number = 0) {
    let bannerFormGroup = {
      id: [id],
      page: ['', Validators.required],
      weeks: ['', Validators.required],
    };

    return this.formBuilder.group(bannerFormGroup);
  }

  buildTimerForm(id: string | number = 0) {
    let timerFormGroup = {
      id: [id],
      title: ['', Validators.required],
      country: ['', Validators.required],
      email: [''],
      phone: [''],
      postal_code: ['', Validators.required],
      place: ['', Validators.required],
      website: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      default_results_page: ['', Validators.pattern('^(https?://)?([a-z]+\\.|https?://)(.+)\\.(.+)$')],
      logo: [''],
      dont_exist: [false],
    };

    return this.formBuilder.group(timerFormGroup);
  }

  buildRunnerMissingDataForm(runner, newValues: any = {}) {
    let runnerMissingDataFormGroup = {
      firstname: [
        runner && runner.firstname ? runner.firstname : (newValues && newValues.firstname) || '',
        Validators.required,
      ],
      lastname: [runner && runner.lastname ? runner.lastname : '', Validators.required],
      place: [runner && runner.place ? runner.place : (newValues && newValues.place) || '', Validators.required],
      postal: [runner && runner.postal_code ? runner.postal_code : '', Validators.required],
      country: [runner && runner.country ? runner.country : '', Validators.required],
      nationality: [runner && runner.nationality ? runner.nationality : '', Validators.required],
      gender: [
        runner && runner.gender
          ? runner.gender == 1 || runner.gender == '1'
            ? '1'
            : '0'
          : newValues && newValues.gender && newValues.gender == 'FEMALE'
          ? '1'
          : '0',
        Validators.required,
      ],
      birthdate: [
        newValues && newValues.birthdate
          ? newValues.birthdate
          : runner && runner.birthdate
          ? runner.birthdate * 1000
          : '',
        Validators.required,
      ],
    };
    return this.formBuilder.group(runnerMissingDataFormGroup);
  }
}
